<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">Historial de gestión</div>
  <mat-divider></mat-divider>
  <div class="my-4" style="font-weight: 400; font-size: 16px;">
    Acá podrá ver el histórico de gestión; dando clic en ver, podrá visualizar mas detalles del correo.
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Asunto</mat-label>
        <input [formControl]="filterSubject" (blur)="onChangeFilter($event)" matInput placeholder="Buscar por asunto">
        <span matSuffix class="fi-rr-search fs-5"></span>
        <mat-error *ngIf="filterSubject.errors">
          <span *ngIf="filterSubject.errors.maxlength">Maximo 100 caracteres para buscar</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-12">
      <table mat-table [dataSource]="dataManagementHistory">
        <ng-container *ngFor="let column of configColumnsTable">
          <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell *matCellDef="let element">
              {{ element[column.key] }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell width="10%" class="text-center" *matCellDef="let element">
              <button (click)="onClickShowManagementSelected(element)" mat-icon-button>
                <span class="fi-rr-eye"></span>
              </button>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
        <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
        <tr class="mat-row" style="background: white" *matNoDataRow>
          <td class="mat-cell" colspan="5">No existe información relacionada</td>
        </tr>
      </table>
      <mat-paginator (page)="onChangePage($event)" [length]="configPaginator.length"
        [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>