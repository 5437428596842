import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { SnackBarService } from 'src/app/shared/snack-bar-service/snack-bar.service';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {
  questions: any[] = [
    { typeInput: 'input', type: 'hidden', key: 'id', label: 'Id de la etiqueta', rules: null, value: null },
    { typeInput: 'input', type: 'text', key: 'name', label: 'Nombre de la etiqueta', rules: { required: true, maxLength: 45 }, value: null },
    { typeInput: 'select', key: 'idAccountEmail', label: 'Cuenta de correo', rules: { required: true }, value: null, options: this.data.emailsConfig },
    { typeInput: 'html', key: 'content', label: 'Contenido de la etiqueta', rules: { required: true }, value: null },
    { typeInput: 'input', type: 'hidden', key: 'state', label: 'Estado de la etiqueta', rules: null, value: null }
  ];

  constructor(
    private crudService: CrudService,
    private matDialogRef: MatDialogRef<LabelComponent>,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.label) {
      for (const key in this.data.label) {
        this.questions.forEach(question => {
          if (question.key === key) question.value = this.data.label[key];
        });
      }
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que guarda o actualiza una etiqueta.
    * @param $event Evento emitido por el componente hijo. 
  */
  onSubmitFormLabel($event: any): void {
    if (!this.data.label) {
      this.crudService.postData({ url: `${this.data.url}labels/store`, formData: $event }).subscribe(res => {
        this.snackBarService.showNotification({ message: res.message });
        this.matDialogRef.close(res);
      });
    } else {
      this.crudService.putData({ url: `${this.data.url}labels/update/${$event.id}`, formData: $event }).subscribe(res => {
        this.snackBarService.showNotification({ message: res.message });
        this.matDialogRef.close(res);
      });
    }
  }
}