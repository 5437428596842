import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

const { GESTIONADOR_CORREOS } = environment;
const { GESTIONADOR_CORREOS_PYTHON } = environment;

@Injectable({
  providedIn: 'root'
})
export class EmailSupportService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2024-05-23
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-23
    * Metodo que retorna los logs de errores registrados en las gestiones de las cuentas de correo. 
    * @param paginator Configuracion del paginador.
    * @param idEmailConfig Cuenta de correo seleccionada.
    * @param dateIn Fecha inicial.
    * @param dateEnd Fecha final.
  */
  getLogsErrorsEmails({ paginator, idEmailConfig = null, dateIn = null, dateEnd = null, subject = null, from = null }): Observable<any> {
    let queryParams = new HttpParams()
      .append('page', paginator.pageIndex + 1)
      .append('perPage', paginator.pageSize);
    if (idEmailConfig) queryParams = queryParams.append('id_email_config', idEmailConfig);
    if (dateIn) queryParams = queryParams.append('date_in', moment(dateIn).format('YYYY/MM/DD'));
    if (dateEnd) queryParams = queryParams.append('date_end', moment(dateEnd).format('YYYY/MM/DD'));
    if (subject) queryParams = queryParams.append('asunto', subject);
    if (from) queryParams = queryParams.append('de', from);
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}logsemail/showall`, { params: queryParams }).pipe(
      map((res) => {
        const formatData = {
          data: res.data.data.map((item: any) => {
            const formatItem = { 
              ...item, 
              fecha_correo: moment(item.fecha_correo).format('YYYY/MM/DD - HH:mm'),
              state: item.state === 0 ? 2 : 1 
            };
            delete formatItem.state;
            return formatItem;
          }),
          total: res.data.total
        };
        return formatData;
      }),
      catchError(error => this.handleError(error))
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-27
    * Metodo que consulta los correos de las distintas cuentas registradas. 
    * @param email_config_id Cuenta de correo seleccionada.
    * @param subject Nombre del asunto del correo.
    * @param date_start Fecha inicial del rango.
    * @param date_end Fecha final del rango.
  */
  searchEmailExternal({ email_config_id, subject = null, date_start, date_end, time_start = null, time_end = null, from = null, uid = null }): Observable<any> {
    const formData = new FormData();
    formData.append('email_config_id', email_config_id);
    formData.append('date_start', moment(date_start).format('YYYY-MM-DD'));
    formData.append('date_end', moment(date_end).format('YYYY-MM-DD'));
    if (subject) formData.append('subject', subject);
    if (from) formData.append('from', from);
    if (uid) formData.append('uid', uid);
    if (time_start) formData.append('time_start', time_start);
    if (time_end) formData.append('time_end', time_end);
    return this.httpClient.post(`${GESTIONADOR_CORREOS_PYTHON}EmailSearch/getEmail`, formData).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-27
    * Metodo que agrega el correo externo a las bases de datos internas. 
    * @param email_config_id Cuenta de correo seleccionada.
    * @param uid Id unico del correo.
  */
  addEmailExternalAccount({ email_config_id, uid }): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email/downloademail`, { email_config_id, uid }).pipe(
      catchError(this.handleError)
    );
  }
}