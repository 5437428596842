<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Gestión de grupos de contactos</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535; font-weight: 400; font-size: 16px;">
        En este espacio podrás ver y gestionar los grupos que <br /> tienes creados
      </p>
    </div>
  </div>
  <div class="row mt-5 justify-content-between">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Cuenta de correo</mat-label>
        <mat-select (selectionChange)="onChangeSelectFilter($event)">
          <mat-option *ngFor="let item of emailsConfig" [value]="item.id">{{ item.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Nombre del grupo</mat-label>
        <input [formControl]="filter" (blur)="onChangeFilter($event)" matInput placeholder="Nombre del grupo">
        <span matSuffix class="fi-rr-search fs-5"></span>
        <mat-error *ngIf="filter.errors">
          <span *ngIf="filter.errors.maxlength">Máximo 30 caracteres</span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <button (click)="onClickNewContactGroup()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">Crear grupo de contactos
        <span class="fi-rr-add ms-3"></span>
      </button>
    </div>
  </div>
  <div class="mt-5">
    <app-table-contact-group [dataContactGroup]="dataContactGroup" [configPaginator]="configPaginator"
      [viewToSelectedGroups]="false" (onChangePaginator)="onChangePaginator($event)"
      (updateContactGroupsList)="getContactGroup()"></app-table-contact-group>
  </div>
</div>