import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewMessageComponent } from '../new-message/new-message.component';
import { CategoryComponent } from '../category/category.component';
import { StateComponent } from '../state/state.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AnsComponent } from '../ans/ans.component';
import { NewMessageV2Component } from '../new-message-v2/new-message-v2.component';

@Component({
  selector: 'app-left-menu-main',
  templateUrl: './left-menu-main.component.html',
  styleUrls: ['./left-menu-main.component.css'],
})
export class LeftMenuMainComponent implements OnInit {
  @Input() emailsConfigSelected: any[] = [];
  @Input() keyTraySelected: number = null;
  @Input() emailsConfig: any[] = [];
  @Input() traysEmail: any[] = [];
  @Input() categories: any[] = [];
  @Input() statesTypesMails: any[] = [];
  @Input() emailsExpiration: any[] = [];
  @Input() keyCategorySelected: number = null;
  @Input() keyStateSelected: number = null;
  @Input() keyAnsSelected: number = null;
  @Input() checkAllAccountEmails: boolean = false;
  @Input() folders: any[] = [];
  @Output() onChangeTraySelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSendMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() onStoreCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStoreState: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRefreshDataPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeEmailsConfigSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeCategorySelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() onChangeStateSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() onChangeAnsSelected: EventEmitter<number> = new EventEmitter<number>();
  dataFormNewMessage: any = null;

  constructor(private matDialog: MatDialog) { }

  ngOnInit(): void {

  }

  /**
    * @author Fabian Duran
    * @createdate 2023-06-23
    * Metodo que permite ejecutar la modal para crear un nuevo mensaje. 
  */
  onClickRefreshView(): void {
    this.onRefreshDataPage.emit();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-23
    * Metodo que permite ejecutar la modal para crear un nuevo mensaje. 
  */
  onClickCreateMessage(): void {
    this.matDialog.open(NewMessageComponent, {
      width: '70%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-email-manager',
      disableClose: true,
      data: {
        email: null
      }
    }).afterClosed().subscribe(res => {
      if (res) this.onSendMessage.emit();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-23
    * Metodo que permite emitir la vandeja seleccionada desde el componente. 
    * @param typeEmailManager Tipo de gestion seleccionada. 
  */
  onClickTraySelect(typeEmailManager: any): void {
    this.onChangeTraySelected.emit({ trayId: typeEmailManager.id, trayKey: typeEmailManager.key });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-23
    * Metodo que permite ejecutar la modal para ver una categoria.
    * @param category Categoria seleccionada de una lista. 
  */
  onClickShowCategory(category: any = null): void {
    this.matDialog.open(CategoryComponent, {
      width: '35%',
      autoFocus: false,
      panelClass: 'dialog-padding-category',
      data: {
        titleDialog: 'Ver categoría',
        category,
        viewOnly: true,
        emailsConfig: this.emailsConfig.map(item => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        })
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-23
    * Metodo que permite validar la opcion a la que se le dio clic en la sección de vencimiento. 
    * @param ans Opcion de vencimiento seleccionado. 
  */
  onClickCreateOrEditAns(ans: any): void {
    this.matDialog.open(AnsComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-ans',
      data: {
        titleDialog: 'Ver ANS',
        ans,
        viewOnly: true,
        emailsConfig: this.emailsConfig.map(item => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        })
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que permite abrir la modal de gestion de estados.  
    * @param state Estado seleccionado de la lista. 
  */
  onClickCreateOrEditState(state: any = null): void {
    this.matDialog.open(StateComponent, {
      width: '35%',
      autoFocus: false,
      panelClass: 'dialog-padding-edit-state',
      data: {
        titleDialog: 'Ver estado',
        state,
        viewOnly: true
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-28
    * Metodo que permite añadir un email config a la lista de correos seleccionados.  
    * @param $event Evento emitido por el checkbox. 
    * @param emailConfig Correo seleccionado de la lista.  
  */
  onClickCheckboxEmailConfig($event: MatCheckboxChange, emailConfig: any): void {
    this.onChangeEmailsConfigSelected.emit({ allSelectedEmails: false, checked: $event.checked, emailConfig });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-28
    * Metodo que permite seleccionar todos los correos de la lista.  
    * @param $event Evento emitido por el checkbox. 
  */
  onClickChecboxAllEmailsConfig($event: MatCheckboxChange): void {
    this.onChangeEmailsConfigSelected.emit({ allSelectedEmails: true, checked: $event.checked });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-28
    * Metodo que permite validar si un correo esta seleccionado.   
    * @param email Correo proveniente de la lista de configuracion de correos. 
    * @returns Valor booleano si cumple con la condicion del filtro.   
  */
  ifSelectedEmail(email: any): boolean {
    return this.emailsConfigSelected.filter((item: any) => email.id_email === item.id_email).length > 0 ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-03
    * Metodo que envia al componente padre la categoria seleccionada de la lista. 
    * @param category Categoria seleccionada.    
  */
  onClickCategorySelect(category: any = null): void {
    if (category) this.onChangeCategorySelected.emit(category.id);
    else this.onChangeCategorySelected.emit(0);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-03
    * Metodo que envia al componente padre el estado seleccionado de la lista. 
    * @param state Estado seleccionado.    
  */
  onClickStateSelect(state: any = null): void {
    if (state) this.onChangeStateSelected.emit(state.id);
    else this.onChangeStateSelected.emit(0);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-30
    * Metodo que envia al componente padre el ans seleccionado de la lista. 
    * @param ans ANS seleccionado.    
  */
  onClickAnsSelect(ans: any = null): void {
    if (ans) this.onChangeAnsSelected.emit(ans.id);
    else this.onChangeAnsSelected.emit(0);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-23
    * Metodo que permite ejecutar la modal para crear un nuevo mensaje. 
  */
  onClickCreateMessageV2(): void {
    this.matDialog.open(NewMessageV2Component, {
      width: '70%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-email-manager',
      data: {
        email: this.dataFormNewMessage
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        if (typeof res === 'string') {
          this.dataFormNewMessage = null;
          this.onSendMessage.emit();
        } else {
          this.dataFormNewMessage = res.dataFormNewMessage;
        }
      }
    });
  }
}