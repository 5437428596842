import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/rest/auth.service';

@Component({
  selector: 'app-landing-page-email-manager',
  templateUrl: './landing-page-email-manager.component.html',
  styleUrls: ['./landing-page-email-manager.component.css']
})
export class LandingPageEmailManagerComponent implements OnInit {
  userName: string = '';

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.userName = this.authService.getUser().rrhh.name;
  }
}