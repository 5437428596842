<div class="row">
  <div class="col-10">
    <h3 class="title" mat-dialog-title>Escuchar grabación</h3>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i class="fi-rr-cross-small" style="cursor:pointer; font-size: 20px; color: #353535;" mat-icon-button mat-dialog-close></i>
  </div>
</div>


<mat-dialog-content class="mat-typography">
  <mat-divider></mat-divider>

  <audio class="mt-4 w-100" controls>
    <source [src]="dialog_data.url" type="audio/mpeg">
    Tu navegador no soporta la reproducción de audio.
  </audio>

</mat-dialog-content>

<mat-dialog-actions align="end" class="mt-4 mb-4 actions" style="padding-right: 0 !important;">
  <button mat-raised-button color="primary" mat-dialog-close class="me-2 button-dimensions">Cerrar</button>
</mat-dialog-actions>
