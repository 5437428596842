import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { EmailService } from '../../services/email.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';
import { ViewGraphStatesComponent } from '../../components/view-graph-states/view-graph-states.component';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { fadeInOnEnterAnimation, fadeOutAnimation } from 'angular-animations';
import { ViewEmailsStateComponent } from '../../components/view-emails-state/view-emails-state.component';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Component({
  selector: 'app-supervisor-page-main-v2',
  templateUrl: './supervisor-page-main-v2.component.html',
  styleUrls: ['./supervisor-page-main-v2.component.css'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutAnimation()
  ]
})
export class SupervisorPageMainV2Component implements OnInit, OnDestroy {
  formReport: FormGroup = new FormGroup({
    emailAccount: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    months: new FormControl([], [Validators.required])
  });
  cardsReport: any[] = [
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Correos', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-rr-arrow-left fs-1', value: 0, text: 'Entrantes', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-rr-arrow-right fs-1', value: 0, text: 'Salientes', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-rr-check fs-1', value: 0, text: 'Cerrados', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Borradores', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Eliminados', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Prioritarios', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Reenviados', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Replica', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Spam', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'B. salida', col: 'col-sm-3', styleText: { 'font-size': '24px', 'font-weight': '700' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
  ];
  dataTable: any[] = [];
  configColumnsTable: any[] = [
    { name: 'Asesor', key: 'name' },
    { name: 'Entrada', key: 'entradas' },
    { name: 'Enviados', key: 'enviados' },
    { name: 'Por enviar', key: 'por_enviar' },
    { name: 'Cerrados', key: 'cerrados' },
    { name: 'Total', key: 'total' },
    { name: 'Ver', key: 'actions' },
  ];
  configPaginator: any = { length: 0, pageIndex: 0, pageSize: 5 };
  keysColumnsTable: string[] = [];
  emailsConfig: any[] = [];
  years: any[] = [];
  months: any[] = [];
  listToAdvisers: any[] = [];
  adviserSelected: string = '';
  cardsMonths: any[] = [];
  reportByMonths: any[] = [];
  stateButtonSubmit: boolean = false;
  runCallReportCard: boolean = false;
  messageWhenUpdateReportCard: string | null = 'Actualizando información...';
  messageWhenUpdateReportTable: string | null = 'No hay información disponible';
  messageWhenUpdateReportTableStates: string | null = 'No hay información disponible';
  suscribeInterval: Subscription | null = null;
  dataTableSates: any[] = [];

  constructor(
    private location: Location,
    private emailService: EmailService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private crudService: CrudService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
    this.keysColumnsTable = this.configColumnsTable.map(item => item.key);
    this.getYears();
  }

  ngOnDestroy(): void {
    if (this.suscribeInterval) this.suscribeInterval.unsubscribe();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que se ejecuta al hacer click sobre el boton descargar reporte. 
  */
  onClickDownloadReport(): void {
    if (this.formReport.valid) {
      const formatDataForm = { ...this.formReport.value, months: this.formReport.value.months.toString() };
      this.emailService.downloadExcelReport(formatDataForm).subscribe(res => {
        importedSaveAs(res, 'Reporte');
      });
    } else {
      this.formReport.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que emite el cambio sobre la configuracion de la paginacion.
    * @param $event Evento emitido por el paginador. 
  */
  onChangePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getTableReport();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formReport.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que genera el reporte sobre la vista.  
  */
  generateReport(): void {
    if (this.formReport.valid) {
      this.getCardsReport();
      this.getTableReport(true);
      this.getReportByStates();
      this.createIntervalToShowReport();
    } else {
      this.formReport.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-21
    * Metodo que permite crear el intervalo para llamar a los dos servicios de reporte.
  */
  createIntervalToShowReport(): void {
    const time = interval(120000);
    this.suscribeInterval = time.subscribe(_ => {
      this.getCardsReport();
      this.getTableReport();
      this.getReportByStates();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que trae los años registrados en las gestiones de correo. .  
  */
  getYears(): void {
    this.emailService.getYearsToReport().subscribe(res => {
      this.years = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que trae los meses registrados en las gestiones de correo realizados en el año seleccionado.
    * @param $event Evento emitido por el campo select. 
  */
  getMonths($event: MatSelectChange): void {
    this.emailService.getMonthsByYear($event.value).subscribe(res => {
      this.months = res.data.length > 0 ? res.data.sort((a: any, b: any) => {
        return a.id - b.id;
      }) : [];
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que trae la informacion para generar el reporte sobre las cards.
  */
  getCardsReport(): void {
    this.stateButtonSubmit = true;
    this.runCallReportCard = true;
    this.cardsMonths = [];
    this.reportByMonths = [];
    const formatDataForm = { ...this.formReport.value, months: this.formReport.value.months.toString() };
    this.emailService.getGraphReport(formatDataForm).subscribe(res => {
      this.cardsReport[0].value = res.data.correos;
      this.cardsReport[1].value = res.data.entradas;
      this.cardsReport[2].value = res.data.salientes;
      this.cardsReport[3].value = res.data.cerrados;
      this.cardsReport[4].value = res.data.borradores;
      this.cardsReport[5].value = res.data.eliminados;
      this.cardsReport[6].value = res.data.prioritarios;
      this.cardsReport[7].value = res.data.reenviados;
      this.cardsReport[8].value = res.data.replicas;
      this.cardsReport[9].value = res.data.spam;
      this.cardsReport[10].value = res.data.b_salida;
      for (const key in res.data.category_mes) this.cardsMonths.push({ name: res.data.category_mes[key] });
      for (const key in res.data.grafica) this.reportByMonths.push({ name: key, data: res.data.grafica[key] });
      this.runCallReportCard = false;
      this.stateButtonSubmit = false;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que trae la informacion para generar el reporte sobre la tabla.
    * @param setFilterAdvisers Valida si se seteo o no el filtro de asesores.
  */
  getTableReport(setFilterAdvisers: boolean = false): void {
    this.dataTable = [];
    this.messageWhenUpdateReportTable = 'Actualizando información...';
    this.stateButtonSubmit = true;
    const formatDataForm = { ...this.formReport.value, months: this.formReport.value.months.toString() };
    this.emailService.getTableReport(this.configPaginator, formatDataForm, this.adviserSelected).subscribe(res => {
      this.dataTable = res.data.table.data;
      if (setFilterAdvisers) this.listToAdvisers = res.data.users;
      this.configPaginator.length = res.data.table.total;
      this.messageWhenUpdateReportTable = this.dataTable.length === 0 && 'No existe información relacionada';
      this.stateButtonSubmit = false;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que setea el filtro de asesor seleccionado.
    * @param $event Evento emitido por el select. 
  */
  onChangeFilterAdviser($event: MatSelectChange): void {
    this.adviserSelected = $event.value;
    this.configPaginator.pageIndex = 0;
    this.getTableReport();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que muestra la grafica de estados en la modal. 
    * @param labels Listado de etiquetas de la grafica. 
    * @param data Datos numericos de la grafica. 
  */
  showDialogStatesGraph(labels: string[], data: number[]) {
    this.matDialog.open(ViewGraphStatesComponent, {
      autoFocus: false,
      width: '30%',
      data: {
        labels: labels,
        data: data
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que construye la data para la grafica por mes. 
    * @param month Mes seleccionado. 
  */
  onClickCardMonth(month: string): void {
    const labels = [];
    const data = [];
    const filterMonth = this.reportByMonths.filter(item => month === item.name);
    if (filterMonth.length > 0) {
      for (const key in filterMonth[0].data) {
        labels.push(key);
        data.push(filterMonth[0].data[key]);
      }
      this.showDialogStatesGraph(labels, data);
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que construye la data para la grafica por asesor. 
    * @param adviser Asesor seleccionado. 
  */
  onClickAdviserTable(adviser: any): void {
    const labels = [];
    const data = [];
    if (Object.keys(adviser.grafica).length > 0) {
      for (const key in adviser.grafica) {
        labels.push(key);
        data.push(adviser.grafica[key]);
      }
      this.showDialogStatesGraph(labels, data);
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-30
    * Metodo que lista en la tabla el reporte de estados mediante los filtros seleccionados. 
  */
  getReportByStates(): void {
    this.dataTableSates = [];
    this.messageWhenUpdateReportTableStates = 'Actualizando información...';
    this.stateButtonSubmit = true;
    this.crudService.getData({ 
      url: `${GESTIONADOR_CORREOS}dashboard/table_estados`, 
      filters: { 
        emailConfig: this.formReport.get('emailAccount').value, 
        month: `[${this.formReport.get('months').value}]`,
        year: this.formReport.get('year').value, 
      } 
    }).subscribe(res => {
      this.dataTableSates = res.data;
      this.messageWhenUpdateReportTableStates = this.dataTableSates.length === 0 && 'No existe información relacionada'; 
      this.stateButtonSubmit = false;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-30
    * Metodo que abre la modal para listar los correos con el estado seleccionado. 
    * @param $event Evento emitido por el componente hijo. 
  */
  onClickButtonTableStates($event: any): void {
    this.matDialog.open(ViewEmailsStateComponent, {
      autoFocus: false,
      width: '70%',
      panelClass: 'dialog-padding-validate-email-account',
      data: {
        emailConfig: this.formReport.get('emailAccount').value,
        month: `[${this.formReport.get('months').value}]`,
        year: this.formReport.get('year').value,
        state: $event.element.state_email
      }
    });
  }
}