import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ManagementHistoryComponent } from '../management-history/management-history.component';
import { AssignEmailComponent } from '../assign-email/assign-email.component';
import { EmailService } from '../../services/email.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { EDITOR_CONFIG } from '../../constantes/mail-config-editor-view';
import { ManagementHistoryViewComponent } from '../management-history-view/management-history-view.component';
import * as html2pdf from 'html2pdf.js';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-mail-view',
  templateUrl: './mail-view.component.html',
  styleUrls: ['./mail-view.component.css']
})
export class MailViewComponent implements OnInit, OnChanges {
  @Input() mailSelected: any = null;
  @Input() showVerticalScroll: boolean = true;
  @Input() showActionsButtons: boolean = true;
  @Input() showButtonHistory: boolean = true;
  @Input() showAccordeonHistory: boolean = true;
  @Input() showDetails: boolean = true;
  @Output() updateViewEmails: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateDataEmailSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteOrRestoreEmail: EventEmitter<void> = new EventEmitter<void>();
  @Output() moveEmail: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('containerViewMail', { static: false }) containerViewMail: ElementRef
  editorConfig: any = EDITOR_CONFIG;
  showHistoryConversationByButton: boolean = false;
  actionSelected: string = null;

  /**
   * CKEDitor Config
   */
  public editorBody = ClassicEditor

  public configTextBody = {
    placeholder: 'Ingresa aqui el cuerpo del PDF',
    language: 'es'
  }

  constructor(
    private matDialog: MatDialog,
    private emailService: EmailService,
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar
  ) { 
    /*
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold', 'italic',
          '|',
          'link',
          '|',
          'bulletedList', 'numberedList',
          '|',
          'insertTable',
          '|',
          'blockQuote',
          '|',
          'undo',
          'redo'
        ],
      },
      heading: {
        options: [
            { model: 'paragraph', title: 'Párrafo', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Título 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Título 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Título 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Título 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Título 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Título 6', class: 'ck-heading_heading6' }
        ]
      },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'es'
    };
    */
  }

  ngOnInit(): void {

  }

  /**
    * @author Fabian Duran
    * @createdate 2023-09
    * Cuando la data del componente cambie vamos a reiciar la vista ocultado el formulario de gestion.
    * @param changes Cambios detectados por el componente.    
  */
  ngOnChanges(changes: SimpleChanges): void {
    this.actionSelected = null;
    this.showHistoryConversationByButton = false;
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que muestra la modal de historial de gestion.  
  */
  onClickShowManagementHistory(): void {
    this.matDialog.open(ManagementHistoryComponent, {
      width: '80%',
      height: '60vh',
      autoFocus: false,
      panelClass: 'dialog-padding-management-history',
      data: {
        email: this.mailSelected
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que setea la accion seleccionada.
    * @param nameAction Nombre de la accion seleccionada.   
  */
  setActionSelected(nameAction: string): void {
    this.actionSelected = nameAction;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que muestra la modal de asignacion de correos.
  */
  onClickShowAssignEmail(): void {
    this.matDialog.open(AssignEmailComponent, {
      width: '40%',
      autoFocus: false,
      panelClass: 'dialog-padding-assign-email',
      data: {
        title: 'Asignación de correo',
        email: [this.mailSelected],
        isEmailReassignment: false,
        configEmailsSelected: this.mailSelected.email_config_id
      }
    }).afterClosed().subscribe(res => {
      if (res) this.updateViewEmails.emit();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que imprime el correo seleccionado en la lista en un PDF.
  */
  async onClickPrintToEmailPdf() {
    const content = this.containerViewMail.nativeElement;
    const options = {
      margin: 10,
      filename: 'Email.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    html2pdf().from(content).set(options).save();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-14
    * Metodo que que descarga el adjunto seleccionado.
    * @param url Url del adjunto.
    * @param name Nombre del adjunto
  */
  downloadAttachments(url: string, name: string): void {
    const urlWithName = `${url}/${name}`;
    this.emailService.downloadAttachment(urlWithName).subscribe(res => {
      importedSaveAs(res, name);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-14
    * Metodo que emite el email gestionado del formulario.
    * @param $event Evento emitido por el componente hijo. 
  */
  onSubmitFormManagerMessage($event: any): void {
    this.actionSelected = null;
    this.updateDataEmailSelected.emit(this.mailSelected);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-03
    * Metodo que abre los detalles del correo en una modal.
  */
  showPreviewEmail(): void {
    this.matDialog.open(ManagementHistoryViewComponent, {
      width: '95%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-management-history-view',
      data: {
        management: this.mailSelected,
        toPreview: true,
        showButtonPrint: true,
        showButtonAddEmail: false,
        showDetails: true
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que que descarga el archivo EML del correo seleccionado.
  */
  downloadEML(): void {
    this.emailService.downloadEmailEML(this.mailSelected.id).subscribe(res => {
      importedSaveAs(res);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-23
    * Metodo que elimina el correo seleccionado.
  */
  deleteEmailsSelected(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De eliminar el correo? <b>Este quedara almacenado en la bandeja de eliminados.</b>').then(confirm => {
      if (confirm.isConfirmed) {
        const formData = new FormData();
        formData.append('email_list', `[${this.mailSelected.id}]`);
        this.emailService.deleteEmails(formData).subscribe(res => {
          this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
            data: {
              title: '¡Excelente!',
              message: res.data
            },
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['green-snackbar']
          });
          this.deleteOrRestoreEmail.emit();
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-23
    * Metodo que elimina el correo seleccionado.
  */
  restoreEmailsSelected(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De restaurar el correo? <b>Este quedara almacenado en la bandeja original.</b>').then(confirm => {
      if (confirm.isConfirmed) {
        const formData = new FormData();
        formData.append('email_list', `[${this.mailSelected.id}]`);
        this.emailService.restoreEmails(formData).subscribe(res => {
          this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
            data: {
              title: '¡Excelente!',
              message: res.data
            },
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['green-snackbar']
          });
          this.deleteOrRestoreEmail.emit();
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-26
    * Metodo que emite el ID del correo seleccionado para ser movido.
  */
  onClickMoveEmail(): void {
    this.moveEmail.emit([{ id: this.mailSelected.id, id_bandeja: this.mailSelected.id_bandeja }]);
  }
}