import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { FormContactGroupComponent } from '../../components/form-contact-group/form-contact-group.component';
import { ContactGroupService } from '../../services/contact-group.service';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { EmailService } from '../../services/email.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-contact-group-main',
  templateUrl: './contact-group-main.component.html',
  styleUrls: ['./contact-group-main.component.css']
})
export class ContactGroupMainComponent implements OnInit {
  dataContactGroup: any[] = [];
  configPaginator: any = { length: 0, pageIndex: 0, pageSize: 5 };
  filter: FormControl = new FormControl('', [Validators.maxLength(30)]);
  emailsConfig: any[] = [];
  filterSelect: number = 0;

  constructor(
    private location: Location,
    private matDialog: MatDialog,
    private contactGroupService: ContactGroupService,
    private authService: AuthService,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que devuelve la pagina anteriormente visitada.  
  */
  backToPreviousPage(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que setea el valor del atributo filter.
    * @param $event Evento emitido por el campo de texto.   
  */
  onChangeFilter($event: any): void {
    if (this.filter.valid) {
      this.configPaginator.pageIndex = 0;
      this.getContactGroup();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que abre la modal para crear un grupo de contacto.
  */
  onClickNewContactGroup(): void {
    this.matDialog.open(FormContactGroupComponent, {
      width: '80%',
      autoFocus: false,
      data: {
        title: 'Crear grupo de contacto',
        contactGroup: null,
        btnText: 'Crear',
        onlyShow: false,
        emailsConfig: this.emailsConfig
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getContactGroup();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que trae los grupos de contacto registrados en el sistema. 
  */
  getContactGroup(): void {
    if (this.filterSelect !== 0) {
      this.contactGroupService.getContactGroups(this.configPaginator, this.filter.value, true, this.filterSelect).subscribe(res => {
        this.dataContactGroup = res.data.data;
        this.configPaginator.length = res.data.total;
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que setea el valor del atributo configPaginator. 
    * @param $event Evento emitido por el componente hijo.   
  */
  onChangePaginator($event: any) {
    this.configPaginator = $event;
    this.getContactGroup();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que emite el cambio del filtro sobre el select.
    * @param $event Evento emitido por el paginador. 
  */
  onChangeSelectFilter($event: MatSelectChange): void {
    this.filterSelect = $event.value;
    this.getContactGroup();
  }
}