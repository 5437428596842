import { Component, OnInit, Input } from '@angular/core';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-swiper-report-trays',
  templateUrl: './swiper-report-trays.component.html',
  styleUrls: ['./swiper-report-trays.component.css']
})
export class SwiperReportTraysComponent implements OnInit {
  swipperConfigTrays: SwiperOptions = {
    navigation: {
      nextEl: '.swiper-button-next-trays',
      prevEl: '.swiper-button-prev-trays',
    },
    pagination: { clickable: true },
    breakpoints: {
      640: { slidesPerView: 3, spaceBetween: 10, pagination: { el: '.swiper-pagination-hide' } },
    },
  };
  @Input() cardsReport: any[] = [];

  constructor() { }

  ngOnInit(): void {
    
  }
}