import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrayComponent } from '../../components/tray/tray.component';
import { TrayService } from '../../services/tray.service';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { EmailService } from '../../services/email.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';

@Component({
  selector: 'app-tray-page-main',
  templateUrl: './tray-page-main.component.html',
  styleUrls: ['./tray-page-main.component.css']
})
export class TrayPageMainComponent implements OnInit {
  trays: any[] = [];
  filter: string = '';
  filterSelect: number = 0;
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Nombre', key: 'name' },
    { name: 'Icono', key: 'icon' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  emailsConfig: any[] = [];

  constructor(
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private trayService: TrayService,
    private emailService: EmailService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getTrays();
    this.getEmailsConfigByRol();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que trae las bandejas registradas en el sistema. 
  */
  getTrays(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.trayService.getTrays(this.configPaginator, this.filter, false, this.filterSelect, 1).subscribe(res => {
        this.trays = res.data.data;
        this.configPaginator.length = res.data.total;
      });
    } else {
      if (this.filterSelect !== 0) {
        this.trayService.getTrays(this.configPaginator, this.filter, false, this.filterSelect, 1).subscribe(res => {
          this.trays = res.data.data;
          this.configPaginator.length = res.data.total;
        });
      }
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getTrays();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que actualiza el estado de una bandeja. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateTray($event: any): void {
    const setDataTray = { ...$event.item, state: $event.state };
    this.trayService.storeTray(setDataTray).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getTrays();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que permite ejecutar la modal para crear o actualizar una bandeja. 
    * @param tray Bandeja seleccionada de la tabla.  
  */
  onClickShowTray(tray: any = null): void {
    this.matDialog.open(TrayComponent, {
      width: '40%',
      autoFocus: false,
      panelClass: 'dialog-padding-tray',
      data: {
        tray,
        emailsConfig: this.emailsConfig
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getTrays();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getTrays();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-23
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-23
    * Metodo que setea el filtro del select emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterSelect($event: number) {
    this.filterSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getTrays();
  }
}