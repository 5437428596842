<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">{{ data.title }}</div>
  <mat-divider></mat-divider>
  <form [formGroup]="formContactGroup" class="mt-5" autocomplete="off">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre del grupo</mat-label>
          <input matInput placeholder="Nombre del grupo" formControlName="name">
          <mat-error *ngIf="error.name.errors">
            <span *ngIf="error.name.errors.required">El campo es requerido</span>
            <span *ngIf="error.name.errors.maxlength">Máximo 30 caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListMails>
            <mat-chip *ngFor="let item of formContactGroup.get('email_list')?.controls; let i = index"
              [selectable]="true" [removable]="data.onlyShow ? false : true" (removed)="removeMail(i)">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input [readOnly]="data.onlyShow" placeholder="Para" #inputMail [matChipInputFor]="chipListMails"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addMail($event)">
          </mat-chip-list>
          <mat-error *ngIf="error.email_list.errors">
            <span *ngIf="error.email_list.errors.validateArrayNotEmpty">El campo es requerido</span>
            <span *ngIf="error.email_list.errors.validateIsEmails">Correo inválido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Emails de configuración</mat-label>
          <mat-select formControlName="id_email_config">
            <mat-option *ngFor="let item of data.emailsConfig" [value]="item.id">{{ item.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_email_config.errors">
            <span *ngIf="error.id_email_config.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="data.onlyShow === false" class="row py-3 d-flex justify-content-end">
      <div class="col-md-2">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-2">
        <button (click)="onClickSubmitFormContactGroup()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">{{
          data.btnText }}</button>
      </div>
    </div>
  </form>
</div>