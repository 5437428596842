import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { CategoryComponent } from '../../components/category/category.component';
import { CategoryService } from '../../services/category.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { EmailService } from '../../services/email.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';

@Component({
  selector: 'app-category-page-main',
  templateUrl: './category-page-main.component.html',
  styleUrls: ['./category-page-main.component.css']
})
export class CategoryPageMainComponent implements OnInit {
  categories: any[] = [];
  filter: string = '';
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' }, 
    { name: 'Nombre', key: 'name' }, 
    { name: 'Color', key: 'color' }, 
    { name: 'Cuenta de correo', key: 'user_name_email_entrada' }
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  emailsConfig: any[] = [];
  filterSelect: number = 0;

  constructor(
    private matDialog: MatDialog,
    private categoryService: CategoryService,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que trae la categorias registradas en el sistema. 
  */
  getCategories(): void {
    if (this.filterSelect !== 0) {
      this.categoryService.getCategories(this.configPaginator, this.filter, true, this.filterSelect).subscribe(res => {
        this.categories = res.data.data;
        this.configPaginator.length = res.data.total;
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getCategories();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que actualiza el estado de una categoria. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateCategory($event: any): void {
    const setDataCategory = { ...$event.item, state: $event.state };
    this.categoryService.storeCategory(setDataCategory).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getCategories();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que permite ejecutar la modal para crear o actualizar una categoria. 
    * @param category Categoria seleccionada de la tabla. 
    * @param titleDialog Titulo que va a tener la modal. 
    * @param viewOnly Modo de vizualizacion de la modal. 
  */
  onClickShowCategory(category: any = null, titleDialog: string = 'Crear categoría', viewOnly: boolean = false): void {
    this.matDialog.open(CategoryComponent, {
      width: '40%',
      autoFocus: false,
      panelClass: 'dialog-padding-category',
      data: {
        titleDialog,
        category,
        viewOnly,
        emailsConfig: this.emailsConfig
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getCategories();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que permite ejecutar la modal para crear o actualizar una categoria.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getCategories();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que setea el filtro del select emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterSelect($event: number) {
    this.filterSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getCategories();
  }
}