<div class="row">
  <div class="col-10">
    <h3 class="title" mat-dialog-title>Reprogramar videollamada</h3>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i class="fi-rr-cross-small" style="cursor:pointer; font-size: 20px; color: #353535;" mat-icon-button
      mat-dialog-close></i>
  </div>
</div>


<mat-dialog-content class="mat-typography">
  <div class="col-12">
    <p>NIC: {{client_nic}}, Agenda actual: {{ current_date | date: 'dd/MM/yyyy hh:mm a' }}</p>
  </div>
  <mat-divider></mat-divider>

  <form [formGroup]="form" class="mt-4">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Asesor</mat-label>
          <mat-select formControlName="adviser_id" required>
            <mat-option *ngFor="let adviser of advisers" [value]="adviser.rrhh_id" (click)="setSchedules(adviser)">{{ adviser.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('adviser_id').hasError('required')">¡Atención! este campo es obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date" required [min]="min" [max]="max" (dateChange)="setHours($event.value)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="form.get('date').hasError('required')">¡Atención! este campo es obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Hora</mat-label>
          <mat-select formControlName="time" required>
            <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour | date: 'shortTime' }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('time').hasError('required')">¡Atención! este campo es obligatorio</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

</mat-dialog-content>

<mat-dialog-actions align="end" class="mt-4 mb-4 actions" style="padding-right: 0 !important;">
  <button mat-stroked-button color="primary" mat-dialog-close class="me-2 button-dimensions">Cancelar</button>
  <button mat-raised-button color="primary" class=" button-dimensions"  (click)="save()">Actualizar</button>
</mat-dialog-actions>
