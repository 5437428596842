import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class AssignPriorityService {
  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que retorna las asignaciones prioritarias registrados en el sistema. 
    * @param paginator Paginador configurado en la vista. 
    * @param filterAccountEmail Filtro de las cuentas de correo.
    * @param filterEmail Filtro texto.
    * @param filterOptional Filtro opcional. 
    * @param showAllStates Filtro que muestra o no todas las prioridades activas.
  */
  getAssignsPriority(paginator: any = null, filterAccountEmail: number = 0, filterEmail: string = '', filterOptional: number = 0, showAllStates: boolean = true): Observable<any> {
    const paramState = showAllStates ? '' : '&state=1';
    const url = paginator === null ? `${GESTIONADOR_CORREOS}priority/showall` : `${GESTIONADOR_CORREOS}priority/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&email=${filterEmail}&id_email_config=${filterAccountEmail}&id_bandeja=${filterOptional}${paramState}`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que guardar o actualiza las asignaciones prioritarias en el sistema. 
    * @param state Informacion proveniente del formulario.  
  */
  storeAssignPriority(state: any): Observable<any> {
    const url = state.id === null ? `${GESTIONADOR_CORREOS}priority/store` : `${GESTIONADOR_CORREOS}priority/update/${state.id}`;
    return this.httpClient.post(url, state).pipe(
      catchError(this.handleError)
    );
  }
}