import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-main-menu',
  templateUrl: './history-main-menu.component.html',
  styleUrls: ['./history-main-menu.component.sass']
})
export class HistoryMainMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
