<div class="row col-12">
    <div class="box-text col-5">
        <h4 class="title">Speech Analytics</h4>
        <h1 class="welcome">¡Bienvenid@<br> {{nameUser}}! </h1>
        <p class="text">Este es el módulo de <b style="color: #000000;">Speech Analytics</b>, donde podrás administrar la transcripción de
            audios y<br/>aplicar estrategias. </p>
    </div>

    <div class="box-image col-7">
        <img src="../../../assets/images/landing/landing-pages/site-mapping/Ilustracion_modulards.svg" alt="Ilustración_modular" style="width: 810px; padding: 0px;">
    </div>
    
</div>