import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
  * @author Fabian Duran
  * @createdate 2023-06-30
  * Valida dentro de los input de tipo chiplist si tienen el formato de validacion correcto. 
  * @param control Campo del formulario. 
*/
export const validateIsEmails = (control: FormArray): any => {
  if (control.value) {
    const filterByStatus = control.controls.filter(field => field.status === 'INVALID');
    return filterByStatus.length > 0 ? { validateIsEmails: { valid: false } } : null;
  }
  return null;
}
/**
  * @author Fabian Duran
  * @createdate 2023-06-30
  * Valida si hay form control dentro de un form array.
  * @param control Campo del formulario.   
*/
export const validateArrayNotEmpty = (control: FormArray): any => {
  if (control.value.length === 0) return { validateArrayNotEmpty: { valid: false } };
  return null;
}
/**
  * @author Fabian Duran
  * @createdate 2023-09-14
  * Metodo valida si el numero es positivo sobre los campos tipo texto.
  * @param control Control de formulario.  
*/
export const validateNumbersNegative = (control: FormControl) => {
  if (control.value && parseInt(control.value) < 0) return { validateNumbersNegative: { valid: false } };
  return null;
};
/**
  * @author Fabian Duran
  * @createdate 2023-10-06
  * Metodo valida el peso maximo de adjuntos.
  * @param control Control de formulario.  
*/
export const validateSizeFiles = (control: FormArray) => {
  const maxSizeInBytes = 20 * 1024 * 1024;
  let sizeFile = 0; 
  control.value.forEach((item: any) => { sizeFile += item.file.size; });
  if (sizeFile > maxSizeInBytes) return { validateSizeFiles: { valid: false } };
  return null;
};
/**
  * @author Fabian Duran
  * @createdate 2023-10-06
  * Metodo valida el rango de tiempos entre los campos tipo time.
  * @param startTimeField Campo tiempo inicial.
  * @param endTimeField Campo tiempo final.
*/
export function validateTimeRange(startTimeField: AbstractControl, endTimeField: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startTime = startTimeField.value;
    const endTime = endTimeField.value;
    if (!startTime || !endTime) return null;
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);
    if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) return { timeRangeError: true };
    return null;
  };
}
/**
  * @author Fabian Duran
  * @createdate 2024-03-22
  * Valida campos multiselect si hay una seleccion.
  * @param control Campo del formulario.   
*/
export const validateArrayDaysNotEmpty = (control: FormControl): any => {
  if (control.value && control.value.length === 0) return { validateArrayDaysNotEmpty: { valid: false } };
  return null;
}
/**
  * @author Fabian Duran
  * @createdate 2024-11-14
  * Valida que un campo de tipo texto enriquecido no contenga espacios en blanco.
  * @param control Campo del formulario.   
*/
export function validateWhiteSpaces(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isOnlySpaces = !value || value.replace(/&nbsp;/g, '').trim().length === 0;
    return isOnlySpaces ? { 'onlySpaces': true } : null;
  };
}