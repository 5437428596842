<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.label ? 'Editar texto rápido' : 'Crear texto rápido' }}
  </div>
  <mat-divider></mat-divider>
  <div class="mt-4">
    <app-print-form-survey [questions]="questions" [textButtonSubmit]="'Guardar'"
      (sendQuestions)="onSubmitFormLabel($event)"></app-print-form-survey>
  </div>
</div>