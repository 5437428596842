<div class="row col-12">
    <div class="box-text col-5">
        <h4 class="title">Videollamadas</h4>
        <h1 class="welcome">¡Bienvenid@<br> {{nameUser}}! </h1>
        <p class="text">Este es el módulo de <b style="color: #000000;">Videollamadas</b>, diseñado para facilitar la gestión y administración eficiente de todas las funciones relacionadas.
        </p>
    </div>

    <div class="box-image col-7 mt-6" style="padding-top: 90px">
        <img src="../../../../../../../assets/images/livechat_img.svg" alt="image_chat" style="width: 810px; padding: 0px;">
    </div>
    
</div>