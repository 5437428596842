<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">Grupos de contactos</div>
  <mat-divider></mat-divider>
  <div class="my-4" style="font-weight: 400; font-size: 16px;">
    Para elegir el o los grupos de contactos, solo debes seleccionarlo(s) dando clic en el check y guardar.
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Cuenta de correo</mat-label>
        <mat-select (selectionChange)="onChangeSelectFilter($event)">
          <mat-option *ngFor="let item of emailsConfig" [value]="item.id">{{ item.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Nombre del grupo</mat-label>
        <input [formControl]="filter" (blur)="onChangeFilter($event)" matInput placeholder="Nombre del grupo">
        <span matSuffix class="fi-rr-search fs-5"></span>
        <mat-error *ngIf="filter.errors">
          <span *ngIf="filter.errors.maxlength">Máximo 30 caracteres</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <app-table-contact-group [dataContactGroup]="dataContactGroup" [configPaginator]="configPaginator"
    [nameFieldSelected]="data.nameField" (onChangePaginator)="onChangePaginator($event)"
    (sendContactGroupSelected)="getGroupSelected($event)"></app-table-contact-group>
</div>