<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Administración de servidores</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">
        En este espacio podrás realizar la gestión de servidores para <br />
        la configuración de descargas. No olvides “El respeto y empatía son clave”.
      </p>
    </div>
  </div>
  <form [formGroup]="formAdminServers" class="py-3">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre</mat-label>
          <input formControlName="name" matInput placeholder="Nombre del servidor">
          <mat-error *ngIf="errorsForm.name.errors">
            <span *ngIf="errorsForm.name.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Servidor</mat-label>
          <input formControlName="host" matInput placeholder="IP del servidor">
          <mat-error *ngIf="errorsForm.host.errors">
            <span *ngIf="errorsForm.host.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Base de datos</mat-label>
          <input formControlName="database" matInput placeholder="Nombre de la base de datos">
          <mat-error *ngIf="errorsForm.database.errors">
            <span *ngIf="errorsForm.database.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Usuario</mat-label>
          <input formControlName="user" matInput placeholder="Nombre de usuario">
          <mat-error *ngIf="errorsForm.user.errors">
            <span *ngIf="errorsForm.user.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Contraseña</mat-label>
          <input [type]="hidePassword ? 'password' : 'text'" formControlName="pass" matInput
            placeholder="Contraseña del usuario">
          <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
            <mat-icon>{{ !hidePassword ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <mat-error *ngIf="errorsForm.pass.errors">
            <span *ngIf="errorsForm.pass.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Conexión</mat-label>
          <input formControlName="type_conexion" matInput placeholder="Tipo de conexión">
          <mat-error *ngIf="errorsForm.type_conexion.errors">
            <span *ngIf="errorsForm.type_conexion.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Vista</mat-label>
          <input formControlName="name_vista" matInput placeholder="Nombre de la vista">
          <mat-error *ngIf="errorsForm.name_vista.errors">
            <span *ngIf="errorsForm.name_vista.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Puerto</mat-label>
          <input type="number" formControlName="port" matInput placeholder="Puerto del servidor">
          <mat-error *ngIf="errorsForm.port.errors">
            <span *ngIf="errorsForm.port.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Comandos</mat-label>
          <mat-select formControlName="id_type_shell">
            <mat-option *ngFor="let item of commands" [value]="item.id">{{ item.nombre }}</mat-option>
          </mat-select>
          <mat-error *ngIf="errorsForm.id_type_shell.errors">
            <span *ngIf="errorsForm.id_type_shell.errors.required">Este campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row d-flex justify-content-end">
      <div class="col-md-2">
        <button (click)="cancelManagmentServers()" type="button" mat-raised-button class="w-100"
          style="height: 48px; border: 1px solid #A9A9A9; color: #A9A9A9; font-weight: 600;">Cancelar</button>
      </div>
      <div class="col-md-2">
        <button (click)="saveOrUpdateServe()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Guardar</button>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">
      <table mat-table [dataSource]="dataServersTable">
        <ng-container *ngFor="let column of configColumnsTable">
          <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell *matCellDef="let element"> {{ element[column.key] }} </td>
          </ng-container>
          <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
              <button (click)="viewServer(element)" mat-icon-button style="width: 25px !important;">
                <span class="fi-rr-edit"></span>
              </button>
              <button (click)="deleteServer(element)" mat-icon-button style="width: 25px !important;">
                <span class="fi-rr-trash"></span>
              </button>
              <button (click)="onClickCheckServer(element.id)" mat-icon-button style="width: 25px !important;">
                <span class="fi-rr-check"></span>
              </button>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
        <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
        <tr class="mat-row" style="background: white" *matNoDataRow>
          <td class="mat-cell" colspan="5">No existe información relacionada</td>
        </tr>
      </table>
      <mat-paginator (page)="changePage($event)" [length]="configPaginator.length"
        [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>