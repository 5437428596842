import { ChartDataset, ChartOptions } from 'chart.js';

export const MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export const DATASET_PENDIENTES: ChartDataset = {
  label: 'Pendientes',
  backgroundColor: '#D14A4A',
  data: []
};

export const DATASET_EN_GESTION: ChartDataset = {
  label: 'En gestión',
  backgroundColor: '#D9A847',
  data: []
};

export const DATASET_CERRADOS: ChartDataset = {
  label: 'Cerrados',
  backgroundColor: '#60BF66',
  data: []
};

export const OPTIONS: ChartOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Estadística General',
      font: {
        size: 20,
        family: '"Poppins", sans-serif'
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        font: {
          size: 16,
          family: '"Poppins", sans-serif'
        }
      }
    },
    tooltip: {
      titleFont: {
        family: '"Poppins", sans-serif'
      },
      bodyFont: {
        family: '"Poppins", sans-serif'
      }
    }
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 12,
          family: '"Poppins", sans-serif'
        }
      }
    },
    y: {
      ticks: {
        font: {
          size: 12,
          family: '"Poppins", sans-serif'
        }
      },
      type: 'logarithmic',
      min: 0
    }
  }
};

export const OPTIONS_PIE: ChartOptions<'pie'> = {
  plugins: {
    title: {
      display: true,
      text: 'Estadística por Estados',
      font: {
        size: 20,
        family: '"Poppins", sans-serif'
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        font: {
          size: 14,
          family: '"Poppins", sans-serif'
        }
      }
    },
    tooltip: {
      titleFont: {
        family: '"Poppins", sans-serif'
      },
      bodyFont: {
        family: '"Poppins", sans-serif'
      }
    }
  },
};

export const DAYS_OF_WEEK = [
  {
    value: "Monday",
    text: "Lunes"
  },
  {
    value: "Tuesday",
    text: "Martes"
  },
  {
    value: "Wednesday",
    text: "Miércoles"
  },
  {
    value: "Thursday",
    text: "Jueves"
  },
  {
    value: "Friday",
    text: "Viernes"
  },
  {
    value: "Saturday",
    text: "Sábado"
  },
  {
    value: "Sunday",
    text: "Domingo"
  }
];