import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HistoryService } from 'src/app/modules/speech-analytics/services/history.service';
import Swal from 'sweetalert2';
import { AdherenciaChatModalComponent } from '../../chat-speech/adherencia-chat-modal/adherencia-chat-modal.component';
import { AnalyticsChatModalComponent } from '../../chat-speech/analytics-chat-modal/analytics-chat-modal.component';
import { ChatViewModalComponent } from '../../chat-speech/chat-view-modal/chat-view-modal.component';
import { ChatService } from '../../../services/chat.service';


@Component({
  selector: 'app-history-chat',
  templateUrl: './history-chat.component.html',
  styleUrls: ['./history-chat.component.sass']
})
export class HistoryChatComponent implements OnInit {

  /**
   * Variables de paginacion
   */
  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 1, previousPageIndex: 0 };

  /**
   * Variable de filtros
   */
  formFilters: FormGroup;
  isFiltersAdvancedActive: boolean = false;

  /**
   * Selectores
   */
  campaigns_list: any[] = [];
  groups: any[] = [];
  skills: any[] = [];
  tipifications: any[] = [];
  type_call: any[] = [{ type_call: 'Inbound' }, { type_call: 'Outbound' }];
  listStrategys: any[] = [];

  /**
   * Columnas de tablas
   */
  displayedColumns: string[] = ['checbox', 'case_number', 'entry_date_time', 'agent_name', 'phone', 'file', 'conversation', 'analisis', 'adherencia'];

  /**
   * Cabeceras de tablas
   */

  tHeaders:any={
    checbox: "", case_number:"Caso",entry_date_time: "Fecha",
    agent_name: 'Nombre agente', phone: "Telefono", file:'Archivo',
    conversation:'Conversación', analisis:'Análisis' , adherencia:'Adherencia'
  }

  /**
   * Variables de seleccion
   */
  selection = new SelectionModel<any>(true, []);

  //selectores
  selectedSearch: Set<number> = new Set<number>(); //selest activos busqueda

  /**
   * Variables de tabla
   */
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<any>();

  strategyControl: FormControl = new FormControl(null);

  rrhh_id_user: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.filterUserCampaign();
    this.initFormFilters();
  }

  constructor(
    private historyService: HistoryService,
    private chatService: ChatService,
    private dialog: MatDialog
  ) {}

  /**
   * Método que se encarga de inicializar los controles apra los filtros
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  initFormFilters(): void {
    this.formFilters = new FormGroup({
      dateStart: new FormControl(null, Validators.required),
      dateEnd: new FormControl(null, Validators.required),
    });
  }

  /**
   * Metodo validar rango de fecha
   * @author Yeison sepulveda
   * @createdate 30-08-2024
   * @returns void {void} 
   */
  myDateFilter = (d: Date | null): boolean => {
    const today = new Date();
    return d ? d <= today : true;
  };

  /**
   * Método para limpiar la selección basado en un parámetro
   * @author Yeison Sepulveda
   * @param param
   */
  searchSelection() {
    this.configPaginator.pageIndex = 0;
    this.selectedSearch.clear();
    this.dataSource.data = [];
    this.paginator.firstPage();
    this.search();
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de grabaciones
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo para para seleccionar todos los elementos en la página actual
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  selectAll(): void {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach((row) => {
        this.selection.deselect(row);
        this.selectedSearch.delete(row.id);
      });
    } else {
      this.dataSource.data.forEach((row) => {
        if (
          !this.selection.selected.some(
            (selectedItem: any) =>
              selectedItem.id === row.id
          )
        ) {
          this.selection.select(row);
          this.selectedSearch.add(row.id);
        }
      });
    }
  }


  /**
   * Método para alternar la selección de un registro en la lista.
   * @author Yeison Sepulveda
   * @param row 
  */
  toggleSelection(row: any): void {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
      this.selectedSearch.delete(row.id);
    } else {
      this.selection.select(row);
      this.selectedSearch.add(row.id);
    }
  }

  /**
   * Recuperar los selec seleccionados en distintas paginas
   * @author Yeison Sepulveda
   * @param param
   */
  syncSelectionWithStored(param: string) {
    if (param === 'search') {
      this.selection.clear();
      this.dataSource.data.forEach((row) => {
        if (this.selectedSearch.has(row.id)) {
          this.selection.select(row);
        }
      });
    }
  }

  /**
   * Metdodo que se encarga de obtener la cantidad de registros en la tabla
   * @author Juan David Guerrero Vargas
   */
  get getTotalDataSource(): number {
    return this.dataSource.data.length;
  }

  /**
   * Metodo que se encarga de identificar sis e peude o no relziar la consutla
   */
  get isAviableSearch(): boolean {
    let endDate = this.formFilters.get('dateEnd').value;
    if (
      endDate !== null &&
      endDate !== ''
    ) {
      return true;
    }
    return false;
  }

  /**
   * Metodo trae las campañas asociadas al usuario
   * @author Yeison sepulveda
   * @createdate    06/05/2023
   * @returns void {void}
   */

  filterUserCampaign(): void {
    this.historyService.getCampaigns().subscribe(
      (res) => {
        this.campaigns_list = res.data;
      },
      (error) => {
        this.campaigns_list = [];
      }
    );
  }

  /**
   * Método para el consumo y busqueda por los selectores
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  search(): void {
    const filters = {
        start_time: this.formFilters.get('dateStart').value,
        end_time: this.formFilters.get('dateEnd').value,
    };

    this.historyService.ChatSearchPaginate(filters, this.configPaginator).subscribe(
        (response) => {
            const data = response.data.data.map((registro: any) => {
                const earliestChatDetail = registro.chat_details.reduce((earliest, current) => {
                    return new Date(earliest.entry_date_time) < new Date(current.entry_date_time) ? earliest : current;
                });

                const agentName = registro.chat_details.find(detail => detail.agent_name)?.agent_name || 'N/A';
                const file = registro.uploads.file;

                // Calcular adherencia
                let adherencia;
                if (
                    (registro.adherence_percentage === null || registro.adherence_percentage === "") &&
                    (registro.not_adherence_percentage === null || registro.not_adherence_percentage === "")
                ) {
                    adherencia = null;
                } else {
                    adherencia = `${registro.adherence_percentage}% / ${registro.not_adherence_percentage}%`;
                }

                const fecha = new Date(registro.date);
                const year = fecha.getFullYear();
                const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
                const day = fecha.getDate().toString().padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;

                return {
                  ...registro,
                  entry_date_time: earliestChatDetail.entry_date_time,
                  agent_name: agentName,
                  date: formattedDate,
                  file: file,
                  adherencia: adherencia,
                };
            });

            this.dataSource.data = data;
            this.dataSource.sort = this.sort;
            this.configPaginator.length = response.data.total;
            this.syncSelectionWithStored('search');
        },
        (error) => {
            console.error('Error en la solicitud:', error);
        }
    );
  }


  /**
   * Método para convertir la diferencia de tiempo entre dos fechas en formato HH:mm:ss.
   * @param startTime La fecha de inicio.
   * @param endTime La fecha de fin.
   * @returns La diferencia de tiempo formateada en HH:mm:ss.
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
   */

  convertTimeToHHMMSS(startTime: Date, endTime: Date): string {
    const diferenciaMs = Math.abs(endTime.getTime() - startTime.getTime());
    const tiempoDiferencia = new Date(diferenciaMs);

    //extraer
    const horas = tiempoDiferencia.getUTCHours().toString().padStart(2, '0');
    const minutos = tiempoDiferencia
      .getUTCMinutes()
      .toString()
      .padStart(2, '0');
    const segundos = tiempoDiferencia
      .getUTCSeconds()
      .toString()
      .padStart(2, '0');

    return `${horas}:${minutos}:${segundos}`;
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * Método que pagina la informacion de la tabla.
   * @param $event Evento emitido por el paginador.
   */
  changePage($event: PageEvent): void {
    this.configPaginator.pageSize = $event.pageSize;
    this.configPaginator.pageIndex = $event.pageIndex + 1;
    this.search();
  }

  /**
   * Metodo para abrir el modal de analisis
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  openAnalyticsModal(id: any): void {
    const dialogRef = this.dialog.open(AnalyticsChatModalComponent, {
      width: '800px',
      data: { id: id } 
    });
  }

  /**
   * Método para abrir el modal de resultados de adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-04-30
   */
  AdherenciaModal(id: any): void {
    const dialogRef = this.dialog.open(AdherenciaChatModalComponent, {
      width: '800px',
      data: { id: id },
    });
  }

  /**
   * Método enviar mensaje swal de error de la grabación
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
   */

  errorAnalyticModal() {
    Swal.fire({
      title: '¡Aviso!',
      text: 'Si la transcripción no está disponible, por favor verifica la conversación, podría carecer contenido. ¡Gracias por tu atención! ',
      icon: 'warning',
      confirmButtonText: '¡Entendido!',
      confirmButtonColor: '#2CABBC',
    });
  }

	/**
	 * Método para tratar los datos que retorna el servicio y descargar el archivo.
	 * @param data data respondida por el servicio
	 * @param fileName nombre con el que se va a descargar el archivo.
	 * @param file_type Tipo del archivo 
	 * @author Juan Carlos Alonso
	 * @createdate 2021-07-27
	 * @updatedate 2022-12-13  
	 */
  fileTransition(data: any, fileName: string, file_type: string = ''): void {
    let blob = new Blob([data], { type: file_type });
    let downloadURL = file_type
      ? window.URL.createObjectURL(blob)
      : window.URL.createObjectURL(data);
    let link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   */
  downloadReport(type: 'analysis' | 'strategy') {
    let filters: any = {};

    if (type === 'analysis') {
      filters = {
        ids_case: this.dataSource.data.map(record => record.id)
      };
    } else if (type === 'strategy') {
      filters = {
        ids_case: this.dataSource.data.map(record => record.id)
      };
    }

    this.chatService.downloadReportChat(filters).subscribe(
      (response: any) => {
        this.fileTransition(response, 'reporte.xlsx');
      },
      (error) => {
        console.error('Error en la solicitud:', error);
      }
    );
  }

    /**
   * Metodo para abrir el modal detalle del caso y visual del chat
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  modalChatView(id: any): void {
    const dialogRef = this.dialog.open(ChatViewModalComponent, {
      width: '800px',
      data: { id: id } 
    });
  }

}
