import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrayService } from '../../services/tray.service';

@Component({
  selector: 'app-tray',
  templateUrl: './tray.component.html',
  styleUrls: ['./tray.component.css']
})
export class TrayComponent implements OnInit {
  formTray: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    icon: new FormControl({ 
      value: this.data.hasOwnProperty('folder') ? 'fi-rr-folder' : '', 
      disabled: this.data.hasOwnProperty('folder') ? true : false }, 
      [Validators.required]),
    orden: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    id_email_config: new FormControl('', [Validators.required]),
    type: new FormControl(this.data.hasOwnProperty('folder') ? '2' : '1', [])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<TrayComponent>,
    private matSnackBar: MatSnackBar,
    private trayService: TrayService
  ) { }

  ngOnInit(): void {
    if (this.data.tray) {
      this.formTray.get('id').setValue(this.data.tray.id);
      this.formTray.get('name').setValue(this.data.tray.name);
      this.formTray.get('icon').setValue(this.data.tray.icon);
      this.formTray.get('orden').setValue(this.data.tray.orden);
      this.formTray.get('id_email_config').setValue(this.data.tray.id_email_config);
      this.formTray.get('type').setValue(this.data.tray.type);
      if (this.data.tray.id_email_config === 0) this.formTray.removeControl('id_email_config');
    }
    if (this.data.folder) {
      this.formTray.get('id').setValue(this.data.folder.id);
      this.formTray.get('name').setValue(this.data.folder.name);
      this.formTray.get('icon').setValue('fi-rr-folder');
      this.formTray.get('orden').setValue(this.data.folder.orden);
      this.formTray.get('id_email_config').setValue(this.data.folder.id_email_config);
      this.formTray.get('type').setValue(this.data.folder.type);
      this.formTray.get('icon').disable();
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formTray.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que guarda o actualiza una bandeja. 
  */
  saveTray(): void {
    if (this.formTray.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.trayService.storeTray(this.formTray.getRawValue()).subscribe(res => {
            const messageCreate = this.data.hasOwnProperty('tray') ? 'Se ha creado una nueva bandeja con éxito' : 'Se ha creado una nueva carpeta con éxito';
            const messageUpdate = this.data.hasOwnProperty('tray') ? 'Se ha actualizado la bandeja con éxito' : 'Se ha actualizado la carpeta con éxito';
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: this.formTray.value.id === null ? messageCreate : messageUpdate
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formTray.markAllAsTouched();
    }
  }
}