import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageEmailManagerComponent } from './pages/landing-page-email-manager/landing-page-email-manager.component';
import { EmailManagerMainComponent } from './pages/email-manager-main/email-manager-main.component';
import { ContactGroupMainComponent } from './pages/contact-group-main/contact-group-main.component';
import { CategoryPageMainComponent } from './pages/category-page-main/category-page-main.component';
import { StatePageMainComponent } from './pages/state-page-main/state-page-main.component';
import { TrayPageMainComponent } from './pages/tray-page-main/tray-page-main.component';
import { EmailPageMainComponent } from './pages/email-page-main/email-page-main.component';
import { AssignTraysUserComponent } from './pages/assign-trays-user/assign-trays-user.component';
import { AssignEmailUserComponent } from './pages/assign-email-user/assign-email-user.component';
import { AnsPageMainComponent } from './pages/ans-page-main/ans-page-main.component';
import { AssignPriorityPageMainComponent } from './pages/assign-priority-page-main/assign-priority-page-main.component';
import { SupervisorPageMainComponent } from './pages/supervisor-page-main/supervisor-page-main.component';
import { EmailReassignmentPageMainComponent } from './pages/email-reassignment-page-main/email-reassignment-page-main.component';
import { FolderPageMainComponent } from './pages/folder-page-main/folder-page-main.component';
import { AssignFolderUserComponent } from './pages/assign-folder-user/assign-folder-user.component';
import { MoveEmailToTraysComponent } from './pages/move-email-to-trays/move-email-to-trays.component';
import { MoveEmailToAccountComponent } from './pages/move-email-to-account/move-email-to-account.component';
import { SupervisorPageMainV2Component } from './pages/supervisor-page-main-v2/supervisor-page-main-v2.component';
import { TemplatesPageMainComponent } from './pages/templates-page-main/templates-page-main.component';
import { EmailSupportComponent } from './pages/email-support/email-support.component';
import { LabelPageMainComponent } from './pages/label-page-main/label-page-main.component';

const routes: Routes = [
  { path: '', component: LandingPageEmailManagerComponent },
  { path: 'correos', component: EmailManagerMainComponent },
  { path: 'contact-group', component: ContactGroupMainComponent },
  { path: 'categories', component: CategoryPageMainComponent },
  { path: 'states', component: StatePageMainComponent },
  { path: 'trays', component: TrayPageMainComponent },
  { path: 'email-config', component: EmailPageMainComponent },
  { path: 'assign-trays', component: AssignTraysUserComponent },
  { path: 'assign-emails', component: AssignEmailUserComponent },
  { path: 'ans', component: AnsPageMainComponent },
  { path: 'assign-priority', component: AssignPriorityPageMainComponent },
  { path: 'supervisor', component: SupervisorPageMainComponent },
  { path: 'email-reassignment', component: EmailReassignmentPageMainComponent },
  { path: 'templates', component: TemplatesPageMainComponent },
  { path: 'folders', component: FolderPageMainComponent },
  { path: 'assign-folders', component: AssignFolderUserComponent },
  { path: 'move-emails', component: MoveEmailToTraysComponent },
  { path: 'move-emails-account', component: MoveEmailToAccountComponent },
  { path: 'supervisor-v2', component: SupervisorPageMainV2Component },
  { path: 'templates', component: TemplatesPageMainComponent },
  { path: 'email-support', component: EmailSupportComponent },
  { path: 'labels', component: LabelPageMainComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailManagerRoutingModule { }
