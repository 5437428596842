import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PhonePanelService{

    private $statusSeePanelSoulPhone:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(){}


    /**
     * Metodo que se encarga de obntener el observable de estado
     */
    get statusSeePanelSoulPhone():Observable<boolean>{
        return this.$statusSeePanelSoulPhone
    }

    /**
     * Metdodo que se encarga de emitir un nuevo estado
     */
    set setStatusSeePanelSoulPhone(status:boolean){
        this.$statusSeePanelSoulPhone.next(status)
    }



}