import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { TemplateService } from '../../services/template.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { EmailService } from '../../services/email.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';
import { TemplatesComponent } from '../../components/templates/templates.component';

@Component({
  selector: 'app-templates-page-main',
  templateUrl: './templates-page-main.component.html',
  styleUrls: ['./templates-page-main.component.css']
})
export class TemplatesPageMainComponent implements OnInit {
  templates: any[] = [];
  filter: string = '';
  filterSelect: number = 0;
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Nombre de la plantilla', key: 'name' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  userLogin: any = null;
  emailsConfig: any[] = [];

  constructor(
    private matDialog: MatDialog,
    private templateService: TemplateService,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
    this.getTemplates();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que trae las plantillas registrados en el sistema. 
  */
  getTemplates(): void {
    if (this.filterSelect !== 0) {
      this.templateService.getTemplates(this.configPaginator, this.filter, true, this.filterSelect).subscribe(res => {
        this.templates = res.data.data.map((item: any) => {
          return {
            ...item,
            name: item.name,
            email: item.user_name_email_entrada,
            time: `Dia: ${item.day} - Hora: ${item.hour} - Minutos: ${item.minutes}`
          };
        });
        this.configPaginator.length = res.data.total;
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getTemplates();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que actualiza el estado de una plantilla. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateValueTemplate($event: any): void {
    const setDataState = { ...$event.item, state: $event.state };
    this.templateService.storeTemplate(setDataState).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getTemplates();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que permite ejecutar la modal para crear o actualizar una plantilla. 
    * @param template Plantilla seleccionada de la tabla. 
  */
  onClickShowTemplate(template: any = null): void {
    this.matDialog.open(TemplatesComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-template',
      data: {
        emailsConfig: this.emailsConfig,
        template
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getTemplates();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getTemplates();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que setea el filtro del select emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterSelect($event: number) {
    this.filterSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getTemplates();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
}