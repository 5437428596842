<div class="row">
  <div class="col-md-12">
    <table mat-table [dataSource]="dataContactGroup">
      <ng-container *ngFor="let column of configColumnsTable">
        <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions' && column.key !== 'mails'">
          <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
          <td mat-cell *matCellDef="let element" class="text-break">
            {{ element[column.key] }}
          </td>
        </ng-container>
        <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
          <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
          <td mat-cell width="10%" class="text-center" *matCellDef="let element">
            <div *ngIf="viewToSelectedGroups === true; else notToSelectedGroups">
              <mat-checkbox (change)="onChangeSelectContactGroup($event, element)" color="primary"></mat-checkbox>
              <button (click)="onClickMoreInfoContactGroup(element)" mat-icon-button>
                <span class="fi-rr-eye"></span>
              </button>
            </div>
            <ng-template #notToSelectedGroups>
              <button (click)="onClickMoreInfoContactGroup(element)" mat-icon-button>
                <span class="fi-rr-edit"></span>
              </button>
              <mat-slide-toggle color="primary" [checked]="element.state === 1 ? true : false"
                (change)="onClickChangeStateContactGroup($event, element)"></mat-slide-toggle>
            </ng-template>
          </td>
        </ng-container>
        <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'mails'">
          <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
          <td mat-cell *matCellDef="let element" class="truncate-cell">
            {{ element[column.key] }}
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
      <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
      <tr class="mat-row" style="background: white" *matNoDataRow>
        <td class="mat-cell" colspan="5">No existe información relacionada</td>
      </tr>
    </table>
    <mat-paginator (page)="onChangePage($event)" [length]="configPaginator.length"
      [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100]">
    </mat-paginator>
  </div>
</div>
<div *ngIf="viewToSelectedGroups === true" class="row d-flex justify-content-end mt-4">
  <div class="col-md-2">
    <button mat-dialog-close type="button" mat-raised-button class="w-100"
      style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
  </div>
  <div class="col-md-2">
    <button (click)="onSubmitGroupContactSelected()" [disabled]="contactGroupSelected.length === 0" type="button"
      mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;">Agregar</button>
  </div>
</div>