<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.titleDialog }}
  </div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formCategory">
    <div class="row d-flex align-items-end">
      <div class="col-md-9">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre de la categoría</mat-label>
          <input matInput placeholder="Nombre de la categoría" formControlName="name">
          <mat-error *ngIf="error.name.errors">
            <span *ngIf="error.name.errors.required">El campo es requerido</span>
            <span *ngIf="error.name.errors.maxlength">Máximo 30 caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <input type="color" matInput formControlName="color">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Emails de configuración</mat-label>
          <mat-select formControlName="id_email_config">
            <mat-option *ngFor="let item of data.emailsConfig" [value]="item.id">{{ item.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_email_config.errors">
            <span *ngIf="error.id_email_config.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!data.viewOnly" class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveCategory()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">{{ data.category === null ? 'Crear' : 'Guardar' }}</button>
      </div>
    </div>
  </form>
</div>