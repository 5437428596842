import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { DashboardDosService } from 'src/app/modules/livechat/services/rest/dashboardDos.service';
import { VideollamadaService } from 'src/app/shared/videollamada/services/videollamada.service';
import { ModalMeetingsComponent } from '../../components/modal-meetings/modal-meetings.component';

@Component({
  selector: 'app-dashboard-videocall',
  templateUrl: './dashboard-videocall.component.html',
  styleUrls: ['./dashboard-videocall.component.sass']
})
export class DashboardVideocallComponent implements OnInit, AfterViewInit {

  public icon_salas: string = 'fi-rr-video-camera';
  public espera: string = 'Video Llamadas en espera';
  public programada: string = 'Video Llamadas programadas';
  public proximas: string = 'Video Llamadas proximas a iniciar';
  public icon_agents: string = 'fi-users';
  public listos: string = 'Disponibles';
  public llamada: string = 'En llamada';
  public desconectados: string = 'Totales';
  public data: any = {};
  public AuthUser: any;
  public datosEnvio: any;
  public interval: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['first_name', 'id_number', 'adviser_id','client_name', 'client_nic', 'duration'];
  dataSource = new MatTableDataSource<any>();

  constructor(private service: DashboardDosService, public dialog: MatDialog, private videoService: VideollamadaService,
    private authService: AuthService, private destroyService: DestroyComponentService) {

    this.AuthUser = this.authService.getUser();
    this.videoService.getAdviserById(this.AuthUser.rrhh_id).subscribe(resp => {

      this.datosEnvio = {
        "campaign_id" : resp.campaign_id
      }

      this.service.dashboard(this.datosEnvio).subscribe(resp =>{
          this.datos(resp);
      });

    });

   }

  ngOnInit(): void {

    this.interval = interval(5000)
    .pipe(
        mergeMap(() => this.service.dashboard(this.datosEnvio))
    )
    .subscribe(data => this.datos(data));

  }

  datos(data){
    this.data = data;

    data.agentsBusyDetails.forEach(element => {

      if(element.start_date !== null){
        element.start_date = new Date( new Date().getTime() - new Date(element.start_date).getTime() );
      }

    });

    this.dataSource.data = data.agentsBusyDetails;
  }

  modal(info){
    this.dialog.open(ModalMeetingsComponent, {
      width: '60%',
      data: info,
      panelClass: 'custom-dialog-container'
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
    this.interval.unsubscribe();
  }

}
