import * as moment from 'moment';

export const createDataFormCrmV2 = (dataForm: any, typeCreate: string, form: any, formAction: string = 'Crear'): FormData => {
  try {
    /** Variable de control donde se recolectara la informaicon por separado para su funcion especifica **/
    let listIdFiles: string[] = [] //Arreglo donde se envia los Id delos campos tipo file apra relacionar los archivos
    let listclientNewInfo: any[] = []//Arreglo de objetos { 1123154:value} id:valor para identificar las respuestas del formulario
    let listPreload: any[] = [] // Arreglo de Fields que marcan como preload(precargados)
    let formAnswerIndexData: any = {} //Objeto Id:value {1354156:value} donde se almaceanan todas las respuestas de todos los campos del formulario sin importar las secciones
    let formAnswerData2: any = {} //Objeto que indexa lo valores tipificados en un unico objeto de respuestas
    let listReports: any = {} // Objeto unico de Id:value {1325645:value} done van el id y valor de todos los campos marcados para reporteria
    let listLabelsReports: any = {} // Objeto unico de Id:value {1325645:label} done van el id y Label de todos los campos marcados para reporteria
    let isAnonymous: string = "0"; //Variable que valdia si dentro del formulario hay algun campo que indique que el formulario se manjara de forma anonima
    let listTypificated: any = {} //Objeto unico id:value {112358:value} con todos los campos que se marquen para tipificacion
    let listSelect = {}; //Variable que se encarga de almacenar un objeto unico de clave valor para todos los selectores tipificados
    let notificationIndexData: any = {} //Variable que se encarga de almacenar un objeto unico de clave valor con lso valores y label de las opciones marcdas en el formulario
    let sections = JSON.parse(JSON.stringify(dataForm.sections))
    let demarche: any = { client_id: null, form_id: null, user_id: null, sections: [] };

    sections.forEach((section: any, index: number) => {
      if (Array.isArray(section.fields)) {
        section.fields.forEach((field: any, indexField: number) => {
          if ((typeCreate == "typification" || typeCreate == "editFromTrays") && dataForm.hasOwnProperty('answer') == true) {
            for (const key in dataForm.answer[index]) {
              if (key === field.key && field.seeSons === true) {
                field.value = dataForm.answer[index][key];
              }
            }
          }
          //En caso de que sea vacio se asigna el valor False
          if (field.isClientInfo) {
            let modelClientInfo = {}
            modelClientInfo[field.id] = field.value
            listclientNewInfo.push(modelClientInfo)
          } else {
            field.isClientInfo = false
          }
          switch (field.controlType) {
            case 'currency':
              //remplazamos la (,) de los valores tipo mondea por espacio en blanco
              field.value = field.value.replaceAll(/,/ig, '')
              break;
            case 'datepicker':
              if (field.value !== null && field.value !== "") {
                field.value = moment(field.value).format('YYYY-MM-DD');
              }
              break;
            case 'multiselect':
              if (!Array.isArray(field.value)) {
                field.value = [field.value]
              }
              break;
            case 'file':
              if (typeCreate != "editFromTrays") {
                if (field.value != '') {
                  field['storage_path'] = "@@" + field.id + "@@";
                  listIdFiles.push(field.id)
                }
                if (field.storage_path != "") {
                  field['storage_path'] = "@@" + field.id + "@@"
                }
              } else {
                if (field.storage_path != "" && field.storage_path == "@@" + field.id + "@@") {
                  listIdFiles.push(field.id)
                }
              }
              break;
          }
          //Si el field tiene algun valor y es de precarga lo agregamos al array de valores precargados
          if (field.value && (field.preloaded !== undefined && field.preloaded !== null && field.preloaded !== '')) {
            listPreload.push(field)
          }
          //Si el campo tiene algun valor Tipoficado Su valor se indexa en el formAnwerIndexData
          if (field.value !== undefined && field.value !== null && field.value !== '') {
            //formAnswerIndexData = this.getObjectWithValues(formAnswerIndexData,field,{formAnswerIndexData:true})
            if (field.controlType == 'multiselect') {
              if (Array.isArray(field.value) == true) {
                formAnswerIndexData[field.id] = field.value.toString()
              } else {
                formAnswerIndexData[field.id] = field.value
              }
            } else {
              formAnswerIndexData[field.id] = field.value
            }
            if (field.type == "options") {
              if (field.controlType == 'multiselect') {

                if (Array.isArray(field.value) == true) {
                  let labels = "";
                  let optionsFinded = field.options.filter(option => field.value.find(ffnn => ffnn == option.id) !== undefined)
                  if (Array.isArray(optionsFinded) == true && optionsFinded.length > 0) {
                    optionsFinded.forEach((finded: any, i: number) => {
                      labels += finded.name
                      if (i > 0 && i < (optionsFinded.length - 1)) {
                        labels += ","
                      }
                    });
                  }

                  notificationIndexData[field.id] = labels
                } else {
                  let optionFinded = field.options.find(option => option.id == field.value)
                  if (optionFinded !== undefined) {
                    notificationIndexData[field.id] = optionFinded.name
                  }
                }

              } else {
                let optionFinded = field.options.find(option => option.id == field.value)
                if (optionFinded !== undefined) {
                  notificationIndexData[field.id] = optionFinded.name
                }
              }
            } else {
              notificationIndexData[field.id] = field.value
            }
          }//Fin recolexcion formAnswerIndexData
          //Si se marca el campo como entrada para reprte se almacena en su objeto clave valor como identificador
          if (field.hasOwnProperty('inReport') == true && field.inReport !== undefined && field.inReport == true) {
            listReports = getObjectWithValues(listReports, field, { toReport: true })
            listLabelsReports[field.id] = field.label
          }
          //En caso de ser marcado como Tipificar este se almacenara en el objeto Clave Valor apra su indexacion
          if (field.hasOwnProperty('isTypificated') == true && field.isTypificated !== undefined && field.isTypificated == true) {
            listTypificated = getObjectWithValues(listTypificated, field, { typificated: true })
          }
          //Si algun campo tiene como nombre anonimo se asumira que el formulario cuenta con un apartado de anonimato porlo que se envia 1 como true
          if (field.key.includes('anonimo') && field.value == '1') {
            isAnonymous = "1"
          }
          if (field.type == "options") {
            let tempOptionObject = {};
            field.options.forEach(option => {
              tempOptionObject[option.id] = option.name
            });
            formAnswerData2[field.id] = tempOptionObject
          }
          //Pasamos los valores enviados para la tipififacion dentro del clien_unique envez de enviarle solo el Id con @@
          if (field.client_unique !== undefined && field.client_unique == true) {
            if (form['client_unique'] !== undefined) {
              form['client_unique'].forEach(unique => {
                if (unique.id == field.id) {
                  unique.value = field.value
                }
              });
            }
          }
          //Recogemos los labels de los selectores en el formulario
          if (field.options.length > 0) {
            //Si el valor es un array es un Multiselect
            if (Array.isArray(field.value)) {
              listSelect[field.id] = []
              field.value.forEach(option => {
                listSelect[field.id].push(option);
              });
            } else {
              if (field.value && field.value != "") {

              }
              listSelect[field.id] = [field.value]
            }
          }
          //La validacion y limpieza de esta opcion siempre se debe hacer a lo ultimo de la iteracion
          //Ya que las opciones cargas desde un enpoint no se registraran dentro del formulario pero si se usaran para determinar los valores de algunas variables de control en la cabecera del metodo
          if (field.hasOwnProperty('isChargeableOption') == true && field.isChargeableOption && field.isChargeableOption == true) {
            field.options = []
          }
        });
      }
    });
    //Se carga la informacion a guardar entro de nustro Objeto padre
    demarche.sections = sections;
    demarche['formAnswerIndexData'] = JSON.stringify(formAnswerIndexData)
    demarche['reporting_fields'] = JSON.stringify(listReports)
    demarche['headers_reporting_fields'] = JSON.stringify(listLabelsReports)
    demarche['typified_fields'] = JSON.stringify(listTypificated)
    demarche['attachmentsList'] = JSON.stringify(listIdFiles)
    demarche['clientInfo'] = JSON.stringify(listclientNewInfo)
    demarche['preloaded'] = JSON.stringify(listPreload)
    demarche['indexData'] = JSON.stringify(formAnswerData2)
    demarche['listSelect'] = JSON.stringify(listSelect)
    demarche['notificationIndexData'] = JSON.stringify(notificationIndexData)
    const formData = new FormData();
    if (formAction === 'Crear') {
      demarche.client_id = null
    }
    // if(this.trayEdit['client_id'] && typeCreate == 'editFromTrays' ){
    //   this.demarche.client_id = this.trayEdit['client_id']
    // }

    // if(this.trayEdit['form_answer_id'] && typeCreate == 'editFromTrays'){
    //   formData.append('form_answer_id', this.trayEdit['form_answer_id']);
    // }
    formData.append('form_id', String(form.id));
    formData.append('sections', JSON.stringify(demarche.sections));
    formData.append('client_id', demarche.client_id);
    formData.append('user_id', demarche.user_id);
    formData.append('client_unique', JSON.stringify(form['client_unique']));
    formData.append('chronometer', null);
    formData.append('listSelect', demarche['listSelect']);
    formData.append('formAnswerIndexData', demarche.formAnswerIndexData);
    formData.append('reporting_fields', demarche.reporting_fields);
    formData.append('headers_reporting_fields', demarche['headers_reporting_fields']);
    formData.append('attachmentsList', demarche.attachmentsList);
    formData.append('clientInfo', demarche.clientInfo);
    formData.append('preloaded', demarche.preloaded);
    formData.append('typified_fields', demarche.typified_fields);
    formData.append('isAnonymous', isAnonymous);
    formData.append('indexData', demarche.indexData);
    formData.append('notificationIndexData', demarche['notificationIndexData']);
    formData.append('chanel', JSON.stringify(5));
    dataForm.files.forEach((element) => {
      formData.append(element.id, element.file);
    });
    return formData;
  } catch (error) {
    throw new Error(error);
  }
}

export const getObjectWithValues = (objectToModify: any, field: any, config?: any): any => {
  if (field.options.length > 0 && field.controlType == 'multiselect') {
    if (Array.isArray(field.value)) {
      let labels = "";
      field.value.forEach(valueSelect => {
        let optionFinded = field.options.filter(filterd => filterd.id == valueSelect);
        if (optionFinded) {
          labels += optionFinded[0].name + ",";
        }
      });
      objectToModify[field.id] = labels;
    }
  } else if (field.options.length > 0) {
    let optionSelected = field.options.filter(filterd => filterd.id == field.value);
    if (optionSelected && optionSelected.length > 0) {
      objectToModify[field.id] = optionSelected[0].name;
    }
  }
  if (field.options.length <= 0) {
    if ((config && config.hasOwnProperty('typificated') == true && config.typificated == true)) {
      objectToModify[field.id] = field.value;
    }
    if ((config && config.hasOwnProperty('formAnswerIndexData') == true && config.formAnswerIndexData == true) && field.controlType == 'file') {
      if (field['storage_path']) {
        objectToModify[field.id] = field['storage_path'];
      }
    } else {
      objectToModify[field.id] = field.value;
    }
    if (!config && field.value && field.value !== "") {
      objectToModify[field.id] = field.value;
    }
  }
  return objectToModify;
}