import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailService } from '../../services/email.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-assign-email',
  templateUrl: './assign-email.component.html',
  styleUrls: ['./assign-email.component.css']
})
export class AssignEmailComponent implements OnInit {
  advisersToRolManagerEmail: any[] = [];
  filteredOptions: Observable<any[]> = null;
  nameAdviser: string = '';
  formAssignEmail: FormGroup = new FormGroup({
    ids_email: new FormControl(null, []),
    id_user_asignado: new FormControl(null, [Validators.required]),
    name_user_asignado: new FormControl('', [Validators.required]),
    observacion_asignacion: new FormControl({ value: this.data.isEmailReassignment ? 'Este correo fue reasignado' : '', disabled: false }, [Validators.required, Validators.maxLength(500)]),
    email: new FormControl({ value: '', disabled: true }, [Validators.required])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<AssignEmailComponent>,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
    this.getAdviserssByRolManagerEmail();
    if (this.data.email) {
      const idListEmails = this.data.email.map((item: any) => item.id);
      const listNamesEmails = this.data.email.map((item: any) => item.de).toString();
      this.formAssignEmail.get('ids_email').setValue(JSON.stringify(idListEmails));
      this.formAssignEmail.get('email').setValue(listNamesEmails);
    }
    this.filteredOptions = this.formAssignEmail.get('name_user_asignado').valueChanges.pipe(
      startWith(''),
      map(value => this.filterAdviser(value))
    );
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-09-26
    * Filtra el array de asesores a traves de lo digitado en el input.  
  */
  private filterAdviser(value: string): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.advisersToRolManagerEmail.filter(option => option.name_user_asignado.toLowerCase().includes(filterValue));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formAssignEmail.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que guarda la asignacion de un correo. 
  */
  saveAssignMail(): void {
    if (this.formAssignEmail.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.emailService.assignUserToEmail(this.formAssignEmail.value).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: this.data.isEmailReassignment ? 'La reasignación se ha realizado con éxito.' : 'Se han asignado los correos con éxito'
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          })
        }
      });
    } else {
      this.formAssignEmail.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que trae los usuarios que tienen el rol de gestor de correos. 
  */
  getAdviserssByRolManagerEmail(): void {
    this.emailService.getConfigEmailByUser(null, this.data.configEmailsSelected).subscribe(res => {
      this.advisersToRolManagerEmail = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-26
    * Metodo que busca el asesor para sacar el rrhh id. 
    * @param $event Evento emitido por el option. 
  */
  findAdviser($event: MatOptionSelectionChange): void {
    const adviser = this.advisersToRolManagerEmail.find(item => item.id_rrhh === $event.source.value);
    if (adviser) {
      this.formAssignEmail.get('id_user_asignado').setValue(adviser.id_rrhh);
      this.formAssignEmail.get('name_user_asignado').setValue(adviser.name_user_asignado);
      this.nameAdviser = adviser.name;
    }
  }
}