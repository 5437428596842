import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-reassignment-page-main',
  templateUrl: './email-reassignment-page-main.component.html',
  styleUrls: ['./email-reassignment-page-main.component.css']
})
export class EmailReassignmentPageMainComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    
  }
}