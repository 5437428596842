import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { AssignPriorityComponent } from '../../components/assign-priority/assign-priority.component';
import { AssignPriorityService } from '../../services/assign-priority.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { EmailService } from '../../services/email.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';
import { TrayService } from '../../services/tray.service';

@Component({
  selector: 'app-assign-priority-page-main',
  templateUrl: './assign-priority-page-main.component.html',
  styleUrls: ['./assign-priority-page-main.component.css']
})
export class AssignPriorityPageMainComponent implements OnInit {
  assignsPriority: any[] = [];
  filter: string = '';
  filterSelect: number = 0;
  filterOptionalSelect: number = 0;
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Cuenta de correo', key: 'user_name_email_entrada' },
    { name: 'Correo electrónico', key: 'email' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  userLogin: any = null;
  emailsConfig: any[] = [];
  trays: any[] = [];

  constructor(
    private matDialog: MatDialog,
    private assignPriorityService: AssignPriorityService,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService,
    private authService: AuthService,
    private trayService: TrayService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
    this.getTrays();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que trae las asignaciones prioritarias registrados en el sistema. 
  */
  getAssignsPriority(): void {
    if (this.filterSelect !== 0) {
      this.assignPriorityService.getAssignsPriority(this.configPaginator, this.filterSelect, this.filter, this.filterOptionalSelect).subscribe(res => {
        this.assignsPriority = res.data.data;
        this.configPaginator.length = res.data.total;
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getAssignsPriority();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que actualiza el estado de una asignacion prioritaria. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateValueAns($event: any): void {
    const setDataState = { ...$event.item, state: $event.state };
    this.assignPriorityService.storeAssignPriority(setDataState).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getAssignsPriority();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que permite ejecutar la modal para crear o actualizar una asignacion prioritaria. 
    * @param state Estado seleccionada de la tabla. 
    * @param titleDialog Titulo que va a tener la modal. 
    * @param viewOnly Modo de vizualizacion de la modal. 
  */
  onClickShowPriority(assignPriority: any = null, titleDialog: string = 'Asignar prioridad', viewOnly: boolean = false): void {
    this.matDialog.open(AssignPriorityComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-assign-priority',
      data: {
        titleDialog,
        assignPriority,
        viewOnly,
        emailsConfig: this.emailsConfig,
        trays: this.trays
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getAssignsPriority();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getAssignsPriority();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que setea el filtro del select emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterSelect($event: number) {
    this.filterSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getAssignsPriority();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-23
    * Metodo que trae solo las bandejas prioritaria y spam. 
  */
  getTrays(): void {
    this.trayService.getTrays(null, '', false, 0, 1).subscribe(res => {
      this.trays = res.data.filter((item: any) => item.key === 'prioritaria' || item.key === 'spam');
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-23
    * Metodo que setea el filtro del select opcional emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterOptionalSelect($event: number) {
    this.filterOptionalSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getAssignsPriority();
  }
}