<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">{{ data.titleDialog }}</div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formAssignPriority">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select formControlName="id_email_config">
            <mat-option *ngFor="let email of data.emailsConfig" [value]="email.id">{{ email.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_email_config.errors">
            <span *ngIf="error.id_email_config.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div *ngIf="data.assignPriority === null" class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListEmails>
            <mat-chip *ngFor="let item of formAssignPriority.get('listEmails')?.controls; let i = index" [selectable]="true"
              [removable]="true" (removed)="removeItem(i)">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Correos" #inputEmails [matChipInputFor]="chipListEmails"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEmail($event)">
          </mat-chip-list>
        </mat-form-field>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.listEmails.errors">
          <span *ngIf="error.listEmails.errors.validateArrayNotEmpty">El campo es requerido</span>
          <span *ngIf="error.listEmails.errors.validateIsEmails">Correo invalido</span>
        </div>
      </div>
      <div *ngIf="data.assignPriority !== null" class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Correo</mat-label>
          <input matInput placeholder="Correo" formControlName="email">
          <mat-error *ngIf="error.email.errors">
            <span *ngIf="error.email.errors.required">El campo es requerido</span>
            <span *ngIf="error.email.errors.email">Correo inválido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Bandejas</mat-label>
          <mat-select formControlName="id_bandeja">
            <mat-option *ngFor="let tray of data.trays" [value]="tray.id">{{ tray.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_bandeja.errors">
            <span *ngIf="error.id_bandeja.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!data.viewOnly" class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveAssignPriority()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Guardar</button>
      </div>
    </div>
  </form>
</div>