<div class="d-flex justify-content-between align-items-center p-2" style="background-color: #51B0CB;">
  <div style="font-weight: 700; font-size: 18px; color: #FFFFFF;">
    Nuevo mensaje
  </div>
  <div style="color: #FFFFFF;">
    <button (click)="onClickNabvarButton('minus')" mat-icon-button>
      <i class="fi-rr-minus"></i>
    </button>
    <button (click)="onClickNabvarButton('cross')" mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="py-2 px-3">
  <div *ngIf="typeManagerMessage !== 'Guardar información'" class="row">
    <div class="col-md-12">
      <div class="mb-4" style="font-weight: 700; font-size: 16px;">Configuración de correo</div>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select [formControl]="formNewMessage.get('emailConfig')" placeholder="Configuración de correo"
          (selectionChange)="onChangeSelectEmailConfig($event)">
          <mat-option *ngFor="let item of emailsConfig" [value]="item.id_email">{{ item.user_name_email_entrada
            }}</mat-option>
        </mat-select>
        <mat-error *ngIf="error.emailConfig.errors">
          <span *ngIf="error.emailConfig.errors.required">El campo es requerido</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="formNewMessage.get('emailConfig').value !== null">
    <div class="row" *ngIf="formCrm !== null && formId !== null">
      <div class="col-md-12">
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div style="font-weight: 700; font-size: 16px;">Gestión de formulario</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <shared-dynamic-form-v2 *ngIf="formCrm !== null" #formCrmComponent
              [sections]="{sections: formCrm?.sections, app: 'crm2'}" [formId]="formId"
              (submitForm)="submitFormCrm($event)" [showButtonsActions]="false"></shared-dynamic-form-v2>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-4" style="font-weight: 700; font-size: 16px;">Correo</div>
        <app-mail-form [formNewMessage]="formNewMessage" [typeManagerMessage]="typeManagerMessage"
          [templates]="templates" [filesToLoadEmail]="filesToLoadEmail" (onChangeTemplate)="onChangeTemplate($event)"
          (onDeleteFilesToLoadEmail)="onDeleteFileToLoadEmail($event)"
          (clearMessage)="formNewMessage.get('message').setValue('')"></app-mail-form>
      </div>
    </div>
    <div class="row py-3 d-flex justify-content-end">
      <div class="col-md-3">
        <button (click)="saveMessageToDraftEmail()" type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Descartar</button>
      </div>
      <div class="col-md-3">
        <button (click)="validateTypeManagerForSubmitMail()" type="button" mat-raised-button color="primary"
          class="w-100" style="height: 48px; font-weight: 600;">{{ typeManagerMessage }}</button>
      </div>
    </div>
  </div>
</div>