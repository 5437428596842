<div class="row">
  <div class="col-10">
    <h3 class="title" mat-dialog-title>Detalles de la Videollamada</h3>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i class="fi-rr-cross-small" style="cursor:pointer; font-size: 20px; color: #353535;" mat-icon-button mat-dialog-close></i>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <!-- Detalles de la Agenda -->
  <div class="col-12">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>NIC Cliente</mat-label>
      <input matInput [value]="roomInfo.client_nic" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Nombre Cliente</mat-label>
      <input matInput [value]="roomInfo.client_name" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Agenda Programada</mat-label>
      <input matInput [value]="roomInfo.scheduled_date" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Hora Programada</mat-label>
      <input matInput [value]="roomInfo.scheduled_time" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Estado de la Agenda</mat-label>
      <input matInput [value]="roomInfo.state_name" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Duración de la Sesión</mat-label>
      <input matInput [value]="roomInfo.duration" readonly>
    </mat-form-field>
  </div>

  <!-- Participantes -->
  <div class="col-12">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Participantes</mat-label>
      <input matInput [value]="roomInfo.participantsNames" readonly>
    </mat-form-field>
  </div>

  <!-- Agente Asignado y Agente que Atendió -->
  <div class="col-12">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>ID Agente Asignado</mat-label>
      <input matInput [value]="roomInfo.adviser_id" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Nombre Agente Asignado</mat-label>
      <input matInput [value]="roomInfo.adviser_name" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>ID Agente que Atendió</mat-label>
      <input matInput [value]="roomInfo.attended_by_agent_id" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Nombre Agente que Atendió</mat-label>
      <input matInput [value]="roomInfo.attended_by_agent_name" readonly>
    </mat-form-field>
  </div>

  <!-- Documentos Adjuntos -->
  <!-- Documentos adjuntos -->
  <div class="row mt-3">
    <div class="col-12">
      <p><strong>Documentos Adjuntos:</strong></p>
      <div *ngIf="roomInfo.files?.length > 0; else noFiles">
        <div *ngFor="let file of roomInfo.files">
          <p class="mb-1">
            {{ file.name }}
            <a [href]="file.url" target="_blank" style="text-decoration: none;">
              <i class="fi-rr-eye" style="font-size: 20px; color: #007bff; cursor: pointer;"></i>
            </a>
          </p>
        </div>
      </div>
      <ng-template #noFiles>
        <p>No hay documentos adjuntos disponibles.</p>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
