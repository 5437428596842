import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AssignCategoryComponent } from '../assign-category/assign-category.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EmailService } from '../../services/email.service';
import { AssignEmailComponent } from '../assign-email/assign-email.component';
import { MassiveManagementComponent } from '../massive-management/massive-management.component';
import { NewMessageComponent } from '../new-message/new-message.component';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';

@Component({
  selector: 'app-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.css'],
})
export class MailingListComponent implements OnInit, OnChanges {
  mailSelected: any = null;
  mailsSelected: any[] = [];
  checkAllSelect: boolean = false;
  @Input() listMails: any[] = [];
  @Input() configPaginator: PageEvent = null;
  @Input() configEmailsSelected: any[] = [];
  @Input() keyTraySelected: string = '';
  @Input() totalNumberEmails: number = 0;
  @Output() selectMail: EventEmitter<any> = new EventEmitter<any>();
  @Output() changePage: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() updateMails: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveEmail: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor(
    private matDialog: MatDialog,
    private emailService: EmailService,
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.keyTraySelected?.currentValue !== changes.keyTraySelected?.previousValue) {
      this.mailsSelected = [];
      this.checkAllSelect = false;
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-04
    * Metodo que envia la informacion del email seleccionado al componente padre. 
  */
  onClickSelectMail(mail: any): void {
    this.emailService.getEmailById(mail).subscribe(res => {
      this.mailSelected = res.data;
      if (this.mailSelected.key === 'borrador' || this.mailSelected.key === 'salida') {
        this.matDialog.open(NewMessageComponent, {
          width: '70%',
          maxHeight: '95vh',
          autoFocus: false,
          panelClass: 'dialog-padding-email-manager',
          disableClose: true,
          data: {
            email: this.mailSelected
          }
        }).afterClosed().subscribe(res => {
          if (res) this.updateMails.emit();
        });
      } else {
        this.selectMail.emit(this.mailSelected);
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que emite el cambio de la paginacion al componente padre.
    * @param $event Evento emitido por el paginador.  
  */
  onChangePage($event: PageEvent): void {
    this.changePage.emit($event);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que abre la modal para asignar una categoria.  
  */
  onClickAssignToCategory(mail: any | any[]): void {
    this.matDialog.open(AssignCategoryComponent, {
      width: '35%',
      autoFocus: false,
      panelClass: 'dialog-padding-assign-category',
      data: {
        mail
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.updateMails.emit();
        this.mailsSelected = [];
        this.checkAllSelect = false;
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-11
    * Metodo que añade un correo a la lista de correos seleccionados. 
    * @param $event Evento emitido por el checkbox.  
    * @param mail Correo seleccionado de la lista. 
  */
  onClickCheckboxMail($event: MatCheckboxChange, mail: any): void {
    if ($event.checked) this.mailsSelected.push(mail);
    else this.mailsSelected = this.mailsSelected.filter((item: any) => item.id !== mail.id);
    this.checkAllSelect = this.mailsSelected.length === this.totalNumberEmails ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-11
    * Metodo que selecciona todos los correos de la lista de correos.  
    * @param $event Evento emitido por el checkbox.  
  */
  onClickSelectAllMails($event: MatCheckboxChange): void {
    this.checkAllSelect = $event.checked;
    if ($event.checked) {
      this.listMails.forEach((item: any) => {
        item.mails.forEach((mail: any) => {
          this.mailsSelected.push(mail);
        });
      });
    }
    else this.mailsSelected = [];
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-11
    * Metodo que valida si esta o no seleccionado el correo dentro de la lista de correos seleccionados. 
    * @param mail Mail ubicado en la lista de correos. 
    * @returns Valor booleano con la respectiva validacion. 
  */
  ifSelectedMail(mail: any): boolean {
    return this.mailsSelected.filter((item: any) => item.id === mail.id).length > 0 ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-31
    * Metodo que muestra la modal de asignacion de correos.
  */
  onClickShowAssignEmail(): void {
    this.matDialog.open(AssignEmailComponent, {
      width: '40%',
      autoFocus: false,
      panelClass: 'dialog-padding-assign-email',
      data: {
        title: 'Asignación de correo',
        email: this.mailsSelected,
        isEmailReassignment: false,
        configEmailsSelected: this.configEmailsSelected.map(item => item.id_email).toString()
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.updateMails.emit();
        this.mailsSelected = [];
        this.checkAllSelect = false;
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-10
    * Metodo que muestra la modal de gestion masiva de correos.
  */
  onClickShowMassiveManagmentEmails(): void {
    this.matDialog.open(MassiveManagementComponent, {
      width: '80%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-massive-management-emails',
      data: {
        emails: this.mailsSelected
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.updateMails.emit();
        this.mailsSelected = [];
        this.checkAllSelect = false;
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-20
    * Metodo que elimina los correos seleccionados en la lista.
  */
  deleteEmailsSelected(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De eliminar el correo? <b>Este quedara almacenado en la bandeja de eliminados.</b>').then(confirm => {
      if (confirm.isConfirmed) {
        const mapIdsEmails = this.mailsSelected.map(item => item.id).toString();
        const formData = new FormData();
        formData.append('email_list', `[${mapIdsEmails}]`);
        this.emailService.deleteEmails(formData).subscribe(res => {
          this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
            data: {
              title: '¡Excelente!',
              message: res.data
            },
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['green-snackbar']
          });
          this.updateMails.emit();
          this.mailsSelected = [];
          this.checkAllSelect = false;
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-20
    * Metodo que restaura los correos seleccionados en la lista.
  */
  restoreEmailsSelected(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De restaurar el correo? <b>Este quedara almacenado en la bandeja original.</b>').then(confirm => {
      if (confirm.isConfirmed) {
        const mapIdsEmails = this.mailsSelected.map(item => item.id).toString();
        const formData = new FormData();
        formData.append('email_list', `[${mapIdsEmails}]`);
        this.emailService.restoreEmails(formData).subscribe(res => {
          this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
            data: {
              title: '¡Excelente!',
              message: res.data
            },
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['green-snackbar']
          });
          this.updateMails.emit();
          this.mailsSelected = [];
          this.checkAllSelect = false;
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-26
    * Metodo que emite los ID de los correo seleccionado para ser movidos.
  */
  onClickMoveEmail(): void {
    this.moveEmail.emit(this.mailsSelected);
  }
}