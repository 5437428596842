<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">{{ titlePage }}</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">{{ descriptionPage }}</p>
    </div>
  </div>
  <div class="row my-5 justify-content-start">
    <div class="col-md-3" *ngIf="showFilterDate">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label class="fs-6">Seleccione una fecha</mat-label>
        <input [(ngModel)]="dateFilter" matInput [matDatepicker]="picker" (dateChange)="onChangeDateFilter.emit($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="selectList.length > 0" class="col-md-3">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label class="fs-6">{{ textSelectFilter }}</mat-label>
        <mat-select [(ngModel)]="selectFilterModel" (selectionChange)="onChangeSelectFilter($event)">
          <mat-option *ngFor="let item of selectList" [value]="item.id">{{ item.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3" *ngIf="optionalSelectList.length > 0">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label class="fs-6">{{ textOptionalSelectFilter }}</mat-label>
        <mat-select [(ngModel)]="selectOptionalFilterModel" (selectionChange)="onChangeOptionalSelectFilter($event)">
          <mat-option *ngFor="let item of optionalSelectList" [value]="item.id">{{ item.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3" *ngIf="showFilterText">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label class="fs-6">{{ textInputFilter }}</mat-label>
        <input [(ngModel)]="textFilterModel" matInput [placeholder]="textInputFilter" (keydown.enter)="onChangeFilter($event)">
        <span matSuffix class="fi-rr-search fs-5"></span>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <button *ngIf="textButtonPage" (click)="onClickButtonAdd()" type="button" mat-raised-button color="primary"
        class="w-100" style="height: 48px; font-weight: 600;">{{
        textButtonPage }}
        <span class="fi-rr-add ms-3"></span>
      </button>
    </div>
    <div class="col-md-2" *ngIf="showClearFilters">
      <button *ngIf="textButtonPage" (click)="onClickClearFilters()" type="button" mat-raised-button color="primary"
        class="w-100" style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Limpiar
        <span class="fi-rr-trash ms-3"></span>
      </button>
    </div>
    <div *ngIf="showAditionalButton" class="col-md-3">
      <button (click)="onClickAditionalButton.emit()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">{{
        textAditionalButton }}
      </button>
    </div>
    <div *ngIf="showAditionalButtonTwo" class="col-md-3">
      <button (click)="onClickAditionalButtonTwo.emit()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">{{
        textAditionalButtonTwo }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <shared-basic-table *ngIf="configColumnsTable.length > 0" [columnsTable]="configColumnsTable"
        [buttonsOptions]="buttonsOptions" [dataTables]="dataTable" [configPaginator]="configPaginator"
        [showTooltipTable]="showTooltipTable" (eventEmitButton)="onClickButtonTable($event)"
        (eventEmitSlider)="onClickChangeState($event)" (updatePaginator)="onChangePage($event)"
        (eventEmitCheck)="onRowSelected.emit($event)"></shared-basic-table>
      <!-- <table mat-table [dataSource]="dataTable">
        <ng-container *ngFor="let column of configColumnsTable">
          <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell *matCellDef="let element"> {{ element[column.key] }} </td>
          </ng-container>
          <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell width="10%" class="text-center ps-0" *matCellDef="let element">
              <button [disabled]="(element.hasOwnProperty('is_editable') && element.is_editable === 2)" (click)="onClickButtonUpdate(element)" mat-icon-button style="width: 25px !important;">
                <span class="fi-rr-edit"></span>
              </button>
              <mat-slide-toggle *ngIf="element.hasOwnProperty('state')" (change)="onClickChangeState($event, element)"
                [disabled]="(element.hasOwnProperty('is_editable') && element.is_editable === 2)"
                color="primary" [checked]="element.state === 1 ? true : false"></mat-slide-toggle>
              <button *ngIf="showAditionalButtonTable" (click)="onClickAditionalButtonTable(element)" mat-icon-button style="width: 25px !important;">
                <span class="fi-rr-layers"></span>
              </button>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
        <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
        <tr class="mat-row" style="background: white" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="keysColumnsTable.length">La búsqueda no coincide con los registros</td>
        </tr>
      </table>
      <mat-paginator (page)="onChangePage($event)" [length]="configPaginator.length"
        [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]">
      </mat-paginator> -->
    </div>
  </div>
</div>