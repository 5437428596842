import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { validateArrayNotEmpty, validateIsEmails, validateSizeFiles } from '../../constantes/custom-validations';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { FormsRequestService } from 'src/app/modules/crm_v2/services/rest/forms-request.service';
import { StateService } from '../../services/state.service';
import { createDataFormCrmV2 } from '../../constantes/create-data-form-crm';
import { DynamicFormV2Component } from 'src/app/shared/dynamic-forms-v2/dynamic-form/dynamic-form.component';
import { EmailService } from '../../services/email.service';
import { MatSelectChange } from '@angular/material/select';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { TemplateService } from '../../services/template.service';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Component({
  selector: 'app-manager-message',
  templateUrl: './manager-message.component.html',
  styleUrls: ['./manager-message.component.css'],
  
})
export class ManagerMessageComponent implements OnInit, OnChanges {
  @Input() typeManagerMessage: string = 'Enviar';
  @Input() emailSelected: any = null;
  @Input() emailsSelectedByMassiveManager: any[] = [];
  @Input() emailDataDraft: any = null;
  @Output() submitMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClickCancelButton: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('formCrmComponent') private formCrmComponent: DynamicFormV2Component;
  formNewMessage: FormGroup = null;
  formId: number = null;
  formCrm: any = null;
  states: any[] = [];
  formStructureCrm: any = null;
  validFormCrm: boolean = false;
  emailsConfig: any[] = [];
  userLogin: any = null;
  templates: any[] = [];
  filesToLoadEmail: any[] = [];
  filesToAttachments: any[] = [];
  labels: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar,
    private formRequestService: FormsRequestService,
    private stateService: StateService,
    private emailService: EmailService,
    private authService: AuthService,
    private templateService: TemplateService,
    private crudService: CrudService
  ) { }

  ngOnInit(): void {
    this.userLogin = this.authService.getUser();
    this.setFormGroupByTypeManager();
    this.getEmailsConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userLogin = this.authService.getUser();
    this.formId = null;
    this.formCrm = null;
    this.setFormGroupByTypeManager();
    this.getEmailsConfig();
    this.filesToAttachments = this.typeManagerMessage === 'Reenviar' ? this.emailSelected.attachments : [];
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que setea la estructura del formulario.  
  */
  setFormGroupByTypeManager(): void {
    if (this.typeManagerMessage === 'Enviar' || this.typeManagerMessage === 'Responder' || this.typeManagerMessage === 'Reenviar' || this.typeManagerMessage === 'Enviar borrador') {
      this.formNewMessage = new FormGroup({
        emailConfig: new FormControl(null, [Validators.required]),
        state: new FormControl('', [Validators.required]),
        from: new FormControl({ value: '', disabled: true }, []),
        to: this.formBuilder.array([], [validateArrayNotEmpty, validateIsEmails]),
        cc: this.formBuilder.array([], []),
        cco: this.formBuilder.array([], []),
        subject: new FormControl('', [Validators.required]),
        attachments: this.formBuilder.array([], validateSizeFiles),
        template: new FormControl('', []),
        label: new FormControl('', []),
        message: new FormControl('', [Validators.required])
      });
      if (this.typeManagerMessage === 'Reenviar' || this.typeManagerMessage === 'Enviar') this.formNewMessage.removeControl('state');
      if (this.emailSelected !== null && this.typeManagerMessage === 'Responder') this.addEmailToInput(this.emailSelected.de, 'to');
      if (this.emailSelected !== null && (this.typeManagerMessage === 'Responder' || this.typeManagerMessage === 'Reenviar')) {
        if (this.emailSelected.cc !== "") this.addEmailToInput(this.emailSelected.format_cc, 'cc');
      }
      if (this.emailSelected !== null && this.typeManagerMessage === 'Reenviar') this.filesToAttachments = this.emailSelected.attachments;
      if (this.emailSelected !== null && (this.typeManagerMessage === 'Responder' || this.typeManagerMessage === 'Reenviar')) this.formNewMessage.get('subject').setValue(this.emailSelected.asunto);
      this.loadDataEmailDraft();
    } else {
      this.formNewMessage = new FormGroup({
        emailConfig: new FormControl('', []),
        state: new FormControl('', [Validators.required]),
        from: new FormControl({
          value: this.emailsSelectedByMassiveManager.length === 0 ? this.emailSelected.de : this.emailsSelectedByMassiveManager[0].de,
          disabled: true
        }, []),
        description: new FormControl('', [Validators.required, Validators.maxLength(10000)]),
        listEmails: new FormControl({
          value: this.emailsSelectedByMassiveManager.length === 0 ? this.emailSelected.para : this.emailsSelectedByMassiveManager.map(item => item.para).toString(),
          disabled: true
        }, []),
        cc: new FormControl({
          value: this.emailsSelectedByMassiveManager.length === 0 ? this.emailSelected.cc : this.emailsSelectedByMassiveManager.filter(item => item.cc !== '').map(item => item.cc).toString(),
          disabled: true
        }),
      });
      if (this.emailsSelectedByMassiveManager.length === 0) {
        this.getStates(this.emailSelected.email_config_id);
      } else {
        this.getStates(this.emailsSelectedByMassiveManager[0].email_config_id);
      }
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que carga los valores del correo registrados en borrador.
  */
  loadDataEmailDraft(): void {
    if (this.typeManagerMessage === 'Enviar borrador' && this.emailDataDraft !== null) {
      this.formNewMessage.get('emailConfig').setValue(this.emailDataDraft.email_config_id);
      this.formNewMessage.get('state').setValue(this.emailDataDraft.estado);
      this.formNewMessage.get('from').setValue(this.emailDataDraft.de);
      if (this.emailDataDraft.para !== null) this.addEmailToInput(this.emailDataDraft.para, 'to');
      if (this.emailDataDraft.cc !== null) this.addEmailToInput(this.emailDataDraft.cc, 'cc');
      if (this.emailDataDraft.cco !== null) this.addEmailToInput(this.emailDataDraft.cco, 'cco');
      this.formNewMessage.get('subject').setValue(this.emailDataDraft.asunto);
      this.formNewMessage.get('message').setValue(this.emailDataDraft.cuerpo);
      if (this.emailDataDraft.attachments.length > 0) this.filesToLoadEmail = this.emailDataDraft.attachments;
      this.getTemplates();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-16
    * Metodo carga el formulario dependiendo a la configuracion de email cargado. 
  */
  loadFormWhenSendDraftEmail(): void {
    if (this.typeManagerMessage === 'Enviar borrador') {
      const searchEmailConfigSelect = this.emailsConfig.find(item => item.id_email === this.formNewMessage.get('emailConfig').value);
      if (searchEmailConfigSelect) {
        this.formId = searchEmailConfigSelect.form_id;
        this.getForm();
      }
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que agrega una cadena de texto sobre los input de correos.
    * @param textEmails Cadena con el correo. 
    * @param typeList Nombre del campo con el cual se va a trabajar.  
  */
  addEmailToInput(textEmails: string, typeList: string): void {
    const arrayToEmails = textEmails.split(',');
    arrayToEmails.forEach(value => {
      if (value && value !== '') {
        const newFormGroup = this.formBuilder.group({ name: new FormControl(value, [Validators.email]) });
        const arrayForm = this.formNewMessage.get(typeList) as FormArray;
        arrayForm.push(newFormGroup);
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que actualiza la lista de adjuntos cargados en un correo borrador.
    * @param $event Evento emitido por el componente hijo. 
  */
  onDeleteFileToLoadEmail($event: any): void {
    this.filesToLoadEmail = this.filesToLoadEmail.filter(file => file.id !== $event);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-30
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formNewMessage.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-04
    * Metodo que obtiene el formulario de CRM mediante el ID. 
  */
  getForm(): void {
    this.formRequestService.getForm(`searchform/${this.formId}`, 1000, 1).subscribe(res => {
      this.formCrm = res;
      if (this.emailsSelectedByMassiveManager.length === 0) {
        if (this.typeManagerMessage === 'Responder' || this.typeManagerMessage === 'Guardar información') {
          if (this.emailSelected.id_referencia_crm) {
            const typificationId = this.emailSelected.id_referencia_crm;
            this.formRequestService.getData(`formanswer/show/${typificationId}`).subscribe(res => {
              let preloadDataFormCrm = JSON.parse(res.data.structure_answer);
              this.formCrm.sections = JSON.parse(JSON.stringify(preloadDataFormCrm));
              this.formCrm.sections.forEach((element: any, index: number) => {
                element.fields.forEach((elementInt: any) => {
                  elementInt.value = '';
                  preloadDataFormCrm[index].fields.forEach((field: any) => {
                    if (field.id === elementInt.id) {
                      elementInt['newValue'] = field.value;
                      if (elementInt.type === 'options') {
                        if (field.value === '') {
                          elementInt.value = field.value;
                        } else {
                          elementInt.value = Number(field.value);
                        }
                        if (elementInt.controlType === 'multiselect') {
                          elementInt.value = field.value.split(',').map(Number);
                        }
                      } else if (elementInt.type === 'others') {
                        elementInt.value = field.value;
                        elementInt.nameFile = field.nameFile;
                      } else if (elementInt.type !== 'options') {
                        elementInt.value = field.value;
                      }
                    }
                  });
                });
              });
            });
          } else {
            this.alertService.alertError('Error', 'No se tiene una tipificación sobre el correo.');
          }
        }
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-04
    * Metodo que ejecuta el submit del formulario de CRM.
    * @param $event Evento emitido por el formulario de CRM.  
  */
  submitFormCrm($event: any): void {
    this.formStructureCrm = $event;
    this.validFormCrm = $event.isValid;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo valida el tipo de gestion a realizar y hace el llamado al submit del formulario.  
  */
  validateTypeManagerForSubmitMail(): void {
    if (this.typeManagerMessage === 'Enviar') {
      this.formCrmComponent.onSubmit();
      this.sendEmail();
    } else if (this.typeManagerMessage === 'Responder') {
      this.formCrmComponent.onSubmit();
      this.replyEmail();
    } else if (this.typeManagerMessage === 'Reenviar') {
      this.resendEmail();
    } else if (this.typeManagerMessage === 'Enviar borrador') {
      this.formCrmComponent.onSubmit();
      this.resendDraftEmail();
    } else {
      this.formCrmComponent.onSubmit();
      this.manageEmail();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que trae los estados registrados en el sistema.
    * @param idEmailConfig ID de configuracion de correo seleccionada.   
  */
  getStates(idEmailConfig: string = ''): void {
    this.stateService.getStates(null, '', false, idEmailConfig).subscribe(res => {
      if (this.emailsSelectedByMassiveManager.length === 0) this.states = res.data.filter((item: any) => item.id !== this.emailSelected.id_email_state);
      else this.states = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-31
    * Metodo que trae las configuraciones de correos de salida registrados en el sistema. 
  */
  getEmailsConfig(): void {
    const idRrhh = this.userLogin.rrhh_id;
    this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
      this.emailsConfig = res.data;
      this.loadFormCrmWhenIsManager();
      this.loadFormWhenSendDraftEmail();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-31
    * Metodo que carga el formulario de CRM a partir de la configuracion de email seleccionado.
    * @param $event Evento emitido por el campo select.    
  */
  onChangeSelectEmailConfig($event: MatSelectChange): void {
    if (this.typeManagerMessage !== 'Guardar información') {
      this.getStates($event.value);
      this.getLabelsByEmailAccountSelected($event.value);
      const searchEmailConfigSelect = this.emailsConfig.find(item => item.id_email === $event.value);
      if (searchEmailConfigSelect) {
        this.getTemplates();
        this.formNewMessage.get('from').setValue(searchEmailConfigSelect.user_name_email_entrada);
        if (this.typeManagerMessage !== 'Reenviar') {
          this.formId = searchEmailConfigSelect.form_id;
          this.getForm();
        }
      }
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-29
    * Metodo que envia el correo con la data del formulario.  
  */
  sendEmail(): void {
    const dataFormCrm = createDataFormCrmV2(this.formStructureCrm, 'typification', this.formCrm);
    if (this.validFormCrm && dataFormCrm && this.formNewMessage.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De enviar el correo?').then(confirm => {
        if (confirm.isConfirmed) {
          this.formRequestService.postForm('formanswer/saveinfo', dataFormCrm).subscribe(resCrm => {
            if (resCrm.data) {
              const formDataMessage = new FormData();
              formDataMessage.append('type', 'nuevo');
              formDataMessage.append('id_referencia_crm', resCrm.data.formAsnwerId);
              formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
              formDataMessage.append('listImageBase', '');
              formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
              formDataMessage.append('asunto', this.formNewMessage.value.subject);
              formDataMessage.append('cuerpo', this.formNewMessage.value.message);
              this.formNewMessage.value.attachments.forEach((item: any) => {
                formDataMessage.append('files[]', item.file);
              });
              this.emailService.sendEmail(formDataMessage).subscribe(resEmail => {
                this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                  data: {
                    title: '¡Excelente!',
                    message: 'El correo se ha enviado con éxito.'
                  },
                  duration: 5000,
                  verticalPosition: 'top',
                  panelClass: ['green-snackbar']
                });
                this.submitMessage.emit(this.typeManagerMessage);
              });
            }
          });
        }
      });
    } else {
      this.formNewMessage.markAllAsTouched();
      this.alertService.alertError('Error', 'Error al procesar los formularios');
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-03
    * Metodo que envia el correo a la bandeja de borradores.
  */
  saveMessageToDraftEmail(): void {
    if (this.typeManagerMessage !== 'Guardar información' && this.typeManagerMessage !== 'Enviar borrador') {
      if (!this.valuesFormIsEmpty()) {
        this.alertService.alertWarning('¿Desea guardar el contenido en borradores?', '', 'Si', 'No').then(confirm => {
          if (confirm.isConfirmed) {
            const formDataMessage = new FormData();
            const typeManager = this.typeManagerMessage === 'Enviar' ? 'nuevo' : this.typeManagerMessage === 'Responder' ? 'responder' : 'reenviar';
            formDataMessage.append('id_email', this.emailSelected !== null ? this.emailSelected.id : '');
            formDataMessage.append('type', typeManager);
            formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
            formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
            formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
            formDataMessage.append('listImageBase', '');
            formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
            formDataMessage.append('asunto', this.formNewMessage.value.subject);
            formDataMessage.append('cuerpo', this.formNewMessage.value.message);
            formDataMessage.append('filesToResend', this.filesToAttachments.map((item: any) => item.id).join());
            this.formNewMessage.value.attachments.forEach((item: any) => {
              formDataMessage.append('files[]', item.file);
            });
            formDataMessage.append('es_borrador', true.toString());
            this.emailService.sendEmail(formDataMessage).subscribe(res => {
              this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                data: {
                  title: '¡Excelente!',
                  message: 'Mensaje almacenado en borradores'
                },
                duration: 5000,
                verticalPosition: 'top',
                panelClass: ['green-snackbar']
              });
              this.submitMessage.emit(this.typeManagerMessage);
            });
          }
        });
      } else {
        this.onClickCancelButton.emit();
      }
    } else {
      this.onClickCancelButton.emit();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-10
    * Metodo que responde a un correo.
  */
  replyEmail(): void {
    const dataFormCrm = createDataFormCrmV2(this.formStructureCrm, 'typification', this.formCrm);
    if (this.validFormCrm && dataFormCrm && this.formNewMessage.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De responder el correo?').then(confirm => {
        if (confirm.isConfirmed) {
          this.formRequestService.postForm('formanswer/saveinfo', dataFormCrm).subscribe(resCrm => {
            if (resCrm.data) {
              const formDataMessage = new FormData();
              formDataMessage.append('id_email', this.emailSelected.id);
              formDataMessage.append('id_referencia_crm', resCrm.data.formAsnwerId);
              formDataMessage.append('type', 'responder');
              formDataMessage.append('estado', this.formNewMessage.value.state);
              formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
              formDataMessage.append('listImageBase', '');
              formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
              formDataMessage.append('asunto', this.formNewMessage.value.subject);
              formDataMessage.append('cuerpo', this.formNewMessage.value.message);
              this.formNewMessage.value.attachments.forEach((item: any) => {
                formDataMessage.append('files[]', item.file);
              });
              this.emailService.sendEmail(formDataMessage).subscribe(resEmail => {
                this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                  data: {
                    title: '¡Excelente!',
                    message: 'El correo se ha respondido con éxito.'
                  },
                  duration: 5000,
                  verticalPosition: 'top',
                  panelClass: ['green-snackbar']
                });
                this.submitMessage.emit(this.typeManagerMessage);
              });
            }
          });
        }
      });
    } else {
      this.formNewMessage.markAllAsTouched();
      this.alertService.alertError('Error', 'Error al procesar los formularios');
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-10
    * Metodo que reenvia un correo.
  */
  resendEmail(): void {
    if (this.formNewMessage.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De reenviar el correo?').then(confirm => {
        if (confirm.isConfirmed) {
          const formDataMessage = new FormData();
          formDataMessage.append('id_email', this.emailSelected.id);
          formDataMessage.append('type', 'reenviar');
          formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
          formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
          formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
          formDataMessage.append('listImageBase', '');
          formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
          formDataMessage.append('asunto', this.formNewMessage.value.subject);
          formDataMessage.append('cuerpo', this.formNewMessage.value.message);
          formDataMessage.append('filesToResend', this.filesToAttachments.map((item: any) => item.id).join());
          this.formNewMessage.value.attachments.forEach((item: any) => {
            formDataMessage.append('files[]', item.file);
          });
          this.emailService.sendEmail(formDataMessage).subscribe(resEmail => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: 'El correo se ha respondido con éxito.'
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.submitMessage.emit(this.typeManagerMessage);
          });
        }
      });
    } else {
      this.formNewMessage.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-10
    * Metodo que gestiona uno o varios correos.
  */
  manageEmail(): void {
    const dataFormCrm = createDataFormCrmV2(this.formStructureCrm, 'typification', this.formCrm);
    if (this.validFormCrm && dataFormCrm && this.formNewMessage.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De gestionar el correo?').then(confirm => {
        if (confirm.isConfirmed) {
          this.formRequestService.postForm('formanswer/saveinfo', dataFormCrm).subscribe(resCrm => {
            if (resCrm.data) {
              const formDataMessage = new FormData();
              formDataMessage.append('id_referencia_crm', resCrm.data.formAsnwerId);
              formDataMessage.append('id_email_state', this.formNewMessage.value.state);
              formDataMessage.append('observacion', this.formNewMessage.value.description);
              if (this.emailsSelectedByMassiveManager.length > 0) {
                const mapIdsEmailMassiveSelected = this.emailsSelectedByMassiveManager.map(email => email.id);
                formDataMessage.append('email_list', `[${mapIdsEmailMassiveSelected.toString()}]`);
              } else {
                formDataMessage.append('email_list', `[${this.emailSelected.id}]`);
              }
              this.emailService.manageEmail(formDataMessage).subscribe(resEmail => {
                this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                  data: {
                    title: '¡Excelente!',
                    message: this.emailsSelectedByMassiveManager.length === 0 ? 'Se ha gestionado el correo con éxito' : 'Se han gestionado los correos masivamente con éxito'
                  },
                  duration: 5000,
                  verticalPosition: 'top',
                  panelClass: ['green-snackbar']
                });
                this.submitMessage.emit(this.typeManagerMessage);
              });
            }
          });
        }
      });
    } else {
      this.formNewMessage.markAllAsTouched();
      this.alertService.alertError('Error', 'Error al procesar los formularios');
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-11
    * Metodo que setea el mensaje del correo si se seleccionó una plantilla.
    * @param $event Evento emitido por el componente hijo. 
  */
  onChangeTemplate($event: number): void {
    const findTemplateSelected = this.templates.find(item => item.id === $event);
    if (findTemplateSelected) this.formNewMessage.get('message').setValue(findTemplateSelected.body);
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-11
    * Metodo que trae las platillas registradas en el sistema.
  */
  getTemplates(): void {
    this.templateService.getTemplatesByIdEmailConfig(this.formNewMessage.get('emailConfig').value).subscribe(res => {
      this.templates = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-14
    * Metodo que setea el form id cuando se esta realizando una gestion de tipo guardar informacion.
  */
  loadFormCrmWhenIsManager(): void {
    if (this.typeManagerMessage === 'Guardar información') {
      const idConfigByEmail = this.emailsSelectedByMassiveManager.length === 0 ? this.emailSelected.email_config_id : this.emailsSelectedByMassiveManager[0].email_config_id;
      if (idConfigByEmail) {
        const findFormId = this.emailsConfig.find(item => item.id_email === idConfigByEmail);
        if (findFormId) {
          this.formId = findFormId.form_id;
          this.getForm();
        }
      }
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-16
    * Metodo que reenvia un correo que quedo como borrador.
  */
  resendDraftEmail(): void {
    const dataFormCrm = createDataFormCrmV2(this.formStructureCrm, 'typification', this.formCrm);
    if (this.validFormCrm && dataFormCrm && this.formNewMessage.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De enviar el correo?').then(confirm => {
        if (confirm.isConfirmed) {
          this.formRequestService.postForm('formanswer/saveinfo', dataFormCrm).subscribe(resCrm => {
            if (resCrm.data) {
              const formDataMessage = new FormData();
              formDataMessage.append('id_referencia_crm', resCrm.data.formAsnwerId);
              formDataMessage.append('type', this.emailDataDraft.type);
              formDataMessage.append('estado', this.formNewMessage.value.state);
              formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
              formDataMessage.append('listImageBase', '');
              formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
              formDataMessage.append('asunto', this.formNewMessage.value.subject);
              formDataMessage.append('cuerpo', this.formNewMessage.value.message);
              formDataMessage.append('filesToResend', this.filesToLoadEmail.map((item: any) => item.id).join());
              this.formNewMessage.value.attachments.forEach((item: any) => {
                formDataMessage.append('files[]', item.file);
              });
              this.emailService.resendDraftEmail(formDataMessage, this.emailDataDraft.id).subscribe(resEmail => {
                this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                  data: {
                    title: '¡Excelente!',
                    message: 'El correo se ha enviado con éxito.'
                  },
                  duration: 5000,
                  verticalPosition: 'top',
                  panelClass: ['green-snackbar']
                });
                this.submitMessage.emit(this.typeManagerMessage);
              });
            }
          });
        }
      });
    } else {
      this.formNewMessage.markAllAsTouched();
      this.alertService.alertError('Error', 'Error al procesar los formularios');
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-27
    * Metodo que valida si todos los campos del formulario quedan vacios.
  */
  valuesFormIsEmpty(): boolean {
    if (
      this.formNewMessage.value.to.length === 0 &&
      this.formNewMessage.value.subject === '' &&
      this.formNewMessage.value.message === ''
    ) return true;
    return false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-03
    * Metodo que actualiza los archivos precargados asociados a un correo.
    * @param $event Id del archivo emitido por el componente hijo. 
  */
  updatePreloadAttachments($event: number): void {
    this.filesToAttachments = this.filesToAttachments.filter(item => item.id !== $event);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-30
    * Metodo que actualiza la informacion del borrador si la visual se cierra. 
  */
  updateDataToCloseEmailDraft(): void {
    if (!this.valuesFormIsEmpty()) {
      const formDataMessage = new FormData();
      formDataMessage.append('type', this.emailDataDraft.type);
      formDataMessage.append('estado', this.formNewMessage.value.state);
      formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
      formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
      formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
      formDataMessage.append('listImageBase', '');
      formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
      formDataMessage.append('asunto', this.formNewMessage.value.subject);
      formDataMessage.append('cuerpo', this.formNewMessage.value.message);
      formDataMessage.append('filesToResend', this.filesToLoadEmail.map((item: any) => item.id).join());
      formDataMessage.append('es_borrador', true.toString());
      this.formNewMessage.value.attachments.forEach((item: any) => {
        formDataMessage.append('files[]', item.file);
      });
      this.emailService.resendDraftEmail(formDataMessage, this.emailDataDraft.id).subscribe(resEmail => {
        this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
          data: {
            title: '¡Excelente!',
            message: 'Se ha actualizado la información del borrador'
          },
          duration: 5000,
          verticalPosition: 'top',
          panelClass: ['green-snackbar']
        });
        this.submitMessage.emit(this.typeManagerMessage);
      });
    } else {
      this.onClickCancelButton.emit();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que lista las etiquetas asociadas a una cuenta de correo.
    * @param idAccountEmail Id de la cuenta de correo seleccionada. 
  */
  getLabelsByEmailAccountSelected(idAccountEmail: number): void {
    this.crudService.getData({
      url: `${GESTIONADOR_CORREOS}labels/showall`,
      filters: { state: 1, idAccountEmail }
    }).subscribe(res => {
      this.labels = res;
    })
  }
}