import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApplicationsService } from '../../../services/rest/applications.service';
import { RolesService } from '../../../services/rest/roles.service';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { startWith, map } from 'rxjs/operators';
import { MenusService } from '../../../services/rest/menus.service';
import { ThrowStmt } from '@angular/compiler';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-save-rol',
  templateUrl: './save-rol.component.html',
  styleUrls: ['./save-rol.component.sass']
})
export class SaveRolComponent implements OnInit, OnDestroy {

  rolForm: FormGroup;
  filteredOptions: Observable<any[]>;
  applications: any;
  menus: any;
  menusSet: any = [];
  gridPermit: boolean = false;
  actions: any;
  permit: any = [];
  actionsPermit: any;
  options: any;

  length;
  pageSize = 5;
  page = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newRolComponent: boolean = false;

  displayedColumns: string[] = ['modulos'];
  dataSource = new MatTableDataSource<any>();

  applicationsAccesToGrid:string[]= [ 'CRM', 'CRM2','CRMNSDP']

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private appService: ApplicationsService,
              private rolesService: RolesService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              private menusService: MenusService,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.options = this.fb.group({});
  }

  ngOnInit(): void {    
    this.formControl();
    this.appService.getApplications().subscribe((resp) => {
      this.applications = resp.data;
      this.setEdit();
      this.filteredOptions = this.rolForm.get('applications').valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(applications => applications ? this._filter(applications) : this.applications.slice())
      );
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * filtra por nombre, se usa para el autocompletable
   * @param value valor a filtrar
   */
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.applications.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que devuelve el nombre al momento de seleccionar una opcion, busca en el arreglo por id y devuelve el nombre
   */
  displayFn(id: number): string{
    if (!id) { return ''; }
    let index = this.applications.findIndex(resp => resp.id === id);
    return this.applications[index].name;

  }

  get error(): any { return this.rolForm.controls; }

  /**
   * @author Daniel Martinez
   * @createdate 2021-01-27
   * Metodo donde se establecen las validaciones del formulario
   */
  formControl(): void{

    this.rolForm = new FormGroup({
      rol: new FormControl('', [Validators.required, Validators.maxLength(35), Validators.minLength(3)]),
      key: new FormControl('', [Validators.required, Validators.maxLength(35), Validators.minLength(3)]),
      applications: new FormControl('', [this.autocompleteObjectValidator(), Validators.required]),
      menu: new FormControl('')
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que valida las funciones del autocompetable
   */
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  /**
   * @author Daniel Martinez
   * @update Juan David Guerrer Vargas 
   * @createdate 2021-02-04
   * Metodo que setea los valores al momento de editar
   */
  async setEdit(){
    if (this.data.type === 'editar') {
      this.rolForm.controls.rol.setValue(this.data.rol.name);
      this.rolForm.controls.key.setValue(this.data.rol.key);
      this.rolForm.controls.applications.setValue(Number(this.data.rol.application_id));
      await this.menusService.getMenus(this.data.rol.application_id).subscribe((respo) => {
        this.menus = respo.data;        
        this.appSelected(this.rolForm.get('applications').value);
      });
      this.data.rol.menus.forEach(element => {
        this.menusSet.push(Number(element.id));
      });
      this.rolForm.controls.menu.setValue(this.menusSet);
      console.log(this.rolForm);
      
    }
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que a partir de una variable enviada desde el administrador de roles crea un nuevo rol o edita un rol
   * @param rolForm datos que recolecta el formulario
   */
  saveRol(rolForm): void {

    if (this.data.type === 'crear'){
      const rol = {
        application_id: rolForm.value.applications,
        name: rolForm.value.rol,
        key: rolForm.value.key,
        menu_ids: rolForm.value.menu,
        permissions_crm: this.permit
      };

      this.rolesService.saveRol(rol).subscribe((resp) => {
        this.alertsService.alertSuccess('Guardado', resp.data);
      });
    }
    else if (this.data.type === 'editar'){
      this.editRol(rolForm);
    }

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que edita un rol
   * @param rolForm datos que recolecta el formulario
   */
  editRol(rolForm): void {

    const rol = {
      application_id: rolForm.value.applications,
      name: rolForm.value.rol,
      key: rolForm.value.key,
      menu_ids: rolForm.value.menu,
      permissions_crm: this.permit
    };

    this.rolesService.editRol(this.data.rol.id, rol).subscribe((resp) => {
      this.alertsService.alertSuccess('Editado', resp.data);
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-22
   * Metodo que busca los menus de un aplicativo seleccionado
   * @param id id del aplicativo
   */
  appSelected(id: number): void {
    const aplication = this.applications.find( finded => finded.id == id  )
    const findAplication = this.applicationsAccesToGrid.find( finded => finded === aplication?.name )
    
    if(findAplication){
      switch (findAplication) {
        case 'CRM':
          this.getModulesCrm(1);
          this.gridPermit = true;
        break;
        case 'CRM2':
          this.getModulesCrm(2);
          this.gridPermit = true;
        break;
        case 'CRMNSDP':
          this.getModulesCrm(3);
          this.gridPermit = true;
        break;
      }

    }
    this.menusService.getMenus(id).subscribe((resp) => {
      this.menus = resp.data;
    });


  }

  getModulesCrm(indCRM:number): void {
    let observToSubscribe = undefined
    if(indCRM == 1){
      observToSubscribe = this.rolesService.getModulesCrm();
    }
    if(indCRM == 2){      
      observToSubscribe = this.rolesService.getModulesCrm2();
    }
    if(indCRM == 3){
      observToSubscribe = this.rolesService.getModulesCrmNSDP()
    }
    
    observToSubscribe.subscribe(resp => {

      this.dataSource.data = resp.modules;
      this.dataSource.sort = this.sort;
      this.length = resp.total;
      this.pageSize = resp.per_page;
      this.actions = resp.action_permission;

      this.actions.forEach(element => {
        this.displayedColumns.push(element.name);
      });

      resp.modules.forEach(element => {
        this.actions.forEach(elementActions => {
          this.options.addControl(elementActions.id + '_' + element.id, new FormControl(''));
        });
      });

    });

    if (this.data.type === 'editar') {
      let observerPermission = undefined;
      const findAplication = this.applicationsAccesToGrid.find( finded => finded === this.data.rol?.application?.name )
      if(findAplication){
        switch (findAplication) {
          case 'CRM':
            observerPermission = this.rolesService.getPermissionsByIdRol(this.data.rol.id);     
          break;
          case 'CRM2':
            observerPermission = this.rolesService.getPermissionsByIdRolCRM2(this.data.rol.id);     
          break;
          case 'CRMNSDP':
            observerPermission = this.rolesService.getPermissionsByIdRolCRMNSDP(this.data.rol.id);     

          break;
        }
      }
      observerPermission.subscribe(resp => {
        this.actionsPermit = resp;

        this.actionsPermit.forEach(element => {
          this.options.controls[element.action_permission_id + '_' + element.module_id].setValue(true);
          let pusheo = JSON.stringify({'action': element.action_permission_id, 'module': element.module_id});
          this.permit.push(pusheo);
        });

      });
    }



  }

  setValue(event: any, action: any, module: any): void{

    let pusheo = JSON.stringify({'action': action, 'module': module});

    if (event){

      this.permit.push(pusheo);

    } else {

      let i = this.permit.indexOf(pusheo);

      if ( i !== -1){
        this.permit.splice( i, 1);
      }
    }

  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }

}
