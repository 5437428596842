<mat-dialog-content class="padding-mat-dialog-content" style="padding-bottom: 10px;">
    <i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button
        mat-dialog-close></i>
    <div id="containerViewReport" class="avoid-page-break" #containerViewReport>
        <h3 mat-dialog-title>Resultados de adherencia</h3>
        <mat-divider class="w-100"></mat-divider>
        <div class="col-md-12" *ngIf="applied_strategy">
            <p style="font-size: 12px;">
                <span style="font-weight: bold; vertical-align: middle;">Estrategia anteriormente aplicada: </span> 
                <span>{{ applied_strategy }}</span> 
                &nbsp; | &nbsp; 
                <span style="font-weight: bold; vertical-align: middle;">Fecha estrategia aplicada: </span> 
                <span>{{ date_strategy }}</span>
            </p>
        </div>
        <!-- Mostrar porcentajes generales de adherencia y no adherencia -->
        <div class="row col-12 justify-content-center" style="padding-bottom: 20px;">
            <div fxFlex="80%" fxLayout="row" fxLayoutAlign="end end" class="pt-5">
                <!-- Card de Adherencia General -->
                <mat-card class="puntos" matTooltip="{{ labels_found?.length ? 'Palabras encontradas: ' + labels_found.join(', ') : 'No se encontraron palabras' }}">
                    <p class="puntaje">{{ adherenciaResponse?.adherencePercentage || 'N/A' }}</p>
                    <p class="titulo-puntaje">Adherencia General</p>
                </mat-card>
                <!-- Card de No Adherencia General -->
                <mat-card class="puntos" matTooltip="{{ labels_notfound?.length ? 'Palabras no encontradas: ' + labels_notfound.join(', ') : 'No se encontraron palabras' }}">
                    <p class="puntaje-no">{{ adherenciaResponse?.notAdherencePercentage || 'N/A' }}</p>
                    <p class="titulo-puntaje-no">No Adherencia General</p>
                </mat-card>
            </div>
        </div>


        <!-- Mostrar transcripción -->
        <mat-expansion-panel #transcripcionPanel class="full-width-panel">
            <mat-expansion-panel-header>
                <mat-panel-title>Transcripción</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row" #scrollContainer>
                <div class="col-md-12" *ngFor="let item of transcripcion">
                    <p style="font-size: 14px;">
                        <button class="btn btn-custom rounded-circle p-2"
                            style="width: 26px; height: 26px; background-color: #2cabbc; border-color: #2cabbc; display: inline-flex; align-items: center;">
                            <i class="fi-sr-user" style="color: white; font-size: 10px;"></i>
                        </button>
                        <strong style="vertical-align: middle;">
                            {{ item.agent === null && chatData.phone === null ? 'Mensaje' : (item.agent === null ? 'Cliente (' + chatData.phone + ') ' : 'Agente (' + item.agent + ') ') }}
                        </strong>
                        <span style="vertical-align: middle;">
                            ({{ item.entry_date_time ? item.entry_date_time : ':' }}):
                          </span>
                        <span [innerHTML]="item.text"></span>
                    </p>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- Mostrar análisis de la IA -->
        <mat-expansion-panel #adherencia class="full-width-panel">
            <mat-expansion-panel-header>
                <mat-panel-title>Análisis de adherencia general</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col col-md-12">
                    <div style="font-size: 14px;">
                        <span [innerHTML]="formattedAdherenciaIA !== null ? formattedAdherenciaIA : 'Análisis en proceso...'"></span>
                    </div>
                    <div style="font-size: 14px;">
                        <strong>Nivel de cumplimiento de adherencia: </strong>
                        <span>{{ cumplimiento_general || 'Análisis en proceso...' }}</span>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- Secciones dinámicas de análisis -->
        <ng-container *ngFor="let section of percentageSections">
            <mat-expansion-panel class="full-width-panel" #sectionPanel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Análisis sección {{ section.section }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col col-md-12">
                        <div style="font-size: 14px;">
                            <strong>Adherencia: </strong>
                            <span>{{ section.adherence_percentage || 'No disponible' }}</span>
                        </div>
                        <div style="font-size: 14px;">
                            <strong>No Adherencia: </strong>
                            <span>{{ section.not_adherence_percentage || 'No disponible' }}</span>
                        </div>
                        <div style="font-size: 14px;">
                            <strong>Cumple: </strong>
                            <span>{{ section.strategy_result_section?.cumple || 'No disponible' }}</span>
                        </div>
                        <div style="font-size: 14px;">
                            <strong>Nivel de cumplimiento de adherencia: </strong>
                            <span>{{ section?.cumplimiento || 'No disponible' }}</span>
                        </div>
                    </div>
                    <div style="font-size: 14px;">
                        <strong>Análisis IA: </strong>
                        <span>{{ section.strategy_result_section?.analisis || 'Análisis en proceso...' }}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </div>
</mat-dialog-content>

<div class="col-md-12 pt-0">
    <div class="row py-3 d-flex justify-content-end">
        <div class="col-md-2 d-flex justify-content-center">
            <button mat-flat-button color="primary" class="w-100"
                    (click)="onClickPrintPdf()"
                    matTooltip="Descargar informe">
                <i class="fi-rr-download"></i>
            </button>
        </div>
    </div>
</div>
