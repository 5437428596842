import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class ContactGroupService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que retorna los grupos de contacto registrados en el sistema.
    * @param paginator Configuracion de la paginacion.
    * @param filter Filtro de texto.
    * @param allStates Muestra o no todas los grupos de contacto activos.
    * @param filterEmail Filtro para las cuentas de correo.    
  */
  getContactGroups(paginator: any, filter: string, allStates: boolean = true, filterEmail: number = 0): Observable<any> {
    const allStatesFilter = !allStates ? 'state=1' : '';
    const paramEmail = filterEmail !== 0 ? `&email_list=[${filterEmail}]` : '';
    return this.httpClient.get(`${GESTIONADOR_CORREOS}groups/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filter}&${allStatesFilter}${paramEmail}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que guardar o actualiza un grupo de contacto en el sistema. 
    * @param groupContact Informacion proveniente del formulario.  
  */
  storeGroupContact(groupContact: any): Observable<any> {
    const url = groupContact.id === null ? `${GESTIONADOR_CORREOS}groups/store` : `${GESTIONADOR_CORREOS}groups/update/${groupContact.id}`;
    return this.httpClient.post(url, groupContact).pipe(
      catchError(this.handleError)
    );
  }
}