<mat-dialog-content class="padding-mat-dialog-content" style="padding-bottom: 10px;">
    <i class="fi-rr-cross-small" style="float: right; font-size: 32px; color: #353535;" mat-icon-button mat-dialog-close></i>
    <div id="containerViewReport" class="avoid-page-break" #containerViewReport>
        <h3 mat-dialog-title>Resultados de análisis.</h3>
        <mat-divider class="w-100"></mat-divider>
        
        <mat-expansion-panel #transcripcionPanel closed>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p>Transcripción</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row" #scrollContainer>
                <!-- Chips palabras claves-->
                <div class="col-md-12" style="padding-bottom: 10px;">
                    <mat-chip-list>
                        <mat-chip *ngFor="let palabra of palabrasClaveArray" class="chip" (click)="toggleHighlight(palabra)">
                            {{ palabra }}
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <!-- Checkbox Global para Seleccionar/Deseleccionar Todo -->
                <div class="col-md-12" style="padding-bottom: 10px; text-align: right;" *ngIf="modificarInteracciones">
                    <strong>
                        <span style="font-size: 10px;">Seleccionar/Deseleccionar todo</span>
                        <mat-checkbox 
                            [(ngModel)]="selectAll" 
                            (change)="toggleSelectAll($event.checked)"
                            [indeterminate]="isIndeterminate()"
                            style="margin-left: 10px;">
                        </mat-checkbox>
                    </strong>
                </div>
                <!-- Interacciones -->
                <div class="col-md-12" *ngFor="let item of interactionsFormat; let i = index">
                    <p [ngClass]="{'disabled-text': !item.selected}" style="font-size: 14px; position: relative; padding-right: 30px;">
                        <button class="btn btn-custom rounded-circle p-2"
                                style="width: 26px; height: 26px; background-color: #2cabbc; border-color: #2cabbc; display: inline-flex; align-items: center;"
                                (click)="logStartAndEnd(item.start, item.end)">
                            <i class="fi-sr-play" style="color: white; font-size: 10px;"></i>
                        </button>
                        <strong style="vertical-align: middle;" [matTooltip]="item.tooltip">
                            {{ item.emoji }} {{ item.speaker ? item.speaker : 'SPEAKER' }}
                        </strong>
                        <span style="vertical-align: middle;"> ({{ item.start_format }} - {{ item.end_format }}): </span>
                        <span [innerHTML]="item.text"
                                [contentEditable]="editMode"
                                (focus)="saveCursorPos($event)"
                                (blur)="saveTextContent($event, i)">
                        </span>
                        <!-- Checkbox -->
                        <mat-checkbox *ngIf="modificarInteracciones" [(ngModel)]="item.selected" (change)="onSelectionChange()" style="position: absolute; right: 0; top: 50%; transform: translateY(-50%);"></mat-checkbox>
                        <!-- Icono para mostrar explicación -->
                        <i *ngIf="!modificarInteracciones && item.explicacion" (click)="item.expanded = !item.expanded" [ngClass]="{'fi-rr-angle-up': item.expanded, 'fi-rr-angle-down': !item.expanded}"  style="position: absolute; right: 0; top: 50%; transform: translateY(-50%);"></i>
                    </p>
                    <!-- Explicación adicional -->
                    <p *ngIf="item.expanded" style="font-size: 14px; margin-left:40px">
                        <span style="font-weight: bold;">Explicación: </span>
                        <span> {{ item.explicacion }}</span>
                    </p>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel #tiemposMuertosPanel closed>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p>Tiempos muertos</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <!-- Recta grafica para tiempos muertos -->
                <div class="col col-md-12" style="padding-top: 20px;">
                    <p style="font-size: 14px;">"Dar clic en el segmento requerido sobre la barra, para ir al audio"</p>
                    <div class="recta">
                        <div class="segmento" *ngFor="let segmento of tiemposMuertos" 
                        [ngStyle]="calcularPosicionSegmento(segmento)" 
                        (click)="logStartAndEnd(segmento.start, segmento.end)"></div>
                    </div>
                </div>
                <br>
                <div class="col col-md-12" style="padding-top: 20px; font-size: 14px;">
                    <p>
                        <strong>Total tiempo audio:</strong> {{ audioDurationFormatted }}
                        <strong>Total de tiempo muerto:</strong> {{ deadTimeaudioDurationFormatted }}</p>
                    <br>
                </div>         
                <div class="col-md-12" style="padding-top: 20px;" *ngFor="let tiempo of tiemposMuertosMayores; let i = index">
                    <p style="font-size: 14px;">                             
                        <button class="btn btn-custom rounded-circle p-2" style="width: 26px; height: 26px; background-color: #2cabbc; border-color: #2cabbc; display: inline-flex; align-items: center;" mattooltip="Reproducir" (click)="logStartAndEnd(tiempo.start, tiempo.end)">
                            <i class="fi-sr-play" style="color: white; font-size: 10px;"></i>
                        </button>
                        <strong> Tiempo Muerto N°{{ i + 1 }}</strong> ({{ formatTiempo(tiempo) }}) - <strong> Duración:</strong> {{ calcularDuracion(tiempo.start, tiempo.end) }}
                    </p>
                </div>
            </div>
        </mat-expansion-panel>

        
        <mat-expansion-panel #resumenPanel closed>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p>Resumen e Identificación de Objetivo</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
                <div class="row" *ngIf="llmResponseObject">
                    <div class="col col-md-12">
                        <div style="font-size: 14px;">
                            <p *ngIf="llmResponseObject.resumen"><strong>Resumen: </strong> {{ llmResponseObject.resumen }}</p>
                            
                            <p *ngIf="llmResponseObject.palabras_clave && llmResponseObject.palabras_clave.length > 0">
                                <strong>Palabras Clave: </strong>
                                <span *ngFor="let palabra of llmResponseObject.palabras_clave">{{ palabra }}, </span>
                            </p>
                    
                            <p *ngIf="llmResponseObject.entidades_principales && llmResponseObject.entidades_principales.length > 0">
                                <strong>Entidades principales: </strong> 
                                <span *ngFor="let entidad of llmResponseObject.entidades_principales">{{ entidad }}, </span>
                            </p>
                    
                            <p *ngIf="llmResponseObject.temas_principales && llmResponseObject.temas_principales.length > 0">
                                <strong>Temas Principales: </strong> 
                                <span *ngFor="let tema of llmResponseObject.temas_principales">{{ tema }}, </span>
                            </p>
                    
                            <p *ngIf="llmResponseObject.puntos_clave && llmResponseObject.puntos_clave.length > 0">
                                <strong>Puntos Clave: </strong>
                                <span *ngFor="let punto of llmResponseObject.puntos_clave">{{ punto }}, </span>
                            </p>
                        </div>
                    </div>
                </div>
        </mat-expansion-panel>
                    
        <mat-expansion-panel #analisisTono closed>
            <mat-expansion-panel-header>
            <mat-panel-title>Análisis de tono de voz</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row avoid-page-break">
            <div class="col-12 text-center">
                <strong>Gráfica de Análisis de tono de voz general</strong>
            </div>
            <div class="col-12">
                <ngx-charts-pie-chart
                [view]="view"
                [results]="emocionesData"
                [legend]="showLegend"
                [labels]="showLabels"
                [doughnut]="isDoughnut"
                [explodeSlices]="false"
                [gradient]="gradient"
                [animations]="false"
                [scheme]="colorScheme"
                [labelFormatting]="pieChartLabel.bind(this, emocionesData)">
                </ngx-charts-pie-chart>
            </div>
            </div>
    
            <div class="avoid-page-break" *ngFor="let speakerData of emocionesDataPorSpeaker">
            <div class="row">
                <div class="col-12 text-center">
                <strong>Gráfica de Análisis de tono de voz {{ speakerData.name }}</strong>
                </div>
                <div class="col-12">
                <ngx-charts-pie-chart
                    [view]="view"
                    [results]="speakerData.series"
                    [legend]="showLegend"
                    [labels]="showLabels"
                    [doughnut]="isDoughnut"
                    [explodeSlices]="false"
                    [gradient]="gradient"
                    [animations]="false"
                    [scheme]="colorScheme"
                    [labelFormatting]="pieChartLabel.bind(this, speakerData.series)">
                </ngx-charts-pie-chart>
                </div>
            </div>
            </div>
        </mat-expansion-panel>
    
        <mat-expansion-panel #analisisTexto closed>
            <mat-expansion-panel-header>
            <mat-panel-title>Análisis de texto</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row avoid-page-break">
            <div class="col-12 text-center">
                <strong>Gráfica de Análisis de texto general</strong>
            </div>
            <div class="col-12">
                <ngx-charts-pie-chart
                [view]="view"
                [results]="sentimientosData"
                [legend]="showLegend"
                [labels]="showLabels"
                [doughnut]="isDoughnut"
                [explodeSlices]="false"
                [gradient]="gradient"
                [animations]="false"
                [scheme]="colorScheme2"
                [labelFormatting]="pieChartLabel.bind(this, sentimientosData)">
                </ngx-charts-pie-chart>
            </div>
            </div>
    
            <div class="avoid-page-break" *ngFor="let speakerData of sentimientosDataPorSpeaker">
            <div class="row" >
                <div class="col-12 text-center">
                <strong>Gráfica de Análisis de texto {{ speakerData.name }}</strong>
                </div>
                <div class="col-12">
                <ngx-charts-pie-chart
                    [view]="view"
                    [results]="speakerData.series"
                    [legend]="showLegend"
                    [labels]="showLabels"
                    [doughnut]="isDoughnut"
                    [explodeSlices]="false"
                    [gradient]="gradient"
                    [animations]="false"
                    [scheme]="colorScheme2"
                    [labelFormatting]="pieChartLabel.bind(this, speakerData.series)">
                </ngx-charts-pie-chart>
                </div>
            </div>
            </div>
        </mat-expansion-panel>

    </div>
</mat-dialog-content>
    
<div class="contenedor" style="text-align: center;">
    <audio #audioPlayer controls style="width: 100%; max-width: 700px;">
        <source [src]="audioUrl" type="audio/mpeg">
        Tu navegador no soporta el elemento de audio.
    </audio>
</div>
    
<div class="col-md-12 pt-0">
    <div class="row py-3 d-flex justify-content-end">
        <div class="col-md-2">
            <button mat-flat-button color="primary" class="w-100"
                (click)="toggleModificacion()" 
                matTooltip="{{ modificarInteracciones ? 'Desactivar interaciones' : 'Activar interaciones' }}">
            <i class="fi-rr-list-check"></i>
            </button>
        </div>
        <div class="col-md-2">
            <button type="button" mat-flat-button color="primary" class="w-100"
                (click)="toggleEditMode()" 
                matTooltip="{{ editMode ? 'Guardar Cambios' : 'Editar transcripción' }}">
            <i class="fi-rr-edit"></i>
            </button>
        </div>
        <div class="col-md-2">
            <button type="button" mat-flat-button color="primary" class="w-100"
                (click)="onClickPrintPdf()"
                matTooltip="Descarga reporte">
                <i class="fi-rr-download"></i>
            </button>
        </div>
    </div>
</div>
