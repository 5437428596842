<div class="row px-2">
  <div class="col-md-3 border-end" style="background-color: #FFFFFF;">
    <app-left-menu-main [emailsConfig]="emailsConfig" [emailsConfigSelected]="emailsConfigSelected"
      [traysEmail]="traysEmail" [keyTraySelected]="advanceFilters.id_bandeja" [categories]="categories"
      [statesTypesMails]="statesTypesMails" [emailsExpiration]="emailsExpiration"
      [keyCategorySelected]="advanceFilters.id_category" [keyStateSelected]="advanceFilters.id_email_state"
      [keyAnsSelected]="advanceFilters.id_ans" [checkAllAccountEmails]="checkAllAccountEmails" [folders]="folders"
      (onChangeTraySelected)="onChangeTraySelected($event)" (onSendMessage)="getTraysEmail()"
      (onStoreCategory)="getCategories()" (onStoreCategory)="getStates()" (onRefreshDataPage)="loadDataPage()"
      (onChangeEmailsConfigSelected)="updateEmailsConfigSelected($event)"
      (onChangeCategorySelected)="updateFilterIdCategory($event)" (onChangeStateSelected)="updateFilterIdState($event)"
      (onChangeAnsSelected)="updateFilterIdAns($event)"></app-left-menu-main>
  </div>
  <div class="col-md-9">
    <div class="row p-2">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Filtro de búsqueda</mat-label>
          <input matInput placeholder="Búsqueda por asunto" (blur)="onChangeFilterSearchGlobal($event)"
            (keyup.enter)="onKeyupSearchGlobal($event)">
          <button mat-icon-button matSuffix (click)="openDialogAdvanceFilters()">
            <span class="fi-rr-settings-sliders fs-4"></span>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Filtro de correos</mat-label>
          <mat-select [(ngModel)]="filterOrder" (selectionChange)="onChangeFilterOrder($event)">
            <mat-option value="desc">Del más nuevo al más antiguo</mat-option>
            <mat-option value="asc">Del más antiguo al más nuevo</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-1">
        <button class="w-100 h-75" mat-raised-button (click)="resetAdvanceFilters()"
          style="font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">
          <div class="pt-2">
            <span class="fi-rr-cross-circle fs-4"></span>
          </div>
        </button>
      </div>
      <div class="col-md-1">
        <button class="w-100 h-75" mat-raised-button color="primary" (click)="onClickToOpenDialogMeeting()">
          <div class="pt-2">
            <span class="fi-rr-users fs-4"></span>
          </div>
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-md-4 border-top border-end border-bottom px-0" style="background-color: #FFFFFF;">
        <app-mailing-list [listMails]="listMails" [configPaginator]="configPaginator"
          [configEmailsSelected]="emailsConfigSelected" [keyTraySelected]="keyTraySelected"
          [totalNumberEmails]="configPaginator.length" (selectMail)="onChangeSelectedMail($event)"
          (changePage)="onChangePage($event)" (updateMails)="getTraysEmail()" (moveEmail)="onClickButtonMoveEmails($event)"></app-mailing-list>
      </div>
      <div *ngIf="mailSelected === null; else blockMail"
        class="col-md-8 d-flex justify-content-center align-items-center">
        <div style="color: #A9A9A9">
          <img src="../../../../../assets/images/email-manager-view-mail.svg" alt="Index image for view">
          <div class="mt-5 text-center">
            <div style="font-weight: 700; font-size: 20px;">Elige un correo para leerlo</div>
            <div class="mt-2" style="font-weight: 400; font-size: 16px;">No hay nada seleccionado</div>
          </div>
        </div>
      </div>
      <ng-template #blockMail>
        <div class="col-md-8" style="background-color: #FFFFFF;">
          <app-mail-view [mailSelected]="mailSelected" (updateViewEmails)="onAssignEmailUser()"
            (updateDataEmailSelected)="updateDataEmailSelected($event)"
            (deleteOrRestoreEmail)="onDeleteOrRestoreEmail()" (moveEmail)="onClickButtonMoveEmails($event)"></app-mail-view>
        </div>
      </ng-template>
    </div>
  </div>
</div>