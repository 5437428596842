import { Component, OnInit, Input } from '@angular/core';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-history-acordion-mail',
  templateUrl: './history-acordion-mail.component.html',
  styleUrls: ['./history-acordion-mail.component.css']
})
export class HistoryAcordionMailComponent implements OnInit {
  @Input() idEmail: any = null;
  historyConversationList: any[] = [];

  constructor(private emailService: EmailService) { }

  ngOnInit(): void {
    this.getHistoryConversation();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-12-01
    * Metodo que trae el historico de conversacion del correo seleccionado.
  */
  getHistoryConversation(): void {
    this.emailService.getHistoryConversationByEmail(this.idEmail).subscribe(res => {
      this.historyConversationList = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-12-01
    * Metodo que trae el historico de una conversacion segun seleccionado en el acordeon.
    * @param email Informacion del correo seleccionado. 
    * @param index Indice seleccionado del expasion panel. 
  */
  getDataToAccordeonOpen(email: any, index: number): void {
    if (!this.historyConversationList[index].hasOwnProperty('dataEmail')) {
      this.emailService.getEmailById(email).subscribe(res => {
        this.historyConversationList[index] = { 
          ...this.historyConversationList[index],  
          dataEmail: res.data
        };
      });
    }
  }
}