<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div *ngIf="data.hasOwnProperty('tray')" class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.tray === null ? 'Crear bandeja' : 'Editar bandeja' }}
  </div>
  <div *ngIf="data.hasOwnProperty('folder')" class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.folder === null ? 'Crear folder' : 'Editar folder' }}
  </div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formTray">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>{{ data.hasOwnProperty('tray') ? 'Nombre de la bandeja' : 'Nombre de la carpeta' }}</mat-label>
          <input matInput [placeholder]="data.hasOwnProperty('tray') ? 'Nombre de la bandeja' : 'Nombre de la carpeta'" formControlName="name">
          <mat-error *ngIf="error.name.errors">
            <span *ngIf="error.name.errors.required">El campo es requerido</span>
            <span *ngIf="error.name.errors.maxlength">Máximo 30 caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre del icono</mat-label>
          <input matInput placeholder="Nombre del icono" formControlName="icon">
          <mat-error *ngIf="error.icon.errors">
            <span *ngIf="error.icon.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Orden</mat-label>
          <input type="number" matInput placeholder="Orden de prioridad" formControlName="orden">
          <mat-error *ngIf="error.orden.errors">
            <span *ngIf="error.orden.errors.required">El campo es requerido</span>
            <span *ngIf="error.orden.errors.min">Solo valores positivos</span>
            <span *ngIf="error.orden.errors.max">Máximo tres digitos</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="data.hasOwnProperty('tray') && (data.tray === null || data.tray.id_email_config !== 0)">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select formControlName="id_email_config">
            <mat-option *ngFor="let email of data.emailsConfig" [value]="email.id">{{ email.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_email_config.errors">
            <span *ngIf="error.id_email_config.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="data.hasOwnProperty('folder')">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select formControlName="id_email_config">
            <mat-option *ngFor="let email of data.emailsConfig" [value]="email.id">{{ email.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_email_config.errors">
            <span *ngIf="error.id_email_config.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveTray()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">{{ data.tray === null ? 'Crear' : 'Guardar' }}</button>
      </div>
    </div>
  </form>
</div>