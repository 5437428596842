export const PDF_OPTIONS = {
  margin: 13,
  filename: 'Análisis del correo',
  image: { type: 'jpeg', quality: 0.98 },
  html2canvas: {
    scale: 2,
    useCORS: true,
    logging: true
  },
  jsPDF: {
    unit: 'mm',
    format: 'tabloid',
    orientation: 'portrait'
  },
  pagebreak: {
    mode: ['avoid-all', 'css', 'legacy']
  }
};