<div [ngClass]="showVerticalScroll ? 'container-view-mail p-3 vh-100' : 'container-view-mail p-3'"
  [ngStyle]="showVerticalScroll && { 'overflow-y' : 'auto' }">
  <div id="containerViewMail" #containerViewMail class="my-3">
    <div *ngIf="showActionsButtons" class="py-2">
      <div *ngIf="mailSelected.key !== 'eliminados'; else mailDelete">
        <span *ngIf="mailSelected.state_email !== 'Cerrado'" matTooltip="Responder" class="fi-rr-redo px-2"
          style="cursor: pointer;" (click)="setActionSelected('Responder')"></span>
        <span *ngIf="mailSelected.state_email !== 'Cerrado'" matTooltip="Reenviar" class="fi-rr-undo px-2"
          style="cursor: pointer;" (click)="setActionSelected('Reenviar')"></span>
        <span *ngIf="mailSelected.state_email !== 'Cerrado'" matTooltip="Gestión" class="fi-rr-settings px-2"
          style="cursor: pointer;" (click)="setActionSelected('Guardar información')"></span>
        <!-- <span *ngIf="mailSelected.state_email !== 'Cerrado' && mailSelected.state_email !== 'Abierto'"
          matTooltip="Asignar" class="fi-rr-user px-2" style="cursor: pointer;"
          (click)="onClickShowAssignEmail()"></span> -->
        <span *ngIf="mailSelected.key !== 'eliminados'" (click)="deleteEmailsSelected()" matTooltip="Eliminar"
          class="fi-rr-trash px-2" style="cursor: pointer;"></span>
        <span *ngIf="mailSelected.key !== 'cerrados'" matTooltip="Mover"
          class="fi-rr-data-transfer px-2" style="cursor: pointer;" (click)="onClickMoveEmail()"></span>
        <span matTooltip="Descargar" class="fi-rr-document px-2" style="cursor: pointer;"
          (click)="showPreviewEmail()"></span>
        <span matTooltip="Descargar EML" class="fi-rr-file px-2" style="cursor: pointer;"
          (click)="downloadEML()"></span>
      </div>
      <ng-template #mailDelete>
        <span (click)="restoreEmailsSelected()" matTooltip="Restaurar" class="fi-rr-undo px-2"
          style="cursor: pointer;"></span>
      </ng-template>
    </div>
    <app-manager-message *ngIf="actionSelected !== null" [typeManagerMessage]="actionSelected"
      [emailSelected]="mailSelected" (submitMessage)="onSubmitFormManagerMessage($event)"
      (onClickCancelButton)="actionSelected = null"></app-manager-message>
    <div class="my-3" style="font-weight: 700; font-size: 16px;">
      {{ mailSelected.asunto }}
    </div>
    <mat-divider></mat-divider>
    <div class="my-3">
      <div class="d-flex justify-content-between">
        <div class="d-flex w-75">
          <div class="avatar m-0">
            <span>MT</span>
          </div>
          <div class="ms-2" style="font-weight: 400; font-size: 12px;">
            <div>De: {{ mailSelected.de }}</div>
            <div *ngIf="mailSelected.hasOwnProperty('para')">Para: {{ mailSelected.para }}</div>
            <div *ngIf="mailSelected.cc">CC: {{ mailSelected.cc }}</div>
          </div>
        </div>
        <div *ngIf="mailSelected.hasOwnProperty('created_at')" style="font-weight: 400; font-size: 9px;">
          {{ mailSelected.created_at | date: 'short' }}
        </div>
        <div *ngIf="mailSelected.hasOwnProperty('fecha_hora')" style="font-weight: 400; font-size: 9px;">
          {{ mailSelected.fecha_hora }}
        </div>
      </div>
    </div>
    <section *ngIf="showDetails">
      <mat-divider></mat-divider>
      <div class="my-2">
        <div class="d-flex justify-content-between align-items-center">
          <div style="font-weight: 700; font-size: 12px;">
            Detalles
          </div>
          <div *ngIf="showButtonHistory">
            <span style="font-weight: 400; font-size: 12px;">Historial</span>
            <button (click)="onClickShowManagementHistory()" mat-mini-fab style="background-color: transparent;"
              class="mat-elevation-z0">
              <i class="fi-rr-angle-small-right" style="color: #A9A9A9;"></i>
            </button>
          </div>
        </div>
        <div class="my-2">
          <div *ngIf="mailSelected.hasOwnProperty('state_email')" class="my-1" style="font-weight: 400; font-size: 12px;">
            <span style="font-weight: 700; color: #717171;">Estado: </span><span
              [ngStyle]="{ 'font-weight': '700', 'font-size': '12px', 'color': mailSelected.email_state_color.color }">{{
              mailSelected.state_email }}</span>
          </div>
          <div *ngIf="mailSelected.hasOwnProperty('category_email')" class="d-flex my-1" style="font-weight: 400; font-size: 12px;">
            <div><span style="font-weight: 700; color: #717171;">Categoría: </span>{{ mailSelected.category_email }}</div>
            <div><i class="fi-rr-flag fs-6 ms-2" [ngStyle]="{ 'color': mailSelected.category_color }"></i></div>
          </div>
          <div class="my-1" class="d-flex justify-content-start">
            <div style="font-weight: 400; font-size: 12px;" *ngIf="mailSelected.expiration">
              <span style="font-weight: 700; color: #717171;">Vencimiento: </span>{{ mailSelected.expiration }}
            </div>
            <div class="ms-5" style="font-weight: 400; font-size: 12px;" *ngIf="mailSelected.timeWithExpiration">
              <span style="font-weight: 700; color: #717171;">Tiempo dentro del vencimiento: </span>{{
              mailSelected.timeWithExpiration }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <div *ngIf="mailSelected.hasOwnProperty('attachments') && mailSelected.attachments.length > 0">
      <mat-divider></mat-divider>
      <div class="my-3">
        <div style="font-weight: 700; font-size: 12px;">{{ mailSelected.attachments.length }} Adjuntos</div>
        <div class="row my-2">
          <div *ngFor="let item of mailSelected.attachments" class="col-md-6 my-1">
            <div style="font-weight: 400; font-size: 12px;">
              {{ item.nombre }}
              <span (click)="downloadAttachments(item.url, item.nombre)" class="fi-sr-download ms-1"
                style="cursor: pointer;"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="my-3">
      <!--
      <ckeditor #editorPDF [(ngModel)]="mailSelected.cuerpo" [disabled]="true" [editor]="editorBody" [config]="configTextBody" ></ckeditor>
        -->
      
        <angular-editor [config]="editorConfig" [(ngModel)]="mailSelected.cuerpo"></angular-editor>
      
      
    </div>
    <div *ngIf="showAccordeonHistory">
      <mat-divider></mat-divider>
      <div class="my-3" *ngIf="!showHistoryConversationByButton"
        style="font-weight: 700; color: #00acc1; cursor: pointer;" (click)="showHistoryConversationByButton = true">
        Ver historico de conversación
      </div>
      <div *ngIf="showHistoryConversationByButton">
        <div class="my-3" style="font-weight: 700; font-size: 12px;">
          Historico de conversación
        </div>
        <app-history-acordion-mail [idEmail]="mailSelected.id"></app-history-acordion-mail>
      </div>
    </div>
  </div>
</div>