<div class="col-12 p-5">
  <div class="col-12 p-3">
    <h3 class="text-bold">Dashboard Video Llamadas</h3>
    <p class="col-12 col-md-12 col-lg-6 paragraph">
      En este espacio podrás visualizar los indicadores de gestión de los asesores, No olvides, “El respeto y empatía
      son clave”.
    </p>
  </div>

  <!-- Tarjetas datos -->
  <div class="row">

    <div class="col-12">

      <mat-card>
        <h3>Asesores</h3>
        <div class="row">
          <div class="col-4">
            <app-card-dashboard [icon]="icon_agents" [num]="data?.agentsAvailable?.length" [info]="listos">
            </app-card-dashboard>
          </div>
          <div class="col-4">
            <app-card-dashboard [icon]="icon_agents" [num]="data?.agentsBusy?.length" [info]="llamada">
            </app-card-dashboard>
          </div>
          <div class="col-4">
            <app-card-dashboard [icon]="icon_agents" [num]="data?.agentsTotal" [info]="desconectados">
            </app-card-dashboard>
          </div>
        </div>
      </mat-card>
      <br>
    </div>

    <div class="col-12">
      <mat-card>
        <h3>Video llamadas</h3>
        <div class="row">

          <div class="col-4">
            <app-card-dashboard [icon]="icon_salas" [num]="data?.QueueMeeting?.length" [info]="espera">
            </app-card-dashboard>
          </div>
          <div class="col-4">
            <app-card-dashboard [icon]="icon_salas" [num]="data?.scheduledMeeting?.length"  (click)="modal(data?.scheduledMeeting)" [info]="programada">
            </app-card-dashboard>
          </div>
          <div class="col-4">
            <app-card-dashboard [icon]="icon_salas" [num]="data?.scheduledMeetingAlert?.length" (click)="modal(data?.scheduledMeetingAlert)" [info]="proximas">
            </app-card-dashboard>
          </div>
        </div>

      </mat-card>
      <br>
    </div>


    <!-- datos del agente -->
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Position Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef> Nombre Asesor</th>
        <td mat-cell *matCellDef="let element"> {{element.adviser.first_name}} {{element.adviser.last_name}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="id_number">
        <th mat-header-cell *matHeaderCellDef> Cedula Asesor </th>
        <td mat-cell *matCellDef="let element"> {{element.adviser.id_number}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="adviser_id">
        <th mat-header-cell *matHeaderCellDef> Id Asesor </th>
        <td mat-cell *matCellDef="let element"> {{element.adviser_id}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="client_name">
        <th mat-header-cell *matHeaderCellDef> Nombre Cliente </th>
        <td mat-cell *matCellDef="let element"> {{element.client_name}} </td>
      </ng-container>

      <ng-container matColumnDef="client_nic">
        <th mat-header-cell *matHeaderCellDef> Nic Cliente </th>
        <td mat-cell *matCellDef="let element"> {{element.client_nic}} </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef> Duración Llamada </th>
        <td mat-cell *matCellDef="let element"> {{element.start_date | date:'mm:ss'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 15, 25, 50, 100]">
      </mat-paginator>

    <!-- end datos del agente -->
  </div>
