<mat-dialog-content>
  <form [formGroup]="formFilters" class="p-3" autocomplete="off">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListFrom>
            <mat-chip *ngFor="let item of formFilters.get('from')?.controls; let i = index" [selectable]="true"
              [removable]="true" (removed)="removeEmail(i, 'from')">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="De" #inputFrom [matChipInputFor]="chipListFrom"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addEmail($event, 'from')"
              [matChipInputAddOnBlur]="true">
          </mat-chip-list>
          <mat-error *ngIf="error.from.errors">
            <span *ngIf="error.from.errors.validateIsEmails">Correo invalido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListTo>
            <mat-chip *ngFor="let item of formFilters.get('to')?.controls; let i = index" [selectable]="true"
              [removable]="true" (removed)="removeEmail(i, 'to')">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Para" #inputTo [matChipInputFor]="chipListTo"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addEmail($event, 'to')"
              [matChipInputAddOnBlur]="true">
          </mat-chip-list>
          <mat-error *ngIf="error.to.errors">
            <span *ngIf="error.to.errors.validateIsEmails">Correo invalido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Asunto</mat-label>
          <input matInput placeholder="Asunto" formControlName="affair">
          <mat-error *ngIf="error.affair.errors">
            <span *ngIf="error.affair.errors.maxlength">Máximo 100 caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Contiene palabras</mat-label>
          <input matInput placeholder="Contiene palabras" formControlName="containsWords">
          <mat-error *ngIf="error.containsWords.errors">
            <span *ngIf="error.containsWords.errors.maxlength">Máximo 200 caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="state">
            <mat-option value="">Todos</mat-option>
            <mat-option *ngFor="let item of states" [value]="item.id">{{ item.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Rango de fechas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="dateStart" placeholder="Start date">
            <input matEndDate formControlName="dateEnd" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="row d-flex justify-content-end">
      <div class="col-md-3">
        <button type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;"
          (click)="onClickClearFilters()">Limpiar</button>
      </div>
      <div class="col-md-3">
        <button type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;"
          (click)="sendFilters()">Filtrar</button>
      </div>
    </div>
  </form>
</mat-dialog-content>