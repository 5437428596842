import { Component, OnInit, SimpleChanges, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { validateArrayNotEmpty, validateIsEmails } from '../../constantes/custom-validations';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { FormsRequestService } from 'src/app/modules/crm_v2/services/rest/forms-request.service';
import { StateService } from '../../services/state.service';
import { createDataFormCrmV2 } from '../../constantes/create-data-form-crm';
import { DynamicFormV2Component } from 'src/app/shared/dynamic-forms-v2/dynamic-form/dynamic-form.component';
import { EmailService } from '../../services/email.service';
import { MatSelectChange } from '@angular/material/select';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { TemplateService } from '../../services/template.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-message-v2',
  templateUrl: './new-message-v2.component.html',
  styleUrls: ['./new-message-v2.component.css']
})
export class NewMessageV2Component implements OnInit {
  @ViewChild('formCrmComponent') private formCrmComponent: DynamicFormV2Component;
  typeManagerMessage: string = 'Enviar';
  formNewMessage: FormGroup = null;
  formId: number = null;
  formCrm: any = null;
  states: any[] = [];
  formStructureCrm: any = null;
  validFormCrm: boolean = false;
  emailsConfig: any[] = [];
  userLogin: any = null;
  templates: any[] = [];
  filesToLoadEmail: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar,
    private formRequestService: FormsRequestService,
    private stateService: StateService,
    private emailService: EmailService,
    private authService: AuthService,
    private templateService: TemplateService,
    private matDialogRef: MatDialogRef<NewMessageV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.userLogin = this.authService.getUser();
    this.getStates();
    this.setFormGroupByTypeManager();
    this.getEmailsConfig();
    console.log('Dialog data', this.data);

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userLogin = this.authService.getUser();
    this.formId = null;
    this.formCrm = null;
    this.setFormGroupByTypeManager();
    this.getEmailsConfig();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que setea la estructura del formulario.  
  */
  setFormGroupByTypeManager(): void {
    this.formNewMessage = new FormGroup({
      emailConfig: new FormControl(null, [Validators.required]),
      from: new FormControl({ value: '', disabled: true }, []),
      to: this.formBuilder.array([], [validateArrayNotEmpty, validateIsEmails]),
      cc: this.formBuilder.array([], []),
      cco: this.formBuilder.array([], []),
      subject: new FormControl('', [Validators.required]),
      attachments: this.formBuilder.array([], []),
      template: new FormControl('', []),
      message: new FormControl('', [Validators.required])
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-16
    * Metodo carga el formulario dependiendo a la configuracion de email cargado. 
  */
  loadFormWhenSendDraftEmail(): void {
    if (this.typeManagerMessage === 'Enviar borrador') {
      const searchEmailConfigSelect = this.emailsConfig.find(item => item.id_email === this.formNewMessage.get('emailConfig').value);
      if (searchEmailConfigSelect) {
        this.formId = searchEmailConfigSelect.form_id;
        this.getForm();
      }
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que agrega una cadena de texto sobre los input de correos.
    * @param textEmails Cadena con el correo. 
    * @param typeList Nombre del campo con el cual se va a trabajar.  
  */
  addEmailToInput(textEmails: any[], typeList: string): void {
    const arrayToEmails = textEmails.map(item => item.name);
    arrayToEmails.forEach(value => {
      if (value && value !== '') {
        const newFormGroup = this.formBuilder.group({ name: new FormControl(value, [Validators.email]) });
        const arrayForm = this.formNewMessage.get(typeList) as FormArray;
        arrayForm.push(newFormGroup);
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que actualiza la lista de adjuntos cargados en un correo borrador.
    * @param $event Evento emitido por el componente hijo. 
  */
  onDeleteFileToLoadEmail($event: any): void {
    this.filesToLoadEmail = this.filesToLoadEmail.filter(file => file.id !== $event);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-30
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formNewMessage.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-04
    * Metodo que obtiene el formulario de CRM mediante el ID. 
  */
  getForm(): void {
    this.formRequestService.getForm(`searchform/${this.formId}`, 1000, 1).subscribe(res => {
      this.formCrm = res;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-04
    * Metodo que ejecuta el submit del formulario de CRM.
    * @param $event Evento emitido por el formulario de CRM.  
  */
  submitFormCrm($event: any): void {
    this.formStructureCrm = $event;
    this.validFormCrm = $event.isValid;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo valida el tipo de gestion a realizar y hace el llamado al submit del formulario.  
  */
  validateTypeManagerForSubmitMail(): void {
    this.formCrmComponent.onSubmit();
    this.sendEmail();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que trae los estados registrados en el sistema.   
  */
  getStates(): void {
    this.stateService.getStates().subscribe(res => {
      this.states = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-31
    * Metodo que trae las configuraciones de correos de salida registrados en el sistema. 
  */
  getEmailsConfig(): void {
    const idRrhh = this.userLogin.rrhh_id;
    this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
      this.emailsConfig = res.data;
      this.loadFormWhenSendDraftEmail();
      this.setDataFormForDataDialog();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-31
    * Metodo que carga el formulario de CRM a partir de la configuracion de email seleccionado.
    * @param $event Evento emitido por el campo select.    
  */
  onChangeSelectEmailConfig($event: MatSelectChange): void {
    if (this.typeManagerMessage !== 'Guardar información') {
      const searchEmailConfigSelect = this.emailsConfig.find(item => item.id_email === $event.value);
      if (searchEmailConfigSelect) {
        this.getTemplates();
        this.formNewMessage.get('from').setValue(searchEmailConfigSelect.user_name_email_entrada);
        if (this.typeManagerMessage !== 'Reenviar') {
          this.formId = searchEmailConfigSelect.form_id;
          this.getForm();
        }
      }
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-29
    * Metodo que envia el correo con la data del formulario.  
  */
  sendEmail(): void {
    const dataFormCrm = createDataFormCrmV2(this.formStructureCrm, 'typification', this.formCrm);
    if (this.validFormCrm && dataFormCrm && this.formNewMessage.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De enviar el correo?').then(confirm => {
        if (confirm.isConfirmed) {
          this.formRequestService.postForm('formanswer/saveinfo', dataFormCrm).subscribe(resCrm => {
            if (resCrm.data) {
              const formDataMessage = new FormData();
              formDataMessage.append('type', 'nuevo');
              formDataMessage.append('id_referencia_crm', resCrm.data.formAsnwerId);
              formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
              formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
              formDataMessage.append('listImageBase', '');
              formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
              formDataMessage.append('asunto', this.formNewMessage.value.subject);
              formDataMessage.append('cuerpo', this.formNewMessage.value.message);
              this.formNewMessage.value.attachments.forEach((item: any) => {
                formDataMessage.append('files[]', item.file);
              });
              this.emailService.sendEmail(formDataMessage).subscribe(resEmail => {
                this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                  data: {
                    title: '¡Excelente!',
                    message: 'El correo se ha enviado con éxito.'
                  },
                  duration: 5000,
                  verticalPosition: 'top',
                  panelClass: ['green-snackbar']
                });
                this.matDialogRef.close(this.typeManagerMessage);
              });
            }
          });
        }
      });
    } else {
      this.formNewMessage.markAllAsTouched();
      this.alertService.alertError('Error', 'Error al procesar los formularios');
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-03
    * Metodo que envia el correo a la bandeja de borradores.
  */
  saveMessageToDraftEmail(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De descartar el envió del correo?, recuerde que este quedara almacenado en la bandeja de borradores').then(confirm => {
      if (confirm.isConfirmed) {
        const formDataMessage = new FormData();
        const typeManager = this.typeManagerMessage === 'Enviar' ? 'nuevo' : this.typeManagerMessage === 'Responder' ? 'responder' : 'reenviar';
        formDataMessage.append('type', typeManager);
        formDataMessage.append('recipients', this.formNewMessage.value.to.map((item: any) => item.name).join());
        formDataMessage.append('recipients_cc', this.formNewMessage.value.cc.map((item: any) => item.name).join());
        formDataMessage.append('recipients_cco', this.formNewMessage.value.cco.map((item: any) => item.name).join());
        formDataMessage.append('listImageBase', '');
        formDataMessage.append('email_config_id', this.formNewMessage.value.emailConfig);
        formDataMessage.append('asunto', this.formNewMessage.value.subject);
        formDataMessage.append('cuerpo', this.formNewMessage.value.message);
        this.formNewMessage.value.attachments.forEach((item: any) => {
          formDataMessage.append('files[]', item.file);
        });
        formDataMessage.append('es_borrador', true.toString());
        this.emailService.sendEmail(formDataMessage).subscribe(res => {
          this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
            data: {
              title: '¡Excelente!',
              message: 'Mensaje almacenado en borradores'
            },
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['green-snackbar']
          });
          this.matDialogRef.close(this.typeManagerMessage);
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-11
    * Metodo que setea el mensaje del correo si se seleccionó una plantilla.
    * @param $event Evento emitido por el componente hijo. 
  */
  onChangeTemplate($event: number): void {
    const findTemplateSelected = this.templates.find(item => item.id === $event);
    if (findTemplateSelected) this.formNewMessage.get('message').setValue(findTemplateSelected.body);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-11
    * Metodo que trae las platillas registradas en el sistema.
  */
  getTemplates(): void {
    this.templateService.getTemplatesByIdEmailConfig(this.formNewMessage.get('emailConfig').value).subscribe(res => {
      this.templates = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-23
    * Metodo que trae las platillas registradas en el sistema.
    * @param actionButton Tipo de accion a ejecutar. 
  */
  onClickNabvarButton(actionButton: string): void {
    if (actionButton === 'cross') {
      this.alertService.alertWarning('¿Está seguro?', '¿De salir del formulario?. Los datos registrados en el formulario se perderan').then(confirm => {
        if (confirm.isConfirmed) this.matDialogRef.close({ dataFormNewMessage: null });
      });
    } else {
      this.matDialogRef.close({ dataFormNewMessage: this.formNewMessage.value });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-23
    * Metodo que precarga la data del formulario cuando la modal se minimiza. 
  */
  setDataFormForDataDialog(): void {
    if (this.data.email !== null) {
      console.log(this.data.email);
      this.formNewMessage.get('emailConfig').setValue(this.data.email.emailConfig);
      const searchEmailConfigSelect = this.emailsConfig.find(item => item.id_email === this.formNewMessage.get('emailConfig').value);
      if (searchEmailConfigSelect) {
        this.getTemplates();
        this.formNewMessage.get('from').setValue(searchEmailConfigSelect.user_name_email_entrada);
        if (this.typeManagerMessage !== 'Reenviar') {
          this.formId = searchEmailConfigSelect.form_id;
          this.getForm();
        }
      }
      if (this.data.email.to.length > 0) this.addEmailToInput(this.data.email.to, 'to');
      if (this.data.email.cc.length > 0) this.addEmailToInput(this.data.email.cc, 'cc');
      if (this.data.email.cco.length > 0) this.addEmailToInput(this.data.email.cco, 'cco');
      if (this.data.email.attachments.length > 0) this.addFiles(this.data.email.attachments);
      this.formNewMessage.get('subject').setValue(this.data.email.subject);
      this.formNewMessage.get('template').setValue(this.data.email.template);
      this.formNewMessage.get('message').setValue(this.data.email.message);
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-23
    * Metodo agrega un archivo a la lista de archivos del formulario. 
    * @param listFiles Lista de archivos precargados. 
  */
  addFiles(listFiles: any[]) {
    for (let i = 0; i < listFiles.length; i++) {
      const newFormGroup = this.formBuilder.group({ file: new FormControl(listFiles[i].file, []) });
      const arrayForm = this.formNewMessage.get('attachments') as FormArray;
      arrayForm.push(newFormGroup);
    }
  }
}