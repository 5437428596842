import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FullCalendarComponent, CalendarOptions, EventClickArg } from '@fullcalendar/angular';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { validateArrayNotEmpty, validateIsEmails, validateTimeRange, validateArrayDaysNotEmpty } from '../../constantes/custom-validations';
import { MatSelectChange } from '@angular/material/select';
import { ContactGroupComponent } from '../contact-group/contact-group.component';
import { EDITOR_CONFIG as editor_modify } from '../../constantes/mail-config-editor';
import { EDITOR_CONFIG as editor_view } from '../../constantes/mail-config-editor-view';
import { TeamsService } from '../../services/teams.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { DAYS_OF_WEEK } from '../../constantes/create-data-chart';
import * as moment from 'moment';
import esLocale from '@fullcalendar/core/locales/es';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-create-meeting',
  templateUrl: './create-meeting.component.html',
  styleUrls: ['./create-meeting.component.css']
})
export class CreateMeetingComponent implements OnInit {
  @ViewChild('calendar') calendar: FullCalendarComponent;
  @ViewChild('participants') participants: MatChipList;
  formMeeting: FormGroup = new FormGroup({
    id: new FormControl('', []),
    emailAccount: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required]),
    dateStart: new FormControl('', [Validators.required]),
    dateEnd: new FormControl('', [Validators.required]),
    timeStart: new FormControl('', [Validators.required]),
    timeEnd: new FormControl('', [Validators.required]),
    typeOfRecurrence: new FormControl('Ninguna', [Validators.required]),
    repeatEach: new FormControl('1', []),
    days: new FormControl([], []),
    typeOptionDayMonth: new FormControl('', []),
    recurrenceDaysInMonth: new FormControl('', []),
    participants: this.formBuilder.array([], [validateArrayNotEmpty, validateIsEmails]),
    linkByTeams: new FormControl(false, []),
    description: new FormControl('', [Validators.required]),
    limitDate: new FormControl('', [])
  });
  showCalendar: boolean = false;
  showFormMeeting: boolean = false;
  calendarOptions: CalendarOptions = null;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  editorConfig: any = editor_modify;
  eventsTeams: any[] = [];
  eventTeamsSelected: any = null;
  stateFieldsForm: boolean = false;
  buttonsManagerMeeting: any[] = [
    { name: 'Editar Meeting', class: 'fi-rr-edit fs-4', styles: { 'height': '48px', 'background-color': '#2CABBC', 'color': '#FFFFFF' }, show: false },
    { name: 'Eliminar Meeting', class: 'fi-rr-trash fs-4', styles: { 'height': '48px', 'background-color': '#BE0F0F', 'color': '#FFFFFF' }, show: false },
    { name: 'Aceptar Meeting', class: 'fi-rr-check fs-4', styles: { 'height': '48px', 'background-color': '#2CABBC', 'color': '#FFFFFF' }, show: false },
    { name: 'Rechazar Meeting', class: 'fi-rr-cross fs-4', styles: { 'height': '48px', 'background-color': '#BE0F0F', 'color': '#FFFFFF' }, show: false },
  ];
  typesRecurrence: string[] = ['Ninguna', 'Diario', 'Semanal', 'Mensual'];
  daysOfWeek: any[] = DAYS_OF_WEEK;
  typesOptionDayMonth: string[] = ['Día del mes'];
  currentDate: any = moment().format();
  allowManagement: boolean = true;

  /**
   * CKEDitor Config
   */
  isDisabledEditEditor:boolean = false
  public editorBody = ClassicEditor

  public configTextBody = {
    placeholder: 'Ingresa aqui el cuerpo del PDF',
    language: 'es'
  }

  constructor(
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private teamsService: TeamsService,
    private alertService: AlertsService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    /*
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold', 'italic',
          '|',
          'link',
          '|',
          'bulletedList', 'numberedList',
          '|',
          'insertTable',
          '|',
          'blockQuote',
          '|',
          'undo',
          'redo'
        ],
      },
      heading: {
        options: [
            { model: 'paragraph', title: 'Párrafo', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Título 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Título 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Título 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Título 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Título 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Título 6', class: 'ck-heading_heading6' }
        ]
      },
  
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'es'
    };
    */
  }

  ngOnInit(): void {
    this.formMeeting.get('participants')?.statusChanges.subscribe(status => { this.participants.errorState = status === 'INVALID'; });
    this.formMeeting.get('timeStart').setValidators([Validators.required, validateTimeRange(this.formMeeting.get('timeStart'), this.formMeeting.get('timeEnd'))]);
    this.formMeeting.get('timeEnd').setValidators([Validators.required, validateTimeRange(this.formMeeting.get('timeStart'), this.formMeeting.get('timeEnd'))]);
    this.formMeeting.get('timeStart').updateValueAndValidity();
    this.formMeeting.get('timeEnd').updateValueAndValidity();
    this.formMeeting.get('timeEnd').valueChanges.subscribe(() => {
      this.formMeeting.get('timeStart').updateValueAndValidity();
    });
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que precarga los eventos en el calendario cuando se cambia la cuenta de correo seleccionada.
    * @param $event Evento emitido por el campo select.  
  */
  getEventsByEmailSelected($event: MatSelectChange): void {
    const startDateMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endDateMonth = moment().endOf('month').format('YYYY-MM-DD');
    this.getEvents(startDateMonth, endDateMonth, $event.value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que precarga los eventos en el calendario.
    * @param startDate Fecha inicial del mes. 
    * @param endDate Fecha inicial del mes.
    * @param idEmailConfig Id de la cuenta de email. 
  */
  getEvents(startDate: string, endDate: string, idEmailConfig: number): void {
    this.eventsTeams = [];
    this.teamsService.getCalendarByEmailAccount(idEmailConfig, startDate, endDate).subscribe(res => {
      if (!res.error) {
        const events = res.data;
        for (const key in events) {
          const objectEvent = {
            id: events[key].object_id,
            title: events[key].subject !== '' ? events[key].subject : 'Sin título',
            start: moment(events[key].start).format('YYYY-MM-DD'),
            end: moment(events[key].end).format('YYYY-MM-DD'),
          };
          this.eventsTeams.push(objectEvent);
        }
        this.calendarOptions = {
          initialView: 'dayGridMonth',
          locales: [esLocale],
          locale: 'es',
          customButtons: {
            prev: { click: this.onClickButtonPreviousCalendar.bind(this) },
            today: { text: 'Hoy', click: this.onClickButtonTodayCalendar.bind(this) },
            next: { click: this.onClickButtonNextCalendar.bind(this) },
          },
          showNonCurrentDates: false,
          handleWindowResize: true,
          windowResizeDelay: 200,
          eventClick: this.onClickEventCalendar.bind(this),
          events: this.eventsTeams,
        };
        this.showCalendar = true;
      } else {
        this.showCalendar = false;
        this.snackBarService.showNotification({ title: '¡Error!', message: res.message });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que ejecuta el los eventos del boton previous del calendario.
  */
  onClickButtonPreviousCalendar(): void {
    const calendarApi = this.calendar.getApi();
    calendarApi.prev();
    const startDateMonth = moment(calendarApi.getDate()).startOf('month').format('YYYY-MM-DD');
    const endDateMonth = moment(calendarApi.getDate()).endOf('month').format('YYYY-MM-DD');
    this.getEvents(startDateMonth, endDateMonth, this.formMeeting.get('emailAccount').value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que ejecuta el los eventos del boton today del calendario.
  */
  onClickButtonTodayCalendar(): void {
    const calendarApi = this.calendar.getApi();
    calendarApi.today();
    const startDateMonth = moment(calendarApi.getDate()).startOf('month').format('YYYY-MM-DD');
    const endDateMonth = moment(calendarApi.getDate()).endOf('month').format('YYYY-MM-DD');
    this.getEvents(startDateMonth, endDateMonth, this.formMeeting.get('emailAccount').value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que ejecuta el los eventos del boton next del calendario.
  */
  onClickButtonNextCalendar(): void {
    const calendarApi = this.calendar.getApi();
    calendarApi.next();
    const startDateMonth = moment(calendarApi.getDate()).startOf('month').format('YYYY-MM-DD');
    const endDateMonth = moment(calendarApi.getDate()).endOf('month').format('YYYY-MM-DD');
    this.getEvents(startDateMonth, endDateMonth, this.formMeeting.get('emailAccount').value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que busca un evento seleccionado desde el calendario.
    * @param event Evento emitido por el calendario.
  */
  onClickEventCalendar($event: EventClickArg): void {
    this.teamsService.getEvent(this.formMeeting.get('emailAccount').value, $event.event.id).subscribe(res => {
      this.eventTeamsSelected = res.data;
      this.allowManagement = moment(this.eventTeamsSelected.start).isBefore(moment()) ? false : true;
      this.showFormMeeting = true;
      this.formMeeting.get('id').setValue(this.eventTeamsSelected.object_id);
      this.formMeeting.get('title').setValue(this.eventTeamsSelected.subject);
      this.formMeeting.get('dateStart').setValue(moment(this.eventTeamsSelected.start).format());
      this.formMeeting.get('dateEnd').setValue(moment(this.eventTeamsSelected.end).format());
      this.formMeeting.get('timeStart').setValue(moment(this.eventTeamsSelected.start).format('HH:mm'));
      this.formMeeting.get('timeEnd').setValue(moment(this.eventTeamsSelected.end).format('HH:mm'));
      this.formMeeting.get('linkByTeams').setValue(this.eventTeamsSelected.is_online_meeting);
      this.currentDate = moment(this.eventTeamsSelected.start).format();
      const arrayForm = this.formMeeting.get('participants') as FormArray;
      arrayForm.clear();
      for (const key in this.eventTeamsSelected.data_attendees) {
        const newFormGroup = this.formBuilder.group({ name: new FormControl(this.eventTeamsSelected.data_attendees[key].address, [Validators.email]) });
        arrayForm.push(newFormGroup);
      }
      this.editorConfig = editor_view;
      this.isDisabledEditEditor = true
      this.formMeeting.get('description').setValue(this.eventTeamsSelected.body);
      this.changeDisableFieldsForm(true);
      this.updateShowActionsButton(this.eventTeamsSelected.is_organizer, this.eventTeamsSelected.status);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-14
    * Metodo que actualiza el show de los botones.
    * @param isOrganizer Valida si es o no el dueño del evento.
  */
  updateShowActionsButton(isOrganizer: boolean, status: string): void {
    const setButtonsManagerMeeting = Array.from(this.buttonsManagerMeeting);
    if (isOrganizer) {
      setButtonsManagerMeeting[0].show = true;
      setButtonsManagerMeeting[1].show = true;
      setButtonsManagerMeeting[2].show = false;
      setButtonsManagerMeeting[3].show = false;
    } else {
      setButtonsManagerMeeting[0].show = false;
      setButtonsManagerMeeting[1].show = false;
      if (status === 'NotResponded') {
        setButtonsManagerMeeting[2].show = true;
        setButtonsManagerMeeting[3].show = true;
      } else {
        setButtonsManagerMeeting[2].show = status === 'Accepted' ? false : true;
        setButtonsManagerMeeting[3].show = true;
      }
    }
    this.buttonsManagerMeeting = setButtonsManagerMeeting;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que agrega una cadena de texto a cada uno de los chip list.
    * @param event Manejador de eventos de los mat chip.
    * @param typeList Nombre del list chip en el cual se van agregar una cadena de texto.  
  */
  addEmail($event: MatChipInputEvent, typeList: string): void {
    const input = $event.input;
    const value = ($event.value || '').trim();
    if (value && value !== '') {
      const newFormGroup = this.formBuilder.group({ name: new FormControl(value, [Validators.email]) });
      const arrayForm = this.formMeeting.get(typeList) as FormArray;
      arrayForm.push(newFormGroup);
      if (input) input.value = '';
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que elimina una cadena de texto dentro un chip list.
    * @param index Cadena de texto seleccionada dentro del chip list. 
    * @param typeList Nombre del chip list en el cual se va a remover el elemento.
  */
  removeItem(index: number, typeList: string): void {
    const arrayForm = this.formMeeting.get(typeList) as FormArray;
    arrayForm.removeAt(index);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formMeeting.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que muestra la modal de grupos de contacto. 
    * @param nameField Nombre del campo de donde esta ubicado el boton.
  */
  onClickOpenDialogContacts(nameField: string): void {
    this.matDialog.open(ContactGroupComponent, {
      width: '70%',
      autoFocus: false,
      panelClass: 'dialog-padding-contact-group',
      maxHeight: '95vh',
      data: {
        nameField
      }
    }).afterClosed().subscribe(res => {
      if (res.contactGroupSelected && res.contactGroupSelected.length > 0)
        this.addMailsContactGroup(res.contactGroupSelected, res.nameField);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que registra los correos de los grupos de contacto sobre los respectivos campos de texto.
    * @param mails Informacion del grupo de contacto. 
    * @param nameField Nombre del campo de donde esta ubicado el boton.
  */
  addMailsContactGroup(mails: any, nameField: string): void {
    const mailsToSplit = mails.map((item: any) => { return { ...item, mails: item.email_list.split(',') }; });
    mailsToSplit.forEach((contactGroup: any) => {
      contactGroup.mails.forEach((mail: string) => {
        const newFormGroup = this.formBuilder.group({ name: new FormControl(mail, [Validators.email]) });
        const arrayForm = this.formMeeting.get(nameField) as FormArray;
        arrayForm.push(newFormGroup);
      });
    });
    this.snackBarService.showNotification({ message: 'Se ha añadido el grupo de contacto con éxito' });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que cambia el disabled de los campos del formulario. 
    * @param disabledFieldsForm Nuevo estado para el disabled de los campos del formulario. 
  */
  changeDisableFieldsForm(disabledFieldsForm: boolean): void {
    this.stateFieldsForm = disabledFieldsForm;
    Object.keys(this.formMeeting.controls).forEach(controlName => {
      const control = this.formMeeting.get(controlName);
      if (this.stateFieldsForm) control.disable();
      else control.enable();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que valida que accion se ejecuto de los botones de gestion de eventos.
    * @param typeManager Tipo de accion ejecutado.
  */
  onClickButtonsMeetingManager(typeManager: string): void {
    if (typeManager === 'Editar Meeting') this.editEventMeeting();
    if (typeManager === 'Eliminar Meeting') this.deleteEventMeeting();
    if (typeManager === 'Aceptar Meeting') this.acceptOrDeclineEventMeeting('Aceptar Meeting');
    if (typeManager === 'Rechazar Meeting') this.acceptOrDeclineEventMeeting('Rechazar Meeting');
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que cambia el estado de los campos de formulario y del texto enriquecido. 
  */
  editEventMeeting(): void {
    this.changeDisableFieldsForm(false);
    this.editorConfig = editor_modify;
    this.isDisabledEditEditor = false
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que elimina un evento seleccionado. 
  */
  deleteEventMeeting(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De eliminar el evento seleccionado?').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        this.teamsService.deleteEvent(this.formMeeting.get('emailAccount').value, this.formMeeting.get('id').value).subscribe(res => {
          this.snackBarService.showNotification({ title: res.error ? '¡Error!' : '¡Excelente!', message: res.message });
          this.onClickButtonCancel();
          this.updateDataCalendar();
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que guarda o actualiza un meeting.
    * @param typeManager Tipo de gestion a realizar. 
  */
  saveOrUpdateMeeting(typeManager: string = 'save'): void {
    if (this.formMeeting.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.teamsService.saveOrUpdateEvent(this.formMeeting.value, typeManager).subscribe(res => {
            this.snackBarService.showNotification({ title: res.error ? '¡Error!' : '¡Excelente!', message: res.message });
            this.updateDataCalendar();
            this.onClickButtonCancel();
          });
        }
      });
    } else this.formMeeting.markAllAsTouched();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-06
    * Metodo que setea la validacion de los campos del formulario en general.
    * @param $event Evento emitido por el select.
  */
  onChangeTypeOfRecurrence($event: MatSelectChange): void {
    if ($event.value === 'Ninguna') {
      this.clearValidatorsTypesRecurrence();
    } else if ($event.value === 'Diario') {
      this.formMeeting.get('repeatEach').setValidators([Validators.required, Validators.min(1)]);
      this.formMeeting.get('days').setValidators([]);
      this.formMeeting.get('typeOptionDayMonth').setValidators([]);
      this.formMeeting.get('recurrenceDaysInMonth').setValidators([]);
      this.formMeeting.get('limitDate').setValidators([Validators.required]);
    } else if ($event.value === 'Semanal') {
      this.formMeeting.get('repeatEach').setValidators([Validators.required, Validators.min(1)]);
      this.formMeeting.get('days').setValidators([validateArrayDaysNotEmpty]);
      this.formMeeting.get('typeOptionDayMonth').setValidators([]);
      this.formMeeting.get('recurrenceDaysInMonth').setValidators([]);
      this.formMeeting.get('limitDate').setValidators([Validators.required]);
    } else {
      this.formMeeting.get('repeatEach').setValidators([]);
      this.formMeeting.get('days').setValidators([]);
      this.formMeeting.get('typeOptionDayMonth').setValidators([Validators.required]);
      this.formMeeting.get('recurrenceDaysInMonth').setValidators([]);
      this.formMeeting.get('limitDate').setValidators([Validators.required]);
    }
    this.resetFormValuesTypesRecurrence();
    this.clearAndUpdateValidatorsForm();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-22
    * Metodo que limpia el valor de los campos cuando hay cambios en el tipo de recurrencia.
  */
  resetFormValuesTypesRecurrence(): void {
    this.formMeeting.get('repeatEach').setValue('1');
    this.formMeeting.get('days').setValue([]);
    this.formMeeting.get('typeOptionDayMonth').setValue('');
    this.formMeeting.get('recurrenceDaysInMonth').setValue('');
    this.formMeeting.get('limitDate').setValue('');
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-22
    * Metodo que limpia las validaciones de los campos correspondientes a las recurrencias.
  */
  clearValidatorsTypesRecurrence(): void {
    this.formMeeting.get('repeatEach').setValidators([]);
    this.formMeeting.get('days').setValidators([]);
    this.formMeeting.get('typeOptionDayMonth').setValidators([]);
    this.formMeeting.get('recurrenceDaysInMonth').setValidators([]);
    this.formMeeting.get('limitDate').setValidators([]);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-06
    * Metodo que setea la validacion de los campos del formulario para tipo mensual.
    * @param $event Evento emitido por el select.
  */
  onChangeTypeOptionDayMonth($event: MatSelectChange): void {
    if ($event.value === 'Día del mes') {
      this.formMeeting.get('recurrenceDaysInMonth').setValidators([Validators.required]);
      this.formMeeting.get('days').setValidators([]);
    } else {
      this.formMeeting.get('recurrenceDaysInMonth').setValidators([]);
      this.formMeeting.get('days').setValidators([validateArrayDaysNotEmpty]);
    }
    this.clearAndUpdateValidatorsForm();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-06
    * Metodo que limpia las validaciones. 
  */
  clearAndUpdateValidatorsForm(): void {
    Object.keys(this.formMeeting.controls).forEach(controlName => {
      this.formMeeting.get(controlName).updateValueAndValidity();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-07
    * Metodo que ejecuta las acciones para el boton cancelar. 
  */
  onClickButtonCancel(showForm: boolean = false): void {
    if (this.eventTeamsSelected) this.changeDisableFieldsForm(false);
    this.resetValuesFormMeeting();
    this.clearValidatorsTypesRecurrence();
    this.eventTeamsSelected = null;
    this.showFormMeeting = showForm;
    this.currentDate = moment().format();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-07
    * Metodo que reinicia los valores del formulario. 
  */
  resetValuesFormMeeting(): void {
    Object.keys(this.formMeeting.controls).forEach(controlName => {
      if (controlName !== 'emailAccount') {
        if (controlName !== 'typeOfRecurrence' && controlName !== 'repeatEach') {
          const arrayForm = this.formMeeting.get('participants') as FormArray;
          this.formMeeting.get(controlName).reset();
          if (arrayForm.length > 0) arrayForm.clear();
        } else {
          this.formMeeting.get('typeOfRecurrence').setValue('Ninguna');
          this.formMeeting.get('repeatEach').setValue('1');
        }
      }
    });
    this.editorConfig = editor_modify;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-11
    * Metodo que retorna una fecha siguiente con respecto a la fecha fin del rango de fechas. 
  */
  addDateToEndRangeDate(): string {
    if (this.formMeeting.get('dateEnd').value !== '' || this.formMeeting.get('dateEnd').value !== null) return moment(this.formMeeting.get('dateEnd').value).add(1, 'days').format();
    else return moment().format();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-14
    * Metodo que acepta o rechaza una reunión.
    * @param typeManager Tipo de gestion a realizar. 
  */
  acceptOrDeclineEventMeeting(typeManager: string): void {
    this.alertService.alertWarning('¿Está seguro?', typeManager === 'Aceptar Meeting' ? 'De <b>aceptar</b> la reunión' : 'De <b>rechazar</b> la reunión').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        const data = {
          id_email: this.formMeeting.get('emailAccount').value,
          event: this.eventTeamsSelected.object_id,
          comment: typeManager === 'Aceptar Meeting' ? 'Acepto la reunión' : 'Rechazo la reunión',
          send_response: true
        };
        this.teamsService.acceptOrDeclineEventMeeting(typeManager, this.formMeeting.get('emailAccount').value, data).subscribe(res => {
          this.snackBarService.showNotification({ title: res.error ? '¡Error!' : '¡Excelente!', message: res.message });
          this.updateDataCalendar();
          this.onClickButtonCancel();
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-14
    * Metodo que actualiza los eventos en el calendario.
  */
  updateDataCalendar(): void {
    const calendarApi = this.calendar.getApi();
    const startDateMonth = moment(calendarApi.getDate()).startOf('month').format('YYYY-MM-DD');
    const endDateMonth = moment(calendarApi.getDate()).endOf('month').format('YYYY-MM-DD');
    this.getEvents(startDateMonth, endDateMonth, this.formMeeting.get('emailAccount').value);
  }
}