import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const MEETING_URL = environment.MEETINGS_URL;
const PQRS_URL = environment.PQRS_URL;

@Injectable({
  providedIn: 'root'
})
export class DashboardDosService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  dashboard(data: any): Observable<any> {
    return this.http.post<any>(`${MEETING_URL}Dashboard`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  dashboardMail(): Observable<any> {
    return this.http.get<any>(`${PQRS_URL}PQRS/statusEscalamientos`)
      .pipe(
        catchError(this.handleError)
      );
  }

}
