import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class ModelIAService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }


  /**
   * Metodo que retorna el listado de modelos de IA activos y disponibles para usar
   * @author Yeison Sepulveda
   * @createdate 2024-10-21
  */
  listModels(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}models-ia/list`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo actualiza la información de un modelo
   * @author Yeison Sepulveda
   * @createdate 2024-10-21
   * @param modelData Informacion actualizar
  */
  UpdateModel(modelData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}/models-ia/update/${modelData.id}`, modelData).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo para crear un nuevo registro modelos
   * @author Yeison Sepulveda
   * @createdate 2024-10-21
   * @param body
  */
  CreaServer(body: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}models-ia/create`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Desactivar un modelo de IA activo
   * @author Yeison Sepulveda
   * @createdate 2024-10-21
   * @param id id modelos a desactivar
  */
  desactiveModel(id: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}models-ia/desactive`, id).pipe(
      catchError(this.handleError)
    );
  }
}