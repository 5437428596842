import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-11
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que retorna las platillas asociadas a un id de configuracion de correo. 
    * @param idEmailConfig Configuracion de correo seleccionado.  
  */
  getTemplatesByIdEmailConfig(idEmailConfig: number): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}templates/showall?id_email_config=${idEmailConfig}&state=1`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que retorna las plantillas registradas en el sistema. 
  */
  getTemplates(paginator: any, filter: string, allStates: boolean = true, filterEmail: number): Observable<any> {
    const allStatesFilter = !allStates ? '&state=1' : '';
    return this.httpClient.get(`${GESTIONADOR_CORREOS}templates/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filter}&id_email_config=${filterEmail}${allStatesFilter}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-29
    * Metodo que guardar o actualiza una plantilla en el sistema. 
    * @param template Informacion proveniente del formulario.  
  */
  storeTemplate(template: any): Observable<any> {
    const url = template.id === null ? `${GESTIONADOR_CORREOS}templates/store` : `${GESTIONADOR_CORREOS}templates/update/${template.id}`;
    return this.httpClient.post(url, template).pipe(
      catchError(this.handleError)
    );
  }
}