<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="cancelProcess()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Asignación de bandejas</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">
        En este espacio podrás asignar las bandejas a sus respectivos asesores, no olvides,
        <br /> "El respeto y la empatía son clave".
      </p>
    </div>
  </div>
  <div class="row my-5 justify-content-start">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Cuenta de correo</mat-label>
        <mat-select [(ngModel)]="emailConfigSelected" (selectionChange)="onChangeSelectAccountEmail($event)">
          <mat-option *ngFor="let item of emailsConfig" [value]="item.id">{{ item.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Asesores</mat-label>
        <mat-select [(ngModel)]="adviserSelected" (selectionChange)="onChangeSelectedAdviser($event)">
          <mat-option *ngFor="let item of advisersToRolManagerEmail" [value]="item.id_rrhh">{{ item.name_user_asignado }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <app-exchange-lists *ngIf="emailConfigSelected && adviserSelected"
    [taskDescription]="'Nota: Recuerda que para asignar bandejas a los asesores, deberás seleccionarlas con los checks del lado izquierdo (bandejas disponibles) y luego dar clic en el bóton de la flecha para pasarlas al lado derecho (bandejas asignadas).'"
    [titleFirstList]="'Bandejas disponibles'" [titleSecondList]="'Bandejas asignadas'"
    [textInputFirstList]="'Buscar bandejas disponible'" [textInputSecondList]="'Buscar bandejas asignadas'"
    [firstList]="firstList" [secondList]="secondList" (runFilter)="runFilterList($event)"
    (updateList)="updateList($event)" (saveConfig)="saveConfig($event)" (cancelProcess)="cancelProcess()">
  </app-exchange-lists>
</div>