<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.title }}
  </div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formAssignEmail">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Asesores</mat-label>
          <input matInput formControlName="name_user_asignado" cdkFocusInitial [matAutocomplete]="auto"
            [value]="nameAdviser">
          <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let item of filteredOptions | async" [value]="item.id_rrhh"
              (onSelectionChange)="findAdviser($event)">
              {{ item.name_user_asignado }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="error.name_user_asignado.errors">
            <span *ngIf="error.name_user_asignado.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!data.isEmailReassignment" class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Descripción</mat-label>
          <input matInput placeholder="Descripción" formControlName="observacion_asignacion">
          <mat-error *ngIf="error.observacion_asignacion.errors">
            <span *ngIf="error.observacion_asignacion.errors.required">El campo es requerido</span>
            <span *ngIf="error.observacion_asignacion.errors.maxlength">Máximo 500 caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="!data.isEmailReassignment" class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Correo electrónico</mat-label>
          <input matInput placeholder="Correo electrónico" formControlName="email">
          <mat-error *ngIf="error.email.errors">
            <span *ngIf="error.email.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Descartar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveAssignMail()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Guardar</button>
      </div>
    </div>
  </form>
</div>