<div class="d-flex justify-content-between align-items-center p-4">
  <div style="font-weight: 700; font-size: 24px;">
    Resultados de análisis
  </div>
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="w-100 d-flex justify-content-end px-4">
  <span class="fi-rr-document" style="cursor: pointer;" (click)="onClickPrintPdf()">
    &nbsp;<span class="fw-bold text-decoration-underline">Imprimir</span>
  </span>
</div>
<div class="px-4 py-3" #container>
  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="fw-bold">Contenido del correo</mat-panel-title>
      </mat-expansion-panel-header>
      <app-mail-view [mailSelected]="data.email" [showVerticalScroll]="false" [showActionsButtons]="false"
        [showButtonHistory]="false" [showAccordeonHistory]="false" [showDetails]="true"></app-mail-view>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="fw-bold">Resumen e identificación de objetivo</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex flex-column gap-2" *ngIf="formatDataByResultIa.length > 0; else notData">
        <div class="text-justify text-break lh-base" *ngFor="let item of formatDataByResultIa">
          <b>{{ item.title }}</b>{{ item.content }}
        </div>
      </div>
      <ng-template #notData>
        <div class="d-flex justify-content-center p-4 fw-bold">
          No hay información disponible para mostrar
        </div>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="fw-bold">Análisis de sentimientos</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex flex-column gap-2">
        <div class="text-justify text-break lh-base">
          <b>Motivo: </b>{{ sentimentSummary.motivo }};
        </div>
        <div class="text-justify text-break">
          <b>Sentimiento: </b>{{ sentimentSummary.sentimiento }}
        </div>
        <!-- <canvas id="pieChart" #pieChart style="max-width: 600px; max-height: 400px;"></canvas> -->
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>