<div class="swiper-container months-swiper">
  <swiper [config]="swipperConfigMonths">
    <ng-template *ngFor="let month of cardsMonths" swiperSlide>
      <div style="cursor: pointer;" (click)="onClickMonthSelected.emit(month.name)">
        <div style="height: 120px; border-radius: 10px; background-color: #FFFFFF; border-color: #2CABBC;"
          class="alert d-flex justify-content-center align-items-center border-2">
          <div style="color: #2CABBC; font-weight: 700; font-size: 24px; text-decoration: underline;">{{ month.name }}
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
  <div class="swiper-button-prev swiper-button-prev-months  d-none d-sm-block"></div>
  <div class="swiper-button-next swiper-button-next-months d-none d-sm-block"></div>
</div>