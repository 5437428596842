<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Reportes de correos</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">En este espacio podrás consultar y/o descargar los reportes de gestión.
        No <br /> olvides, “El respeto y empatía son clave”.</p>
    </div>
  </div>
  <div class="row d-flex justify-content-between mt-5">
    <div class="col-md-7">
      <form class="row" [formGroup]="formReport">
        <div class="col-md-5">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Cuenta de correo</mat-label>
            <mat-select formControlName="emailAccount">
              <mat-option *ngFor="let email of emailsConfig" [value]="email.id">{{ email.name
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.emailAccount.errors">
              <span *ngIf="error.emailAccount.errors.required">El campo es requerido</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Año</mat-label>
            <mat-select (selectionChange)="getMonths($event)" formControlName="year">
              <mat-option *ngFor="let item of years" [value]="item.year">{{ item.year }}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.year.errors">
              <span *ngIf="error.year.errors.required">El campo es requerido</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Mes</mat-label>
            <mat-select multiple formControlName="months">
              <mat-option *ngFor="let item of months" [value]="item.id">{{ item.nombre }}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.months.errors">
              <span *ngIf="error.months.errors.required">El campo es requerido</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-1">
          <button [disabled]="stateButtonSubmit" (click)="generateReport()" type="button" mat-raised-button
            color="primary" class="w-100" style="height: 48px; font-weight: 600;">
            <span class="fi-rr-search fs-5"></span>
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-3">
      <button (click)="onClickDownloadReport()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">Descargar reporte</button>
    </div>
  </div>
  <div *ngIf="!runCallReportCard; else loadData" [@fadeInOnEnter] [@fadeOut]>
    <app-swiper-report-trays [cardsReport]="cardsReport"></app-swiper-report-trays>
    <app-swiper-report-months *ngIf="cardsMonths.length > 0" [cardsMonths]="cardsMonths"
      (onClickMonthSelected)="onClickCardMonth($event)"></app-swiper-report-months>
  </div>
  <ng-template #loadData>
    <div style="height: 200px;"
      class="w-100 bg-white d-flex justify-content-center align-items-center border border-2 rounded my-4"
      [@fadeInOnEnter] [@fadeOut]>
      <div class="fs-3 fw-bold">{{ messageWhenUpdateReportCard }}</div>
    </div>
  </ng-template>
  <div class="row px-1">
    <div class="col-md-12">
      <shared-basic-table [columnsTable]="[{ name: '', key: 'actions', type: 'events' }, { name: 'Estado', key: 'estado' }, { name: 'Total', key: 'total' }]" 
        [buttonsOptions]="[{ class: 'fi-rr-eye', action: 'view' }]" [dataTables]="dataTableSates" [messageLoading]="messageWhenUpdateReportTableStates"
        (eventEmitButton)="onClickButtonTableStates($event)"></shared-basic-table>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Asesor</mat-label>
        <mat-select (selectionChange)="onChangeFilterAdviser($event)">
          <mat-option [value]="''">Todos los asesores</mat-option>
          <mat-option *ngFor="let item of listToAdvisers" [value]="item.id">{{
            item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row px-1">
    <div class="col-md-12">
      <table mat-table [dataSource]="dataTable">
        <ng-container *ngFor="let column of configColumnsTable">
          <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell *matCellDef="let element"> {{ element[column.key] }} </td>
          </ng-container>
          <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell width="10%" class="text-center ps-0" *matCellDef="let element">
              <button (click)="onClickAdviserTable(element)" mat-icon-button style="width: 25px !important;">
                <span class="fi-rr-eye"></span>
              </button>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
        <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
        <tr class="mat-row" style="background: white" *matNoDataRow>
          <td class="mat-cell" style="font-weight: 600;" [attr.colspan]="keysColumnsTable.length">{{ messageWhenUpdateReportTable }}
          </td>
        </tr>
      </table>
      <mat-paginator (page)="onChangePage($event)" [length]="configPaginator.length"
        [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>