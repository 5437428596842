<mat-card class="example-card" appearance="outlined" *ngIf="statusOpenClosePanel">
    <div class="row pb-3">
        <div class="col-12">
            <button mat-icon-button class="close_button" *ngIf="conectionStatus && conectionStatus.id == 2"
                (click)="colsePanelPhone()">
                <i class="fi-rr-cross-small"></i>
            </button>
            <button mat-icon-button class="view_button" (click)="changeState()">
                <i class="fi-rr-compress-alt" *ngIf="!statusMinimize"></i>
                <i class="fi-rr-expand" *ngIf="statusMinimize"></i>
            </button>
        </div>
    </div>
    <mat-card-header>



        <div mat-card-avatar style="position: relative;">
            <img class="w-100" src="/assets/images/agent.png">
            <div class="state-circle ml-2" style="position: absolute; right: 0;bottom: 0;"
                [ngClass]="conectionStatus.class"></div>
        </div>
        <mat-card-title>
            {{this.user ? this.user.name : ""}}
            <mat-icon *ngIf="conectionStatus.id == 3" (click)="getUserConfigs()" class="refresh-icon">refresh</mat-icon>
        </mat-card-title>

        <mat-card-subtitle>
            <span *ngIf="conectionStatus && conectionStatus.id == 1">
                {{"Ext:" + this.user.username}}
            </span>
            <hr>
            <div *ngIf="callStatus && callStatus.id != 2">
                <span [ngClass]="callStatus.class">{{callStatus.name}}</span><br>
                <span>{{this.originTransfer}}</span><br>
                <span>
                    {{"Tiempo llamada: "+this.timeInCall.join(":")}}</span><br>
                <span>
                    {{"Número: "+this.numberPhone}}</span><br>
                <span class="text-danger" *ngIf="isVipClient">Cliente VIP</span>
            </div>
            <hr *ngIf="callStatus && callStatus.id != 2 && user2user != null">
            <div *ngIf="callStatus && callStatus.id != 2 && user2user != null">
                <div *ngFor="let item of user2user | keyvalue">
                    <b>{{ item.key }}:</b> {{ item.value }}<br>
                </div>
            </div>

        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content class="mt-4"
        *ngIf="conectionStatus && [1].includes(conectionStatus.id) && statusMinimize == false ">


            <div class="row mb-2 p-1">
                <div class="col-12 d-flex justify-content-end">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Número</mat-label>
                        <input matInput
                        placeholder="Ingrese el número al que desea llamar"
                        [(ngModel)]="numberOutputCall">
                    </mat-form-field>
                </div>
            </div>


        <div class="row">
            <div class="col-12 d-flex justify-content-center mt-1 p-1 " *ngIf="userCampaigns">
                <mat-form-field class="w-100 ml-4 mr-4">
                    <mat-label>Transferir</mat-label>
                    <mat-select [(ngModel)]="transferCampaign">
                        <mat-option *ngFor="let camp of userCampaigns" [value]="camp.did">
                            {{ camp.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 d-flex justify-content-center mt-1 p-1 ">
                <div class="col-4 d-flex justify-content-center mt-1 p-1 ">
                    <button
                    mat-raised-button
                    (click)="internalTransfer()"
                    color="primary"
                    [disabled]="![1].includes(callStatus.id) || !transferCampaign"
                    class="h-100 w-100">
                        <mat-icon>play_arrow</mat-icon>
                        <mat-label>Transferir</mat-label>
                    </button>
                </div>
                <div class="col-4 d-flex justify-content-center mt-1 p-1 ">
                    <button
                    mat-raised-button
                    [disabled]="![2].includes(callStatus.id)"
                    (click)="call()"
                    color="primary"
                    class="h-100 w-100">
                        <mat-icon>phone</mat-icon>
                        <mat-label>{{ callStatus.id == 2 ? "Llamar" : "Llamando..." }}</mat-label>
                    </button>
                </div>
                <div class="col-4 d-flex justify-content-center mt-1 p-1 ">
                    <button
                    mat-raised-button
                    (click)="transfer(user.survey_extension)"
                    [disabled]="![1].includes(callStatus.id)"
                    color="warn"
                    class="h-100 w-100">
                        <mat-icon>call end</mat-icon>
                        <mat-label>Colgar</mat-label>
                    </button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr />
            </div>
        </div>



        <div class="row">
            <div class="col-4 mb-1 p-1">
                <button (click)="hold()" [disabled]="![1,4].includes(callStatus.id)"
                    class="square-button w-100 h-100 d-flex flex-column justify-content-center align-items-center m-2">
                    <mat-icon *ngIf="![4,6].includes(callStatus.id)">pause</mat-icon>
                    <mat-label *ngIf="![4,6].includes(callStatus.id)">{{callStatus.id==7 ? "Retomando" : "Detener"
                        }}</mat-label>

                    <mat-icon *ngIf="[4,6].includes(callStatus.id)">play_arrow</mat-icon>
                    <mat-label *ngIf="[4,6].includes(callStatus.id)">{{callStatus.id == 6 ? "Deteniendo" :
                        "Retomar"}}</mat-label>
                </button>
            </div>
            <div class="col-4 mb-1 p-1">
                <button [disabled]="callStatus.id == 5 || callStatus.id == 2 || !user.survey_extension"
                    class="square-button w-100 h-100 d-flex flex-column justify-content-center align-items-center m-2"
                    (click)="transfer(user.survey_extension)">
                    <mat-label>{{callStatus.id == 5 ? "Transfiriendo..." : "Encuesta"}}</mat-label>
                </button>

            </div>
            <div class="col-4 mb-1 p-1">
                <button (click)="closeSession()"
                    class="square-button w-100 h-100 d-flex flex-column justify-content-center align-items-center m-2">
                    <mat-icon>cancel</mat-icon>
                    <mat-label>Desconectar</mat-label>
                </button>
            </div>
        </div>


    </mat-card-content>

</mat-card>
