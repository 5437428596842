import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { VicidialService } from '../services/vicidal.service';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { RecordDialogComponent } from './dialogs/record-dialog/record-dialog.component';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.sass']
})
export class RecordsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filters: FormGroup;
  tableColumns: string[] = ['actions', 'name', 'phone', 'created_at'];
  //Paginador
  length: number;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 50, 100];
  page: number = 1;
  records_list: any[] = []

  constructor(private formBuilder: FormBuilder, private dialog:MatDialog, private alertService:AlertsService, private vicidialService:VicidialService) { }

  ngOnInit(): void {
    this.getInfo(this.page, this.pageSize)
    this.initializeForm()
  }
  /**
    * @author Juan Carlos Alonso Pérez
    * @purpose Metodo que inicializa el formulario
    * @createdate 25-11-2024
  */
  initializeForm() {
    this.filters = this.formBuilder.group({
      'start_date': ['', []],
      'end_date': ['', []]
    })
  }
  /**
    * @author Juan Carlos Alonso Pérez
    * @purpose Metodo que controla la paginación
    * @createdate 25-11-2024
  */
  paginatorEvent(event: any) {
    this.pageSize = event.pageSize
    this.page = event.pageIndex + 1
    this.getInfo(this.page, this.pageSize, this.filters.get('start_date').value, this.filters.get('end_date').value)
  }
  /**
    * @author Juan Carlos Alonso Pérez
    * @purpose Metodo que obtiene la información de la tabla
    * @createdate 25-11-2024
  */
  getInfo(page: number, pageSize: number, start_date:string = '', end_date:string = '') {
    const start = start_date ? moment(start_date).format('YYYY-MM-DD') : ''
    const end = end_date ? moment(end_date).format('YYYY-MM-DD') : ''
    this.vicidialService.getSoulphoneRecords(page, pageSize, start,end).subscribe((resp:any) => {
      this.records_list = resp.data?.data
      this.length = resp.data?.total
      this.pageSize = resp.data?.per_page
    })
  }


  filterInfo(){
    this.page = 1
    this.getInfo(this.page, this.pageSize, this.filters.get('start_date').value, this.filters.get('end_date').value)
    this.paginator.firstPage()
  }
  resetFilters(){
    this.page = 1
    this.filters.patchValue({start_date: '', end_date: ''})
    this.getInfo(this.page, this.pageSize)
    this.paginator.firstPage()
  }
  /**
    * @author Juan Carlos Alonso Pérez
    * @purpose Metodo que despĺiega el modal para escuchar la grabación
    * @createdate 25-11-2024
  */
  formDialog(url:string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'dialogCrudCategory';
    dialogConfig.width = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      url: url,

    }

    const dialogRef = this.dialog.open(RecordDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
    });

  }

}
