import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailService } from '../../services/email.service';
import { TrayService } from '../../services/tray.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-move-email-account',
  templateUrl: './move-email-account.component.html',
  styleUrls: ['./move-email-account.component.css']
})
export class MoveEmailAccountComponent implements OnInit {
  formMoveEmail: FormGroup = new FormGroup({
    idAccountEmail: new FormControl('', [Validators.required]),
    idTray: new FormControl('', [Validators.required])
  });
  traysAndFolders: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<MoveEmailAccountComponent>,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService,
    private trayService: TrayService) { }

  ngOnInit(): void {

  }

  /**
    * @author Fabian Duran
    * @createdate 2024-01-30
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formMoveEmail.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-01
    * Metodo que consulta las bandejas asociada a la cuenta de correo seleccionado.
    * @param $event Evento emitido por el select 
  */
  onChangeEmailConfigSelected($event: MatSelectChange): void {
    this.trayService.getTrays(null, '', false, $event.value, 1).subscribe(resTrays => {
      const trays = resTrays.data.map((item: any) => { return { id: item.id, name: item.name, idEmailConfig: item.id_email_config } });
      this.traysAndFolders.push({ name: 'Bandejas', list: trays });
      this.traysAndFolders[0].list = this.traysAndFolders[0].list.filter((item: any) => (item.name === 'Recibidos' || item.name === 'Gestión prioritaria') && item.idEmailConfig === 0);
      this.trayService.getTrays(null, '', false, $event.value, 2).subscribe(resFolders => {
        const folders = resFolders.data.map((item: any) => { return { id: item.id, name: item.name } });
        this.traysAndFolders.push({ name: 'Carpetas', list: folders });
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-01
    * Metodo que guarda la informacion del movimiento de correos.
  */
  saveMoveEmails(): void {
    if (this.formMoveEmail.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          if (!this.data.isMassive) this.callServiceMoveEmailsBetweenAccounts();
          else this.callServiceMoveMassiveEmailsBetweenAccounts();
        }
      });
    } else {
      this.formMoveEmail.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-01
    * Metodo que guarda el movimiento de los correos seleccionados. 
  */
  callServiceMoveEmailsBetweenAccounts(): void {
    const formData = new FormData();
    formData.append('email_list', `[${this.data.emails.toString()}]`);
    formData.append('to', this.formMoveEmail.value.idAccountEmail);
    formData.append('id_bandeja_to', this.formMoveEmail.value.idTray);
    this.emailService.moveEmailsBetweenAccounts(formData).subscribe(res => {
      this.showMessageNotification(res.data);
      this.matDialogRef.close(res);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-01
    * Metodo que guarda el movimiento masivo de correos entre cuentas. 
  */
  callServiceMoveMassiveEmailsBetweenAccounts(): void {
    this.emailService.moveMassiveEmailsBetweenAccounts(
      this.data.idAccountEmailSelected, 
      this.formMoveEmail.value.idAccountEmail,
      this.data.idTraySelected,
      this.formMoveEmail.value.idTray).subscribe(res => {
      this.showMessageNotification(res.data);
      this.matDialogRef.close(res);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-01
    * Metodo que muestra las notificaciones con los mensajes. 
    * @param message Cadena que se mostrara en el mensaje 
  */
  showMessageNotification(message: string): void {
    this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
      data: {
        title: '¡Excelente!',
        message: message
      },
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['green-snackbar']
    });
  }
}