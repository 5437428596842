import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

const { GESTIONADOR_CORREOS_PYTHON } = environment;

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que retorna los eventos de calendario de una cuenta de correo. 
    * @param idEmailAccount Cuenta de correo seleccionada.
    * @param dateStart Fecha inicial dentro del calendario.
    * @param dateEnd Fecha final de calendario.  
  */
  getCalendarByEmailAccount(idEmailAccount: number, dateStart: string, dateEnd: string): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS_PYTHON}Calendar/list_event/${idEmailAccount}?start=${dateStart}&end=${dateEnd}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que retorna la informacion de un evento seleccionado. 
    * @param idEmailAccount Cuenta de correo seleccionada.
    * @param idEvent Id del evento. 
  */
  getEvent(idEmailAccount: number, idEvent: string): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS_PYTHON}Calendar/show/${idEmailAccount}?event=${idEvent}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que guarda o actualiza un evento en el sistema. 
    * @param data Información del formulario.
    * @param typeManager Tipo de gestion que se va a realizar. 
  */
  saveOrUpdateEvent(data: any, typeManager: string = 'save'): Observable<any> {
    const formatData = this.generateInfoToSaveOrUpdate(typeManager, data);
    const url = typeManager === 'save' ? `${GESTIONADOR_CORREOS_PYTHON}Calendar/create_event` : `${GESTIONADOR_CORREOS_PYTHON}Calendar/update_event`;
    return this.httpClient.post(url, formatData).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que elimina un evento del sistema. 
    * @param idEmailAccount Cuenta de correo seleccionada.
    * @param idEvent Id del evento.
  */
  deleteEvent(idEmailAccount: number, idEvent: string): Observable<any> {
    return this.httpClient.delete(`${GESTIONADOR_CORREOS_PYTHON}Calendar/delete_event/${idEmailAccount}?event=${idEvent}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que formatea la informacion del formulario para su respectivo envio. 
    * @param typeManager Tipo de gestion a realizar.
    * @param dataForm Informacion proveniente del formulario.
  */
  generateInfoToSaveOrUpdate(typeManager: string = 'save', dataForm: any): any {
    const dataFormDates = {
      dateStart: moment(dataForm.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(dataForm.dateEnd).format('YYYY-MM-DD'),
      timeStart: moment(dataForm.timeStart, 'HH:mm').format('HH:mm:ss'),
      timeEnd: moment(dataForm.timeEnd, 'HH:mm').format('HH:mm:ss'),
    };
    const data = {
      id_email: dataForm.emailAccount,
      subject: dataForm.title,
      location: 'Teams',
      is_all_day: false,
      is_online: dataForm.linkByTeams,
      start: moment(`${dataFormDates.dateStart} ${dataFormDates.timeStart}`).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(`${dataFormDates.dateEnd} ${dataFormDates.timeEnd}`).format('YYYY-MM-DD HH:mm:ss'),
      description: dataForm.description,
      attendees: dataForm.participants.map((item: any) => item.name).join(),
      event: false,
      recurrence_interval: dataForm.repeatEach,
      recurrence_end: dataForm.limitDate !== '' ? moment(dataForm.limitDate).format('YYYY-MM-DD') : '',
      recurrence_type: null,
      recurrence_month: null,
      recurrence_days_of_week: null,
      typeOptionDayMonth: dataForm.typeOptionDayMonth,
      recurrence_day_of_month: null
    };
    if (dataForm.typeOfRecurrence === 'Ninguna') {
      data.recurrence_type = false;
      data.recurrence_month = false;
      data.recurrence_day_of_month = false;
      data.recurrence_days_of_week = '';
    } else if (dataForm.typeOfRecurrence === 'Diario') {
      data.recurrence_type = 'day';
      data.recurrence_month = false;
      data.recurrence_day_of_month = false;
      data.recurrence_days_of_week = '';
    } else if (dataForm.typeOfRecurrence === 'Semanal') {
      data.recurrence_type = 'week';
      data.recurrence_month = false;
      data.recurrence_day_of_month = false;
      data.recurrence_days_of_week = dataForm.days.join()
    } else {
      if (data.typeOptionDayMonth === 'Día del mes') {
        data.recurrence_type = 'month';
        data.recurrence_month = true;
        data.recurrence_day_of_month = dataForm.recurrenceDaysInMonth;
        data.recurrence_days_of_week = '';
      } else {
        data.recurrence_type = 'month';
        data.recurrence_month = true;
        data.recurrence_day_of_month = false;
        data.recurrence_days_of_week = dataForm.days.join();
      }
    }
    if (typeManager === 'update') data.event = dataForm.id;
    return data;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-14
    * Metodo que acepta o rechaza un evento. 
    * @param typeManager Tipo de gestion a realizar.
    * @param idEmailAccount Cuenta de correo seleccionada.
    * @param data Informacion adicional para la peticion. 
  */
  acceptOrDeclineEventMeeting(typeManager: string, idEmailAccount: number, data: any): Observable<any> {
    const url = typeManager === 'Aceptar Meeting' ? `${GESTIONADOR_CORREOS_PYTHON}Calendar/decline_accept_event/${idEmailAccount}/accept` : `${GESTIONADOR_CORREOS_PYTHON}Calendar/decline_accept_event/${idEmailAccount}/decline`;
    return this.httpClient.put(url, data).pipe(
      catchError(this.handleError)
    );
  }
}