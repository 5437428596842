import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingRoutingModule } from './meetings-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from "../../shared/shared.module";
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsersGroupComponent } from './pages/users-group/users-group.component';
import { MeetingsAdminComponent } from './pages/meetings-admin/meetings-admin.component';
import { RescheduleFormComponent } from './pages/meetings-admin/dialogs/reschedule-form/reschedule-form.component';
import { MeetingHistoryComponent } from './pages/meeting-history/meeting-history.component';
import { HistoryDetailsComponent } from './components/history-details/history-details.component';


@NgModule({
    declarations: [
        UsersGroupComponent,
        MeetingsAdminComponent,
        RescheduleFormComponent,
        MeetingHistoryComponent,
        HistoryDetailsComponent,
    ],
    imports: [
        CommonModule,
        MeetingRoutingModule,
        MaterialModule,
        MatDialogModule,
        SharedModule,
        MatSortModule,
        MatExpansionModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatChipsModule,
        MatTooltipModule,
    ]
})
export class MeetingsModule { }
