import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare var JitsiMeetExternalAPI: any;
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VideollamadaService } from '../videollamada/services/videollamada.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import * as moment from 'moment';
import 'moment-timezone';
import { NotificationService } from 'src/app/core/services/rest/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.sass']
})
export class MeetingsComponent implements OnInit, AfterViewInit, OnDestroy {
  domain: string = "meetings.appsouls.net"; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;
  viewFiles: boolean = true;
  files: any[] = [];
  docsWidth = 0;
  frameWidth = 100;
  frameHeight = 400;
  minView: boolean = false;
  modalWidth = 900;
  modalHeight = 500;
  roomData: any;
  AuthUser: any;
  fileData: any;
  formData = new FormData();
  filesInterval: any;
  stateRoom: any;
  endDate: any = '';

  constructor(
    private router: ActivatedRoute,
    public dialogRef: MatDialogRef<MeetingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private videoService: VideollamadaService,
    private authService: AuthService,
    private notificacionService: NotificationService,
  ) { }

  ngOnInit(): void {
    //console.log(this.data);
    this.AuthUser = this.authService.getUser();
    this.videoService.getAdviserById(this.AuthUser.rrhh_id).subscribe(res => {
      const body = {
        id: res.id,
        state_id: 3
      };
      this.videoService.UpdateAdviserState(body).subscribe(resp => { });
    });

    this.roomData = this.data.data.aplicacion.split('::');
    const bodyRoom = {
      id: this.roomData[1],
      state_id: 2
    };
    this.videoService.UpdateRoomState(bodyRoom).subscribe(resp => { });

    this.room = this.roomData[0]; // Set your room name
    this.user = {
      name: this.data.user ? this.data.user : 'Asesor'// Set your username
    }

    this.videoService.getFilesByRoomId(this.roomData[1]).subscribe(resp => {
      this.files = resp;
    });

    this.filesInterval = setInterval(() => { this.getFiles() }, 15000);

    this.videoService.getRoomById(this.roomData[1]).subscribe(res => {
      this.videoService.setMeetResponse({
        variabliNIC: +res.client_nic
      });
      this.stateRoom = res.state_id;
      this.endDate = res.end_date;
      if(res.state_id != 3) {
        var date = new Date();
        const bodyRoom = {
          id: this.roomData[1],
          start_date: moment(date).tz('America/Bogota').format('YYYY-MM-DDTHH:mm:ss')
        };
        this.videoService.UpdateRoomState(bodyRoom).subscribe(resp => { });
      }
    });

    this.videoService.setStateCall('incall');
  }

  ngAfterViewInit(): void {
    this.options = {
      roomName: this.room,
      width: 100 + '%',
      height: 100 + '%',
      jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJteV9zZXJ2ZXIxIiwiaXNzIjoibXlfd2ViX2NsaWVudCIsInN1YiI6Im1lZXQuaml0c2kiLCJyb29tIjoiKiJ9.Br6Tkhu7gHJMCv3w3AFcKDD2dmudBLrXCKK96Ka0K9s",
      configOverwrite: { prejoinPageEnabled: false },
      interfaceConfigOverwrite: {
        // overwrite interface properties
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: this.user.name
      }
    }

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    // Event handlers
    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus
    });

  }

  handleClose = () => {
    //console.log("handleClose");
  }

  handleParticipantLeft = async (participant) => {
    //console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  }

  handleParticipantJoined = async (participant) => {
    //console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  }

  handleVideoConferenceJoined = async (participant) => {
    //console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  }

  handleVideoConferenceLeft = () => {
    //console.log("handleVideoConferenceLeft");
    this.videoService.getAdviserById(this.AuthUser.rrhh_id).subscribe(res => {
      const body = {
        campaign_id: res.campaign_id,
        adviser_id: res.id
      };
      this.videoService.sendEncolamiento(body).subscribe(resp => { });
    });
    var date = new Date();
    var end_date = this.stateRoom != 3 ? moment(date).tz('America/Bogota').format('YYYY-MM-DDTHH:mm:ss') : this.endDate;
    const bodyRoom = {
      id: this.roomData[1],
      state_id: 3,
      end_date: end_date
    };
    this.videoService.UpdateRoomState(bodyRoom).subscribe(resp => { });
    this.notificacionService.deleteNotificationById(this.data.data.id).subscribe(() => { });
    this.videoService.setStateCall('ready');
    this.dialogRef.close();
    //this.router.navigate(['/thank-you']);
  }

  handleMuteStatus = (audio) => {
    //console.log("handleMuteStatus", audio); // { muted: true }
  }

  handleVideoStatus = (video) => {
    //console.log("handleVideoStatus", video); // { muted: true }
  }

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500)
    });
  }

  //Execute Command
  executeCommand(command: string) {
    this.api.executeCommand(command);;
    if (command == 'hangup') {
      //this.router.navigate(['/thank-you']);

      return;
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }

  seeFiles() {
    if (this.viewFiles == true) {
      this.viewFiles = false;
      this.docsWidth = 35;
      this.frameWidth = 65;
    }
  }

  hideFiles() {
    if (this.viewFiles == false) {
      this.viewFiles = true;
      this.docsWidth = 0;
      this.frameWidth = 100;
    }
  }

  /**
 * @author Javier Castañeda
 * @createdate 2022-07-25
 * Metodo para la captura de archivos
 */
  public onChangeFile(e): void {
    const file = e.target.files[0];
    
    if (file) {
      const maxSizeInMB = 10;
      if (file.size > maxSizeInMB * 1024 * 1024) {
        Swal.fire({
          icon: 'error',
          title: 'Archivo demasiado grande',
          text: 'El archivo debe tener un tamaño máximo de 10 MB.',
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false
        });
        return;
      }
  
      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'image/jpeg',
        'image/png'
      ];
      
      if (!allowedTypes.includes(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Formato no permitido',
          text: 'Solo se permiten archivos PDF, Word, Excel, TXT, JPEG y PNG.',
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false
        });
        return;
      }
  
      this.fileData = file;
      this.formData = new FormData();
      this.formData.append('file', this.fileData);
      this.formData.append('room_name', this.roomData[0]);
      this.formData.append('room_id', this.roomData[1]);
      this.videoService.SendFileRoom(this.formData).subscribe(resp => {
        this.seeFiles();
        this.getFiles();
      });
    }
  }


  getFiles() {
    this.files = [];
    this.videoService.getFilesByRoomId(this.roomData[1]).subscribe(resp => {
      this.files = resp;
    });
  }

  minimize() {
    if (this.minView == false) {
      this.minView = true;
      this.viewFiles == false ? this.hideFiles() : '';
      this.modalWidth = 300;
      this.modalHeight = 300;
      this.frameHeight = 230;
    } else {
      this.minView = false;
      this.modalWidth = 900;
      this.modalHeight = 500;
      this.frameHeight = 400;
    }
  }

  seeDocuments() {
    if (this.viewFiles == true) {
      this.viewFiles = false;
      this.docsWidth = 35;
      this.frameWidth = 65;
      this.getFiles();
    } else {
      this.viewFiles = true;
      this.docsWidth = 0;
      this.frameWidth = 100;
    }
  }

  ngOnDestroy() {
    if (this.filesInterval) {
      clearInterval(this.filesInterval);
    }
  }

}
