<div class="row">
  <div class="col-md-5">
    <div class="landing-div-text">
      <div class="landing-tittle">Gestor de correos</div>
      <div class="landing-welcome mt-3">¡Bienvenid@ <br />
        {{ userName }}
      </div>
      <div class="mt-5 lh-base" style="font-size: 16px; font-weight: 400; color: #A9A9A9">
        Este es el módulo <span style="font-size: 16px; font-weight: 700; color: #353535">gestor de correos</span>, en
        este <br />
        espacio podrás crear, ver, responder y <br />
        gestionar los correos que tienes asignados.
      </div>
      <br>
    </div>
  </div>
  <div class="col-md-7" style="text-align: right;">
    <img src="../../../assets/images/Gestor_de_correo.svg" style="height:90%;"
      alt="Image landing page">
  </div>
</div>