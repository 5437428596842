import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailService } from '../../services/email.service';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-validate-email-account',
  templateUrl: './validate-email-account.component.html',
  styleUrls: ['./validate-email-account.component.css']
})
export class ValidateEmailAccountComponent implements OnInit {
  questions: any[] = [
    { typeInput: 'select', key: 'type_validation', label: 'Tipo de validación', rules: { required: true }, value: '', options: [{ value: 1, text: 'Validar envio de email' }, { value: 2, text: 'Validar ingreso de correos' }] },
    { typeInput: 'input', type: 'hidden', key: 'email', label: 'Correo de destino', rules: null, value: '' },
  ];

  constructor(
    private emailService: EmailService,
    private snackBarService: SnackBarService,
    private matDialogRef: MatDialogRef<ValidateEmailAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que detecta los cambios sobre los campos tipo select.
    * @param $event Evento generado por el componente hijo. 
  */
  onChangeSelectForm($event: any): void {
    const updateQuestion = Array.from(this.questions);
    if ($event.key === 'type_validation' && $event.value === 1) {
      updateQuestion[1].type = 'text';
      updateQuestion[1].rules = { required: true, email: true };
    } else {
      updateQuestion[1].type = 'hidden';
      updateQuestion[1].rules = null;
    }
    this.questions = updateQuestion; 
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que recibe los datos del formulario.
    * @param $event Evento generado por el componente hijo. 
  */
  testEmailAccount($event: any): void {
    const data = { ...$event, id: this.data.emailAccount.id };
    if (data.type_validation === 2) {
      this.emailService.checkAccountEmail(data.id).subscribe(res => {
        this.snackBarService.showNotification({ title: res.error ? '¡Error!' : '¡Excelente!', message: res.message });
        this.matDialogRef.close(res);
      });
    } else {
      this.emailService.checkSendEmail(data.id, data.email).subscribe(res => {
        this.snackBarService.showNotification({ title: res.data.error ? '¡Error!' : '¡Excelente!', message: res.data.message });
        this.matDialogRef.close(res);
      });
    }
  }
}