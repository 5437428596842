import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class AnsService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que retorna los ANS registrados en el sistema. 
    * @param paginator Paginador configurado en la vista. 
    * @param filterName Filtro de texto.
    * @param filterEmail Filtro de cuenta de correo.
    * @param showAllStates Filtro que muestra o no todos los ANS.
  */
  getAns(paginator: any = null, filterName: string = '', filterEmail: number = 0, showAllStates: boolean = true): Observable<any> {
    const paramState = showAllStates ? '' : '&state=1';
    const paramEmail = filterEmail !== 0 ? `&email_list=[${filterEmail}]` : '';
    const url = paginator === null ? `${GESTIONADOR_CORREOS}ans/showall?${paramState}` : `${GESTIONADOR_CORREOS}ans/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filterName}${paramEmail}`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que guardar o actualiza un ANS en el sistema. 
    * @param state Informacion proveniente del formulario.  
  */
  storeAns(ans: any): Observable<any> {
    const url = ans.id === null ? `${GESTIONADOR_CORREOS}ans/store` : `${GESTIONADOR_CORREOS}ans/update/${ans.id}`;
    return this.httpClient.post(url, ans).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-30
    * Metodo que retorna los ANS asociados a cuentas de correos seleccionadas.  
    * @param accountsEmail Cuentas de correo seleccionadas en la vista.  
  */
  getAnsByAccountsEmailSelected(accountsEmail: string): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}ans/showall?state=1&email_list=[${accountsEmail}]`).pipe(
      catchError(this.handleError)
    );
  }
}
