<div class="main">
  <div class="pn-lateral-56-24 mtn-64">
    <div class="col-12">
      <h1>Gestión de videollamadas</h1>
    </div>
    <div class="col-4">
      <p>Aquí podrás ver, reagendar y cancelar las videollamadas vigentes. No olvides, "El respeto y la empatía son claves".</p>
    </div>

    <form class="row mt-4" [formGroup]="filters">
      <div class="col">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Campaña</mat-label>
          <mat-select formControlName="campaign">
            <mat-option value="">--Seleccionar--</mat-option>
            <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">{{ campaign.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Grupo</mat-label>
          <mat-select formControlName="group">
            <mat-option value="">--Seleccionar--</mat-option>
            <mat-option *ngFor="let group of groups" [value]="group.id">{{ group.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Agente</mat-label>
          <mat-select formControlName="adviser">
            <mat-option value="">--Seleccionar--</mat-option>
            <mat-option *ngFor="let adviser of advisers" [value]="adviser.id">{{ adviser.first_name + " " + adviser.last_name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-2">
        <button class="me-3 button-dimensions" mat-raised-button color="primary" (click)="filterInfo()"><mat-icon><i class="fi-rr-search"></i></mat-icon></button>
        <button class="me-3 button-dimensions" mat-stroked-button color="primary" (click)="resetFilters()"><mat-icon><i class="fi-rr-refresh"></i></mat-icon></button>
      </div>
    </form>

    <table class="mt-3" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matTooltip]="element.state?.name !== 'CANCELADA' ? 'Reprogramar videollamada' : null" (click)="openRescheduleForm(element.id)" [disabled]="element.state?.name === 'CANCELADA'">
            <i class="fi-rr-pencil"></i>
          </button>

          <button mat-icon-button [matTooltip]="element.state?.name !== 'CANCELADA' ? 'Cancelar videollamada' : null" (click)="cancelVideoCall(element.id, element.scheduled_date)"  [disabled]="element.state?.name === 'CANCELADA'">
            <i class="fi-rr-cross-circle"></i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="client_nic">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>NIC del cliente</th>
        <td mat-cell *matCellDef="let element">{{ element.client_nic}}</td>
      </ng-container>

      <ng-container matColumnDef="scheduled_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha programada</th>
        <td mat-cell *matCellDef="let element">{{ element.scheduled_date | date: 'dd/MM/yyyy hh:mm a' }}</td>
      </ng-container>

      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grupo</th>
        <td mat-cell *matCellDef="let element">{{ element.group?.name_group }}</td>
      </ng-container>

      <ng-container matColumnDef="adviser">
        <th mat-header-cell *matHeaderCellDef>Nombre del agente</th>
        <td mat-cell *matCellDef="let element">{{ element.advisers?.name_adviser}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
        <td mat-cell *matCellDef="let element">{{ element.state?.name }}</td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" style="background: white" *matNoDataRow>
        <td class="mat-cell" colspan="5">No se encontraron videollamadas que coincidan con los criterios de búsqueda</td>
      </tr>
    </table>
    <mat-paginator (page)="pageEvent($event)" [length]="length" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions">
    </mat-paginator>

  </div>
</div>
