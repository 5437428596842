import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { VideollamadaService } from 'src/app/modules/meetings/services/videollamada.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-reschedule-form',
  templateUrl: './reschedule-form.component.html',
  styleUrls: ['./reschedule-form.component.sass']
})
export class RescheduleFormComponent implements OnInit {
  hours: any[] = []
  schedules: any[] = []
  advisers: any[] = []
  form: FormGroup
  current_date: string
  client_nic: string
  //Mínimo y máximo de fechas
  min: Date
  max: Date
  now: Date = new Date()
  constructor(
    private fb: FormBuilder,
    private vidService: VideollamadaService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private dialogRef: MatDialogRef<RescheduleFormComponent>,
    private alertService: AlertsService
  ) { }

  ngOnInit(): void {
    this.getRoomById(this.dialog_data.id)
    this.initializeForm()
  }

  /**
 * Metodo que inicializa el formulario
 * @author Juan Carlos Alonso
 * @createdate 29-11-2024
*/
  initializeForm() {
    this.form = this.fb.group({
      adviser_id: ['', [Validators.required]],
      date: ['', [Validators.required]],
      time: [{ value: '', disabled: true }, [Validators.required]]
    })
  }
  /**
   * Metodo que envía la información actualizada de la videollamada para actualizarla
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }

    this.vidService.rescheduleVideoCall({ id: this.dialog_data.id, adviser_id: this.form.get('adviser_id').value, scheduled_date: this.form.get('time').value, rrhh_id: this.dialog_data.rrhh_id }).subscribe(resp => {
      this.dialogRef.close({ updated: true })
    }, error => {
      this.alertService.alertError('¡Atención¡', 'Ha ocurrido un error al intentar reagendar la videollamada')
    })
  }
  /**
   * Metodo que obtiene la información de la videollamada por id
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  getRoomById(id: number) {
    this.vidService.getVideoCallById(id).subscribe(resp => {
      this.current_date = resp.scheduled_date
      this.client_nic = resp.client_nic
      this.form.patchValue({ adviser_id: resp.adviser_id })
      this.vidService.getAvailableHours(resp.advisers.user_groups[0].group_id ?? null, resp.campaign_id).subscribe(resp2 => {
        this.advisers = resp2
        const adviser = resp2.find(adviser => adviser.rrhh_id == this.form.get('adviser_id').value)
        this.setSchedules(adviser)
      })
    })
  }

  /**
 * Metodo que asigna las fechas de calendario disponibles
 * @author Juan Carlos Alonso
 * @createdate 29-11-2024
*/
  setSchedules(adviser: any): void {
    this.form.patchValue({ date: '', time: '' })
    this.schedules = adviser.schedules
    let keys = Object.keys(this.schedules).sort((a, b) => +a - +b)
    let fisrt_schedules = this.schedules[keys[0]]
    let last_schedules = this.schedules[keys.length]

    let min_date = new Date(fisrt_schedules[0])
    this.min = (min_date < this.now) ? this.now : min_date
    this.max = new Date(last_schedules[0])
  }
  /**
   * Metodo que asigna los horarios disponibles
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  setHours(selected_date: any): void {
    if (selected_date) {
      this.form.get('time').setValue('')
      this.form.get('time').enable()

      const date = moment(selected_date).format('YYYY-MM-DD')
      for (const key in this.schedules) {
        const first_date = this.schedules[key][0]
        if (first_date.startsWith(date)) {
          this.hours = this.schedules[key].filter(schedule => {
            const schedule_date = new Date(schedule)
            return schedule_date >= this.now
          })
        }
      }
    } else {
      this.form.get('time').setValue('')
      this.form.get('time').disable()
    }
  }


}
