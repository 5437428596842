import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { AnsComponent } from '../../components/ans/ans.component';
import { AnsService } from '../../services/ans.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { EmailService } from '../../services/email.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';

@Component({
  selector: 'app-ans-page-main',
  templateUrl: './ans-page-main.component.html',
  styleUrls: ['./ans-page-main.component.css']
})
export class AnsPageMainComponent implements OnInit {
  ans: any[] = [];
  filter: string = '';
  filterSelect: number = 0;
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Cuenta de correo', key: 'email' },
    { name: 'Nombre de ANS', key: 'name' },
    { name: 'Tiempo límite', key: 'time' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  userLogin: any = null;
  emailsConfig: any[] = [];

  constructor(
    private matDialog: MatDialog,
    private ansService: AnsService,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que trae los ANS registrados en el sistema. 
  */
  getAns(): void {
    if (this.filterSelect !== 0) {
      this.ansService.getAns(this.configPaginator, this.filter, this.filterSelect).subscribe(res => {
        this.ans = res.data.data.map((item: any) => {
          return {
            ...item,
            name: item.name,
            email: item.user_name_email_entrada,
            time: `Dia: ${item.day} - Hora: ${item.hour} - Minutos: ${item.minutes}`
          };
        });
        this.configPaginator.length = res.data.total;
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getAns();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que actualiza el estado de un ANS. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateValueAns($event: any): void {
    const setDataState = { ...$event.item, state: $event.state };
    this.ansService.storeAns(setDataState).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getAns();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que permite ejecutar la modal para crear o actualizar un ANS. 
    * @param state Estado seleccionada de la tabla. 
    * @param titleDialog Titulo que va a tener la modal. 
    * @param viewOnly Modo de vizualizacion de la modal. 
  */
  onClickShowAns(ans: any = null, titleDialog: string = 'Crear ANS', viewOnly: boolean = false): void {
    this.matDialog.open(AnsComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-ans',
      data: {
        titleDialog,
        ans,
        viewOnly,
        emailsConfig: this.emailsConfig
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getAns();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getAns();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que setea el filtro del select emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterSelect($event: number) {
    this.filterSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getAns();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
}