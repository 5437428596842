import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EmailService } from 'src/app/modules/email-manager/services/email.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from 'src/app/modules/email-manager/constantes/keys-roles';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { environment } from 'src/environments/environment';
import { ManagementHistoryViewComponent } from 'src/app/modules/email-manager/components/management-history-view/management-history-view.component';
import { AnalysisResultComponent } from '../../components/analysis-result/analysis-result.component';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { interval, Subscription } from 'rxjs';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';

const URL_BACK_GESTOR_CORREOS = environment.GESTIONADOR_CORREOS;

@Component({
  selector: 'app-manager-list-emails',
  templateUrl: './manager-list-emails.component.html',
  styleUrls: ['./manager-list-emails.component.css']
})
export class ManagerListEmailsComponent implements OnInit, OnDestroy {
  formFilter: FormGroup = new FormGroup({
    email_list_id: new FormControl(null, [Validators.required]),
    id_bandeja: new FormControl(null, [Validators.required]),
    asunto: new FormControl(null, []),
    date_in: new FormControl(null, [Validators.required]),
    date_end: new FormControl(null, [Validators.required]),
    contiene_palabras: new FormControl(null, []),
    allEmailsByOnlyTray: new FormControl(true, [])
  });
  dataTable: any[] = [];
  emailsTableSelected: any[] = [];
  configColumnsTable: any[] = [
    { name: '', key: 'actions' },
    { name: 'ID', key: 'id' },
    { name: 'Cuenta de correo', key: 'para' },
    { name: 'Usuario asignado', key: 'name_user_asignado' },
    { name: 'Correo remitente', key: 'de' },
    { name: 'Asunto', key: 'asunto' },
    { name: 'Fecha - Hora', key: 'fecha_Hora' }
  ];
  configPaginator: any = { length: 0, pageIndex: 0, pageSize: 10 };
  keysColumnsTable: string[] = [];
  emailsConfig: any[] = [];
  checkAllEmails: boolean = false;
  traysAndFolders: any[] = [];
  filterTraysAndFolders: any[] = [];
  subscribeInterval: Subscription | null = null;
  textLoadingTable: string = 'No existe información relacionada';
  stateButtonForm: boolean = false;

  constructor(
    private location: Location,
    private emailService: EmailService,
    private matDialog: MatDialog,
    private authService: AuthService,
    private crudService: CrudService,
    private alertService: AlertsService
  ) { }

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map(item => item.key);
    this.getEmailsConfigByRol();
    const source = interval(200000);
    this.subscribeInterval = source.subscribe(_ => {
      this.getEmails();
    });
  }

  ngOnDestroy(): void {
    if (this.subscribeInterval) this.subscribeInterval.unsubscribe();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formFilter.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que actualiza el filtro de cuenta de correo. 
    * @param $event Evento emitido por el campo.   
  */
  onChangeSelectAccountEmail($event: MatSelectChange): void {
    this.crudService.getData({
      url: `${URL_BACK_GESTOR_CORREOS}emailbox/showall`,
      filters: { state: 1, id_email_config: $event.value }
    }).subscribe(res => {
      const trays = res.data.filter((item: any) => item.type === 1)
        .map((item: any) => { return { id: item.id, name: item.name } });
      const folders = res.data.filter((item: any) => item.type === 2)
        .map((item: any) => { return { id: item.id, name: item.name } });
      this.traysAndFolders.push({ name: 'Bandejas', list: trays });
      this.traysAndFolders.push({ name: 'Carpetas', list: folders });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que trae los correos registrados en el sistema.
    * @param resetPage Reset del estado de la paginación.
    * @param resetChecks Reset del estado de los check.
  */
  getEmails(resetPage: boolean = false, resetChecks: boolean = false): void {
    if (this.formFilter.valid) {
      if (this.onDateInitChange()) {
        this.textLoadingTable = 'Actualizando información...';
        this.stateButtonForm = true;
        this.dataTable = [];
        if (resetChecks) this.checkAllEmails = false;
        if (resetChecks) this.emailsTableSelected = [];
        if (resetPage) this.configPaginator.pageIndex = 0;
        const dataFilterForm = {
          ...this.formFilter.value,
          email_list: `[${this.formFilter.value.email_list_id}]`,
        };
        this.emailService.getEmailsByIaModule(this.configPaginator, dataFilterForm).subscribe(res => {
          this.dataTable = res.data.data.map((item: any) => {
            return {
              ...item,
              nameStateIa: item.stateIa ? item.stateIa : 'N/R'
            };
          });
          this.configPaginator.length = res.data.total;
          this.textLoadingTable = res.data.data.length === 0 && 'No existe información relacionada';
          this.stateButtonForm = false;
        });
      } else this.alertService.alertError('¡Ten en cuenta!', 'Debe estar en un rango de 7 días');
    } else this.formFilter.markAllAsTouched();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que actualiza la lista de correos seleccionados en la tabla.
    * @param $event Evento emitido por el checkbox. 
  */
  onChangeCheckboxAllSelected($event: MatCheckboxChange): void {
    this.emailsTableSelected = $event.checked ? this.dataTable.filter(item => item.state_email !== 'Cerrado') : [];
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que actualiza la lista de correos seleccionados en la tabla.
    * @param $event Evento emitido por el checkbox.
    * @param email Informacion del email seleccionado.  
  */
  onChangeCheckboxSelected($event: MatCheckboxChange, email: any): void {
    if ($event.checked) this.emailsTableSelected.push(email);
    else this.emailsTableSelected = this.emailsTableSelected.filter(item => item.id !== email.id);
    const totalEmailsCount = this.dataTable.filter(item => item.state_email !== 'Cerrado');
    this.checkAllEmails = this.emailsTableSelected.length === totalEmailsCount.length ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que valida si un correo esta o no dentro de la lista de correos seleccionados.
    * @param email Informacion del email seleccionado.  
  */
  ifSelectedMail(email: any): boolean {
    return this.emailsTableSelected.filter(item => item.id === email.id).length > 0 ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que emite el cambio sobre la configuracion de la paginacion.
    * @param $event Evento emitido por el paginador. 
  */
  onChangePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getEmails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que limpia el valor de los filtros avanzados.  
  */
  clearValuesAdvanceFilters(): void {
    this.formFilter.reset();
    this.dataTable = [];
    this.textLoadingTable = 'No existe información relacionada';
    this.stateButtonForm = false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-11-15
   * Metodo que se encarga de abrir el detalle del correo.
   * @param email correo seleccionado de la tabla.
  */
  showEmailHistory(email: any): void {
    this.emailService.getEmailById(email).subscribe(res => {
      this.matDialog.open(ManagementHistoryViewComponent, {
        width: '95%',
        maxHeight: '95vh',
        autoFocus: false,
        panelClass: 'dialog-padding-management-history-view',
        data: {
          management: res.data,
          toPreview: true,
          showButtonPrint: false,
          showButtonAddEmail: false,
          showDetails: true
        }
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-15
    * Metodo que se encarga tomar los correos seleccionados para luego ser analisados.
  */
  analysisEmailsSelected(): void {
    const user = this.authService.decryptToken();
    if (user && user.rrhh_id) {
      this.alertService.alertWarning('¿Está seguro?', '¿Desea analizar los correos seleccionados?').then(confirm => {
        if (confirm.isConfirmed) {
          this.crudService.postData({ url: `${URL_BACK_GESTOR_CORREOS}ia/saveToSelectedEmails`, formData: { emails: this.emailsTableSelected, rrhh_id: user.rrhh_id } }).subscribe(res => {
            this.alertService.alertSuccess('!Excelente¡', res.message);
            this.emailsTableSelected = [];
            this.getEmails();
          });
        }
      });
    } else this.alertService.alertError('!Error¡', 'La información del usuario no puede ser procesada');
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-18
    * Metodo que se encarga de actualizar el rango de fecha maximo en el filtro.
  */
  onDateInitChange(): boolean {
    const momentInit = moment(this.formFilter.get('date_in').value);
    const momentEnd = moment(this.formFilter.get('date_end').value);
    const daysDiff = momentEnd.diff(momentInit, 'days');
    return daysDiff < 8 ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-18
    * Metodo que se encarga de abrir la modal para visualizar los resultados de la IA sobre el correo.
  */
  showDialogAnalysisResult(email: any): void {
    this.emailService.getEmailById(email).subscribe(res => {
      if (res.data.resultToIa) {
        this.matDialog.open(AnalysisResultComponent, {
          width: '70%',
          maxHeight: '95vh',
          autoFocus: false,
          panelClass: 'dialog-padding-management-history-view',
          data: {
            result: JSON.parse(res.data.resultToIa),
            email: res.data,
          }
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-18
    * Metodo que se encarga de mostrar la alerta con el error generado por la IA.
    * @param email Correo seleccionado.
  */
  showAlertByMessageError(email: any): void {
    const parseError = JSON.parse(email.detailsIa).mensaje_error || 'Error desconocido';
    this.alertService.alertSuccess('Error generado', parseError, { icon: 'warning', timer: null });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-21
    * Metodo que descarga el reporte de excel.
  */
  onClickDownloadReportExcel(): void {
    if (this.formFilter.valid) {
      this.crudService.postData({
        url: `${URL_BACK_GESTOR_CORREOS}ia/email/report`,
        formData: {
          ...this.formFilter.value,
          date_in: this.formFilter.value.date_in !== null ? moment(this.formFilter.value.date_in).format('YYYY-MM-DD') : null,
          date_end: this.formFilter.value.date_end !== null ? moment(this.formFilter.value.date_end).format('YYYY-MM-DD') : null
        },
        responseType: 'blob'
      }).subscribe(res => {
        importedSaveAs(res, 'Reporte');
      });
    } else this.formFilter.markAllAsTouched();
  }
}