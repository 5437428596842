import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { EmailComponent } from '../../components/email/email.component';
import { EmailAdminService } from '../../services/email-admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { ValidateEmailAccountComponent } from '../../components/validate-email-account/validate-email-account.component';

@Component({
  selector: 'app-email-page-main',
  templateUrl: './email-page-main.component.html',
  styleUrls: ['./email-page-main.component.css']
})
export class EmailPageMainComponent implements OnInit {
  emails: any[] = [];
  filter: string = '';
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Host email', key: 'host_email_entrada' },
    { name: 'Nombre email', key: 'user_name_email_entrada' },
    { name: 'Encriptación', key: 'encryption_email_entrada' },
    { name: 'Protocolo de entrada', key: 'protocol_email_entrada' },
    { name: 'Protocolo de salida', key: 'protocol_email_salida' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };

  constructor(
    private matDialog: MatDialog,
    private emailAdminService: EmailAdminService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getEmails();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que trae los correos registradas en el sistema. 
  */
  getEmails(): void {
    this.emailAdminService.getEmails(this.configPaginator, this.filter).subscribe(res => {
      this.emails = res.data.data;
      this.configPaginator.length = res.data.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getEmails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que actualiza el estado de un correo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateEmail($event: any): void {
    const setDataEmail = { ...$event.item, activo: $event.state };
    this.emailAdminService.storeEmail(setDataEmail, "Actualizar correo").subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getEmails();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que permite ejecutar la modal para crear o actualizar un correo. 
    * @param email Categoria seleccionada de la tabla.
  */
  onClickShowEmail(email: any = null, typeManager: string = 'Crear correo'): void {
    const cloneObjectEmail = { ...email, date_range_time_parameterization: null };
    this.matDialog.open(EmailComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-category',
      data: {
        title: typeManager,
        email: cloneObjectEmail,
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getEmails();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que permite ejecutar la modal para crear o actualizar un correo.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getEmails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-03-01
    * Metodo que permite ejecutar la modal para la verificacion de cuentas de correo.
    * @param $event Evento emitido por el componente hijo.
  */
  getRegisterSelected($event: any): void {
    this.matDialog.open(ValidateEmailAccountComponent, {
      width: '40%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-validate-email-account',
      data: {
        emailAccount: $event
      }
    });
  }
}