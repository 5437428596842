<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.title }}
  </div>
  <mat-divider></mat-divider>
  <div class="mt-4">
    <app-print-form-survey
      *ngIf="questions.length > 0"
      [questions]="questions" 
      (sendQuestions)="saveEmail($event)" 
      (changeValueSelect)="onChangeSelectForm($event)" 
      (sendIndexField)="updateHideTextField($event)"></app-print-form-survey>
  </div>
</div>