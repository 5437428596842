import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersGroupComponent } from './pages/users-group/users-group.component';
import { MeetingsModuleComponent } from './meetings.component';
import { DashboardVideocallComponent } from '../livechat/modules/chat/pages/dashboard-videocall/dashboard-videocall.component';
import { MeetingsAdminComponent } from './pages/meetings-admin/meetings-admin.component';
import { MeetingHistoryComponent } from './pages/meeting-history/meeting-history.component';



const routes: Routes = [
  { path: '', component:  MeetingsModuleComponent },
  { path: 'usergroup_management', component: UsersGroupComponent},
  { path: 'dashboard', component: DashboardVideocallComponent},
  { path: 'meetings-admin', component: MeetingsAdminComponent},
  { path: 'meetings-history', component: MeetingHistoryComponent},
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeetingRoutingModule { }
