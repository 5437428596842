import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import SwiperCore, { Navigation, Pagination, SwiperOptions, Swiper } from 'swiper';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-swiper-report-months',
  templateUrl: './swiper-report-months.component.html',
  styleUrls: ['./swiper-report-months.component.css']
})
export class SwiperReportMonthsComponent implements OnInit {
  @Input() cardsMonths: any[] = [];
  @Output() onClickMonthSelected: EventEmitter<string> = new EventEmitter<string>();
  swiper: Swiper;
  swipperConfigMonths: SwiperOptions;
  
  constructor() { }

  ngOnInit(): void {
    this.swipperConfigMonths = {
      navigation: {
        nextEl: '.swiper-button-next-months',
        prevEl: '.swiper-button-prev-months',
      },
      pagination: { clickable: true },
      breakpoints: {
        640: { slidesPerView: this.validateLengthCardsMonts(), spaceBetween: 10, pagination: { el: '.swiper-pagination-hide' } },
      },
    };
    this.swiper = new Swiper('.months-swiper', this.swipperConfigMonths);
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-09-17
    * Metodo que retorna la cantidad de slides renderizados en el swiper.
  */
  validateLengthCardsMonts(): number {
    if (this.cardsMonths.length === 1 || this.cardsMonths.length === 2) return this.cardsMonths.length;
    return 3;
  }
}