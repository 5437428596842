import { Component, OnInit, Inject } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OPTIONS_PIE } from '../../constantes/create-data-chart';

@Component({
  selector: 'app-view-graph-states',
  templateUrl: './view-graph-states.component.html',
  styleUrls: ['./view-graph-states.component.css']
})
export class ViewGraphStatesComponent implements OnInit {
  ctx: any = null;
  canvas: HTMLCanvasElement = null;
  barChart: Chart<'pie'> = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.paintGraphic();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-02-09
    * Metodo que crea la grafica. 
  */
  paintGraphic(): void {
    const dataPie = {
      labels: this.data.labels,
      datasets: [{
        data: this.data.data,
        backgroundColor: this.generateColorsByChart(this.data.labels.length),
        hoverOffset: 4
      }]
    };
    this.canvas = <HTMLCanvasElement>document.getElementById('pieChart');
    this.ctx = this.canvas.getContext('2d');
    this.barChart = new Chart(this.ctx, {
      type: 'pie',
      data: dataPie,
      options: OPTIONS_PIE
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-00
    * Metodo que genera colores aleatorios dentro de un array.
    * @param length Longitud del array proveniente de las grafica. 
  */
  generateColorsByChart(length: number): Array<string> {
    const arrayColors = [];
    for (let index = 0; index < length; index ++) {
      const r = Math.floor(Math.random() * 200) + 55;
      const g = Math.floor(Math.random() * 200) + 55;
      const b = Math.floor(Math.random() * 200) + 55;    
      arrayColors.push('rgb(' + r + ',' + g + ',' + b + ', 0.5)');
    }
    return arrayColors; 
  }
}