import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

const { GESTIONADOR_CORREOS, GESTIONADOR_CORREOS_PYTHON } = environment;

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-14
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-14
    * Metodo que trae los correos registrados en el sistema. 
    * @param paginator Configuracion de la paginacion del componente. 
    * @param filters Filtros enviados desde los formularios. 
  */
  getEmails(paginator: any, filters: any): Observable<any> {
    const filtersFormat = {
      ...filters,
      date_in: filters.date_in !== null ? moment(filters.date_in).format('YYYY-MM-DD') : null,
      date_end: filters.date_end !== null ? moment(filters.date_end).format('YYYY-MM-DD') : null
    };
    const url = paginator !== null ? `${GESTIONADOR_CORREOS}email/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}` : `${GESTIONADOR_CORREOS}email/showall`;
    return this.httpClient.post(url, filtersFormat).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-14
    * Metodo que trae las configuraciones de correos de salida registrados en el sistema. 
  */
  getEmailsConfig(): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}emailconfig/showall?state=1`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-14
    * Metodo que actualiza la categoria del email seleccionado.
    * @param mail Correo seleccionado de la lista. 
    * @param category Categoria seleccionada del formulario.  
  */
  updateCategoryToEmail(mail: any, category: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email/update/${mail.id}?id_category=${category}`, {}).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-14
    * Metodo que retorna la informacion del correo seleccionado. 
    * @param mail Correo seleccionado de la lista. 
  */
  getEmailById(mail: any): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}email/show/${mail.id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que trae los asesores que tienen el rol de gestor de correos. 
  */
  getAdviserByRolManagerEmail(): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}services_externos/get_users_gestor`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que asigna un correo a un usuario en especifico. 
    * @param assign Informacion traida del formulario. 
  */
  assignUserToEmail(assign: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email/assign_user`, assign).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-27
    * Metodo que trae las configuraciones de correo asignados a un usuario. 
    * @param idRrhh Usuario seleccionado en el formulario.   
  */
  getConfigEmailByUser(idRrhh: number = null, idAccountEmail: number = null, filterValidateCiu: string = 'true'): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}useremail/showall?id_rrhh=${idRrhh}&id_email=${idAccountEmail}&validate_ciu=${filterValidateCiu}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-27
    * Metodo que asigna unas configuraciones de correo a un usuario seleccionado. 
    * @param data Informacion proveniente del formulario. 
  */
  assignEmailForUser(data: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}useremail/store`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-31
    * Metodo que envia un correo. 
    * @param data Informacion proveniente del formulario. 
  */
  sendEmail(data: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email/send`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-10
    * Metodo que gestiona uno o varios correos especificos. 
    * @param data Informacion proveniente del formulario. 
  */
  manageEmail(data: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email/gestionar`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-14
    * Metodo que trae los correos registrados en el sistema. 
    * @param paginator Configuracion de la paginacion del componente. 
    * @param filters Filtros enviados desde los formularios. 
  */
  getHistoryEmail(paginator: any, idEmail: number, filters: string = ''): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}email/show_history/${idEmail}?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&asunto=${filters}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-14
    * Metodo que retorna el archivo adjunto del correo. 
    * @param url Id del adjunto.     
  */
  downloadAttachment(url: string): Observable<any> {
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}email/descargarAdjunto?url=${url}`, { responseType: 'blob' as 'json' }).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que elimina un adjunto. 
    * @param idFile Id del adjunto.     
  */
  deleteAttachment(idFile: number): Observable<any> {
    return this.httpClient.delete<any>(`${GESTIONADOR_CORREOS}attachment/destroy/${idFile}`).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-16
    * Metodo que reenvia un correo que queda como borrador en el sistema. 
    * @param data Informacion proveniente del formulario.      
  */
  resendDraftEmail(data: any, idEmail: number): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email/resend/${idEmail}`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-26
    * Metodo que almacena la reasignacion de los correos. 
    * @param data Informacion proveniente del formulario.      
  */
  storeEmailReassignment(data: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-18
    * Metodo que trae los años registrados en las gestiones de correo. 
  */
  getYearsToReport(): Observable<any> {
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}dashboard/get_year`).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-18
    * Metodo que trae los meses registrados en las gestiones de correo realizados en el año seleccionado.
    * @param year Filtro seleccionado.  
  */
  getMonthsByYear(year: number): Observable<any> {
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}dashboard/get_month/${year}`).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-19
    * Metodo que trae el reporte para las graficas.
    * @param data Informacion proveniente del formulario. 
  */
  getGraphReport(data: any): Observable<any> {
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}dashboard/showall?year=${data.year}&month=[${data.months}]&emailConfig=${data.emailAccount}`).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-19
    * Metodo que trae el reporte para las tablas.
    * @param paginator Configuracion de la paginacion. 
    * @param data Informacion proveniente del formulario.
    * @param filter Asesor seleccionado en la lista.  
  */
  getTableReport(paginator: any, data: any, filter: string): Observable<any> {
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}dashboard/get_users?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&year=${data.year}&month=[${data.months}]&emailConfig=${data.emailAccount}&id_user_asignado=${filter}`).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-19
    * Metodo que descarga el reporte de excel.
    * @param data Informacion proveniente del formulario. 
  */
  downloadExcelReport(data: any): Observable<any> {
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}dashboard/descargaReporteGeneral?year=${data.year}&month=[${data.months}]&emailConfig=${data.emailAccount}`, { responseType: 'blob' as 'json' }).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-19
    * Metodo que elimina los correos seleccionados de una lista.
    * @param data Correos seleccionados de la lista. 
  */
  deleteEmails(data: FormData): Observable<any> {
    return this.httpClient.post<any>(`${GESTIONADOR_CORREOS}email/destroy_massive`, data).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-28
    * Metodo que actualiza la categoria del email seleccionado.
    * @param data Informacion proveniente del formulario.   
  */
  updateCategoryToListEmail(data: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}email/update_massive_category`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-12-01
    * Metodo que trae el historico de conversacion del correo seleccionado.
    * @param id Id del correo seleccionado.
  */
  getHistoryConversationByEmail(id: number): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}email/get_history/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que descarga el archivo EML del correo seleccionado.
    * @param idEmail Id del correo seleccionado.
  */
  downloadEmailEML(idEmail: number): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}email/getEml/${idEmail}`, { responseType: 'blob' as 'json' }).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-04
    * Metodo que verifica el correcto funcionamiento de la cuenta de correo.
    * @param idEmail Id del correo seleccionado.
  */
  checkAccountEmail(idEmail: number): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS_PYTHON}Email/check_email/${idEmail}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @createdate 2024-01-23
    * Metodo que restaura el correo eliminado.
    * @param data Lista de correos seleccionados. 
  */
  restoreEmails(data: FormData): Observable<any> {
    return this.httpClient.post<any>(`${GESTIONADOR_CORREOS}email/restore_massive`, data).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-26
    * Metodo que mueve los correos entre bandejas.
    * @param data Informacion del formulario. 
  */
  moveEmails(data: FormData): Observable<any> {
    return this.httpClient.post<any>(`${GESTIONADOR_CORREOS}email/set_massive_tray`, data).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-01
    * Metodo que mueve los correos entre cuentas de correo.
    * @param data Informacion del formulario. 
  */
  moveEmailsBetweenAccounts(data: FormData): Observable<any> {
    return this.httpClient.post<any>(`${GESTIONADOR_CORREOS}email/move_emails`, data).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-01
    * Metodo que mueve los correos de forma masiva entre cuentas de correo.
    * @param from Cuenta origen
    * @param to Cuenta destino
    * @param fromTray Bandeja de origen
    * @param toTray Bandeja de destino
  */
  moveMassiveEmailsBetweenAccounts(from: number, to: number, fromTray: number, toTray: number): Observable<any> {
    return this.httpClient.get<any>(`${GESTIONADOR_CORREOS}email/move_emails_massive?from=${from}&to=${to}&id_bandeja_from=${fromTray}&id_bandeja_to=${toTray}`).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-05
    * Metodo que mueve los correos de forma masiva entre cuentas de correo.
    * @param idEmailConfig Cuenta de correo seleccionada. 
    * @param recipient Destinatario al cual le va a llegar el correo.  
  */
  checkSendEmail(idEmailConfig: number, recipient: string): Observable<any> {
    const data = new FormData();
    data.append('recipient', recipient);
    data.append('email_config_id', idEmailConfig.toString());
    data.append('asunto', 'Test de prueba');
    data.append('cuerpo', 'Esto es una prueba para validar el envio de correos.');
    return this.httpClient.post<any>(`${GESTIONADOR_CORREOS}email/sendtest`, data).pipe(catchError(
      this.handleError
    ));
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-20
    * Metodo que trae los correos registrados en el sistema (Modulo de speetch). 
    * @param paginator Configuracion de la paginacion del componente. 
    * @param filters Filtros enviados desde los formularios. 
  */
  getEmailsByIaModule(paginator: any, filters: any): Observable<any> {
    const filtersFormat = {
      ...filters,
      date_in: filters.date_in !== null ? moment(filters.date_in).format('YYYY-MM-DD') : null,
      date_end: filters.date_end !== null ? moment(filters.date_end).format('YYYY-MM-DD') : null
    };
    const url = paginator !== null ? `${GESTIONADOR_CORREOS}ia/email/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}` : `${GESTIONADOR_CORREOS}email/showall`;
    return this.httpClient.post(url, filtersFormat).pipe(
      catchError(this.handleError)
    );
  }
}