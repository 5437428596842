<div id="containerViewMail" #containerViewMail class="px-5 py-3">
  <div *ngIf="!data.toPreview; else notPreview" class="pt-5">
    <button mat-mini-fab mat-dialog-close style="background-color: transparent;" class="mat-elevation-z0">
      <i class="fi-rr-angle-small-left" style="color: #626262;"></i>
    </button>
    <span class="ms-2" style="font-weight: 400; font-size: 12px;">Atras</span>
  </div>
  <ng-template #notPreview>
    <div *ngIf="data.showButtonPrint" class="pt-5">
      <button (click)="onClickPrintToEmailPdf()" mat-mini-fab style="background-color: transparent;"
        class="mat-elevation-z0">
        <i class="fi-sr-download" style="color: #626262;"></i>
      </button>
      <span class="ms-2" style="font-weight: 400; font-size: 12px;">Imprimir</span>
    </div>
  </ng-template>
  <app-mail-view [mailSelected]="data.management" [showVerticalScroll]="false" [showActionsButtons]="false"
    [showButtonHistory]="false" [showAccordeonHistory]="false" [showDetails]="data.showDetails"></app-mail-view>
  <div *ngIf="data.showButtonAddEmail" class="d-flex justify-content-end">
    <button (click)="onClickSaveEmail()" type="button" mat-raised-button color="primary" style="height: 48px; font-weight: 600;">
      Guardar correo
    </button>
  </div>
</div>