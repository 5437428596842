<div class="swiper-container .trays-swiper">
  <swiper [config]="swipperConfigTrays">
    <ng-template *ngFor="let card of cardsReport" swiperSlide>
      <div style="background-color: #FFFFFF; height: 120px; border-radius: 10px; border-color: #2CABBC;"
        class="alert d-flex justify-content-between border-2 w-100">
        <div class="d-flex w-50 justify-content-center align-items-center">
          <i [ngStyle]="card.styleIcon" [ngClass]="card.icon"></i>
        </div>
        <div class="d-flex flex-column w-50 justify-content-center align-items-center">
          <div class="pb-2" [ngStyle]="card.styleValue">{{ card.value }}</div>
          <div [ngStyle]="card.styleText">{{ card.text }}</div>
        </div>
      </div>
    </ng-template>
  </swiper>
  <div class="swiper-button-prev swiper-button-prev-trays d-none d-sm-block"></div>
  <div class="swiper-button-next swiper-button-next-trays d-none d-sm-block"></div>
</div>