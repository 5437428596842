import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MenusService } from '../../../services/rest/menus.service';
import { DestroyComponentService } from '../../../../../core/services/utils/destroy-component.service';
import { AlertsService } from '../../../../../shared/alerts/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveMenusComponent } from '../save-menus/save-menus.component';
import { Menu } from '../../class/menu';
import { ApplicationsService } from '../../../services/rest/applications.service';

@Component({
  selector: 'app-admin-menus',
  templateUrl: './admin-menus.component.html',
  styleUrls: ['./admin-menus.component.sass']
})
export class AdminMenusComponent implements OnInit {
  length;
  pageSize = 5;
  page = 0;
  isPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newRolComponent: boolean = false;
  displayedColumns: string[] = ['acciones', 'nameApp', 'name', 'icon', 'url', 'index'];
  dataSource = new MatTableDataSource<any>();
  filterApp: number | null = null;
  filterName: string | null = null;
  apps: any[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public menuService: MenusService,
    private destroyService: DestroyComponentService,
    private alertsService: AlertsService,
    public dialog: MatDialog,
    private applicationsService: ApplicationsService
  ) { }

  ngOnInit(): void {
    this.applicationsService.getApplications().subscribe(res => {
      this.apps = res.data;
    });
    this.getMenu();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que trae los roles paginados
   * @param size cantidad de roles a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
  getMenu(): void {
    // if (this.dataSource.filter === '') {
    //   this.dataSource.filter = null;
    // }
    this.menuService.getMenuPaginate(this.isPage, this.pageSize, this.page, this.filterApp, this.filterName).subscribe((Response) => {
      this.dataSource.data = Response.data.data;
      // this.dataSource.sort = this.sort;
      this.length = Response.data.total;
      // this.pageSize = Response.data.per_page;
    });
  }
  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que realiza el filtro
   * @param event datos para filtrar que son enviados desde el input
   */
  applyFilter(): void {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.menuService.searchMenu(this.pageSize, this.page, filterValue).subscribe((Response) => {
    //   this.dataSource.data = Response.data.data;
    //   this.dataSource.sort = this.sort;
    //   this.length = Response.data.total;
    //   this.pageSize = Response.data.per_page;
    // });
    this.page = 0;
    this.getMenu();
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {

    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.getMenu();

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo rol, al cerral el modal se refresca la tabla
   */
  newMenu(): void {
    const dialogNewRol = this.dialog.open(SaveMenusComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear Menús',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
  editMenu(menu: Menu): void {
    const dialogNewRol = this.dialog.open(SaveMenusComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar Menu',
        menu
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.pageSize = 5;
      this.ngOnInit();
    });
  }

  // /**
  //  * @author Daniel Martinez
  //  * @createdate 2021-02-04
  //  * Metodo que elimina un rol
  //  * @param rol rol a eliminar
  //  */
  //  deleteCampaign(app: Aplication): void{
  //   app.state =0;

  //   this.applicationService.deleteApplication(app,app.id).subscribe((resp) => {
  //     this.alertsService.alertSuccess('Eliminado', resp.data);
  //     this.ngOnInit();
  //   });
  // }

  viewCampaign() {

  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }
}
