import { Component, OnInit, Inject } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManagementHistoryViewComponent } from '../management-history-view/management-history-view.component';
import { EmailService } from '../../services/email.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-management-history',
  templateUrl: './management-history.component.html',
  styleUrls: ['./management-history.component.css']
})
export class ManagementHistoryComponent implements OnInit {
  configColumnsTable: any[] = [
    { name: '', key: 'actions' },
    { name: 'Estado', key: 'state_email' },
    { name: 'Asunto', key: 'asunto' },
    { name: 'Fecha de envio', key: 'created_at' }
  ];
  dataManagementHistory: any[] = [];
  configPaginator: any = { length: 0, pageIndex: 0, pageSize: 5 };
  keysColumnsTable: string[] = [];
  filterSubject: FormControl = new FormControl('', Validators.maxLength(100));

  constructor(
    private matDialog: MatDialog,
    private emailService: EmailService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map(item => item.key);
    this.getManagementHistory();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que trae los historiales registrados en el sistema.  
  */
  getManagementHistory(): void {
    this.emailService.getHistoryEmail(this.configPaginator, this.data.email.id, this.filterSubject.value).subscribe(res => {
      const mapToRegistersHistory = res.data.email_historial.data.map((item: any) => {
        return { ...item, created_at: moment(item.created_at).format('DD/MM/YYYY') };
      });
      this.dataManagementHistory = mapToRegistersHistory;
      this.configPaginator.length = res.data.email_historial.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que trae la informacion del email seleccionado en la tabla.
    * @param management Gestion seleccionada en la tabla.   
  */
  onClickShowManagementSelected(management: any): void {
    this.emailService.getEmailById(management).subscribe(res => {
      this.matDialog.open(ManagementHistoryViewComponent, {
        width: '80%',
        maxHeight: '95vh',
        autoFocus: false,
        panelClass: 'dialog-padding-management-history-view',
        data: {
          management: res.data,
          toPreview: false
        }
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que setea la configuracion de la paginacion.
    * @param $event Evento emitido por el paginador.
  */
  onChangePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getManagementHistory();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-10
    * Metodo que setea el valor del filtro sobre la vista.
    * @param $event Evento emitido el input.
  */
  onChangeFilter($event: any): void {
    this.filterSubject.setValue($event.target.value);
    if (this.filterSubject.valid) this.getManagementHistory();
  }
}