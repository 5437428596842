import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que retorna los estados registrados en el sistema. 
    * @param paginator Paginador configurado en la vista. 
    * @param filter Filtro configurado en la vista. 
    * @param showAllStates Trae o no todos los estados activos.  
  */
  getStates(paginator: any = null, filter: string = '', showAllStates: boolean = false, idEmailConfig: string = ''): Observable<any> {
    const paramState = showAllStates ? '' : 'state=1';
    const url = paginator === null ? `${GESTIONADOR_CORREOS}emailstate/showall?${paramState}&id_email_config=${idEmailConfig}` : `${GESTIONADOR_CORREOS}emailstate/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filter}&id_email_config=${idEmailConfig}`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que guardar o actualiza un estado en el sistema. 
    * @param state Informacion proveniente del formulario.  
  */
  storeState(state: any): Observable<any> {
    const url = state.id === null ? `${GESTIONADOR_CORREOS}emailstate/store` : `${GESTIONADOR_CORREOS}emailstate/update/${state.id}`;
    return this.httpClient.post(url, state).pipe(
      catchError(this.handleError)
    );
  }
}