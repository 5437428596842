<form [formGroup]="formNewMessage" autocomplete="off">
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>De</mat-label>
        <input matInput placeholder="De" formControlName="from">
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="typeManagerMessage === 'Guardar información'" class="row">
    <div class="col-md-12">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Lista de correos</mat-label>
        <input matInput placeholder="Lista de correos" formControlName="listEmails">
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="typeManagerMessage === 'Guardar información' && formNewMessage.get('cc').value !== ''" class="row">
    <div class="col-md-12">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Con copia</mat-label>
        <input matInput placeholder="Lista de correos" formControlName="cc">
      </mat-form-field>
    </div>
  </div>
  <!-- <div *ngIf="typeManagerMessage === 'Guardar información' && formNewMessage.get('cco').value !== ''" class="row">
    <div class="col-md-12">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Con copia oculta</mat-label>
        <input matInput placeholder="Lista de correos" formControlName="cco">
      </mat-form-field>
    </div>
  </div> -->
  <div *ngIf="typeManagerMessage === 'Guardar información'" class="row">
    <div class="col-md-12">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Descripción*</mat-label>
        <input matInput placeholder="Descripción" formControlName="description">
        <mat-error *ngIf="error.description.errors">
          <span *ngIf="error.description.errors.required">El campo es requerido</span>
          <span *ngIf="error.description.errors.maxlength">Máximo 10000 caracteres</span>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div
    *ngIf="typeManagerMessage === 'Enviar' || typeManagerMessage === 'Responder' || typeManagerMessage === 'Reenviar' || typeManagerMessage === 'Enviar borrador'">
    <div class="row d-flex align-items-center">
      <div class="col-md-9">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListTo>
            <mat-chip *ngFor="let item of formNewMessage.get('to')?.controls; let i = index" [selectable]="true"
              [removable]="true" (removed)="removeItem(i, 'to')">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Para*" #inputTo [matChipInputFor]="chipListTo"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEmail($event, 'to')">
          </mat-chip-list>
        </mat-form-field>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.to.errors">
          <span *ngIf="error.to.errors.validateArrayNotEmpty">El campo es requerido</span>
          <span *ngIf="error.to.errors.validateIsEmails">Correo invalido</span>
        </div>
      </div>
      <div class="col-md-3 d-flex justify-content-between align-items-center">
        <div>
          <button type="button" mat-icon-button (click)="onClickOpenDialogContacts('to')">
            <i class="fi-rr-users"></i>
          </button>
        </div>
        <div [ngClass]="{'text-decoration-underline': ccSelect === true }" (click)="onChangeStateCcSelect()"
          style="font-size: 14px; cursor: pointer;">CC</div>
        <div [ngClass]="{'text-decoration-underline': ccoSelect === true }" (click)="onChangeStateCcoSelect()"
          style="font-size: 14px; cursor: pointer;">CCO</div>
      </div>
    </div>
    <div *ngIf="ccSelect" class="row d-flex align-items-center">
      <div class="col-md-11">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListCc>
            <mat-chip *ngFor="let item of formNewMessage.get('cc')?.controls; let i = index" [selectable]="true"
              [removable]="true" (removed)="removeItem(i, 'cc')">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="CC*" #inputCc [matChipInputFor]="chipListCc"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEmail($event, 'cc')">
          </mat-chip-list>
        </mat-form-field>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.cc.errors">
          <span *ngIf="error.cc.errors.validateArrayNotEmpty">El campo es requerido</span>
          <span *ngIf="error.cc.errors.validateIsEmails">Correo invalido</span>
        </div>
      </div>
      <div class="col-md-1 d-flex justify-content-center align-items-center">
        <button type="button" mat-icon-button (click)="onClickOpenDialogContacts('cc')">
          <i class="fi-rr-users"></i>
        </button>
      </div>
    </div>
    <div *ngIf="ccoSelect" class="row d-flex align-items-center">
      <div class="col-md-11">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListCco>
            <mat-chip *ngFor="let item of formNewMessage.get('cco')?.controls; let i = index" [selectable]="true"
              [removable]="true" (removed)="removeItem(i, 'cco')">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="CCO*" #inputCco [matChipInputFor]="chipListCco"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEmail($event, 'cco')">
          </mat-chip-list>
        </mat-form-field>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.cco.errors">
          <span *ngIf="error.cco.errors.validateArrayNotEmpty">El campo es requerido</span>
          <span *ngIf="error.cco.errors.validateIsEmails">Correo invalido</span>
        </div>
      </div>
      <div class="col-md-1 d-flex justify-content-center align-items-center">
        <button type="button" mat-icon-button (click)="onClickOpenDialogContacts('cco')">
          <i class="fi-rr-users"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Asunto*</mat-label>
          <input matInput placeholder="Asunto" formControlName="subject">
          <mat-error *ngIf="error.subject.errors">
            <span *ngIf="error.subject.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #chipListAdjuntos>
            <mat-chip *ngFor="let item of formNewMessage.get('attachments')?.controls; let i = index"
              [selectable]="true" [removable]="true" (removed)="removeItem(i, 'attachments')">
              {{ item.value.file.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input readonly placeholder="Adjuntos" #inputAdjuntos [matChipInputFor]="chipListAdjuntos"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true">
            <button type="button" mat-icon-button matSuffix (click)="inputAdjuntosHidden.click()">
              <i class="fi-rr-clip"></i>
            </button>
          </mat-chip-list>
        </mat-form-field>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.attachments.errors">
          <span *ngIf="error.attachments.errors.validateSizeFiles">Máximo 20 MB</span>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="preloadAttachments.length > 0">
      <div class="col-md-12">
        <mat-chip-list #chipListFiles>
          <mat-chip *ngFor="let file of preloadAttachments" [selectable]="true" [removable]="true"
            (removed)="deletePreloadAttachments(file.id)">
            {{ file.nombre }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="row mb-3" *ngIf="filesToLoadEmail.length > 0">
      <div class="col-md-12">
        <mat-chip-list #chipListFiles>
          <mat-chip *ngFor="let file of filesToLoadEmail" [selectable]="true" [removable]="true"
            (removed)="deleteFileByEmail(file.id)">
            {{ file.nombre }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="row" *ngIf="templates.length > 0">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-select placeholder="Seleccione una plantilla (opcional)" formControlName="template"
            (selectionChange)="onChangeTemplateSelected($event)">
            <mat-option *ngFor="let item of templates" [value]="item.id">{{ item.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-3 d-flex justify-content-end"> 
      <div class="col-md-8 d-flex justify-content-end gap-2">
        <mat-form-field appearance="fill" class="w-75">
          <mat-label>Textos rápidos</mat-label>
          <input matInput formControlName="label" cdkFocusInitial [matAutocomplete]="auto"
            [value]="nameLabel">
          <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let item of filteredOptions | async" [value]="item.content"
              (onSelectionChange)="findLabel($event)" [matTooltip]="item.content">
              {{ item.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button type="button" mat-icon-button color="primary" class="mt-2" 
          (click)="onClickSelectedLabelToInput()" matTooltip="Copiar etiqueta seleccionada">
          <span class="fs-2 fi-rr-copy"></span>
        </button>
      </div>
      <!-- <div class="col-md-2 d-flex justify-content-end" *ngIf="labels.length > 0">
        <button type="button" mat-raised-button color="primary" [matMenuTriggerFor]="menu" matTooltip="Texto rápido"
          style="height: 48px; font-weight: 600;" *ngIf="labels.length > 0">
          <span class="fs-3 fi-rr-text"></span>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let label of labels" (click)="onClickSelectLabel(label.content)" [matTooltip]="label.content">{{ label.name
            }}</button>
        </mat-menu>
      </div> -->
      <div class="col-md-4">
        <button (click)="clearMessage.emit()" type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Borrar
          mensaje</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <angular-editor #editor [config]="editorConfig" formControlName="message"></angular-editor>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.message.errors">
          <span *ngIf="error.message.errors.required">El campo es requerido</span>
        </div>
      </div>
    </div>
    <input (change)="addFile($event)" type="file" hidden #inputAdjuntosHidden multiple>
  </div>
</form>