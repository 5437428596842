import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { Paginator } from 'src/app/shared/basic-table/type';
import { environment } from 'src/environments/environment';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

const { GESTIONADOR_CORREOS } = environment;

@Component({
  selector: 'app-view-emails-state',
  templateUrl: './view-emails-state.component.html',
  styleUrls: ['./view-emails-state.component.css']
})
export class ViewEmailsStateComponent implements OnInit {
  dataTable: any[] = [];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  messageLoading: string = 'No existe información relacionada';

  constructor(
    private crudService: CrudService,
    private alertService: AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getEmailsByStateSelected();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-10-30
    * Metodo que consulta los correos por estado seleccionado. 
  */
  getEmailsByStateSelected(): void {
    this.dataTable = [];
    this.messageLoading = 'Actualizando información...';
    this.crudService.getData({
      url: `${GESTIONADOR_CORREOS}dashboard/table_estados_detail`,
      filters: {
        emailConfig: this.data.emailConfig,
        month: this.data.month,
        year: this.data.year,
        state: this.data.state,
      },
      paginator: this.configPaginator
    }).subscribe(res => {
      this.dataTable = res.data.data;
      this.configPaginator.length = res.data.total;
      this.messageLoading = this.dataTable.length === 0 && 'No existe información relacionada'; 
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-30
    * Metodo ejecuta la alerta para mostrar mas información. 
    * @param $event Evento emitido por el componente hijo. 
  */
  onClickButtonTableMoreInfo($event: any): void {
    this.alertService.alertSuccess(
      'Más información',
      `
        <div class="d-flex flex-column text-start">
          <div><b>Asunto:</b> ${$event.element.asunto}</div>
          <div><b>Bandeja:</b> ${$event.element.bandeja}</div>
          <div><b>De:</b> ${$event.element.de}</div>
          <div><b>Para:</b> ${$event.element.para}</div>
          <div><b>Fecha:</b> ${$event.element.fecha_Hora}</div>
          <div><b>Fecha cierre:</b> ${$event.element.fecha_cierre ? $event.element.fecha_cierre : 'N/R'}</div>
          <div><b>Diferencia:</b> ${$event.element.diferencia}</div>
          <div><b>Estado:</b> ${$event.element.estado}</div>
        </div>
      `,
      { timer: null, icon: 'info' }
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-30
    * Metodo que actualiza el estado de la paginación.
    * @param $event Evento emitido por el componente hijo.
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getEmailsByStateSelected();
  }
}