<div class="px-2 py-3 container-menu vh-100" style="overflow-y: auto;">
  <div class="d-flex justify-content-between align-items-center py-4">
    <div style="font-size: 22px; font-weight: 700;">
      Gestor de correos
    </div>
    <div>
      <button mat-icon-button (click)="onClickRefreshView()">
        <i class="fi-rr-refresh"></i>
      </button>
    </div>
  </div>
  <div class="w-100">
    <button mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600; font-size: 16px;"
      (click)="onClickCreateMessage()">
      <div class="d-flex justify-content-center align-items-center w-100 h-100">
        <span>Nuevo mensaje</span>
        <span class="ms-4 mt-2 fi-rr-add"></span>
      </div>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="my-2 px-1">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>Gestionar</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mat-checkbox color="primary" [(ngModel)]="checkAllAccountEmails"
            (change)="onClickChecboxAllEmailsConfig($event)">Todos los
            correos</mat-checkbox>
        </div>
        <div class="mt-2 ms-1">
          <mat-checkbox *ngFor="let item of emailsConfig" color="primary"
            (change)="onClickCheckboxEmailConfig($event, item)" [checked]="ifSelectedEmail(item)">
            <div style="font-weight: 400; font-size: 12px;">{{
              item.user_name_email_entrada }}</div>
          </mat-checkbox>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <mat-divider></mat-divider>
  <div class="my-2 px-2">
    <div *ngIf="traysEmail.length > 0; else notTrays">
      <div *ngFor="let item of traysEmail" [ngClass]="item.id === keyTraySelected && 'border-0 rounded-pill'"
        [ngStyle]="{'background-color': item.id === keyTraySelected ? '#F0F0F0' : 'transparent' }"
        style="cursor: pointer;" (click)="onClickTraySelect(item)">
        <div class="d-flex justify-content-between py-2 px-3">
          <div style="font-size: 16px;">
            <span class="me-2" [ngClass]="item.icon"></span>
            {{ item.name }}
          </div>
          <div style="font-size: 16px;">
            {{ item.cantida_correos }}
          </div>
        </div>
      </div>
    </div>
    <ng-template #notTrays>
      <div class="d-flex justify-content-center align-items-center" style="min-height: 100px;">
        <div style="font-size: 16px;">No hay bandejas registradas</div>
      </div>
    </ng-template>
  </div>
  <mat-divider></mat-divider>
  <div class="my-2 px-2">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>Carpetas</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="folders.length > 0; else notFolders">
          <div *ngFor="let item of folders" style="cursor: pointer;" (click)="onClickTraySelect(item)">
            <div class="d-flex justify-content-between py-2 px-3">
              <div style="font-size: 16px;">
                <span class="me-2" [ngClass]="item.icon"></span>
                <span [ngStyle]="{'font-weight': item.id === keyTraySelected ? '700' : '400'}">{{ item.name }}</span>
              </div>
              <div style="font-size: 16px;">
                {{ item.cantida_correos }}
              </div>
            </div>
          </div>
        </div>
        <ng-template #notFolders>
          <div class="text-center" style="font-size: 16px;">
            Seleccioné una cuenta de correo
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <mat-divider></mat-divider>
  <div class="my-2 px-2">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>Categorías</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="categories.length > 0; else notCategories">
          <div style="font-size: 16px;" class="px-1 py-1">
            <span style="color: #D14A4A;" class="fi-rr-cross-circle"></span>
            <span (click)="onClickCategorySelect()" class="ms-2" style="cursor: pointer;">Quitar filtro</span>
          </div>
          <div *ngFor="let item of categories" class="d-flex justify-content-between px-1 py-1">
            <div (click)="onClickCategorySelect(item)" style="font-size: 16px; cursor: pointer;"
              [ngStyle]="{'font-weight': item.id === keyCategorySelected ? '700' : '400'}">
              <span class="me-2" class="fi-rr-flag" [ngStyle]="{'color': item.color}"></span>
              {{ item.name }}
            </div>
            <div style="cursor: pointer;" (click)="onClickShowCategory(item)">
              <span class="fi-rr-eye"></span>
            </div>
          </div>
        </div>
        <ng-template #notCategories>
          <div class="text-center" style="font-size: 16px;">
            Seleccioné una cuenta de correo
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <mat-divider></mat-divider>
  <div class="my-2 px-2">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>Estados</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="statesTypesMails.length > 0; else notStates">
          <div style="font-size: 16px;" class="px-1 py-1">
            <span style="color: #D14A4A;" class="fi-rr-cross-circle"></span>
            <span (click)="onClickStateSelect()" class="ms-2" style="cursor: pointer;">Quitar filtro</span>
          </div>
          <div *ngFor="let item of statesTypesMails" class="d-flex justify-content-between px-1 py-1">
            <div (click)="onClickStateSelect(item)" style="font-size: 16px; cursor: pointer;"
              [ngStyle]="{'font-weight': item.id === keyStateSelected ? '700' : '400'}">
              {{ item.name }}
            </div>
            <div style="cursor: pointer;" (click)="onClickCreateOrEditState(item)">
              <span class="fi-rr-eye"></span>
            </div>
          </div>
        </div>
        <ng-template #notStates>
          <div class="text-center" style="font-size: 16px;">
            Seleccioné una cuenta de correo
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <mat-divider></mat-divider>
  <div class="my-2 px-2">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>Vencimiento</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="emailsExpiration.length > 0; else notAns">
          <div style="font-size: 16px;" class="px-1 py-1">
            <span style="color: #D14A4A;" class="fi-rr-cross-circle"></span>
            <span (click)="onClickAnsSelect()" class="ms-2" style="cursor: pointer;">Quitar filtro</span>
          </div>
          <div *ngFor="let item of emailsExpiration" class="d-flex justify-content-between px-1 py-1">
            <div (click)="onClickAnsSelect(item)" style="font-size: 16px; cursor: pointer;"
              [ngStyle]="{'font-weight': item.id === keyAnsSelected ? '700' : '400'}">
              <span class="me-2" class="fi-rr-time-quarter-to" [ngStyle]="{'color': item.color}"></span>
              {{ item.name }}
            </div>
            <div style="cursor: pointer;" (click)="onClickCreateOrEditAns(item)">
              <span class="fi-rr-eye"></span>
            </div>
          </div>
        </div>
        <ng-template #notAns>
          <div class="text-center" style="font-size: 16px;">
            Seleccioné una cuenta de correo
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>