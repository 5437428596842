<form [formGroup]="formSurvey">
  <div class="row row-gap-3">
    <ng-container *ngFor="let question of questions; let i = index">
      <div *ngIf="question.type !== 'hidden' || question.show" [ngClass]="!ifFormFilter ? 'col-md-12' : question.class">
        <mat-form-field
          *ngIf="(question.typeInput === 'input' || question.typeInput === 'textarea') && question.type !== 'hidden'"
          appearance="fill" class="w-100">
          <mat-label class="fs-6">{{ question.label }}</mat-label>
          <input *ngIf="question.typeInput === 'input' && question.type !== 'file'" [type]="question.type" matInput
            [placeholder]="question.label" [formControlName]="question.key">
          <ngx-mat-file-input *ngIf="question.typeInput === 'input' && question.type === 'file'" #fileInput
            [formControlName]="question.key" [multiple]="question.multiple" [accept]="question.accept"
            (change)="onFileChange($event, question.key)"></ngx-mat-file-input>
          <mat-chip-list *ngIf="question.type === 'file' && !question.multiple && formSurvey.get(question.key).value">
            <mat-chip>{{ formSurvey?.get(question.key)?.value?.name }}</mat-chip>
          </mat-chip-list>
          <textarea *ngIf="question.typeInput === 'textarea'" matInput [placeholder]="question.label"
            [formControlName]="question.key"></textarea>
          <a (click)="onClickShowOrHidePassword(i)" mat-icon-button matSuffix *ngIf="question.hasOwnProperty('hide')"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{ question.hide ? 'visibility' : 'visibility_off' }}</mat-icon>
          </a>
          <mat-error
            *ngIf="formSurvey.controls[question.key].invalid && (formSurvey.controls[question.key].dirty || formSurvey.controls[question.key].touched)">
            <span *ngIf="formSurvey.controls[question.key].errors.required">
              El campo es requerido
            </span>
            <span *ngIf="formSurvey.controls[question.key].errors.minlength">
              Mínimo de caracteres invalido
            </span>
            <span *ngIf="formSurvey.controls[question.key].errors.maxlength">
              Maximo de caracteres invalido
            </span>
            <span *ngIf="formSurvey.controls[question.key].errors.email">
              Email invalido
            </span>
            <span *ngIf="formSurvey.controls[question.key].errors.pattern">
              Cadena no válida
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="question.typeInput === 'select'" appearance="fill" class="w-100">
          <mat-label class="fs-6">{{ question.label }}</mat-label>
          <mat-select [formControlName]="question.key" (selectionChange)="onChangeValueSelect($event, question.key)">
            <mat-option *ngFor="let option of question.options" [value]="option.value">{{ option.text }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="formSurvey.controls[question.key].invalid && (formSurvey.controls[question.key].dirty || formSurvey.controls[question.key].touched)">
            <span *ngIf="formSurvey.controls[question.key].errors.required">
              El campo es requerido
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="question.typeInput === 'date' && question.show" appearance="fill" class="w-100">
          <mat-label class="fs-6">Fecha</mat-label>
          <input [formControlName]="question.key" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            *ngIf="formSurvey.controls[question.key].invalid && (formSurvey.controls[question.key].dirty || formSurvey.controls[question.key].touched)">
            <span *ngIf="formSurvey.controls[question.key].errors.required">
              El campo es requerido
            </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="question.typeInput === 'date-range' && question.show" appearance="fill" class="w-100">
          <mat-label class="fs-6">Rango de fechas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [formControlName]="question.range[0].key" placeholder="Fecha inicial">
            <input matEndDate [formControlName]="question.range[1].key" placeholder="Fecha final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error
            *ngIf="formSurvey.controls[question.range[0].key].invalid && (formSurvey.controls[question.range[0].key].dirty || formSurvey.controls[question.range[0].key].touched)">
            <span *ngIf="formSurvey.controls[question.range[0].key].errors.required">
              Fecha inicial requerida
            </span>
          </mat-error>
          <mat-error
            *ngIf="formSurvey.controls[question.range[1].key].invalid && (formSurvey.controls[question.range[1].key].dirty || formSurvey.controls[question.range[1].key].touched)">
            <span *ngIf="formSurvey.controls[question.range[1].key].errors.required">
              Fecha final requerida
            </span>
          </mat-error>
        </mat-form-field>
        <div *ngIf="question.typeInput === 'html'">
          <angular-editor [config]="editorConfig" [formControlName]="question.key"></angular-editor>
          <div style="font-size: 11px; color: #c62828" *ngIf="formSurvey.controls[question.key].invalid && (formSurvey.controls[question.key].dirty || formSurvey.controls[question.key].touched)">
            <span *ngIf="formSurvey.controls[question.key].errors.required">El campo es requerido</span>
          </div>
        </div>
        <div *ngIf="question.typeInput === 'array'">
          <div class="d-flex flex-column mb-2" style="gap: 0.5rem;">
            <div class="fw-bold fs-6">{{ question.title }}</div>
            <div class="text-justify">{{ question.description }}</div>
          </div>
          <div [formArrayName]="question.key">
            <div *ngFor="let control of options(question.key).controls; let i = index">
              <div [formGroupName]="i" class="row">
                <div class="col-md-10">
                  <mat-form-field class="w-100" appearance="fill">
                    <mat-label class="fs-6">{{ question.label }} {{ i + 1 }}</mat-label>
                    <input matInput formControlName="text" />
                    <mat-error
                      *ngIf="control.get('text').invalid && (control.get('text').dirty || control.get('text').touched)">
                      <span *ngIf="control.get('text').errors.required">Este campo es requerido</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2 d-flex justify-content-center align-items-center">
                  <button *ngIf="options(question.key).length < question.maxElements" type="button" mat-icon-button
                    (click)="addOption(question.key)">
                    <i class="fi-rr-add fs-4" style="color: #51B0CB"></i>
                  </button>
                  <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeOption(question.key, i)">
                    <i class="fi-rr-trash fs-4" style="color: #D14A4A"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="ifFormFilter" class="col-md-3">
      <button (click)="submitForm()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">{{ textButtonSubmit }}</button>
    </div>
  </div>
  <div *ngIf="!ifFormFilter" class="row py-3 d-flex justify-content-end">
    <div class="col-md-3">
      <button *ngIf="!showAlertToCancelButton" mat-dialog-close type="button" mat-raised-button class="w-100"
        style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      <button *ngIf="showAlertToCancelButton" (click)="onClickButtonCancel.emit()" type="button" mat-raised-button
        class="w-100"
        style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
    </div>
    <div class="col-md-3">
      <button (click)="submitForm()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">{{ textButtonSubmit }}</button>
    </div>
  </div>
</form>