import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { SPEECH_URL } = environment;

@Injectable({
  providedIn: 'root'
})
export class StrategyServersService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo que retorna los errores generados por HTTP. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Traer listado de estrategias paginado
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   * @param filter filtros para la búsqueda.
   * @param paginator configuración de paginación (pageSize y pageIndex).
  */
  paginateStrategies(filter: any, paginator: any): Observable<any> {
    const body = { filter, per_page: paginator.pageSize, page: paginator.pageIndex };
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/paginate`, body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Obtener la estrategia por id
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  getStrategyById(id: number): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}strategy/show/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Listar las estrategias para selector
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  listStrategies(ids: number[]): Observable<any> {
    const body = {channel: ids };
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/listStrategy`,body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Crear una nueva estrategia 
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  createStrategy(strategyData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/create`, strategyData).pipe(
      catchError(this.handleError)
    );
  }
  
  /**
   * Actualizar la informacion de  una estrategia
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  updateStrategy(strategyData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/update`, strategyData).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Desactivar/inhabilitar las estrategias
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  desactiveStrategy(strategyData: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/inactivateStrategy`, strategyData).pipe(
      catchError(this.handleError)
    );
  } 

  /**
   * Metodo que se encarga de asignar las estrategias a las grabaciones indicadas
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
   * @returns {Observable<any>}  Observable<any>
   */
  assignStrategys(strateyForm:any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/assignStrategy`, strateyForm).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Aplicar una estrategia a los analisis correspondientes
   * @author Yeison Sepulveda
   * @createdate 2024-04-30
   * @param recordings array con id de las grabaciones para descargar. 
  */
  applyAdherencia(recordings: any[]): Observable<any> {
    const body = { id_recordings: recordings };
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/percentageAdherencia`,body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Listar los canales disponibles
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */
  listChannel(): Observable<any> {
    return this.httpClient.get<any>(`${SPEECH_URL}strategy/list_channels`).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que se encarga de asignar las estrategias de los chats
   * @author Yeison Sepulveda
   * @createdate 2024-08-13
   * @returns {Observable<any>}  Observable<any>
   */
  assignStrategyChat(strateyForm:any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/assignStrategyChat`, strateyForm).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Aplicar una estrategia a los analisis correspondientes
   * @author Yeison Sepulveda
   * @createdate 2024-04-30
   * @param ids_cases array con id de los casos
  */
  applyAdherenciaChats(ids_cases: any[]): Observable<any> {
    const body = { ids_cases: ids_cases };
    return this.httpClient.post<any>(`${SPEECH_URL}chatCases/percentageAdherenciaChats`,body).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Metodo validar estado de adherencia de los audios
   * @author Yeison Sepulveda
   * @param cases array con los id de los casos
   * @createdate 2024-10-15
  */
  AdherenciaState(recordings: any): Observable<any> {
    return this.httpClient.post<any>(`${SPEECH_URL}strategy/stateStrategy`,recordings).pipe(
      catchError(this.handleError)
    );
  }
}