<mat-card class="card-info-dash">
    <div class="row">
        <div class="col-5 icon-card">
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-video-camera'"><i class="fi fi-rr-video-camera"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-users'"><i class="fi fi-rr-users"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-comment'"><i class="fi fi-rr-comment"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-time-half-past'"><i class="fi fi-rr-time-half-past"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-time-forward-ten'"><i class="fi fi-rr-time-forward-ten"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-signal-alt-2'"><i class="fi-rr-signal-alt-2"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-smartphone'"><i class="fi-rr-smartphone"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-envelope'"><i class="fi-rr-envelope"></i></mat-icon>
            <mat-icon matSuffix class="card-icon" *ngIf="icon == 'fi-rr-computer'"><i class="fi-rr-computer"></i></mat-icon>
        </div>
        <div class="col-7 text-center">
            <h2 class="text-bold mios-color">{{num}}</h2>
            <p class="mios-gray">{{info}}</p>
        </div>
    </div>
</mat-card>
