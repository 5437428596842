<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">Mover correos</div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formMoveEmail">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select formControlName="idAccountEmail" (selectionChange)="onChangeEmailConfigSelected($event)">
            <mat-option *ngFor="let item of data.emailsAccount" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="error.idAccountEmail.errors">
            <span *ngIf="error.idAccountEmail.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Bandejas y carpetas</mat-label>
          <mat-select formControlName="idTray">
            <mat-optgroup *ngFor="let group of traysAndFolders" [label]="group.name">
              <mat-option *ngFor="let item of group.list" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error *ngIf="error.idTray.errors">
            <span *ngIf="error.idTray.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Descartar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveMoveEmails()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Guardar</button>
      </div>
    </div>
  </form>
</div>