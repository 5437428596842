<div *ngIf="historyConversationList.length > 0; else noHistory">
  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let item of historyConversationList; let i = index"
      (opened)="getDataToAccordeonOpen(item, i)" class="border border-1 mat-elevation-z0" [expanded]="item.hasOwnProperty('dataEmail')">
      <mat-expansion-panel-header>
        <mat-panel-title class="text-truncate-panel-title">
          <span style="font-weight: 700; font-size: 14px;">
            {{ item.asunto }}
          </span>
        </mat-panel-title>
        <mat-panel-description class="text-truncate-panel-description">
          <span style="font-weight: 600; font-size: 12px; color: #A9A9A9; text-align: end;">{{ item.fecha_Hora }}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-mail-view *ngIf="item.hasOwnProperty('dataEmail'); else notEmailOpened" [mailSelected]="item?.dataEmail"
        [showVerticalScroll]="true" [showActionsButtons]="false" [showButtonHistory]="false"
        [showAccordeonHistory]="false"></app-mail-view>
      <ng-template #notEmailOpened>
        <div class="text-center" style="font-weight: 700;">Cargando...</div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #noHistory>
  <div class="text-center">No se registra historico de conversación</div>
</ng-template>