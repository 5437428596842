import { Component, Input, OnInit } from '@angular/core';

import { GLOBAL } from '../../../../services/config/message.service';

@Component({
  selector: 'app-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.sass']
})
export class CardDashboardComponent implements OnInit {

  @Input() icon:string;
  @Input() num:string;
  @Input() info:string;
  public paragraph: string;
 
  constructor() { }

  ngOnInit(): void {
  }

}
