<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">
    {{ data.template === null ? 'Crear plantilla' : 'Editar plantilla' }}
  </div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formTemplate">
    <div class="row d-flex justify-content-end">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre de la plantilla</mat-label>
          <input matInput placeholder="Nombre de la plantilla" formControlName="name">
          <mat-error *ngIf="error.name.errors">
            <span *ngIf="error.name.errors.required">El campo es requerido</span>
            <span *ngIf="error.name.errors.maxlength">Máximo 30 caracteres</span>
            <span *ngIf="error.name.errors.pattern">El campo debe contener caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Archivo HTML</mat-label>
          <input (click)="inputFile.click()" matInput placeholder="Archivo HTML" formControlName="html" readonly>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select formControlName="id_email_config">
            <mat-option *ngFor="let item of data.emailsConfig" [value]="item.id">{{ item.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_email_config.errors">
            <span *ngIf="error.id_email_config.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-2">
        <button (click)="formTemplate.get('html').setValue(''); formTemplate.get('body').setValue('');" type="button"
          mat-raised-button color="primary" class="w-100" style="font-weight: 600">Borrar
          contenido</button>
      </div>
      <div class="col-md-12">
        <angular-editor [config]="editorConfig" formControlName="body"></angular-editor>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.body.errors" class="d-flex flex-column">
          <span *ngIf="error.body.errors.required">El campo es requerido</span>
          <span *ngIf="error.body.errors.onlySpaces">El campo debe contener caracteres</span>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveTemplate()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">{{ data.template === null ? 'Crear' : 'Guardar' }}</button>
      </div>
    </div>
    <input #inputFile type="file" id="inputFile" name="inputFile" style="display: none"
      (change)="onChangeFileUpload($event)" />
  </form>
</div>