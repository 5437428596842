<div class="container-mails vh-100" style="overflow-y: auto;">
  <div class="d-flex justify-content-between align-items-center py-1 px-3">
    <div class="d-flex">
      <mat-checkbox class="pe-2" [(ngModel)]="checkAllSelect" *ngIf="listMails.length > 0" color="primary"
        (change)="onClickSelectAllMails($event)"
        [disabled]="(keyTraySelected !== 'cerrados') ? false : true"></mat-checkbox>
      <div *ngIf="mailsSelected.length > 0" class="ms-1 mt-1 d-flex">
        <div *ngIf="keyTraySelected !== 'cerrados' && keyTraySelected !== 'eliminados'">
          <div *ngIf="keyTraySelected !== 'borrador' && keyTraySelected !== 'salida'">
            <span *ngIf="configEmailsSelected.length === 1" (click)="onClickShowMassiveManagmentEmails()"
              matTooltip="Gestión" class="fi-rr-settings px-1" style="cursor: pointer;"></span>
            <!-- <span *ngIf="configEmailsSelected.length === 1 && keyTraySelected !== 'enviado'" matTooltip="Asignar"
              (click)="onClickShowAssignEmail()" class="fi-rr-user px-1" style="cursor: pointer;"></span> -->
            <span *ngIf="configEmailsSelected.length === 1" (click)="onClickAssignToCategory(mailsSelected)"
              matTooltip="Categoría" class="fi-rr-flag px-1" style="cursor: pointer;"></span>
            <span *ngIf="configEmailsSelected.length === 1" matTooltip="Mover"
              class="fi-rr-data-transfer px-1" style="cursor: pointer;" (click)="onClickMoveEmail()"></span>
          </div>
        </div>
        <span *ngIf="keyTraySelected !== 'eliminados'" (click)="deleteEmailsSelected(mailsSelected)"
          matTooltip="Eliminar" class="fi-rr-trash px-1" style="cursor: pointer;"></span>
        <span *ngIf="keyTraySelected === 'eliminados'" (click)="restoreEmailsSelected(mailsSelected)"
          matTooltip="Restaurar" class="fi-rr-undo px-1" style="cursor: pointer;"></span>
      </div>
    </div>
    <div class="margin-range-labels-paginator padding-container-paginator mt-1">
      <mat-paginator [length]="configPaginator.length" [pageSize]="configPaginator.pageSize" [hidePageSize]="true"
        (page)="onChangePage($event)"></mat-paginator>
    </div>
  </div>
  <div *ngIf="listMails.length > 0; else notMails" class="mt-2">
    <div *ngFor="let category of listMails">
      <div class="border-top border-bottom px-3 py-2" style="font-weight: 700; font-size: 12px; color: #717171">{{
        category.time }}
      </div>
      <div *ngFor="let mail of category.mails; let last = last; let index = index"
        [ngClass]="last ? 'py-3' : 'py-3 border-bottom'"
        [ngStyle]="{'background-color': mailSelected !== null && mail.id === mailSelected.id ? '#F0F0F0' : '#FFFFFF' }">
        <div [ngStyle]="{ 'border-left': '4px solid' + mail.email_ans_color?.color }">
          <div class="d-flex justify-content-between px-3">
            <div class="d-flex">
              <mat-checkbox color="primary" [checked]="ifSelectedMail(mail)"
                (change)="onClickCheckboxMail($event, mail)"
                [disabled]="(keyTraySelected !== 'cerrados') ? false : true"></mat-checkbox>
              <div class="ms-2 text-truncate"
                style="font-weight: 700; font-size: 14px; cursor: pointer; max-width: 160px;"
                (click)="onClickSelectMail(mail)">{{
                mail.asunto !== "" ? mail.asunto : 'Sin asunto' }}</div>
            </div>
            <div style="font-weight: 400; font-size: 9px; color: #717171">
              {{ mail.created_at | date: 'short' }}
            </div>
          </div>
          <div class="px-3">
            <div class="text-truncate-email-info">{{ mail.de }}</div>
            <div style="font-weight: 400; font-size: 12px; color: #A9A9A9">
              <span style="font-weight: 700;">Usuario asignado: </span>
              {{ mail.name_user_asignado !== '' ? mail.name_user_asignado : 'Sin asignar' }}
            </div>
            <div class="d-flex justify-content-between">
              <div style="font-weight: 400; font-size: 12px; color: #A9A9A9"><span style="font-weight: 700;">ID
                  correo: </span>{{ mail.id }}</div>
              <div [ngStyle]="{ 'font-weight': '700', 'font-size': '12px', 'color': mail.email_state_color?.color }">
                {{ mail.state_email }}
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div *ngIf="keyTraySelected !== 'cerrados'" (click)="onClickAssignToCategory(mail)"><i
                  class="fi-rr-flag fs-6 me-1" [ngStyle]="{ 'color': mail.category_color, 'cursor': 'pointer' }"></i>
              </div>
              <div *ngIf="mail.cantida_adjuntos > 0"><i class="fi-rr-clip fs-6"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #notMails>
    <div class="h-75 d-flex justify-content-center align-items-center">
      <div style="font-weight: 700; font-size: 20px; color: #A9A9A9;">
        No hay correos registrados
      </div>
    </div>
  </ng-template>
</div>