import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { EmailService } from '../../services/email.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';
import { LabelComponent } from '../../components/label/label.component';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/shared/snack-bar-service/snack-bar.service';

const { GESTIONADOR_CORREOS } = environment;

@Component({
  selector: 'app-label-page-main',
  templateUrl: './label-page-main.component.html',
  styleUrls: ['./label-page-main.component.sass']
})
export class LabelPageMainComponent implements OnInit {
  labels: any[] = [];
  filter: string = null;
  filterSelect: number = null;
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Nombre de la etiqueta', key: 'name' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  userLogin: any = null;
  emailsConfig: any[] = [];

  constructor(
    private matDialog: MatDialog,
    private emailService: EmailService,
    private authService: AuthService,
    private crudService: CrudService,
    private alertSnackBar: SnackBarService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
    this.getLabels();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que trae las etiquetas registradas en el sistema. 
  */
  getLabels(): void {
    if (this.filterSelect !== 0) {
      this.crudService.getData(
        {
          url: `${GESTIONADOR_CORREOS}labels/showall`,
          paginator: this.configPaginator,
          filters: { name: this.filter, idAccountEmail: this.filterSelect }
        }).subscribe(res => {
          this.labels = res.data;
          this.configPaginator.length = res.total;
        })
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getLabels();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que actualiza el estado de una etiqueta. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateValueLabel($event: any): void {
    const setDataState = { ...$event.item, state: $event.state };
    this.crudService.putData({
      url: `${GESTIONADOR_CORREOS}labels/update/${$event.item.id}`,
      formData: setDataState
    }).subscribe(res => {
      this.alertSnackBar.showNotification({ message: res.message });
      this.getLabels();
    })
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que permite ejecutar la modal para crear o actualizar una etiqueta. 
    * @param label Etiqueta seleccionada de la tabla. 
  */
  onClickShowLabel(label: any = null): void {
    this.matDialog.open(LabelComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-template',
      data: {
        emailsConfig: this.emailsConfig.map(item => {
          return { value: item.id, text: item.name };
        }),
        label,
        url: GESTIONADOR_CORREOS
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getLabels();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getLabels();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que setea el filtro del select emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterSelect($event: number) {
    this.filterSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getLabels();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-31
    * Metodo que limpia los filtros. 
  */
  clearFilters(): void {
    this.filter = null;
    this.filterSelect = null;
    this.getLabels();
  }
}