import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { validateArrayNotEmpty, validateIsEmails } from '../../constantes/custom-validations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { ContactGroupService } from '../../services/contact-group.service';

@Component({
  selector: 'app-form-contact-group',
  templateUrl: './form-contact-group.component.html',
  styleUrls: ['./form-contact-group.component.css']
})
export class FormContactGroupComponent implements OnInit {
  @ViewChild('chipListMails') chipListMails: MatChipList;
  formContactGroup: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    email_list: this.formBuilder.array([], [validateArrayNotEmpty, validateIsEmails]),
    id_email_config: new FormControl('', [Validators.required])
  });
  separatorKeysCodes: number[] = [ENTER, COMMA];
  typeManagementContactGroup: string = null;

  constructor(
    private matDialogRef: MatDialogRef<FormContactGroupComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar,
    private contactGroupService: ContactGroupService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.contactGroup) {
      this.formContactGroup.get('id').setValue(this.data.contactGroup.id);
      this.formContactGroup.get('name').setValue(this.data.contactGroup.name);
      this.data.contactGroup.email_list.split(',').forEach((mail: string) => {
        const newFormGroup = this.formBuilder.group({ name: new FormControl(mail, [Validators.email]) });
        const arrayForm = this.formContactGroup.get('email_list') as FormArray;
        arrayForm.push(newFormGroup);
      });
      this.formContactGroup.get('email_list').statusChanges.subscribe(status => { this.chipListMails.errorState = status === 'INVALID'; });
      this.formContactGroup.get('id_email_config').setValue(this.data.contactGroup.id_email_config);
      if (this.data.onlyShow === true) {
        this.formContactGroup.get('name').disable();
        this.formContactGroup.get('id_email_config').disable();
      }
    }
    this.formContactGroup.get('email_list').statusChanges.subscribe(status => { this.chipListMails.errorState = status === 'INVALID'; });
    this.typeManagementContactGroup = this.data.contactGroup !== null ? 'editar' : 'crear';
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formContactGroup.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que agrega una cadena de texto a cada uno de los chip list.
    * @param event Manejador de eventos de los mat chip.
  */
  addMail($event: MatChipInputEvent): void {
    const input = $event.input;
    const value = ($event.value || '').trim();
    if (value && value !== '') {
      const newFormGroup = this.formBuilder.group({ name: new FormControl(value, [Validators.email]) });
      const arrayForm = this.formContactGroup.get('email_list') as FormArray;
      arrayForm.push(newFormGroup);
      if (input) input.value = '';
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que elimina una cadena de texto dentro un chip list.
    * @param index Cadena de texto seleccionada dentro del chip list. 
  */
  removeMail(index: number): void {
    const arrayForm = this.formContactGroup.get('email_list') as FormArray;
    arrayForm.removeAt(index);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que almacena o actualiza un grupo de contactos. 
  */
  onClickSubmitFormContactGroup(): void {
    if (this.formContactGroup.valid) {
      this.alertService.alertWarning('¿Estás seguro?', `¿En verdad deseas <b>${this.typeManagementContactGroup}</b> un grupo de contacto?`).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          const formValuesWithSplitMails = { ...this.formContactGroup.value, email_list: this.formContactGroup.value.email_list.map((mail: any) => mail.name).join() };
          this.contactGroupService.storeGroupContact(formValuesWithSplitMails).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: this.formContactGroup.value.id === null ? 'Se ha creado un nuevo grupo de contactos con éxito' : 'Se ha actualizado un grupo de contactos con éxito'
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formContactGroup.markAllAsTouched();
    }
  }
}