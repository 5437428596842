<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">{{ titlePage }}</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">{{ descriptionPage }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Cuenta de correo</mat-label>
        <mat-select (selectionChange)="onChangeSelectAccountEmail($event)" [(ngModel)]="advanceFilters.email_list_id">
          <mat-option *ngFor="let email of emailsConfig" [value]="email.id">{{ email.name
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Agente</mat-label>
        <mat-select (selectionChange)="onChangeSelectedAdviser($event)" [(ngModel)]="advanceFilters.id_user_asignado">
          <mat-option [value]="0">Correos sin asignar</mat-option>
          <mat-option *ngFor="let agent of agents" [value]="agent.id_rrhh">{{ agent.name_user_asignado }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Bandejas y carpetas</mat-label>
        <mat-select [(ngModel)]="advanceFilters.id_bandeja">
          <mat-optgroup *ngFor="let group of traysAndFolders" [label]="group.name">
            <mat-option *ngFor="let item of group.list" [value]="item.id"
              (onSelectionChange)="onChangeSelectTray($event, item.id)">
              {{ item.name }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row justify-content-between">
    <div class="col-md-2">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Estado</mat-label>
        <mat-select (selectionChange)="onChangeSelectState($event)" [(ngModel)]="advanceFilters.id_email_state">
          <mat-option *ngFor="let state of states" [value]="state.id">{{ state.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Rango de fechas</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input (dateChange)="onChangeFilterDateStart($event)" [(ngModel)]="advanceFilters.date_in" matStartDate
            placeholder="Fecha inicial">
          <input (dateChange)="onChangeFilterDateEnd($event)" [(ngModel)]="advanceFilters.date_end" matEndDate
            placeholder="Fecha final">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="col-md-7">
      <div class="row justify-content-end">
        <div class="col-md-3">
          <button (click)="getEmails(true)" color="primary" type="button" mat-raised-button class="w-100"
            style="height: 48px; font-weight: 600;">
            <span class="fi-rr-filter me-2"></span>
            Filtrar
          </button>
        </div>
        <div class="col-md-4">
          <button (click)="clearValuesAdvanceFilters()" type="button" mat-raised-button class="w-100"
            style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">
            <span class="fi-rr-filter me-2"></span>
            Limpiar filtros
          </button>
        </div>
        <div *ngIf="emailsTableSelected.length > 0" class="col-md-3">
          <button (click)="onClickOpenDialogManager()" type="button" mat-raised-button color="primary" class="w-100"
            style="height: 48px; font-weight: 600;">{{ buttonActionText }}</button>
        </div>
        <div
          *ngIf="showButtonMoveMassiveAccount && emailsTableSelected.length === 0 && advanceFilters.email_list_id !== null && advanceFilters.id_user_asignado !== null && advanceFilters.id_bandeja !== null"
          class="col-md-4"
          (click)="openDialogMoveEmailAccount(true)">
          <button type="button" mat-raised-button color="primary" class="w-100"
            style="height: 48px; font-weight: 600;">Movimiento masivo</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="col-md-12">
        <table mat-table [dataSource]="dataTable">
          <ng-container *ngFor="let column of configColumnsTable">
            <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
              <th mat-header-cell *matHeaderCellDef><mat-checkbox [(ngModel)]="checkAllEmails"
                  (change)="onChangeCheckboxAllSelected($event)" color="primary"></mat-checkbox></th>
              <td mat-cell width="10%" class="text-center ps-0" *matCellDef="let element">
                <mat-checkbox [disabled]="element.state_email === 'Cerrado' ? true : false"
                  (change)="onChangeCheckboxSelected($event, element)" [checked]="ifSelectedMail(element)" class="ms-4"
                  color="primary"></mat-checkbox>
                  &nbsp;
                  <button mat-button matTooltip="Ver mas" (click)="showEmailHistory(element)">
                    <i class="fi-rr-eye" ></i>
                  </button>
              </td>
            </ng-container>
            <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
              <th  mat-header-cell *matHeaderCellDef> {{ column.name }} </th>              
              <td  mat-cell [matTooltip]="element[column.key]" [matTooltipPosition]="'above'" *matCellDef="let element"> {{ element[column.key] | limitText:30 }} </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
          <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
          <tr class="mat-row" style="background: white" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="keysColumnsTable.length">No existe información relacionada</td>
          </tr>
        </table>
        <mat-paginator (page)="onChangePage($event)" [length]="configPaginator.length"
          [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
          [pageSizeOptions]="[5, 10, 20, 50, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>