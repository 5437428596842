<div class="row justify-content-between">
  <form [formGroup]="formFilters">
    <div class="row d-flex justify-content-end mt-5 w-100 ">

      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100 custom-form-field">
          <mat-label>Fecha</mat-label>
          <mat-date-range-input [rangePicker]="picker" [dateFilter]="myDateFilter">
            <input matStartDate formControlName="dateStart" placeholder="Fecha inicial">
            <input matEndDate formControlName="dateEnd" placeholder="Fecha final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error
            *ngIf="formFilters.get('dateStart').hasError('required') || formFilters.get('dateEnd').hasError('required')">Por
            favor, seleccione un rango de fechas</mat-error>
        </mat-form-field>
      </div>

      <div class="col-2" style="text-align: left;">
        <button class="w-100 heaight-buttons" [disabled]="!isAviableSearch" matTooltip='Buscar' (click)="searchSelection()"
          matTooltipPosition="above" mat-flat-button color="primary">
          Buscar &nbsp;<i class="fi-rr-search"></i>
        </button>
      </div>
      <div class="col-4"></div>
      <div class="col-3">
        <button class="w-100 heaight-buttons" mat-flat-button color="primary" (click)="downloadReport('strategy')"
          [disabled]="!selection.hasValue()">
          Descargar informe <i class="fi-rr-download"></i>
        </button>
      </div>

    </div>

  </form>

  <div class="table-container mt-5">
    <div class="mat-elevation-z1">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let column of displayedColumns ">
          <ng-container [matColumnDef]="column"
            *ngIf="column !== 'checbox' && column !== 'conversation' && column !== 'adherencia' && column !== 'analisis'  ">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeaders[column] }} </th>
            <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
          </ng-container>
          <ng-container [matColumnDef]="column" *ngIf="column === 'checbox'">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? selectAll() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggleSelection(element) : null"
                [checked]="selection.isSelected(element)"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="column" *ngIf="column === 'analisis'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeaders[column] }} </th>
            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element">
              <ng-container *ngIf="element.analisis  === 'PENDIENTE'">
                Pendiente
              </ng-container>
              <ng-container *ngIf="element.analisis  === 'ERROR'">
                <!-- Si el estado es error, mostrar el modal -->
                <button mat-icon-button style="width: 25px !important;" (click)="errorAnalyticModal()">
                  <span class="fi fi-sr-exclamation"></span>
                </button>
              </ng-container>
              <ng-container *ngIf="element.analisis  !== 'PENDIENTE' && element.analisis !== 'ERROR'">
                <!-- Si el estado no es pendiente, mostrar el botón de búsqueda -->
                <button mat-icon-button style="width: 25px !important;"
                  (click)="openAnalyticsModal(element.id)">
                  <span class="fi-rr-search"></span>
                </button> 
              </ng-container>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="column" *ngIf="column === 'conversation'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ tHeaders[column] }} </th>
            <td mat-cell width="8%" class="text-center ps-0" *matCellDef="let element" >
            <button  mat-icon-button style="width: 25px !important;" (click)="modalChatView(element.id)">
                <span class="fi-rr-comment"></span>
            </button>
            </td>
        </ng-container>

          <ng-container [matColumnDef]="column" *ngIf="column === 'adherencia'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ tHeaders[column] }}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.adherencia">
                <span (click)="AdherenciaModal(element.id)">{{ element[column] }}</span>
              </ng-container>
              <ng-container *ngIf="!element.adherencia">
                <span>No Aplica</span>
              </ng-container>
            </td>
          </ng-container>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr style="background: white" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" style="background: white" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">No existe información relacionada</td>
        </tr>
      </table>

      <mat-paginator (page)="changePage($event)" [length]="configPaginator.length"
        [pageSize]="configPaginator.pageSize" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>

    </div>
  </div>

</div>