import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipList, MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StateService } from '../../services/state.service';
import * as moment from 'moment';

@Component({
  selector: 'app-advance-filter-emails',
  templateUrl: './advance-filter-emails.component.html',
  styleUrls: ['./advance-filter-emails.component.css']
})
export class AdvanceFilterEmailsComponent implements OnInit {
  @ViewChild('chipListFrom') chipListFrom: MatChipList;
  @ViewChild('chipListTo') chipListTo: MatChipList;

  formFilters: FormGroup = new FormGroup({
    from: this.formBuilder.array([], [this.validateIsEmails]),
    to: this.formBuilder.array([], [this.validateIsEmails]),
    affair: new FormControl(null, [Validators.maxLength(100)]),
    containsWords: new FormControl(null, [Validators.maxLength(200)]),
    state: new FormControl(null),
    dateStart: new FormControl(null),
    dateEnd: new FormControl(null)
  });
  separatorKeysCodes: number[] = [ENTER, COMMA];
  states: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<AdvanceFilterEmailsComponent>,
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.formFilters.get('from').statusChanges.subscribe(status => { this.chipListFrom.errorState = status === 'INVALID'; });
    this.formFilters.get('to').statusChanges.subscribe(status => { this.chipListTo.errorState = status === 'INVALID'; });
    this.getStates();
    this.preloadFilters();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-28
    * Metodo que agrega una cadena de texto a cada uno de los chip list.
    * @param event Manejador de eventos de los mat chip.
    * @param typeList Nombre del list chip en el cual se van agregar una cadena de texto.  
  */
  addEmail($event: MatChipInputEvent, typeList: string): void {
    const input = $event.input;
    const value = ($event.value || '').trim();
    if (value && value !== '') {
      const newFormGroup = this.formBuilder.group({ name: new FormControl(value, [Validators.email]) });
      const arrayForm = this.formFilters.get(typeList) as FormArray;
      arrayForm.push(newFormGroup);
      if (input) input.value = '';
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-28
    * Metodo que elimina una cadena de texto dentro un chip list.
    * @param index Cadena de texto seleccionada dentro del chip list. 
    * @param typeList Nombre del chip list en el cual se va a remover el elemento.
  */
  removeEmail(index: number, typeList: string): void {
    const arrayForm = this.formFilters.get(typeList) as FormArray;
    arrayForm.removeAt(index);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-27
    * Metodo que permite enviar los filtros a la pagina principal. 
  */
  sendFilters(): void {
    if (this.formFilters.valid) this.matDialogRef.close(this.formFilters.value);
    else this.formFilters.markAllAsTouched();
  }
  /**
   * @author Fabian Duran
   * @createdate 2023-06-28
   * @param control Campo del formulario. 
 */
  validateIsEmails(control: FormArray): any {
    if (control.value) {
      const filterByStatus = control.controls.filter(field => field.status === 'INVALID');
      return filterByStatus.length > 0 ? { validateIsEmails: { valid: false } } : null;
    }
    return null;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-28
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formFilters.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que retorna los estados registrados en el sistema. 
  */
  getStates(): void {
    this.stateService.getStates().subscribe(res => {
      this.states = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-04
    * Metodo que precarga los filtros seleccionados.  
  */
  preloadFilters(): void {
    this.formFilters.get('affair').setValue(this.data.filters.asunto);
    this.formFilters.get('containsWords').setValue(this.data.filters.contiene_palabras);
    this.formFilters.get('state').setValue(this.data.filters.id_email_state);
    this.formFilters.get('dateStart').setValue(this.data.filters.date_in && moment(this.data.filters.date_in).format());
    this.formFilters.get('dateEnd').setValue(this.data.filters.date_end && moment(this.data.filters.date_end).format());
    if (this.data.filters.de && this.data.filters.de.length > 0) this.preloadFieldsList('from', this.data.filters.de);
    if (this.data.filters.para && this.data.filters.para.length > 0) this.preloadFieldsList('to', this.data.filters.para);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-04
    * Metodo que precarga los filtros en los campos tipo array.
    * @param nameField Nombre del campo. 
    * @param listValues Lista con los correos que se van a precargar.   
  */
  preloadFieldsList(nameField: string, listValues: string[]): void {
    const arrayForm = this.formFilters.get(nameField) as FormArray;
    listValues.forEach(item => {
      const newFormGroup = this.formBuilder.group({ name: new FormControl(item, [Validators.email]) });
      arrayForm.push(newFormGroup);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-04
    * Metodo que limpia los filtros del formulario. 
  */
  onClickClearFilters(): void {
    this.matDialogRef.close({ clearFilters: true });
  }
}