import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { HistoryService } from '../../../services/history.service';
import { ViewService } from '../../../services/view.service';
import { AdherenciaModalComponent } from '../../search-speech/adherencia-modal/adherencia-modal.component';
import { AnalyticsModalComponent } from '../../search-speech/analytics-modal/analytics-modal.component';
import { AudioPlayerComponent } from '../../search-speech/audio-player-modal/audio-player-modal.component';



@Component({
  selector: 'app-search-history-module',
  templateUrl: './search-history-module.component.html',
  styleUrls: ['./search-history-module.component.sass'],
})
export class SearchHistoryhModuleComponent implements OnInit {
  /**
   * Variables de paginacion
   */
  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 1 };

  /**
   * Variable de filtros
   */
  formFilters: FormGroup;
  isFiltersAdvancedActive: boolean = false;

  /**
   * Selectores
   */
  campaigns_list: any[] = [];
  groups: any[] = [];
  skills: any[] = [];
  tipifications: any[] = [];
  type_call: any[] = [{ type_call: 'Inbound' }, { type_call: 'Outbound' }];
  listStrategys: any[] = [];

  /**
   * Columnas de tablas
   */
  displayedColumns: string[] = [
    'checbox',
    'recording_id',
    'start_time',
    'user',
    'full_name',
    'phone_number',
    'user_group',
    'time_difference',
    'audio',
    'analisis',
    'adherencia',
  ];

  /**
   * Cabeceras de tablas
   */

  tHeaders: any = {
    checbox: '',
    recording_id: 'ID Caller',
    start_time: 'Fecha grabación',
    user: 'No. documento',
    full_name: 'Nombre agente',
    phone_number: 'Teléfono',
    user_group: 'Skill',
    time_difference: 'Tiempo',
    audio: 'Audio',
    analisis: 'Análisis',
    adherencia: 'Adherencia',
  };

  /**
   * Variables de seleccion
   */
  selection = new SelectionModel<any>(true, []);

  //selectores
  selectedSearch: Set<number> = new Set<number>(); //selest activos busqueda

  /**
   * Variables de tabla
   */
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<any>();

  strategyControl: FormControl = new FormControl(null);

  rrhh_id_user: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.filterUserCampaign();
    this.initFormFilters();
  }

  constructor(
    private historyService: HistoryService,
    private viewservice: ViewService,
    private dialog: MatDialog
  ) {}

  /**
   * Método que se encarga de inicializar los controles apra los filtros
   * @author Yeison Sepulveda
   * @createdate 02-04-2024
   */
  initFormFilters(): void {
    this.formFilters = new FormGroup({
      campaign_id: new FormControl(null, Validators.required),
      dateStart: new FormControl(null, Validators.required),
      dateEnd: new FormControl(null, Validators.required),
    });
  }

  /**
   * Metodo validar rango de fecha
   * @author Yeison sepulveda
   * @createdate 30-08-2024
   * @returns void {void} 
   */
  myDateFilter = (d: Date | null): boolean => {
    const today = new Date();
    return d ? d <= today : true;
  };

  /**
   * Método para limpiar la selección basado en un parámetro
   * @author Yeison Sepulveda
   * @param param
   */
  searchSelection() {
    this.configPaginator.pageIndex = 0;
    this.selectedSearch.clear();
    this.dataSource.data = [];
    this.paginator.firstPage();
    this.search();
  }

  /**
   * Método que determinao si los checbox estan seleccionados o no en la tabal de grabaciones
   * @author Juan David Guerrero Vargas
   * @returns {Boolean}
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Metodo para para seleccionar todos los elementos en la página actual
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  selectAll(): void {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach((row) => {
        this.selection.deselect(row);
        this.selectedSearch.delete(row.recording_id);
      });
    } else {
      this.dataSource.data.forEach((row) => {
        if (
          !this.selection.selected.some(
            (selectedItem: any) =>
              selectedItem.recording_id === row.recording_id
          )
        ) {
          this.selection.select(row);
          this.selectedSearch.add(row.recording_id);
        }
      });
    }
  }

  toggleSelection(row: any): void {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
      this.selectedSearch.delete(row.recording_id);
    } else {
      this.selection.select(row);
      this.selectedSearch.add(row.recording_id);
    }
  }

  /**
   * Recuperar los selec seleccionados en distintas paginas
   * @author Yeison Sepulveda
   * @param param
   */
  syncSelectionWithStored(param: string) {
    if (param === 'search') {
      this.selection.clear();
      this.dataSource.data.forEach((row) => {
        if (this.selectedSearch.has(row.recording_id)) {
          this.selection.select(row);
        }
      });
    }
  }

  /**
   * Metdodo que se encarga de obtener la cantidad de registros en la tabla
   * @author Juan David Guerrero Vargas
   */
  get getTotalDataSource(): number {
    return this.dataSource.data.length;
  }

  /**
   * Metodo que se encarga de identificar sis e peude o no relziar la consutla
   */
  get isAviableSearch(): boolean {
    let type_call = this.formFilters.get('campaign_id').value;
    let endDate = this.formFilters.get('dateEnd').value;
    if (
      type_call !== null &&
      type_call !== '' &&
      endDate !== null &&
      endDate !== ''
    ) {
      return true;
    }
    return false;
  }

  /**
   * Metodo trae las campañas asociadas al usuario
   * @author Yeison sepulveda
   * @createdate    06/05/2023
   * @returns void {void}
   */

  filterUserCampaign(): void {
    this.historyService.getCampaigns().subscribe(
      (res) => {
        this.campaigns_list = res.data;
      },
      (error) => {
        this.campaigns_list = [];
      }
    );
  }

  /**
   * Método para el consumo y busqueda por los selectores
   * @author Yeison Sepulveda
   * @createdate 2024-02-04
   */
  search(): void {
    const filters = {
      campaign_id: this.formFilters.get('campaign_id').value,
      start_time: this.formFilters.get('dateStart').value,
      end_time: this.formFilters.get('dateEnd').value,
    };

    this.historyService.searchDataPaginate(filters, this.configPaginator).subscribe(
      (response) => {
        const data = response.data.data.map((registro: any) => {
          const startTime = new Date(registro.start_time);
          const endTime = new Date(registro.end_time);
          const timeDifference = this.convertTimeToHHMMSS(startTime, endTime);
  
          const otherInformation = JSON.parse(
            registro.download.other_information
          );
  
          let adherencia;
  
          if (
            (registro.adherence_percentage === null || registro.adherence_percentage === "") &&
            (registro.not_adherence_percentage === null || registro.not_adherence_percentage === "")
          ) {
            adherencia = null;
          } else {
            adherencia = `${registro.adherence_percentage}% / ${registro.not_adherence_percentage}%`;
          }
  
          return {
            recording_id: registro.download.recording_id,
            start_time: otherInformation.date,
            user: otherInformation.user_call,
            full_name: otherInformation.user_soul,
            phone_number: otherInformation.phone,
            user_group: otherInformation.skill,
            time_difference: registro.download.time_duration,
            audio: registro.download.url_record,
            analisis: registro.state,
            adherencia: adherencia,
          };
        });
  
        this.dataSource.data = data;
        this.dataSource.sort = this.sort;
        this.configPaginator.length = response.data.total;
        this.syncSelectionWithStored('search');
      },
      (error) => {
        console.error('Error en la solicitud:', error);
      }
    );
  }
    
  /**
   * Método para convertir la diferencia de tiempo entre dos fechas en formato HH:mm:ss.
   * @param startTime La fecha de inicio.
   * @param endTime La fecha de fin.
   * @returns La diferencia de tiempo formateada en HH:mm:ss.
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
   */

  convertTimeToHHMMSS(startTime: Date, endTime: Date): string {
    const diferenciaMs = Math.abs(endTime.getTime() - startTime.getTime());
    const tiempoDiferencia = new Date(diferenciaMs);

    //extraer
    const horas = tiempoDiferencia.getUTCHours().toString().padStart(2, '0');
    const minutos = tiempoDiferencia
      .getUTCMinutes()
      .toString()
      .padStart(2, '0');
    const segundos = tiempoDiferencia
      .getUTCSeconds()
      .toString()
      .padStart(2, '0');

    return `${horas}:${minutos}:${segundos}`;
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * Método que pagina la informacion de la tabla.
   * @param $event Evento emitido por el paginador.
   */
  changePage($event: PageEvent): void {
    this.configPaginator.pageSize = $event.pageSize;
    this.configPaginator.pageIndex = $event.pageIndex + 1;
    this.search();
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * Metodo para abrir el modal reproductor audio
   */
  openAudioModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AudioPlayerComponent, {
      width: '800px',
      data: { recordingId: recording_id },
    });
  }

  /**
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * Metodo para abrir el modal de analisis
   */
  openAnalyticsModal(recording_id: any, origen: any): void {
    const campaign = this.formFilters.get('campaign_id').value;
    const dialogRef = this.dialog.open(AnalyticsModalComponent, {
      width: '800px',
      data: { recordingId: recording_id, campaignVicidial_id: campaign },
    });
  }

  /**
   * Método para abrir el modal de resultados de adherencia
   * @author Yeison Sepulveda
   * @createdate 2024-04-30
   */
  AdherenciaModal(recording_id: any): void {
    const dialogRef = this.dialog.open(AdherenciaModalComponent, {
      width: '800px',
      data: { recordingId: recording_id },
    });
  }
  /**
   * Método enviar mensaje swal de error de la grabación
   * @author Yeison Sepulveda
   * @createdate 2024-04-23
   */

  errorAnalyticModal() {
    Swal.fire({
      title: '¡Aviso!',
      text: 'Si la transcripción no está disponible, por favor verifica el audio, podría carecer de voz o ser solo tonos. ¡Gracias por tu atención! ',
      icon: 'warning',
      confirmButtonText: '¡Entendido!',
      confirmButtonColor: '#2CABBC',
    });
  }

	/**
	 * Método para tratar los datos que retorna el servicio y descargar el archivo.
	 * @param data data respondida por el servicio
	 * @param fileName nombre con el que se va a descargar el archivo.
	 * @param file_type Tipo del archivo 
	 * @author Juan Carlos Alonso
	 * @createdate 2021-07-27
	 * @updatedate 2022-12-13  
	 */
  fileTransition(data: any, fileName: string, file_type: string = ''): void {
    let blob = new Blob([data], { type: file_type });
    let downloadURL = file_type
      ? window.URL.createObjectURL(blob)
      : window.URL.createObjectURL(data);
    let link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   */
  downloadReport(type: 'analysis' | 'strategy') {
    let filters: any = {};

    if (type === 'analysis') {
      filters = {
        recording_ids: this.dataSource.data
          .filter((record) => record?.analisis === 'COMPLETADO')
          .map((record) => record['recording_id']),
        type: 1,
      };
    } else if (type === 'strategy') {
      filters = {
        recording_ids: this.selection.selected.map(
          (recording) => recording['recording_id']
        ),
        type: 2,
      };
    }

    this.viewservice.downloadReport(filters).subscribe(
      (response: any) => {
        this.fileTransition(response, 'reporte.xlsx');
      },
      (error) => {
        console.error('Error en la solicitud:', error);
      }
    );
  }
}
