<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">Asignar a una categoría</div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formAssignCategory">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Categoría a asignar</mat-label>
          <mat-select formControlName="category">
            <mat-option *ngFor="let item of categories" [value]="item.id">{{ item.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.category.errors">
            <span *ngIf="error.category.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-6">
        <button (click)="onSubmitFormAssignCategory()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Asignar</button>
      </div>
    </div>
  </form>
</div>