import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { delay } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PhoneService {
	private user;
	private server = environment.VICIDIAL_URL;

	constructor(
		private http: HttpClient,
		private authService: AuthService) {
			this.user = this.authService.getUser();
	}



	/**
	 * get user asterisk configs
	 * @returns
	 */
	getUserConfigs(campaign:number) {
		return this.http.get(`${this.server}v2/get-configs/${this.user.rrhh_id}/${campaign}`);
	}

  /**
	 * get user asterisk configs
	 * @returns
	 */
	verifyExten(userExtension:string) {
		return this.http.get(`${this.server}v2/user/verify_ext/${this.user.rrhh_id}/${userExtension}`);
	}



	/**
	 * Drop active conection if exists
	 * @returns
	 */
	markFreeConn() {
		return this.http.get(`${this.server}v2/mark-free/${this.user.rrhh_id}`);
	}



	/**
	 * Add mute time to call
	 * @param callId
	 * @param time
	 * @returns
	 */
	addMuteTime(callId:string , time:number) {
		return this.http.get(`${this.server}v2/call/muted_time/${callId}/${time}`);
	}

	/**
	 * Add mute time to call
	 * @param callId
	 * @param time
	 * @returns
	 */
	addHoldTime(callId:string , time:number) {
		return this.http.get(`${this.server}v2/call/hold_time/${callId}/${time}`);
	}


	/**
	 * Cange the call state
	 * @param callId
	 * @param stateId
	 * @returns
	 */
	changeCallState(callId:string,stateId:number){
		return this.http.get(`${this.server}v2/call/state/${callId}/${stateId}`);

	}

	/**
	 * Mark call as ended
	 * @param callId
	 * @returns
	 */
	endCall(callId:string, originator:string) {
		return this.http.get(`${this.server}v2/call/end/${callId}/${originator}`);
	}

	setUserToCall(userExtension:string,callId:string) {
		return this.http.get(`${this.server}v2/call/user_by_ext/${userExtension}/${callId}`);
	}

	/**
	 * Set conection start log
	 * @param userId  user id
	 */
	connetionLog(extension:string,campaignId:number){
		return this.http.get(`${this.server}v2/user/log-connection/${extension}/${campaignId}/CONNECT`);
	}
	/**
	 * Set conection start log
	 * @param userId  user id
	 */
	unConnetionLog(extension:string,campaignId:number){
		return this.http.get(`${this.server}v2/user/log-connection/${extension}/${campaignId}/UNCONNECT`);
	}

	/**
	 * Se transfer log call
	 * @param from
	 * @param to
	 * @param callId
	 */
	logTransferCall(data){
		return this.http.post(`${this.server}v2/call/log-transfer`,data);
	}

	searchCall(phone){
		return this.http.get(`${this.server}v2/call/get-by-phone/${phone}`);
	}

  getOperationTransfers(){
		return this.http.get(`${this.server}v2/transfers/${this.user.rrhh_id}`);
	}

  getOriginTransfer(callId){
		return this.http.get(`${this.server}v2/transfers/getOrigin/${callId}`);
	}
}
