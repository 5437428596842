import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {
  formState: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.maxLength(30)]),
    id_email_config: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required]),
    is_initial_state: new FormControl({ value: false, disabled: this.data.viewOnly }, []),
    day_green: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    hour_green: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    minutes_green: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    day_yellow: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    hour_yellow: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    minutes_yellow: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
  });

  constructor(
    private matDialogRef: MatDialogRef<StateComponent>,
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar,
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if (this.data.state) {
      this.formState.get('id').setValue(this.data.state.id);
      this.formState.get('name').setValue(this.data.state.name);
      this.formState.get('id_email_config').setValue(this.data.state.id_email_config);
      this.formState.get('is_initial_state').setValue(this.data.state.is_initial_state);
      this.formState.get('day_green').setValue(this.data.state.day_green);
      this.formState.get('hour_green').setValue(this.data.state.hour_green);
      this.formState.get('minutes_green').setValue(this.data.state.minutes_green);
      this.formState.get('day_yellow').setValue(this.data.state.day_yellow);
      this.formState.get('hour_yellow').setValue(this.data.state.hour_yellow);
      this.formState.get('minutes_yellow').setValue(this.data.state.minutes_yellow);
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formState.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que guarda o actualiza un estado. 
  */
  saveState(): void {
    if (this.formState.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          const addDataColorRed = {
            ...this.formState.value,
            day_red: this.formState.value.day_yellow + 1,
            hour_red: 0,
            minutes_red: 0
          };
          this.stateService.storeState(addDataColorRed).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: this.formState.value.id === null ? 'Se ha creado el estado con éxito' : 'Se ha actualizado el estado con éxito'
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formState.markAllAsTouched();
    }
  }
}