// import { CodeEditorModule } from '@ngstack/code-editor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MiosModule } from './mios/mios.module';

import { AuthModule } from './auth/auth.module';
import { BpmsModule } from './modules/bpms/bpms.module';
import { SableModule } from './modules/sable/sable.module';
import { CiuModule } from './modules/ciu/ciu.module';
import { ComprasModule } from './modules/compras/compras.module';
import { CrmModule } from './modules/crm/crm.module';
import { CrmModule2 } from './modules/crm_v2/crm.module';
import { NominaModule } from './modules/nomina/nomina.module';
import { RrhhModule } from './modules/rrhh/rrhh.module';
import { LivechatModule } from './modules/livechat/livechat.module';
import { JuridicaModule } from './modules/juridica/juridica.module';
import { CarteraWomModule } from './modules/cartera-wom/cartera-wom.module';

// Importacion componentes angular material
import { MaterialModule } from './shared/material/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorInterceptor } from './core/services/interceptor/error.interceptor';
import { LoaderInterceptor } from './core/services/interceptor/loader.interceptor';
import { SharedModule } from './shared/shared.module';
import { InterceptorInterceptor } from 'src/app/core/services/interceptor/interceptor.interceptor';
import { CalidadModule } from './modules/calidad/calidad.module';

import { AuditoriaModule } from './modules/auditoria/auditoria.module';

import { ReservasModule } from './modules/reservas/reservas.module';
import { CoachingModule } from './modules/coaching/coaching.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { SchedulesModule } from './modules/schedules/schedules.module';
import {PopoverModule} from "ngx-smart-popover";
import { ReportsModule } from './modules/reports/reports.module';
import { SoulBiModule } from './modules/soul_bi/soul_bi.module';
import { CrmCialModule } from './modules/crm-cial/crm-cial.module';
import { SpeechAnalyticsModule } from './modules/speech-analytics/speech-analytics.module';
import { MqttSoulModule } from './shared/mqtt/mqtt.module';
import { MeetingsModule } from './modules/meetings/meetings.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    PopoverModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MiosModule,
    AuthModule,
    BpmsModule,
    SableModule,
    CiuModule,
    ComprasModule,
    CrmModule,
    CrmModule2,
    NominaModule,
    RrhhModule,
    LivechatModule,
    CalidadModule,
    AuditoriaModule,
    CoachingModule,
    NgxSpinnerModule,
    HttpClientModule,
    SharedModule,
    JuridicaModule,
    ReservasModule,
    ReportsModule,
    SoulBiModule,
    CarteraWomModule,
    SpeechAnalyticsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: null,
        allowedDomains: [],
        disallowedRoutes: [],
      },
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({name:'Test'}),
    EffectsModule.forRoot([]),
    // CodeEditorModule.forRoot()
    CrmCialModule,
    MqttSoulModule,
    MeetingsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
