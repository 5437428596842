import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EmailService } from '../../services/email.service';
import { StateService } from '../../services/state.service';
import { MatDialog } from '@angular/material/dialog';
import { AssignEmailComponent } from '../assign-email/assign-email.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';
import { TrayService } from '../../services/tray.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MoveEmailTrayComponent } from '../move-email-tray/move-email-tray.component';
import { MoveEmailAccountComponent } from '../move-email-account/move-email-account.component';
import { ManagementHistoryViewComponent } from '../management-history-view/management-history-view.component';

@Component({
  selector: 'app-manager-list-emails',
  templateUrl: './manager-list-emails.component.html',
  styleUrls: ['./manager-list-emails.component.css']
})
export class ManagerListEmailsComponent implements OnInit {
  @Input() titlePage: string = '';
  @Input() descriptionPage: string = '';
  @Input() buttonActionText: string = '';
  @Input() typeManagerList: string = '';
  @Input() showButtonMoveMassiveAccount: boolean = false;
  advanceFilters: any = {
    email_list: null,
    email_list_id: null,
    id_user_asignado: null,
    id_email_state: null,
    date_in: null,
    date_end: null,
    id_bandeja: null,
    supervisor: true,
  };
  dataTable: any[] = [];
  emailsTableSelected: any[] = [];
  configColumnsTable: any[] = [
    { name: '', key: 'actions' },
    { name: 'Correo electrónico', key: 'para' },
    { name: 'Asunto', key: 'asunto' },
    { name: 'Estado', key: 'state_email' },
    { name: 'Bandeja', key: 'nombre_bandeja' },
    { name: 'Fecha - Hora', key: 'fecha_Hora' }

  ];
  configPaginator: any = { length: 0, pageIndex: 0, pageSize: 10 };
  keysColumnsTable: string[] = [];
  states: any[] = [];
  emailsConfig: any[] = [];
  agents: any[] = [];
  checkAllEmails: boolean = false;
  traysAndFolders: any[] = [];
  filterTraysAndFolders: any[] = [];

  constructor(
    private location: Location,
    private stateService: StateService,
    private emailService: EmailService,
    private matDialog: MatDialog,
    private authService: AuthService,
    private trayService: TrayService
  ) { }

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map(item => item.key);
    this.getEmailsConfigByRol();
    this.getStates();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que actualiza el filtro de cuenta de correo. 
    * @param $event Evento emitido por el campo.   
  */
  onChangeSelectAccountEmail($event: MatSelectChange): void {
    this.advanceFilters.email_list_id = $event.value;
    this.advanceFilters.id_user_asignado = null;
    this.advanceFilters.id_bandeja = null;
    this.emailService.getConfigEmailByUser(null, this.advanceFilters.email_list_id, 'false').subscribe(res => {
      this.agents = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que actualiza el filtro de estado. 
    * @param $event Evento emitido por el campo.   
  */
  onChangeSelectState($event: MatSelectChange): void {
    this.advanceFilters.id_email_state = $event.value;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que actualiza el filtro de fecha inicial. 
    * @param $event Evento emitido por el campo.   
  */
  onChangeFilterDateStart($event: MatDatepickerInput<Date>): void {
    this.advanceFilters.date_in = $event.value;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que actualiza el filtro de fecha final. 
    * @param $event Evento emitido por el campo.   
  */
  onChangeFilterDateEnd($event: MatDatepickerInput<Date>): void {
    this.advanceFilters.date_end = $event.value;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que trae los correos registrados en el sistema.  
  */
  getEmails(resetPage: boolean = false): void {
    this.checkAllEmails = false;
    this.emailsTableSelected = [];
    if (resetPage) this.configPaginator.pageIndex = 0;
    this.advanceFilters.email_list = `[${this.advanceFilters.email_list_id}]`;
    this.emailService.getEmails(this.configPaginator, this.advanceFilters).subscribe(res => {
      this.dataTable = res.data.data;
      this.configPaginator.length = res.data.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que actualiza la lista de correos seleccionados en la tabla.
    * @param $event Evento emitido por el checkbox. 
  */
  onChangeCheckboxAllSelected($event: MatCheckboxChange): void {
    this.emailsTableSelected = $event.checked ? this.dataTable.filter(item => item.state_email !== 'Cerrado') : [];
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que actualiza la lista de correos seleccionados en la tabla.
    * @param $event Evento emitido por el checkbox.
    * @param email Informacion del email seleccionado.  
  */
  onChangeCheckboxSelected($event: MatCheckboxChange, email: any): void {
    if ($event.checked) this.emailsTableSelected.push(email);
    else this.emailsTableSelected = this.emailsTableSelected.filter(item => item.id !== email.id);
    const totalEmailsCount = this.dataTable.filter(item => item.state_email !== 'Cerrado');
    this.checkAllEmails = this.emailsTableSelected.length === totalEmailsCount.length ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que valida si un correo esta o no dentro de la lista de correos seleccionados.
    * @param email Informacion del email seleccionado.  
  */
  ifSelectedMail(email: any): boolean {
    return this.emailsTableSelected.filter(item => item.id === email.id).length > 0 ? true : false;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que emite el cambio sobre la configuracion de la paginacion.
    * @param $event Evento emitido por el paginador. 
  */
  onChangePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getEmails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-26
    * Metodo que retorna los estados registrados en el sistema. 
  */
  getStates(): void {
    this.stateService.getStates().subscribe(res => {
      this.states = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-26
    * Metodo que limpia el valor de los filtros avanzados.  
  */
  clearValuesAdvanceFilters(): void {
    this.advanceFilters.email_list = null;
    this.advanceFilters.email_list_id = null;
    this.advanceFilters.id_user_asignado = null;
    this.advanceFilters.id_email_state = null;
    this.advanceFilters.date_in = null;
    this.advanceFilters.date_end = null;
    this.advanceFilters.id_bandeja = null;
    this.advanceFilters.supervisor = true;
    this.dataTable = [];
    this.configPaginator.pageIndex = 0;
    this.traysAndFolders = [];
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-26
    * Metodo que abre la modal para reasignar los correos o para mover correos entre bandejas o para mover correos a otras cuentas.  
  */
  onClickOpenDialogManager(): void {
    if (this.typeManagerList === 'reassignment-email') this.openDialogAssignEmail();
    else if (this.typeManagerList === 'move-email') this.openDialogMoveEmailTray();
    else if (this.typeManagerList === 'move-email-account') this.openDialogMoveEmailAccount();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que setea el filtro del asesor seleccionado.
    * @param $event Evento emitido por el select del formulario.  
  */
  onChangeSelectedAdviser($event: MatSelectChange): void {
    this.advanceFilters.id_user_asignado = $event.value;
    this.getTraysAndFoldersByUser();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que actualiza el filtro de bandeja. 
    * @param $event Evento emitido por el campo.
    * @param value Id de la bandeja seleccionada.    
  */
  onChangeSelectTray($event: MatOptionSelectionChange, value: number): void {
    this.advanceFilters.id_bandeja = value;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-26
    * Metodo que consulta las bandejas asignadas al usuario seleccionado.
  */
  getTraysAndFoldersByUser(): void {
    this.traysAndFolders = [];
    this.filterTraysAndFolders = [];
    this.trayService.getTraysByUser(this.advanceFilters.id_user_asignado, 1).subscribe(resTrays => {
      const trays = resTrays.data.map((item: any) => { return { id: item.id_bandeja, name: item.name, idEmailConfig: item.id_email_config } });
      this.traysAndFolders.push({ name: 'Bandejas', list: trays });
      if (this.showButtonMoveMassiveAccount) this.traysAndFolders[0].list = this.traysAndFolders[0].list.filter((item: any) => (item.name === 'Recibidos' || item.name === 'Gestión prioritaria') && item.idEmailConfig === 0);
      this.trayService.getTraysByUser(this.advanceFilters.id_user_asignado, 2).subscribe(resFolders => {
        const folders = resFolders.data.map((item: any) => { return { id: item.id_bandeja, name: item.name } });
        this.traysAndFolders.push({ name: 'Carpetas', list: folders });
        this.filterTraysAndFolders.push({ name: 'Bandejas', list: trays.filter((item: any) => item.idEmailConfig !== 0) });
        this.filterTraysAndFolders.push({ name: 'Carpetas', list: folders });
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-30
    * Metodo que muestra la modal para asignar un correo a un asesor.
  */
  openDialogAssignEmail(): void {
    this.matDialog.open(AssignEmailComponent, {
      width: '50%',
      autoFocus: false,
      panelClass: 'dialog-padding-email-reassignment',
      data: {
        title: 'Reasignación de correos',
        email: this.emailsTableSelected,
        isEmailReassignment: true,
        configEmailsSelected: this.advanceFilters.email_list_id
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.getEmails();
        this.emailsTableSelected = [];
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-30
    * Metodo que muestra la modal para mover un correo a una bandeja especifica.
  */
  openDialogMoveEmailTray(): void {
    const filterByIdTraysAndFolders = [... this.filterTraysAndFolders];
    filterByIdTraysAndFolders[0].list = filterByIdTraysAndFolders[0].list.filter((item: any) => item.id !== this.advanceFilters.id_bandeja);
    filterByIdTraysAndFolders[1].list = filterByIdTraysAndFolders[1].list.filter((item: any) => item.id !== this.advanceFilters.id_bandeja);
    this.matDialog.open(MoveEmailTrayComponent, {
      width: '50%',
      autoFocus: false,
      panelClass: 'dialog-padding-move-email',
      data: {
        traysAndFolders: filterByIdTraysAndFolders,
        emails: this.emailsTableSelected.map(item => item.id)
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.getTraysAndFoldersByUser();
        this.getEmails();
        this.emailsTableSelected = [];
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-30
    * Metodo que muestra la modal para mover un correo a una cuenta especifica.
    * @param massiveMoveEmails Determina si es masiva o no el movimiento de correos entre cuentas. 
  */
  openDialogMoveEmailAccount(massiveMoveEmails: boolean = false): void {
    const filterEmailsConfig = this.emailsConfig.filter(item => item.id !== this.advanceFilters.email_list_id);
    this.matDialog.open(MoveEmailAccountComponent, {
      width: '50%',
      autoFocus: false,
      panelClass: 'dialog-padding-move-email-account',
      data: {
        emails: this.emailsTableSelected.map((item: any) => item.id),
        emailsAccount: filterEmailsConfig,
        isMassive: massiveMoveEmails,
        idAccountEmailSelected: this.advanceFilters.email_list_id,
        idTraySelected: this.advanceFilters.id_bandeja
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.getTraysAndFoldersByUser();
        this.getEmails();
        this.emailsTableSelected = [];
      }
    });
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-09-09
   * Metodo que se encarga de abrir el detalle del correo
   * @param email correo seleccionado de la tabla.
  */
  showEmailHistory(email: any): void {
    this.emailService.getEmailById(email).subscribe(res => {
      this.matDialog.open(ManagementHistoryViewComponent, {
        width: '95%',
        maxHeight: '95vh',
        autoFocus: false,
        panelClass: 'dialog-padding-management-history-view',
        data: {
          management: res.data,
          toPreview: true,
          showButtonPrint: true,
          showButtonAddEmail: false,
          showDetails: true
        }
      });
    });
  }
}