import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DestroyComponentService } from 'src/app/core/services/utils/destroy-component.service';
import { RolesService } from '../../../services/rest/roles.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { SaveRolComponent } from '../save-rol/save-rol.component';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationsService } from '../../../services/rest/applications.service';

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.sass']
})
export class AdminRolesComponent implements OnInit, OnDestroy {

  // MatPaginator Inputs
  length;
  pageSize = 5;
  page = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  newRolComponent: boolean = false;

  displayedColumns: string[] = ['acciones', 'nameApp', 'name', 'key', 'created_at', 'creadoPor'];
  dataSource = new MatTableDataSource<any>();
  apps: any[] = [];
  filterApp: number | null = null;
  filterName: string | null = null;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public rolesService: RolesService,
              private destroyService: DestroyComponentService,
              private alertsService: AlertsService,
              public dialog: MatDialog,
              private applicationsService: ApplicationsService
            ) { }

  ngOnInit(): void {
    this.applicationsService.getApplications().subscribe(res => {
      this.apps = res.data;
    });
    this.getRoles(this.pageSize, this.page);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que trae los roles paginados
   * @param size cantidad de roles a traer en la tabla
   * @param page pagina que va a mostrar la tabla
   */
  getRoles(size: number, page: number): void {
    if (this.dataSource.filter === ''){
      this.dataSource.filter = null;
    }
    this.rolesService.getRoles(this.pageSize, this.page, this.filterApp, this.filterName).subscribe((Response) => {
      this.dataSource.data = Response.data.data;
      this.length = Response.data.total;
    });
  }
 /**
  * @author Daniel Martinez
  * @createdate 2021-02-04
  * Metodo que realiza el filtro
  * @param event datos para filtrar que son enviados desde el input
  */
  applyFilter(): void {
    // this.rolesService.searchRol(this.pageSize, this.filterApp, this.filterName).subscribe((Response) => {
    //   this.dataSource.data = Response.data.data;
    //   this.length = Response.data.total;
    // });
    this.page = 0;
    this.getRoles(this.pageSize, this.page);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que se encarga del movimiento del paginador
   * @param event cuando se da click en el paginador trae este evento
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.getRoles(this.pageSize, this.page);
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de crear un nuevo rol, al cerral el modal se refresca la tabla
   */
  newRol(): void{
    const dialogNewRol = this.dialog.open(SaveRolComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'crear',
        title: 'Crear rol',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.ngOnInit();
    });

  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que abre el modal de editar usuario, envia un usuario, al cerrar el modal se refresca la tabla
   * @param rol rol a editar
   */
  editRol(rol: any): void{
    const dialogNewRol = this.dialog.open(SaveRolComponent, {
      width: '80%',
      disableClose: true,
      data: {
        type: 'editar',
        title: 'Editar rol',
        rol
      },
      panelClass: 'custom-dialog-container',
    });

    dialogNewRol.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  /**
   * @author Daniel Martinez
   * @createdate 2021-02-04
   * Metodo que elimina un rol
   * @param rol rol a eliminar
   */
  deleteRol(rol: any): void{

    let value = this.alertsService.alertConfirm('¿Desea eliminar este rol?');
    value.then((result) =>{
      if (result.isConfirmed) {
        this.rolesService.deleteRol(rol.id).subscribe((resp) => {
          this.alertsService.alertSuccess('Eliminado', resp.data);
          this.ngOnInit();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyService.destroyComponent();
  }



}
