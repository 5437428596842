import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.css']
})
export class NewMessageComponent implements OnInit {
  typeManagerMessage: string = 'Enviar';
  constructor(
    private matDialogRef: MatDialogRef<NewMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.email !== null) this.typeManagerMessage = 'Enviar borrador';
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que es llamado cuando se envia un correo desde el componente hijo.
    * @param $event Evento emitido por el componente hijo.  
  */
  onCloseDialogWhenSubmitMessage($event: any): void {
    this.matDialogRef.close($event);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que es llamado cuando no se quiere guardar un correo en borradores.
  */
  onClickCancelButton(): void {
    this.matDialogRef.close();
  }
}