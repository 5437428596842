import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { CampaignInterface } from '../../interfaces/CampaignInterface';
import { DashboardFilterInterface } from '../../interfaces/DashboardFilter';
import { PositionInterface } from '../../interfaces/PositionInterface';
import { RrhhService } from '../../services/rrhh.service';
import {map, startWith} from 'rxjs/operators';
import { NominaService } from '../../services/nomina.service';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardInterface } from '../../interfaces/DashboardInterface';
import { DialogRole, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidatesService } from 'src/app/modules/rrhh/services/candidates.service';
import { UsersService } from 'src/app/modules/ciu/services/rest/users.service';
import { UserPermissionsInterface } from '../../interfaces/UserPermissionsInterface';
import { MatListItem, MatListOption, MatSelectionList } from '@angular/material/list';



@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.sass']
})
export class EditConfigurationComponent implements OnInit {
  form;
  findUserForm;
  campaings          : CampaignInterface[];
  filteredCampaigns  : Observable<CampaignInterface[]>;
  positions          : PositionInterface[];
  filteredPositions  : Observable<PositionInterface[]>;
  type_assignment    : any[] = [{id: 1, name: "Usuarios"} , {id: 2, name: "Campaña"} ]
  type_documents     : any = []
  usuariosPermisos   : UserPermissionsInterface[]   = []







  constructor(
    private fb                              : FormBuilder,
    private dialogRef                       : MatDialogRef<EditConfigurationComponent>,
    public rrhhService                      : RrhhService,
    public nominaService                    : NominaService,
    public dashService                      : DashboardService,
    public alertService                     : AlertsService,
    private candidateService                : CandidatesService,
    private userService                     : UsersService,
    @Inject(MAT_DIALOG_DATA) public data    : DashboardInterface,
     ) { }




  ngOnInit(): void {
    if(this.data == null){
      this.form = this.fb.group({
        nombre                  : ['', Validators.required],
        campaign_id             : ['', [Validators.required, this.requireMatch]],
        position_id             : ['', [Validators.required , this.requireMatch ]],
        key                     : ['', [Validators.required , Validators.pattern("^[a-zA-Z0-9_\-/]*$") ]],
        filters                 : this.fb.array([]),
        type_assignment         : [ '',Validators.required ],
        type_document           :[''],
        numero_documento        : [''],
        usuarios                : ['', [Validators.required, this.requireMatchUser]]
      });
    }
    else
    {


      this.form = this.fb.group({
        nombre                  : [this.data.nombre, Validators.required],
        campaign_id             : ['', [Validators.required, this.requireMatch]],
        position_id             : ['', [Validators.required , this.requireMatch ]],
        key                     : [this.data.key, [Validators.required ]],
        filters                 : this.fb.array([]),
        type_assignment         : [ '',Validators.required ],
        type_document           : [''],
        numero_documento        : [''],
        usuarios                : ['']
      });

      this.usuariosPermisos = this.data["user_permissions"];


      if(this.data.user_permissions.length > 0){
        this.form.get("type_assignment").setValue(1);
        this.typeChange(1);
        this.form.get("usuarios").setValue(this.usuariosPermisos.map( item => item.rrhh_id ) );
      }
      else
      {
        this.form.get("type_assignment").setValue(2);
        this.typeChange(2);
      }



      this.form.get("campaign_id").setValue( this.data.campaign_id );
      this.data.filters.forEach( f => this.addNewItem( f ) );
    }

  this.candidateService.getIdTypes().subscribe(resp => {
    this.type_documents = resp;
  });




    //Consulta campañas
    this.nominaService.getCampaings().subscribe( (campaings:CampaignInterface[]) => {
      this.campaings = campaings;
      this.filteredCampaigns = this.form.controls["campaign_id"].valueChanges.pipe(
        startWith(''),
        map((value:string) => this._filterCampaign(value)),
      );
    });


    //Consulta cargos
    this.rrhhService.getPositions().subscribe( (positions:PositionInterface[]) => {
      this.positions = positions;

      const position = this.positions.find( p => this.data ? p.name == this.data.position : null );
      if( position !== undefined ){
        this.data.position_id = position.id;
        this.form.get("position_id").setValue(this.data.position_id);
      }

      this.filteredPositions = this.form.controls["position_id"].valueChanges.pipe(
        startWith(''),
        map((value:string) => this._filterPosition(value)),
      );
    });

  }


  /**
   * Filtra campaña al evento onkeyrpress del autocomplete
   * @param id
   * @returns
   */
  private _filterCampaign(id:string): CampaignInterface[] {
    if (typeof id !== "string") return;
    const filterValue = id.toLowerCase();
    return this.campaings.filter(option => option.name.toLowerCase().includes(filterValue));
  }

    /**
   * Filtra cargo al evento onkeyrpress del autocomplete
   * @param id
   * @returns
   */
     private _filterPosition(id:string): PositionInterface[] {
      if (typeof id !== "string") return;
      const filterValue = id.toLowerCase();
      return this.positions.filter(option => option.name.toLowerCase().includes(filterValue));
    }

  /**
   * Muestra el nombre de la campaña al seleeccionarla en el autocomplete
   * @param id
   * @returns
   */
  displayCampaign( id) {
    if (!id) return '';

    let index = this.campaings.findIndex(campaign => campaign.id === id);
    return this.campaings[index].name;
  }


  /**
   * Muestra el nombre del cargo al seleeccionarla en el autocomplete
   * @param id
   * @returns
   */
     displayPosition( id) {
      if (!id) return '';

      let index = this.positions.findIndex(position => position.id === id);
      return this.positions[index].name;
    }


  addNewItem( filter: DashboardFilterInterface | null = null ){
    const controls = this.form.controls['filters'] as FormArray;
    let formGroup;
    if(filter == null){
      formGroup = this.fb.group({
        id      : [ '' ],
        column  : [ '' , Validators.required],
        value  : [ '' , Validators.required],
      });
    }
    else
    {

      formGroup = this.fb.group({
        id      : [ filter.id ],
        column  : [ filter.column, Validators.required],
        value  : [ filter.value, Validators.required],
      });

    }
  controls.push(formGroup);

  }






  removeItem(i: number) {
    const controls = <FormArray>this.form.controls['filters'];
    controls.removeAt(i);
  }

  requireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === "string") {
      return { incorrect: true };
    }
    return null;
  }

  requireMatchUser(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === "string" || (typeof selection === "object" && selection.length < 1)) {
      return { incorrect: true };
    }
    return null;
  }

  getFiltersControls(){
    return (this.form.get('filters') as FormArray).controls;
  }

  /**
   * Envía datos para almacenar el formulario
   */
  guardar(){

    if( this.form.get("type_assignment").value == 2 ){
      //dash.user_permissions = [];
      this.form.get("usuarios").setValue([]);
    }

    const dash:DashboardInterface = this.form.value;
    let action;

    if( dash.position_id.toString() !== ""  )
    {
      dash.position = this.positions.find(p => p.id == dash.position_id ).name
    }
    else
    {
      dash.position = null;
    }


    if( this.data == undefined ){
      //create
      action = this.dashService.guardar(dash);
    }
    else
    {
      action = this.dashService.actualizar(dash,this.data.id);
    }


    action.subscribe( result => {
        this.alertService.alertSuccess('¡Correcto!','Configuración almacenada correctamente');
        this.dialogRef.close();
      },
      error => {
        console.log(error);
        this.alertService.alertError("¡Atención!","No se almacenó el registro")
      }
    );
  }



  typeChange(type){
    if (type==2){
      this.form.get("campaign_id").setValidators([Validators.required, this.requireMatch]);
      this.form.get("position_id").setValidators([Validators.required, this.requireMatch]);
      this.form.get("usuarios").setValidators();
    }else{
      this.form.get("campaign_id").clearValidators();
      this.form.get("position_id").clearValidators();
      this.form.get("usuarios").setValidators([Validators.required, this.requireMatchUser]);
    }
    this.form.get("campaign_id").updateValueAndValidity();
    this.form.get("position_id").updateValueAndValidity();
    this.form.get("usuarios").updateValueAndValidity();


  }

  findPerson(  ){
    const n_documento = this.form.get("numero_documento").value;
    const type_document = this.form.get("type_document").value;

    this.userService.getUserByDocument(n_documento, type_document).subscribe((resp) => {
      if( this.usuariosPermisos.filter( e =>  e.rrhh_id == resp.data.id ).length  == 0 ){
        resp.data["document"] = resp.data["documento"];
        this.usuariosPermisos.push(resp.data);
        this.form.get("numero_documento").setValue("");
        this.form.get("type_document").setValue("");


        this.form.controls.usuarios.patchValue([
          ...this.usuariosPermisos.map((item) => item.rrhh_id)
        ]);



      }
    }) ;
  }




}
