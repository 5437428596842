import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class TrayService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que retorna las bandejas registradas en el sistema.
    * @param paginator Paginador configurado en la vista. 
    * @param filter Filtro configurado en la vista.
    * @param showAllTrays Muestra todas las bandejas activas en el sistema.   
    * @param filterEmail Filtro para la cuenta de correo seleccionado.
    * @param typeTray Tipo de bandeja que se quiere traer. 
  */
  getTrays(paginator: any = null, filter: string = '', showAllTrays: boolean = false, filterEmail: number = 0, typeTray: number = 1): Observable<any> {
    const paramState = showAllTrays ? '' : '&state=1';
    const paramEmail = filterEmail !== 0 ? filterEmail : '';
    const url = paginator === null ? `${GESTIONADOR_CORREOS}emailbox/showall?type=${typeTray}&id_email_config=${paramEmail}${paramState}` : `${GESTIONADOR_CORREOS}emailbox/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filter}&id_email_config=${paramEmail}&type=${typeTray}`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que guardar o actualiza una bandeja en el sistema. 
    * @param tray Informacion proveniente del formulario.  
  */
  storeTray(tray: any): Observable<any> {
    const url = tray.id === null ? `${GESTIONADOR_CORREOS}emailbox/store` : `${GESTIONADOR_CORREOS}emailbox/update/${tray.id}`;
    return this.httpClient.post(url, tray).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-27
    * Metodo que trae las bandejas asignadas a un usuario especifico. 
    * @param idRrhh Usuario seleccionado en el formulario. 
    * @param typeTray Tipo de bandeja a consultar.  
  */
  getTraysByUser(idRrhh: number, typeTray: number = 1, idEmailConfig: number = 0): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}userbox/showall?id_rrhh=${idRrhh}&type=${typeTray}&id_email_config=${idEmailConfig}`).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-27
    * Metodo que asigna una lista de bandejas a un usuario especifico. 
    * @param data Informacion proveniente del formulario. 
  */
  assignTraysForUser(data: any): Observable<any> {
    return this.httpClient.post(`${GESTIONADOR_CORREOS}userbox/store`, data).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-01
    * Metodo que retorna las bandejas registradas con las cantidades de correo dentro de ellas. 
    * @param idEmailConfig Correos seleccionados en configuracion de correos.   
  */
  getTraysByUserAndEmails(idEmailConfig: string): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}userbox/user_list_box?email_list=[${idEmailConfig}]`).pipe(
      catchError(this.handleError)
    );
  }
}