<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">Crear reunión</div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formMeeting" autocomplete="off">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select formControlName="emailAccount" (selectionChange)="getEventsByEmailSelected($event)">
            <mat-option *ngFor="let item of data.emailsConfig" [value]="item.id_email">{{ item.user_name_email_entrada
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.emailAccount.errors">
            <span *ngIf="error.emailAccount.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-2" *ngIf="showCalendar">
      <div class="col-md-12">
        <full-calendar [options]="calendarOptions" #calendar></full-calendar>
      </div>
    </div>
    <div class="row d-flex justify-content-end my-3" *ngIf="showCalendar">
      <div class="col-md-4">
        <button type="button" mat-raised-button color="primary" class="w-100" (click)="onClickButtonCancel(true)"
          style="height: 48px; font-weight: 600;">Registrar evento</button>
      </div>
    </div>
    <div class="row mb-3" *ngIf="eventTeamsSelected && showFormMeeting && allowManagement">
      <ng-container *ngFor="let item of buttonsManagerMeeting">
        <div *ngIf="item.show" class="col-md-1">
          <button (click)="onClickButtonsMeetingManager(item.name)" type="button" mat-button class="w-100"
            [ngStyle]="item.styles" [matTooltip]="item.name" matTooltipPosition='above'>
            <i [ngClass]="item.class"></i>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="row" *ngIf="showFormMeeting">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Titúlo y/o asunto</mat-label>
          <input matInput placeholder="Titúlo" formControlName="title">
          <mat-error *ngIf="error.title.errors">
            <span *ngIf="error.title.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Rango de fechas</mat-label>
          <mat-date-range-input [rangePicker]="picker" [min]="currentDate">
            <input matStartDate placeholder="Fecha inicial" formControlName="dateStart">
            <input matEndDate placeholder="Fecha final" formControlName="dateEnd">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="error.dateStart.errors || error.dateEnd.errors">
            <span *ngIf="error.dateStart.errors?.required || error.dateEnd.errors?.required">Por favor ingrese
              un rango de fecha</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Hora inicial</mat-label>
          <input type="time" matInput formControlName="timeStart">
          <mat-error *ngIf="error.timeStart.errors">
            <span *ngIf="error.timeStart.errors.required">El campo es requerido</span>
            <span *ngIf="error.timeStart.errors.timeRangeError">La hora inicial debe ser inferior a la
              final</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Hora final</mat-label>
          <input type="time" matInput formControlName="timeEnd">
          <mat-error *ngIf="error.timeEnd.errors">
            <span *ngIf="error.timeEnd.errors.required">El campo es requerido</span>
            <span *ngIf="error.timeEnd.errors.timeRangeError">La hora final debe ser superior a la
              inicial</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div
        [ngClass]="{'col-md-12': formMeeting.value.typeOfRecurrence === 'Ninguna', 'col-md-4': formMeeting.value.typeOfRecurrence === 'Diario' || (formMeeting.value.typeOfRecurrence === 'Mensual' && formMeeting.value.typeOptionDayMonth === ''), 'col-md-3': formMeeting.value.typeOfRecurrence !== 'Diario'}">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Tipo de recurencia</mat-label>
          <mat-select formControlName="typeOfRecurrence" (selectionChange)="onChangeTypeOfRecurrence($event)">
            <mat-option *ngFor="let item of typesRecurrence" [value]="item">{{ item
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.typeOfRecurrence.errors">
            <span *ngIf="error.typeOfRecurrence.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="formMeeting.value.typeOfRecurrence !== 'Mensual' && formMeeting.value.typeOfRecurrence !== 'Ninguna'"
        [ngClass]="{'col-md-4': formMeeting.value.typeOfRecurrence === 'Diario', 'col-md-3': formMeeting.value.typeOfRecurrence === 'Semanal'}">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Repetir cada</mat-label>
          <input formControlName="repeatEach" type="number" matInput placeholder="Repetir cada">
          <mat-error *ngIf="error.repeatEach.errors">
            <span *ngIf="error.repeatEach.errors.required">El campo es requerido</span>
            <span *ngIf="error.repeatEach.errors.min">Solo valores mayores a 0</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="formMeeting.value.typeOfRecurrence === 'Mensual'"
        [ngClass]="{'col-md-4': formMeeting.value.typeOptionDayMonth === '', 'col-md-3': formMeeting.value.typeOfRecurrence !== 'Diario'}">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Repetir cada</mat-label>
          <mat-select formControlName="typeOptionDayMonth" (selectionChange)="onChangeTypeOptionDayMonth($event)">
            <mat-option *ngFor="let item of typesOptionDayMonth" [value]="item">{{ item }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.typeOptionDayMonth.errors">
            <span *ngIf="error.typeOptionDayMonth.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="formMeeting.value.typeOfRecurrence === 'Semanal' || (formMeeting.value.typeOfRecurrence === 'Mensual' && formMeeting.value.typeOptionDayMonth === 'Día de la semana')"
        [ngClass]="{'col-md-6': formMeeting.value.typeOfRecurrence === '' || formMeeting.value.typeOfRecurrence === 'Diario', 'col-md-3': formMeeting.value.typeOfRecurrence !== 'Diario' }">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Dias de la semana</mat-label>
          <mat-select formControlName="days" multiple>
            <mat-option *ngFor="let item of daysOfWeek" [value]="item.value">{{ item.text }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.days.errors">
            <span *ngIf="error.days.errors.validateArrayDaysNotEmpty">Seleccione un día</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="formMeeting.value.typeOfRecurrence === 'Mensual' && formMeeting.value.typeOptionDayMonth === 'Día del mes'"
        [ngClass]="{'col-md-6': formMeeting.value.typeOfRecurrence === '' || formMeeting.value.typeOfRecurrence === 'Diario', 'col-md-3': formMeeting.value.typeOfRecurrence !== 'Diario' }">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Día del mes</mat-label>
          <input formControlName="recurrenceDaysInMonth" type="number" matInput placeholder="Repetir cada dia del mes">
          <mat-error *ngIf="error.recurrenceDaysInMonth.errors">
            <span *ngIf="error.recurrenceDaysInMonth.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="formMeeting.value.typeOfRecurrence !== 'Ninguna'"
        [ngClass]="{'col-md-4': formMeeting.value.typeOfRecurrence === 'Diario' || (formMeeting.value.typeOfRecurrence === 'Mensual' && formMeeting.value.typeOptionDayMonth === ''), 'col-md-3': formMeeting.value.typeOfRecurrence !== 'Diario'}">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Fecha limite</mat-label>
          <input formControlName="limitDate" matInput [matDatepicker]="picker" [min]="addDateToEndRangeDate()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="error.limitDate.errors">
            <span *ngIf="error.limitDate.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-11">
        <mat-form-field appearance="fill" class="w-100">
          <mat-chip-list #participants>
            <mat-chip *ngFor="let item of formMeeting.get('participants')?.controls; let i = index" [selectable]="true"
              [removable]="true" (removed)="removeItem(i, 'participants')">
              {{ item.value.name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Para*" #inputTo [matChipInputFor]="participants"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEmail($event, 'participants')">
          </mat-chip-list>
        </mat-form-field>
        <div style="font-size: 11px; color: #c62828" *ngIf="error.participants.errors">
          <span *ngIf="error.participants.errors.validateArrayNotEmpty">El campo es requerido</span>
          <span *ngIf="error.participants.errors.validateIsEmails">Correo invalido</span>
        </div>
      </div>
      <div class="col-md-1">
        <button type="button" mat-raised-button color="primary" class="w-100" style="height: 50px"
          (click)="onClickOpenDialogContacts('participants')">
          <i class="fi-rr-users fs-4"></i>
        </button>
      </div>
      <div class="col-md-12">
        <div class="my-1">
          <mat-checkbox formControlName="linkByTeams" color="primary">¿Es una reunión por Teams?</mat-checkbox>
        </div>
        <!--
        <ckeditor #editorPDF formControlName="description" [disabled]="isDisabledEditEditor"  [editor]="editorBody" [config]="configTextBody" ></ckeditor>
          -->
        
        <angular-editor [config]="editorConfig" formControlName="description"></angular-editor>
        

        <div style="font-size: 11px; color: #c62828" *ngIf="error.description.errors">
          <span *ngIf="error.description.errors.required">El campo es requerido</span>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-end mt-5" *ngIf="showFormMeeting">
      <div class="col-md-3">
        <button (click)="onClickButtonCancel()" type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div *ngIf="showFormMeeting && !eventTeamsSelected" class="col-md-3">
        <button (click)="saveOrUpdateMeeting()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Guardar</button>
      </div>
      <div *ngIf="showFormMeeting && eventTeamsSelected && !stateFieldsForm" class="col-md-3">
        <button (click)="saveOrUpdateMeeting('update')" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Actualizar</button>
      </div>
    </div>
  </form>
</div>