<h3>Agenda Llamadas</h3>

<br>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!-- Position Column -->
  <ng-container matColumnDef="client_name">
    <th mat-header-cell *matHeaderCellDef> Nombre Cliente</th>
    <td mat-cell *matCellDef="let element"> {{element.client_name}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="client_email">
    <th mat-header-cell *matHeaderCellDef> Email Cliente </th>
    <td mat-cell *matCellDef="let element"> {{element.client_email}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="client_nic">
    <th mat-header-cell *matHeaderCellDef> Nic Cliente </th>
    <td mat-cell *matCellDef="let element"> {{element.client_nic}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="client_observation">
    <th mat-header-cell *matHeaderCellDef> Observacion Cliente </th>
    <td mat-cell *matCellDef="let element"> {{element.client_observation}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Nombre Sala </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="scheduled_date">
    <th mat-header-cell *matHeaderCellDef> Fecha </th>
    <td mat-cell *matCellDef="let element"> {{element.scheduled_date}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10]"
             showFirstLastButtons
             aria-label="Select page of periodic elements">
  </mat-paginator>
