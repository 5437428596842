import { ChartOptions } from 'chart.js';
import 'chartjs-plugin-datalabels';

export const OPTIONS_PIE: ChartOptions<'pie'> = {
  plugins: {
    datalabels: {
      backgroundColor: function (_) {
        return 'rgba(0, 0, 0, 0.5)'; 
      },
      anchor: 'end',  
      align: 'start',
      offset: 10,
      color: 'white',
      font: {
        weight: 'bold',
        size: 14,
        family: '"Poppins", sans-serif'
      },
      formatter: (value) =>  `${Math.round(value * 100)}%`,
      borderRadius: 4,
      padding: 6
    },
    legend: {
      position: 'bottom',
      labels: {
        font: {
          size: 14,
          family: '"Poppins", sans-serif'
        }
      }
    },
    tooltip: {
      titleFont: {
        family: '"Poppins", sans-serif'
      },
      bodyFont: {
        family: '"Poppins", sans-serif'
      },
      callbacks: {
        label: function(tooltipItem) {
          let value = Number(tooltipItem.raw);
          if (isNaN(value)) return 'Invalid data';
          return `${Math.round(value * 100)}%`;
        }
      }
    }
  },
};