<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Reportes de correos</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">En este espacio podrás consultar y/o descargar los reportes de gestión.
        No <br /> olvides, “El respeto y empatía son clave”.</p>
    </div>
  </div>
  <div class="row d-flex justify-content-between mt-5">
    <div class="col-md-7">
      <form class="row" [formGroup]="formReport">
        <div class="col-md-5">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Cuenta de correo</mat-label>
            <mat-select formControlName="emailAccount">
              <mat-option *ngFor="let email of emailsConfig" [value]="email.id">{{ email.name
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.emailAccount.errors">
              <span *ngIf="error.emailAccount.errors.required">El campo es requerido</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Año</mat-label>
            <mat-select (selectionChange)="getMonths($event)" formControlName="year">
              <mat-option *ngFor="let item of years" [value]="item.year">{{ item.year }}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.year.errors">
              <span *ngIf="error.year.errors.required">El campo es requerido</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Mes</mat-label>
            <mat-select multiple formControlName="months">
              <mat-option *ngFor="let item of months" [value]="item.id">{{ item.nombre }}</mat-option>
            </mat-select>
            <mat-error *ngIf="error.months.errors">
              <span *ngIf="error.months.errors.required">El campo es requerido</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-1">
          <button (click)="generateReport()" type="button" mat-raised-button color="primary" class="w-100"
            style="height: 48px; font-weight: 600;">
            <span class="fi-rr-search fs-5"></span>
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-3">
      <button (click)="onClickDownloadReport()" type="button" mat-raised-button color="primary" class="w-100"
        style="height: 48px; font-weight: 600;">Descargar reporte</button>
    </div>
  </div>
  <div class="row justify-content-between mt-3">
    <div *ngFor="let card of cardsReport" [ngClass]="card.col">
      <div style="background-color: #FFFFFF; height: 120px; border-radius: 10px;"
        class="alert d-flex justify-content-between border">
        <div class="d-flex w-50 justify-content-center align-items-center">
          <i [ngStyle]="card.styleIcon" [ngClass]="card.icon"></i>
        </div>
        <div class="d-flex flex-column w-50 justify-content-center align-items-center">
          <div class="pb-2" [ngStyle]="card.styleValue">{{ card.value }}</div>
          <div [ngStyle]="card.styleText">{{ card.text }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row px-3">
    <div class="col-md-12 p-4 border" style="background-color: #FFFFFF; border-radius: 10px;">
      <canvas id="barChart" #barChart height="100"></canvas>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Asesor</mat-label>
        <mat-select (selectionChange)="onChangeFilterAdviser($event)">
          <mat-option *ngFor="let item of listToAdvisers" [value]="item.id_rrhh">{{
            item.name_user_asignado }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row px-1">
    <div class="col-md-12">
      <table mat-table [dataSource]="dataTable">
        <ng-container *ngFor="let column of configColumnsTable">
          <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
            <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
            <td mat-cell *matCellDef="let element"> {{ element[column.key] }} </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
        <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
        <tr class="mat-row" style="background: white" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="keysColumnsTable.length">No existe información relacionada</td>
        </tr>
      </table>
      <mat-paginator (page)="onChangePage($event)" [length]="configPaginator.length"
        [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]">
      </mat-paginator>
    </div>
  </div>
</div>