import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { StateComponent } from '../../components/state/state.component';
import { StateService } from '../../services/state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { EmailService } from '../../services/email.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';

@Component({
  selector: 'app-state-page-main',
  templateUrl: './state-page-main.component.html',
  styleUrls: ['./state-page-main.component.css']
})
export class StatePageMainComponent implements OnInit {
  states: any[] = [];
  filter: string = '';
  filterSelect: number = 0;
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'Nombre', key: 'name' },
    { name: 'A tiempo', key: 'green' },
    { name: 'Próximo a vencer', key: 'yellow' },
    { name: 'Vencido', key: 'red' }
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  emailsConfig: any[] = [];

  constructor(
    private matDialog: MatDialog,
    private stateService: StateService,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
    this.getStates();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que trae la estados registradas en el sistema. 
  */
  getStates(): void {
    this.stateService.getStates(this.configPaginator, this.filter, false, this.filterSelect.toString()).subscribe(res => {
      this.states = res.data.data.map((item: any) => {
        return {
          ...item,
          green: `Dias: ${item.day_green} - Horas: ${item.hour_green} - Minutos: ${item.minutes_green}`,
          yellow: `Dias: ${item.day_yellow} - Horas: ${item.hour_yellow} - Minutos: ${item.minutes_yellow}`,
          red: `Dias: ${item.day_red} - Horas: ${item.hour_red} - Minutos: ${item.minutes_red}`,
        }
      });
      this.configPaginator.length = res.data.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que setea el filtro emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilter($event: string) {
    this.filter = $event;
    this.configPaginator.pageIndex = 0;
    this.getStates();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que actualiza el estado de un estado. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeStateValueState($event: any): void {
    const setDataState = { ...$event.item, state: $event.state };
    this.stateService.storeState(setDataState).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.getStates();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que permite ejecutar la modal para crear o actualizar un estado. 
    * @param state Estado seleccionada de la tabla. 
    * @param titleDialog Titulo que va a tener la modal. 
    * @param viewOnly Modo de vizualizacion de la modal. 
  */
  onClickShowState(state: any = null, titleDialog: string = 'Crear estado', viewOnly: boolean = false): void {
    this.matDialog.open(StateComponent, {
      width: '50%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-edit-state',
      data: {
        titleDialog,
        state,
        viewOnly,
        emailsConfig: this.emailsConfig
      }
    }).afterClosed().subscribe(res => {
      if (res) this.getStates();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-17
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getStates();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-06
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-06
    * Metodo que setea el filtro del select emitido por el componente hijo. 
    * @param $event Evento emitido por el componente hijo.  
  */
  onChangeFilterSelect($event: number) {
    this.filterSelect = $event;
    this.configPaginator.pageIndex = 0;
    this.getStates();
  }
}