import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PhoneService } from '../vicidial-voz/services/phone.service';
import * as JsSIP from 'jssip';
import { ConnectingEvent, EndEvent, IncomingEvent, OutgoingEvent, PeerConnectionEvent, RTCSession, RTCSessionEventMap, SDPEvent } from 'jssip/lib/RTCSession';
import { CallOptions, RTCSessionEvent, IncomingMessageEvent, OutgoingMessageEvent } from 'jssip/lib/UA';
import { VicidialService } from '../vicidial-voz/services/vicidial.service';
import { Observable, of } from 'rxjs';
import { find, first, map, skipUntil } from 'rxjs/operators';
import { PhonePanelService } from './phone.service';



interface StatusInterface{
  id:number,
  name:string,
  class:string
}

interface AsteriskUser{
  server:string,
  username:string,
  name:string,
  password:string,
  survey_extension:string,
}

interface TimeRangeInterface{
  start: Date,
  end: Date
}

const CONN_STATUS:StatusInterface[] = [
  //Active status
  {id: 1 , name:"Conectado"  , class :"active" },
  {id: 2 , name:"Conectando"  , class :"warning" },
  {id: 3 , name:"Sin conexión"  , class :"error" },
  {id: 4 , name:"Registro falló"  , class :"error" },
  {id: 5 , name:"Sesión expiró"  , class :"error" }
]

const CALL_STATUS:StatusInterface[] = [
  {id: 1 , name:"En llamada" , class :"t-active" },
  {id: 2 , name:"Disponible" , class :"status-disponible" },
  {id: 3 , name:"Silenciado" , class :"status-silenciado" },
  {id: 4 , name:"Pausado" , class :"status-pausado" },
  {id: 5 , name:"Transfiriendo" , class :"status-transfiriendo" },
  {id: 6 , name:"Deteniendo" , class :"status-deteniendo" },
  {id: 7 , name:"Retomando" , class :"status-retomando" },
  {id: 8 , name:"Llamando..." , class :"status-retomando" },
]




@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.sass']
})


export class PhoneComponent implements OnInit, OnDestroy {

  @Input() enableConection: boolean = false;
  public conectionStatus    : StatusInterface = null;
  public callStatus         : StatusInterface = null;
  localStream               : MediaStream = null;
  inputDeviceList           : MediaDeviceInfo[];
  outputDeviceList          : MediaDeviceInfo[];
  userAgent                 : JsSIP.UA;
  currentSession            : RTCSession;
  outgoingSession           : RTCSession;
  incomingSession           : RTCSession;
  incomingStreams           : MediaStream[];
  incomingStream            : MediaStream;
  callId                    : string = null;
  numberPhone               : string = null;
  isVipClient               : boolean = false;
  user2user                 : object = null;
  holdTime                  : TimeRangeInterface = {start:null, end:null};
  muteTime                  : TimeRangeInterface = {start:null, end:null};
  showRemoteAudio           = false;
  remoteAudio               = new Audio();
  public numberOutputCall   = "";
  timeInCall:any[]       = [0,0,0];
  call_interval;
  campaignId:number = 1;
  ua$: Observable<JsSIP.UA>;

  transferCampaign: string;
  public userCampaigns;
  intervalVerifyExten;
  originTransfer;



  // Register callbacks to desired call events
  eventHandlers: Partial<RTCSessionEventMap> = {
  progress: (e: IncomingEvent | OutgoingEvent) => {
    console.log('%cCall is in progress', 'color:black;background-color:yellow', e);
    //this.openSnackBar('call is in progress');
  },
  failed: (e: EndEvent) => {
    //console.error('%cCall failed: ', e);
    console.error( e , e.message );
    //this.openSnackBar('call failed', 'confirmed');
  },
  ended: (e: EndEvent) => {
    console.log('%cCall ended : ', 'color:white;background-color:red', e);
    //this.openSnackBar('call ended', 'confirmed');
    // this.vicidialService.setInCall(false);
  },
  confirmed: (e: IncomingEvent | OutgoingEvent) => {
    console.log('%cCall confirmed', 'color:black;background-color:lightgreen', e);
    //this.openSnackBar('call is in progress', null, { duration: 2000 });
  },
  peerconnection: (e: PeerConnectionEvent) => {
    console.log('%cOn peerconnection', 'color:black;background-color:orange', e);
    //this.openSnackBar('on peerconnection', null, { duration: 3000 });
    console.log('state', e.peerconnection.getStats());
    e.peerconnection.ontrack = (ev: RTCTrackEvent) => {
      console.log('onaddtrack from remote - ', ev);
      this.remoteAudio.srcObject = ev.streams[0];
      this.remoteAudio.play();
      this.showRemoteAudio = true;
    };
  }

  };

  getConnectedDevices = async (type: string) => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (type === 'audioinput') {
      return this.inputDeviceList = devices.filter(device => device.kind === type);
    }
    if (type === 'audiooutput') {
      return this.outputDeviceList = devices.filter(device => device.kind === type);
    }
  }

  callOptions: CallOptions = {
    eventHandlers: this.eventHandlers,
    mediaConstraints: {
      audio: true,
      video: false
    },
      mediaStream: this.localStream
  };



  public user:AsteriskUser = {
    server:null,
    username:null,
    name:null,
    password:null,
    survey_extension:null
  };
  sipUA: JsSIP.UA;

    /**
   * Estado de minimizacion de la ventana
   */
    statusMinimize:boolean = false
    statusOpenClosePanel:boolean = false;

  constructor(
    private phoneService: PhoneService,
    private vicidialService: VicidialService,
    private phonePanelService:PhonePanelService,

  ) {
    this.phonePanelService.statusSeePanelSoulPhone.subscribe(
      (status:boolean)=>{
        this.statusOpenClosePanel = status;
        if(status == true){
          this.setConnectionStatus(2);
          this.setCallStatus(2);
        }

      }
    )
   }

  ngOnInit(): void {
    this.vicidialService.statusCiuObservable.subscribe(resp=>{
      if(resp[1] == undefined){
        //Unpause
        this.connect();

      }
      else
      {
        this.userAgent.unregister();
      }

    })
  }



  getUserConfigs(){
    if (this.enableConection == true){
      this.setConnectionStatus(2);
      this.phoneService.getUserConfigs(this.campaignId).subscribe( conf => {
        this.user.server    = conf["server"];
        this.user.username  = conf["extension"];
        this.user.name      = conf["name"];
        this.user.password  = conf["extension_pass"];
        this.user.survey_extension  = conf["survey_extension"];
        this.connect();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getUserConfigs();

  }

  /**
   * Set contection status
   * @param id: state id
   */
  private setConnectionStatus(id:number){
    this.conectionStatus = CONN_STATUS.find(st => st.id == id);
  }

  /**
   * Set contection status
   * @param id: state id
   */
  private setCallStatus(id:number){
    this.callStatus = CALL_STATUS.find(st => st.id == id);
  }

  connect():void{
    const socket = new JsSIP.WebSocketInterface(`wss://${this.user.server}/ws`);
    const configuration = {
      sockets               : [socket],
      outbound_proxy_set    : `wss://${this.user.server}/ws`,
      uri                   : `sip:${this.user.username}@${this.user.server}`,
      password              : this.user.password,
      register              : true,
      session_timers        : false,
      register_expires      : 3600
    };

    this.userAgent = new JsSIP.UA(configuration);

    this.userAgent.on('registered', (registeredEvent) => {
      console.log('registered: ', registeredEvent.response.status_code, ',', registeredEvent.response.reason_phrase);
      this.setConnectionStatus(1);
      this.vicidialService.setInCall(true);
      this.phoneService.connetionLog(this.user.username,this.campaignId).subscribe();
      this.verifyExten();
    });

    this.userAgent.on('registrationFailed', (unRegisteredEvent) => {
      this.setConnectionStatus(4);
      console.log('registrationFailed, ', unRegisteredEvent);
    });

    this.userAgent.on('registrationExpiring', () => {
      this.connect();
    });

    this.userAgent.on('unregistered', () => {
      this.phoneService.markFreeConn().subscribe();
      this.setConnectionStatus(3);
      this.vicidialService.setInCall(false);
      this.ngOnDestroy();
      console.warn('unregistered');
    });


    this.userAgent.on('newRTCSession', (sessionEvent: RTCSessionEvent) => {
      console.log('onNewRTCSession: ', sessionEvent);
      if (sessionEvent.originator === 'remote') { // incoming call

        this.incomingSession = sessionEvent.session;
        this.currentSession = this.incomingSession;
        console.log('remote stream', this.incomingStream);
        this.currentSession.answer({
          mediaConstraints: this.callOptions.mediaConstraints,
          mediaStream: this.callOptions.mediaStream
        });
        this.currentSession.connection.ontrack = (ev: RTCTrackEvent) => {
          console.log('onaddtrack from remote - ', ev);
          this.remoteAudio.srcObject = ev.streams[0];
          this.remoteAudio.play();
          this.showRemoteAudio = true;
        };

      } else {
        console.log('outgoingSession');
        this.outgoingSession = sessionEvent.session;
        this.outgoingSession.on('connecting', (event: ConnectingEvent) => {
          console.log('onConnecting - ', event.request);
          this.currentSession = this.outgoingSession;
          this.outgoingSession = null;
          console.log('call session', this.currentSession);
        });

      }
      sessionEvent.session.on('accepted', (event: IncomingEvent | OutgoingEvent) => {
        console.log('onAccepted - ', event);
        if (event.originator === 'remote' && this.currentSession == null) {
          this.currentSession = this.incomingSession;
          this.incomingSession = null;
          console.log('accepted setCurrentSession - ', this.currentSession);
        }
      });
      sessionEvent.session.on('ended', (event: EndEvent) => {
        this.setCallStatus(2);
        this.phoneService.endCall(this.callId, event.originator).subscribe();

        console.log('%conEnded - ', 'color:white;background-color:red', event);
        this.timeInCall = [0,0,0];
        clearInterval(this.call_interval);
        this.numberOutputCall = "";
        /*
        if (event.originator === 'remote') {
          this.currentSession = null;
          this.showRemoteAudio = false;
        }
          */
      })
      sessionEvent.session.on('confirmed', (event: IncomingEvent | OutgoingEvent) => {
        console.log('%conConfirmed - ', 'color:black;background-color:lightgreen', event);

        this.getOperationTransfers();
        if(event.originator == "local"){
          //Output call
          this.phoneService.searchCall(this.numberOutputCall).subscribe(call => {
            console.log(call);
            this.callId = call["id"];
            this.numberPhone = call["phone_number"];
            // this.userCampaigns = call["campaigns"];

            this.phoneService.setUserToCall(this.user.username,this.callId).subscribe();

          });
        }
        else{
          //Llamado de búsqueda en crm por el campo phone: La key del campo en db debe ser siempre "phone"
          this.callId  = event["ack"]["from"]["_display_name"];
          this.getOriginTransfer(this.callId);
          this.numberPhone = event["ack"]["from"]["_uri"]["_user"];
          this.phoneService.setUserToCall(this.user.username,this.callId).subscribe(resp=>{
            this.isVipClient = resp["isVip"];
            this.user2user = resp["user_to_user"];
            // this.getOperationTransfers();

            if (this.user2user && this.user2user['Cedula']){
              this.vicidialService.setCallResponse({documentNumber: this.user2user['Cedula']});
            }
          });

          // this.vicidialService.setInCall(true);
          this.vicidialService.setConversationId(this.callId);
        }
        this.setCallStatus(1);
        this.startTimer();
        if (event.originator === 'remote' && this.currentSession == null) {
          this.currentSession = this.incomingSession;
          this.incomingSession = null;
          console.log('%cconfirmed setCurrentSession - ', 'color:black;background-color:kightgreen', this.currentSession);
        }
      });
      sessionEvent.session.on('sdp', (event: SDPEvent) => {
        //console.log('onSDP, type - ', event.type, ' sdp - ', event.sdp);
      });

      sessionEvent.session.on('hold', (event) => {
        this.setCallStatus(6);
        setTimeout(() => {
          this.setCallStatus(4);
        }, 3000);
      });

      sessionEvent.session.on('unhold', (event) => {
        console.log("unholding....");
        this.setCallStatus(7);
        setTimeout(() => {
          this.setCallStatus(1);
        }, 3000);
      });

      sessionEvent.session.on('progress', (event: IncomingEvent | OutgoingEvent) => {
        //this.setConnectionStatus(5);
        console.log('%conProgress - ', 'color:black;background-color:yellow', event.originator);
        if (event.originator === 'remote') {
          console.log('%conProgress, response - ', 'color:black;background-color:yellow', event.response);
        }
      });
      sessionEvent.session.on('peerconnection', (event: PeerConnectionEvent) => {
        console.log('%conPeerconnection - ', 'color:black;background-color:orange', event.peerconnection);
      });
    });
    this.userAgent.start();
    console.log("Result: ",this.userAgent.status);
  }

  getOriginTransfer(callId) {
    this.phoneService.getOriginTransfer(callId).subscribe( resp => {
      this.originTransfer = resp['name'];
    });
  }

  getOperationTransfers() {
    this.phoneService.getOperationTransfers().subscribe( resp => {
      this.userCampaigns = resp;
    });
  }

  disconnect() {
    if (this.userAgent) {
      this.userAgent.stop();
      this.userAgent = null; // Cleanup
    } else {
      console.warn('No active SIP connection to close');
    }
  }

  verifyExten(){
    this.intervalVerifyExten = setInterval(() => {
      this.phoneService.verifyExten(this.user.username).subscribe( isVerify => {
        if(isVerify){
        }else {
          this.closeSession();
          setTimeout(() => {
            this.getUserConfigs();
          }, 2000);
        }
      });
    }, 20000);
  }



  /**
   * Mute and inmute active call
   */
  mute(){
    if (this.currentSession.isMuted().audio){
      this.currentSession.unmute();
      this.setCallStatus(1);
      this.muteTime.end = new Date();
      this.phoneService.addMuteTime(this.callId,this.timeDiffSeconds(this.muteTime)).subscribe();
      this.phoneService.changeCallState(this.callId,1).subscribe();
      this.muteTime = {start:null, end:null};
    }
    else
    {
      this.muteTime.start = new Date();
      this.currentSession.mute();
      this.setCallStatus(3);
      this.phoneService.changeCallState(this.callId,4).subscribe();

    }
  }


  /**
   * Hold current call
   */
  public hold(){
    if(this.currentSession.isOnHold().local){
      this.currentSession.unhold();
      this.holdTime.end = new Date();
      this.phoneService.addHoldTime(this.callId,this.timeDiffSeconds(this.holdTime)).subscribe();
      this.phoneService.changeCallState(this.callId,1).subscribe();
      this.holdTime = {start:null, end:null};

    }
    else
    {
      this.currentSession.hold();
      this.holdTime.start = new Date();
      this.phoneService.changeCallState(this.callId,2).subscribe();

    }

  }

  /**
   * Hungup current call
   */
  hungup(): void {
    this.userAgent.terminateSessions();
  }

  /**
   * Start a new call
   */
  call() :void{
    this.setCallStatus(8);
    this.connect();
    const sipPhoneNumber = `sip:1245${this.numberOutputCall}@${this.user.server}`;
    const options: CallOptions = this.callOptions;
    this.ua$ = of(this.userAgent);
    this.ua$.pipe(
      find(x => x.isRegistered())
    ).subscribe(() => {
      this.outgoingSession = this.userAgent.call(sipPhoneNumber, options);
    });


  }

  /**
   * Cierra la sesión remota
   */
  closeSession(){
    this.vicidialService.setInCall(false);
    this.hungup();
    this.userAgent.unregister();
    this.phoneService.unConnetionLog(this.user.username,this.campaignId).subscribe();
    this.ngOnDestroy();
    this.disconnect();
  }



  internalTransfer(){
    const numberTransfer = "69"+this.transferCampaign
    console.log(numberTransfer);
    this.transfer(numberTransfer);
  }
  /**
   * Session call transfer
   */
  public transfer(extension:string){
    const toUser = this.user.username;
    const phoneService = this.phoneService;

    // this.vicidialService.setInCall(false);
    this.setCallStatus(5);
    setTimeout(() => {
      this.userAgent.terminateSessions();
    }, 500);
    //clearInterval(transfer_interval);
    clearInterval(this.call_interval);


    console.log("Transfiriendo a.."+extension);
    this.currentSession.refer(extension,{
      'eventHandlers': {
        'accepted':function(e){
          const data = {
            from_extension:toUser,
            to_extension:extension,
            call_id:this.callId,
          }
          phoneService.logTransferCall(data).subscribe();
        },
        'failed':function(e){
          console.log('Transfer failed',e);
        },
    },
    });

  }

  public startTimer() {
    this.call_interval = setInterval(() => {
      this.timeInCall[2]++;
      if (this.timeInCall[2] === 60) {
        this.timeInCall[2] = 0;
        this.timeInCall[1]++;
        if (this.timeInCall[1] === 60) {
          this.timeInCall[1] = 0;
          this.timeInCall[0]++;
        }
      }
      this.timeInCall[0] = String(this.timeInCall[0]).padStart(2, '0'); // Hours
      this.timeInCall[1] = String(this.timeInCall[1]).padStart(2, '0'); // Minutes
      this.timeInCall[2] = String(this.timeInCall[2]).padStart(2, '0'); // Seconds
    }, 1000);
  }



  /**
   * Calculate the differemnce in seconds between start and end
   * @param timeRange
   * @returns
   */
  private timeDiffSeconds(timeRange:TimeRangeInterface): number {
    return Math.round((timeRange.end.getTime() - timeRange.start.getTime()) / 1000); // in seconds

  }

  /**
   * Metodo que se encarga de cambiar el estado de minimizacion del componente
   * @author Juan David Guerrero Vargas
   * @param state:boolean {boolean} Opcional estado de la minimizacion
   */
  public changeState(state?:boolean){
    if(state !== undefined){
      this.statusMinimize = state
    }
    this.statusMinimize = !this.statusMinimize
  }


  /**
   * Metodo que se encarga de desconectar al usuario y cerrar el panel de soulphone
   */
  colsePanelPhone(){
    this.closeSession();
    this.phonePanelService.setStatusSeePanelSoulPhone = false
  }

  ngOnDestroy(): void {
    if (this.intervalVerifyExten) {
      clearInterval(this.intervalVerifyExten);
    }
  }

}
