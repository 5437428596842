<div class="main">
  <div class="pn-lateral-56-24 mtn-64">
    <div class="col-12">
      <h1>Histórico de agendamientos</h1>
    </div>
    <div class="col-6">
      <p class="lh-base" style="color: #353535">
        Aquí podrás consultar el historial de agendamientos de videollamadas,
        junto con los detalles de cada una. No olvides, “El respeto y empatía son clave”.
      </p>
    </div>
    <form [formGroup]="formFilters">
      <div class="row d-flex justify-content-end mt-5 w-100 ">
        <div class="col-3">
  
          <mat-form-field class="w-100 custom-form-field" appearance="fill">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state">
                <mat-option *ngFor="let state of state_list" [value]="state.id">{{ state.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div class="col-md-3">
          <mat-form-field appearance="fill" class="w-100 custom-form-field">
            <mat-label>Fecha</mat-label>
            <mat-date-range-input [rangePicker]="picker" [dateFilter]="myDateFilter">
              <input matStartDate formControlName="dateStart" placeholder="Fecha inicial">
              <input matEndDate formControlName="dateEnd" placeholder="Fecha final">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error
              *ngIf="formFilters.get('dateStart').hasError('required') || formFilters.get('dateEnd').hasError('required')">Por
              favor, seleccione un rango de fechas</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field appearance="fill" class="w-100 custom-form-field">
            <mat-label>Nombre cliente</mat-label>
            <input matInput formControlName="name" placeholder="Ingrese el nombre del cliente">
            <mat-error *ngIf="formFilters.get('name').hasError('required')">Por favor, ingrese un nombre</mat-error>
          </mat-form-field>
        </div>
  
        <div class="col-1">
          <button class="me-3 heaight-buttons" matTooltip='Buscar' matTooltipPosition="above" mat-flat-button color="primary" (click)="searchSelection()">
            <i class="fi-rr-search"></i>
          </button>
        </div>
        
        <div class="col-1">
          <button class="me-3 heaight-buttons" matTooltip="Limpiar filtros" matTooltipPosition="above" mat-flat-button color="primary" (click)="resetFilters()">
            <i class="fi-rr-refresh"></i>
          </button>
        </div>

        <div class="col-1">
          <button class="me-3 heaight-buttons" matTooltip="Descargar informe" matTooltipPosition="above" mat-flat-button color="primary" (click)="downloadReport()">
            <i class="fi-rr-download"></i>
          </button>
        </div>
  
      </div>
    </form>
    
    <table class="mt-3" mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let column of configColumnsTable">
  
        <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column.name }} </th>
          <td mat-cell *matCellDef="let element"> {{ element[column.key] }} </td>
        </ng-container>
  
        <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column.name }} </th>
          <td mat-cell class="text-center" *matCellDef="let element">
            <button mat-icon-button style="width: 25px !important;" (click)="openViewHistoryDetails(element.id)"
              matTooltip="Detalle videollamada">
              <span class="fi-rr-eye"></span>
            </button>
          </td>
        </ng-container>
  
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
      <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
      <tr class="mat-row" style="background: white" *matNoDataRow>
      <td class="mat-cell" colspan="5">No se encontraron registros de videollamadas que coincidan con los criterios de búsqueda</td>
      </tr>
    </table>
  
    <mat-paginator (page)="changePage($event)" [length]="configPaginator.length"
      [pageSize]="configPaginator.pageSize" [pageSizeOptions]="pagesizeOptions">
    </mat-paginator>
  

  </div>
</div>
