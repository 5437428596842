import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailManagerRoutingModule } from './email-manager-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { EmailManagerComponent } from './email-manager.component';
import { EmailManagerMainComponent } from './pages/email-manager-main/email-manager-main.component';
import { LeftMenuMainComponent } from './components/left-menu-main/left-menu-main.component';
import { MailingListComponent } from './components/mailing-list/mailing-list.component';
import { LandingPageEmailManagerComponent } from './pages/landing-page-email-manager/landing-page-email-manager.component';
import { AdvanceFilterEmailsComponent } from './components/advance-filter-emails/advance-filter-emails.component';
import { NewMessageComponent } from './components/new-message/new-message.component';
import { MailFormComponent } from './components/mail-form/mail-form.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ContactGroupComponent } from './components/contact-group/contact-group.component';
import { MailViewComponent } from './components/mail-view/mail-view.component';
import { ManagementHistoryComponent } from './components/management-history/management-history.component';
import { ManagementHistoryViewComponent } from './components/management-history-view/management-history-view.component';
import { CategoryComponent } from './components/category/category.component';
import { ManagerMessageComponent } from './components/manager-message/manager-message.component';
import { TableContactGroupComponent } from './components/table-contact-group/table-contact-group.component';
import { ContactGroupMainComponent } from './pages/contact-group-main/contact-group-main.component';
import { FormContactGroupComponent } from './components/form-contact-group/form-contact-group.component';
import { AssignCategoryComponent } from './components/assign-category/assign-category.component';
import { StateComponent } from './components/state/state.component';
import { StatePageMainComponent } from './pages/state-page-main/state-page-main.component';
import { CategoryPageMainComponent } from './pages/category-page-main/category-page-main.component';
import { TrayPageMainComponent } from './pages/tray-page-main/tray-page-main.component';
import { TrayComponent } from './components/tray/tray.component';
import { AssignEmailComponent } from './components/assign-email/assign-email.component';
import { EmailPageMainComponent } from './pages/email-page-main/email-page-main.component';
import { EmailComponent } from './components/email/email.component';
import { AssignTraysUserComponent } from './pages/assign-trays-user/assign-trays-user.component';
import { AssignEmailUserComponent } from './pages/assign-email-user/assign-email-user.component';
import { MassiveManagementComponent } from './components/massive-management/massive-management.component';
import { AnsPageMainComponent } from './pages/ans-page-main/ans-page-main.component';
import { AnsComponent } from './components/ans/ans.component';
import { AssignPriorityComponent } from './components/assign-priority/assign-priority.component';
import { AssignPriorityPageMainComponent } from './pages/assign-priority-page-main/assign-priority-page-main.component';
import { SupervisorPageMainComponent } from './pages/supervisor-page-main/supervisor-page-main.component';
import { EmailReassignmentPageMainComponent } from './pages/email-reassignment-page-main/email-reassignment-page-main.component';
import { NewMessageV2Component } from './components/new-message-v2/new-message-v2.component';
import { HistoryAcordionMailComponent } from './components/history-acordion-mail/history-acordion-mail.component';
import { CreateMeetingComponent } from './components/create-meeting/create-meeting.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { TemplatesPageMainComponent } from './pages/templates-page-main/templates-page-main.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { ValidateEmailAccountComponent } from './components/validate-email-account/validate-email-account.component';
import { FolderPageMainComponent } from './pages/folder-page-main/folder-page-main.component';
import { AssignFolderUserComponent } from './pages/assign-folder-user/assign-folder-user.component';
import { ManagerListEmailsComponent } from './components/manager-list-emails/manager-list-emails.component';
import { MoveEmailToTraysComponent } from './pages/move-email-to-trays/move-email-to-trays.component';
import { MoveEmailTrayComponent } from './components/move-email-tray/move-email-tray.component';
import { MoveEmailToAccountComponent } from './pages/move-email-to-account/move-email-to-account.component';
import { MoveEmailAccountComponent } from './components/move-email-account/move-email-account.component';
import { SupervisorPageMainV2Component } from './pages/supervisor-page-main-v2/supervisor-page-main-v2.component';
import { ViewGraphStatesComponent } from './components/view-graph-states/view-graph-states.component';
import { EmailSupportComponent } from './pages/email-support/email-support.component';
import { LimitTextPipe } from './utils/pipes/limit-text.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SwiperModule } from 'swiper/angular';
import { SwiperReportTraysComponent } from './components/swiper-report-trays/swiper-report-trays.component';
import { SwiperReportMonthsComponent } from './components/swiper-report-months/swiper-report-months.component';
import { LabelPageMainComponent } from './pages/label-page-main/label-page-main.component';
import { LabelComponent } from './components/label/label.component';
import { ViewEmailsStateComponent } from './components/view-emails-state/view-emails-state.component';

FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin]);

@NgModule({
  declarations: [
    EmailManagerComponent,
    EmailManagerMainComponent,
    LeftMenuMainComponent,
    MailingListComponent,
    LandingPageEmailManagerComponent,
    AdvanceFilterEmailsComponent,
    NewMessageComponent,
    MailFormComponent,
    ContactGroupComponent,
    MailViewComponent,
    ManagementHistoryComponent,
    ManagementHistoryViewComponent,
    CategoryComponent,
    ManagerMessageComponent,
    TableContactGroupComponent,
    ContactGroupMainComponent,
    FormContactGroupComponent,
    AssignCategoryComponent,
    StateComponent,
    StatePageMainComponent,
    CategoryPageMainComponent,
    TrayPageMainComponent,
    TrayComponent,
    AssignEmailComponent,
    EmailPageMainComponent,
    EmailComponent,
    AssignTraysUserComponent,
    AssignEmailUserComponent,
    MassiveManagementComponent,
    AnsPageMainComponent,
    AnsComponent,
    AssignPriorityComponent,
    AssignPriorityPageMainComponent,
    SupervisorPageMainComponent,
    EmailReassignmentPageMainComponent,
    NewMessageV2Component,
    HistoryAcordionMailComponent,
    CreateMeetingComponent,
    TemplatesPageMainComponent,
    TemplatesComponent,
    ValidateEmailAccountComponent,
    FolderPageMainComponent,
    AssignFolderUserComponent,
    ManagerListEmailsComponent,
    MoveEmailToTraysComponent,
    MoveEmailTrayComponent,
    MoveEmailToAccountComponent,
    MoveEmailAccountComponent,
    SupervisorPageMainV2Component,
    ViewGraphStatesComponent,
    TemplatesPageMainComponent,
    TemplatesComponent,
    EmailSupportComponent,
    LimitTextPipe,
    SwiperReportTraysComponent,
    SwiperReportMonthsComponent,
    LabelPageMainComponent,
    LabelComponent,
    ViewEmailsStateComponent
  ],
  imports: [
    CommonModule,
    EmailManagerRoutingModule,
    SharedModule,
    FormsModule,
    MaterialModule,
    AngularEditorModule,
    FullCalendarModule,
    CKEditorModule,
    SwiperModule
  ],
  exports: [
    ManagementHistoryViewComponent,
    MailViewComponent,
    ManagerMessageComponent,
    MailFormComponent
  ]
})
export class EmailManagerModule { }
