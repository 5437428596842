import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoryService } from '../../services/category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  formCategory: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.maxLength(30)]),
    color: new FormControl({ value: '#ff0000', disabled: this.data.viewOnly }, []),
    id_email_config: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<CategoryComponent>,
    private matSnackBar: MatSnackBar,
    private categoryService: CategoryService,
  ) { }

  ngOnInit(): void {
    if (this.data.category) {
      this.formCategory.get('id').setValue(this.data.category.id);
      this.formCategory.get('name').setValue(this.data.category.name);
      this.formCategory.get('color').setValue(this.data.category.color);
      this.formCategory.get('id_email_config').setValue(this.data.category.id_email_config);
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formCategory.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que guarda o actualiza una categoria. 
  */
  saveCategory(): void {
    if (this.formCategory.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.categoryService.storeCategory(this.formCategory.value).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: this.formCategory.value.id === null ? 'Se ha creado la categoría con éxito' : 'Se ha actualizado la categoría con éxito'
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formCategory.markAllAsTouched();
    }
  }
}