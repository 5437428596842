import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertsService } from '../../../../shared/alerts/alerts.service';
import { VideollamadaService } from '../../services/videollamada.service';

@Component({
  selector: 'app-users-group',
  templateUrl: './users-group.component.html',
  styleUrls: ['./users-group.component.sass']
})
export class UsersGroupComponent implements OnInit, OnDestroy  {

  private destroy$ = new Subject<void>();

  userSearchControl = new FormControl();
  userSelected: any[] = [];
  userToGroupMeeting: any[] = [];
  filteredUsers: any[] = [];

  baseFirstList: any[] = [];
  baseSecondList: any[] = [];
  firstList: any[] = [];
  secondList: any[] = [];
  showExchangeLists: boolean = false;
  filteredFirstList: any[] = [];
  filteredSecondList: any[] = [];

  constructor(
    private alertService: AlertsService,
    private videollamadaService: VideollamadaService
  ) { }

  ngOnInit(): void {
    this.getUsersToRolSpeech();

    //maneja los cambios en el valor del campo de búsqueda de usuarios.
    this.userSearchControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterUsers(value)),
        takeUntil(this.destroy$)
      )
      .subscribe(filteredUsers => {
        this.filteredUsers = filteredUsers;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Metodo que filtra las listas a traves de una cadena de texto.
   * @author Yeison Seoulveda
   * @createdate 2024-06-12
   * @param filterValue Valor de filtro para buscar en los nombres de usuario.
   * @returns Arreglo de usuarios filtrados que coinciden con el filtro.
  */

  filterUsers(filterValue: string): any[] {
    const lowerCaseFilter = filterValue.toLowerCase();
    return this.userToGroupMeeting.filter(user =>
      user.candidate_name.toLowerCase().includes(lowerCaseFilter)
    );
  }

  /**
   * Función que devuelve el nombre del usuario para mostrar en la interfaz.
   * @author Yeison Seoulveda
   * @createdate 2024-06-12
   * @param user Objeto usuario del cual se desea mostrar el nombre.
   * @returns Nombre del usuario o cadena vacía si el usuario no está definido.
   */
  displayFn(user: any): string {
    return user ? user.candidate_name : '';
  }

  /**
   * Maneja el evento de clic en una opción de usuario.
   * @author Yeison Seoulveda
   * @createdate 2024-06-12
   * @param event Evento de clic generado por el usuario.
   * @param user Usuario seleccionado en la lista.
   */
  optionClicked(event: Event, user: any) {
    event.stopPropagation();
    this.toggleSelection(user);
  }

  /**
   * Agrega el usuario a la lista de seleccionados si está marcado,
   * @author Yeison Seoulveda
   * @createdate 2024-06-12
   * @param user Usuario que se desea marcar o desmarcar.
   */
  toggleSelection(user: any) {
    user.selected = !user.selected;
    if (user.selected) {
      this.userSelected.push(user);
    } else {
      const index = this.userSelected.findIndex(u => u.rrhh_id === user.rrhh_id);
      if (index >= 0) {
        this.userSelected.splice(index, 1);
      }
    }
  }

  /**
   * Metodo que filtra las listas a traves de una cadena de texto.
   * @author Yeison Sepulveda
   * @createdate 2024-11-07
   * @param $event Evento emitido por el componente hijo.  
  */

  runFilterList($event: any): void {
    if ($event.name === 'FIRST') {
      if ($event.value !== '') this.firstList = this.baseFirstList.filter(item => item.candidate_name.toLowerCase().includes($event.value.toLowerCase()));
      else this.firstList = this.baseFirstList;
    } else {
      if ($event.value !== '') this.secondList = this.baseSecondList.filter(item => item.candidate_name.toLowerCase().includes($event.value.toLowerCase()));
      else this.secondList = this.baseSecondList;
    }
  }

  /**
   * Metodo que trae las configuraciones de las listas del componente hijo.
   * @author Yeison Sepulveda
   * @createdate 2024-11-07
   * @param $event Evento emitido por el componente hijo.  
  */

  saveConfig($event: any): void {
    const dataAssignTrays = {
      id_rrhh: this.userSelected.map(u => u.rrhh_id),
      data: $event.secondList.map((item: any) => item.id),
    };
    this.alertService.alertWarning('¿Está seguro?', '¿De asociar los grupos al usuario seleccionado?').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        this.videollamadaService.saveGroupsUser(dataAssignTrays.id_rrhh, dataAssignTrays.data).subscribe(res => {
          this.alertService.alertSuccess('¡Excelente!', res.message);
          this.cancelProcess();
        });
      }
    });
  }

  /**
   * Metodo que emite la cancelacion del proceso. 
   * @author Yeison Sepulveda
   * @createdate 2024-11-07
   * @update Yeison Sepulveda
   * @updatedate 2024-09-10
  */
  cancelProcess(): void {
    this.userToGroupMeeting.forEach(user => {
      user.selected = false;
    });

    this.userSelected = [];
    this.showExchangeLists = false;
  }

  /**
   * Metodo que setea los items entre las listas. 
   * @createdate 2024-11-07
   * @author Yeison Sepulveda
   * @param $event Evento emitido por el componente hijo.   
  */
  updateList($event: any): void {
    if ($event.name === 'FIRST') {
      $event.list.forEach((itemList: any) => {
        this.secondList.push(itemList); 
        this.firstList = this.firstList.filter(item => item.id !== itemList.id); 
  
        this.baseFirstList = this.baseFirstList.filter(item => item.id !== itemList.id);
        if (!this.baseSecondList.some(item => item.id === itemList.id)) {
          this.baseSecondList.push(itemList);
        }
  
        this.filteredFirstList = this.filteredFirstList.filter(item => item.id !== itemList.id);
        this.filteredSecondList = [...this.secondList];
      });
    } else {
      $event.list.forEach((itemList: any) => {
        this.firstList.push(itemList);
        this.secondList = this.secondList.filter(item => item.id !== itemList.id); 
  
        this.baseSecondList = this.baseSecondList.filter(item => item.id !== itemList.id);
        if (!this.baseFirstList.some(item => item.id === itemList.id)) {
          this.baseFirstList.push(itemList);
        }
  
        this.filteredFirstList = [...this.firstList];
        this.filteredSecondList = this.filteredSecondList.filter(item => item.id !== itemList.id);
      });
    }
  }

  /**
   * Trae la informacion de los usuarios selccionados
   * @author Yeison Sepulveda 
   * @createdate 2024-05-22
  */
  getInfoUserSelection(): void {
    this.showExchangeLists = true;
    this.videollamadaService.getAssociateGroups(this.userSelected.map(u => u.rrhh_id)).subscribe(resByUser => {
      this.baseSecondList = resByUser;
      this.secondList = this.baseSecondList;
      this.videollamadaService.getAvailableGroups(this.userSelected.map(u => u.rrhh_id)).subscribe(resAllCampaigns => {
        this.baseFirstList = resAllCampaigns;
        this.baseSecondList.forEach(itemSecondList => {
          this.baseFirstList = this.baseFirstList.filter(itemFirstList => itemSecondList.id !== itemFirstList.id);
        });
        this.firstList = this.baseFirstList;
      });
    });
  }

  /**
   * Metodo que lista los usuarios que tienen el rol de meetings.
   * @author Yeison Sepulveda
   * @createdate 2024-11-07
  */
  getUsersToRolSpeech(): void {
    this.videollamadaService.getAdvisersList().subscribe(res => {
      this.userToGroupMeeting = res;
      this.filteredUsers = this.userToGroupMeeting;
    });
  }
  

}
