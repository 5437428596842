import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StrategyModalComponent } from '../strategy-modal/strategy-modal.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StrategyServersService } from '../../../services/strategy.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-strategy-menu',
  templateUrl: './strategy-menu.component.html',
  styleUrls: ['./strategy-menu.component.sass']
})
export class StrategyMenuComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 1 };
  pagesizeOptions: number[] = [5, 10, 20, 50, 100]

  keysColumnsTable: string[] = [];
  displayedColumns: string[] = ['actions', 'state', 'id', 'date', 'user_id', 'strategy_name', 'channelNames'];

  listChannel: any; //listado canales
  dataSource = new MatTableDataSource<any>(); 
  @ViewChild(MatSort) sort: MatSort;

  //firltro estrategia
  textInputFilter: string = '';
  filter: string = ''; 
  private pollingSubscription: Subscription;

  ngOnInit(): void {
    this.ListChannel();
    this.getStrategy();
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
    
    // Polling para ejecutar getStrategy cada minuto
    this.startPolling();
  }

  ngOnDestroy(): void {
    //destruir la suscripcion polling cuando el componente se destruya
    this.stopPolling();
  }

  constructor(
    private dialog:MatDialog,
    private strategyService: StrategyServersService
  ) { }

  /**
   * Método para iniciar el polling de datos.
   * @author Yeison Sepulveda
   * @createdate 2024-08-01
   * @returns {void}
  */

  startPolling(): void {
    this.pollingSubscription = interval(59000).subscribe(() => {
      this.getStrategy();
    });
  }

  /**
   * Método para detener el polling de datos.
   * @author Yeison Sepulveda
   * @createdate 2024-08-01
   * @returns {void}
  */

  stopPolling(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  /**
   * Método que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent): void {
    this.configPaginator.pageSize  = $event.pageSize;
    this.configPaginator.pageIndex  = $event.pageIndex + 1;
    this.getStrategy();
  }

  /**
   * Configuración para visualizar la informacion en la tabla
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */

  configColumnsTable: any[] = [
    { key: 'actions', name: 'Acciones' },
    { key: 'state', name: 'Estado' },
    { key: 'id', name: 'Id' },
    { key: 'date', name: 'Fecha creación' },
    { key: 'user_name', name: 'Usuario SOUL' },
    { key: 'strategy_name', name: 'Nombre estrategia' },
    { key: 'channelNames', name: 'Canal' },
  ]; 

  /**
   * Método lista las estartegias creadas
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */

  getStrategy(): void {
    this.strategyService.paginateStrategies(this.filter, this.configPaginator).subscribe(res => {
        // Mapea los IDs de los canales a los nombres
        res.data.data.forEach(strategy => {
            const channelIds = JSON.parse(strategy.channel);
            strategy.channelNames = channelIds.map(id => {
                const channel = this.listChannel.find(c => c.id === id);
                return channel ? channel.name : id;
            }).join(', '); // Convierte el array de nombres en un string separado por comas
        });

        // Asigna los datos mapeados a la fuente de datos
        this.dataSource.data = res.data.data;
        this.dataSource.sort = this.sort;
        this.configPaginator.length = res.data.total;
    });
}

  /**
   * Metodo para cargar la lista de estartegias 
   * @createdate 2024-04-08
   * @author Yeison Sepulveda
  */
  ListChannel(): void {
    this.strategyService.listChannel().subscribe(
      (response) => {
        this.listChannel = response.data;
      },
      (error) => {
        console.error('Error al cargar:', error);
      }
    );
  }

  /**
   * Método para modal editar
   * @author Yeison Sepulveda
   * @param {number} id - El ID de la estrategia
   * @createdate 2024-02-15
  */

  editStrategy(id: number): void {
    this.stopPolling();
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { id: id, modo: 'editar' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getStrategy();
      this.startPolling();
    });
  }

  /**
   * Metodo para abrir modal y crear la estrategia
   * @author Yeison Sepulveda
   * @createdate 2024-04-08
  */

  createdStrategy(): void{
    this.stopPolling();
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { modo: 'crear' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getStrategy();
      this.startPolling();
    });
  }
  
  /**
   * Abrir modal de estrategia
   * @author Yeison Sepulveda
   * @param {number} id - El ID de la estrategia
   * @createdate 2024-04-08
  */
  
  viewModal(id: number): void {
    this.stopPolling();
    const dialogRef = this.dialog.open(StrategyModalComponent, {
      width: '50%',
      maxHeight: '95vh',
      data: { id: id, modo: 'visualizar' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getStrategy();
      this.startPolling();
    });
  }

  /**
   * Método eliminar registro
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param {number} id - El ID de la estrategia a eliminar.
   * @returns {void}
  */

  deleteStrategy(id: number): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Estás seguro de eliminar la estrategia seleccionada?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.strategyService.desactiveStrategy({ id: id }).subscribe(
          response => {
            this.getStrategy();
            Swal.fire({
              title: '¡Excelente!',
              text: 'Se ha eliminado la estrategia',
              icon: 'success',
              timer: 3000, 
              showConfirmButton: false 
            });
          },
          error => {
            console.error('Error al desactivar la estrategia', error);
          }
        );
      }
    });
  }

  /**
   * Metodo que filtra los datos de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param {string} value - El valor ingresado en el campo de búsqueda.
   * @returns {void}
  */

  onSearchClick(value: string): void {
    this.filter = value;
    this.configPaginator.pageIndex= 0;
    this.paginator.firstPage();
    this.getStrategy();
  }

  /**
   * Metodo paraobtener el color correspondiente al estado de uso de la estrategia
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @param {boolean} state - El estado de actividad representado por un número (0 o 1).
   * @returns {string} - El color correspondiente al estado de actividad 
   */

  getColor(state: number): string {
    switch (state) {
      case 0:
        return '#F25A1B';   
      case 1:
        return '#1BF26C';   
      default:
        return 'gray';     
    }
  }

  /**
   * Metodo cambiar de estado el toggle
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
  */
  toggleStateChanged(element: any): void {
    let confirmationMessage = '';
    if (!element.active) {
      confirmationMessage = '¿Estás seguro de deshabilitar la estrategia?';
    } else {
      confirmationMessage = '¿Estás seguro de habilitar la estrategia?';
    }
  
    Swal.fire({
      title: '¿Estás seguro?',
      icon: 'warning',
      text: confirmationMessage,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.changeState(element);
      } else {
        element.active = !element.active;
      }
    });
  }
  
  /**
   * Metodo actualizar el estado de la campaña
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
   * 
  */
  changeState(element: any): void {
    const formdata = { id: element.id };
    this.strategyService.desactiveStrategy(formdata).subscribe(
      (resp) => {
      let confirmationMessage = resp.data.message;
      Swal.fire({
        icon: 'success',
        title: '¡Excelente!',
        text: confirmationMessage,
        timer: 3000, 
        showConfirmButton: false 
      });
    },
    (error) => {
      console.error('Error al cambiar de estado', error.message)
        Swal.fire({
          icon: 'error',
          title: 'No se pudo cambiar el estado',
          text: error.message,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#2CABBC',
        }).then(() => {
          this.getStrategy();
        });
    });
  }
  
}
