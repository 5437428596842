import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Chart, registerables, ChartDataset } from 'chart.js';
import { MONTHS, OPTIONS } from '../../constantes/create-data-chart';
import { PageEvent } from '@angular/material/paginator';
import { EmailService } from '../../services/email.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';

@Component({
  selector: 'app-supervisor-page-main',
  templateUrl: './supervisor-page-main.component.html',
  styleUrls: ['./supervisor-page-main.component.css']
})
export class SupervisorPageMainComponent implements OnInit {
  formReport: FormGroup = new FormGroup({
    emailAccount: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    months: new FormControl([], [Validators.required])
  });
  cardsReport: any[] = [
    { icon: 'fi-sr-envelope fs-1', value: 0, text: 'Correos', col: 'col-sm-4', styleText: { 'font-size': '24px' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-rr-arrow-left fs-1', value: 0, text: 'Entrantes', col: 'col-sm-4', styleText: { 'font-size': '24px' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-rr-arrow-right fs-1', value: 0, text: 'Salientes', col: 'col-sm-4', styleText: { 'font-size': '24px' }, styleValue: { 'color': '#51B0CB', 'font-size': '32px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '64px' } },
    { icon: 'fi-rr-time-quarter-to fs-1', value: 0, text: 'Pendientes', col: 'col-sm-4', styleText: { 'font-size': '16px' }, styleValue: { 'color': '#51B0CB', 'font-size': '24px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '32px' } },
    { icon: 'fi-sr-layers fs-1', value: 0, text: 'En gestión', col: 'col-sm-4', styleText: { 'font-size': '16px' }, styleValue: { 'color': '#51B0CB', 'font-size': '24px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '32px' } },
    { icon: 'fi-rr-check fs-1', value: 0, text: 'Cerrados', col: 'col-sm-4', styleText: { 'font-size': '16px' }, styleValue: { 'color': '#51B0CB', 'font-size': '24px', 'font-weight': '700' }, styleIcon: { 'color': '#51B0CB', 'font-size': '32px' } },
  ];
  ctx: any = null;
  canvas: HTMLCanvasElement = null;
  barChart: Chart = null;
  dataTable: any[] = [];
  configColumnsTable: any[] = [
    { name: 'Asesor', key: 'name' },
    { name: 'Pendientes', key: 'pendientes' },
    { name: 'En gestión', key: 'en_gestion' },
    { name: 'Cerrados', key: 'cerrados' },
    { name: 'Total', key: 'total' },
    { name: 'Por enviar', key: 'por_enviar' },
    { name: 'Enviados', key: 'enviados' },
  ];
  configPaginator: any = { length: 0, pageIndex: 0, pageSize: 5 };
  keysColumnsTable: string[] = [];
  emailsConfig: any[] = [];
  years: any[] = [];
  months: any[] = [];
  listToAdvisers: any[] = [];
  adviserSelected: string = '';

  constructor(
    private location: Location,
    private emailService: EmailService,
    private authService: AuthService,
  ) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
    this.drawBarChart();
    this.keysColumnsTable = this.configColumnsTable.map(item => item.key);
    this.getYears();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-24
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que se ejecuta al hacer click sobre el boton descargar reporte. 
  */
  onClickDownloadReport(): void {
    if (this.formReport.valid) {
      const formatDataForm = { ...this.formReport.value, months: this.formReport.value.months.toString() };
      this.emailService.downloadExcelReport(formatDataForm).subscribe(res => {
        importedSaveAs(res, 'Reporte');
      });
    } else {
      this.formReport.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-24
    * Metodo que pinta el grafico de barras. 
  */
  drawBarChart(): void {
    this.canvas = <HTMLCanvasElement>document.getElementById('barChart');
    this.ctx = this.canvas.getContext('2d');
    this.barChart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: MONTHS,
        datasets: []
      },
      options: OPTIONS
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-25
    * Metodo que emite el cambio sobre la configuracion de la paginacion.
    * @param $event Evento emitido por el paginador. 
  */
  onChangePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getTableReport();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-14
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formReport.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-14
    * Metodo que genera el reporte sobre la vista.  
  */
  generateReport(): void {
    if (this.formReport.valid) {
      this.getGraphReport();
      this.getTableReport();
    } else {
      this.formReport.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-18
    * Metodo que trae los años registrados en las gestiones de correo. .  
  */
  getYears(): void {
    this.emailService.getYearsToReport().subscribe(res => {
      this.years = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-18
    * Metodo que trae los meses registrados en las gestiones de correo realizados en el año seleccionado.
    * @param $event Evento emitido por el campo select. 
  */
  getMonths($event: MatSelectChange): void {
    this.emailService.getMonthsByYear($event.value).subscribe(res => {
      this.months = res.data.length > 0 ? res.data.sort((a: any, b: any) => {
        return a.id - b.id;
      }) : [];
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-19
    * Metodo que trae la informacion para generar el reporte en la grafica.
  */
  getGraphReport(): void {
    const formatDataForm = { ...this.formReport.value, months: this.formReport.value.months.toString() };
    this.emailService.getGraphReport(formatDataForm).subscribe(res => {
      this.cardsReport[0].value = res.data.correos;
      this.cardsReport[1].value = res.data.entradas;
      this.cardsReport[2].value = res.data.salientes;
      this.cardsReport[3].value = res.data.pendientes;
      this.cardsReport[4].value = res.data.en_gestion;
      this.cardsReport[5].value = res.data.cerrados;
      if (!Array.isArray(res.data.grafica)) {
        const labels = [];
        const datasetPendientes: ChartDataset = { label: 'Pendientes', backgroundColor: '#D14A4A', data: [] };
        const datasetEnGestion: ChartDataset = { label: 'En gestión', backgroundColor: '#D9A847', data: [] };
        const datasetCerrados: ChartDataset = { label: 'Cerrados', backgroundColor: '#60BF66', data: [] };
        for (const keyA in res.data.grafica) {
          labels.push(keyA);
          const objectMonth = res.data.grafica[keyA];
          for (const keyB in objectMonth) {
            if (keyB === 'Abierto') datasetPendientes.data.push(objectMonth[keyB]);
            if (keyB === 'En gestión') datasetEnGestion.data.push(objectMonth[keyB]);
            if (keyB === 'Cerrado') datasetCerrados.data.push(objectMonth[keyB]);
          }
        }
        this.barChart.data.labels = labels;
        this.barChart.data.datasets = [datasetPendientes, datasetEnGestion, datasetCerrados];
        this.barChart.update();
        this.emailService.getConfigEmailByUser(null, this.formReport.get('emailAccount').value, 'false').subscribe(res => {
          this.listToAdvisers = res.data;
        });
      } else {
        this.barChart.data.labels = MONTHS;
        this.barChart.data.datasets = [];
        this.barChart.update();
        this.listToAdvisers = [];
        this.dataTable = [];
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-19
    * Metodo que trae la informacion para generar el reporte sobre la tabla.
  */
  getTableReport(): void {
    const formatDataForm = { ...this.formReport.value, months: this.formReport.value.months.toString() };
    this.emailService.getTableReport(this.configPaginator, formatDataForm, this.adviserSelected).subscribe(res => {
      this.dataTable = res.data.data;
      this.configPaginator.length = res.data.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-09-19
    * Metodo que setea el filtro de asesor seleccionado.
    * @param $event Evento emitido por el select. 
  */
  onChangeFilterAdviser($event: MatSelectChange): void {
    this.adviserSelected = $event.value;
    this.configPaginator.pageIndex = 0;
    this.getTableReport();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
}