import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailAdminService } from '../../services/email-admin.service';
import * as moment from 'moment';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { environment } from 'src/environments/environment';

const { SURVEYS_URL } = environment;

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  idEmail: number = null;
  questions: any[] = [];
  surveys: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<EmailComponent>,
    private matSnackBar: MatSnackBar,
    private emailAdminService: EmailAdminService,
    private crudService: CrudService
  ) { }

  ngOnInit(): void {
    this.getSurveys().then(_ => {
      const updateQuestion = this.getConfigFormEmail();
      updateQuestion[29].options = this.surveys;
      if (this.data.email !== null) {
        for (let clave in this.data.email) {
          updateQuestion.forEach(item => {
            if (item.key === clave) {
              if (clave === 'auth_type') {
                if (this.data.email[clave] === 1) {
                  updateQuestion[19].type = 'text';
                  updateQuestion[20].type = 'text';
                  updateQuestion[21].type = 'text';
                  updateQuestion[19].rules = { required: true };
                  updateQuestion[20].rules = { required: true };
                  updateQuestion[21].rules = { required: true };
                } else {
                  updateQuestion[19].type = 'hidden';
                  updateQuestion[20].type = 'hidden';
                  updateQuestion[21].type = 'hidden';
                  updateQuestion[19].rules = null;
                  updateQuestion[20].rules = null;
                  updateQuestion[21].rules = null;
                }
              }
              if (clave === 'time_parameterization') {
                if (this.data.email[clave] === 1) {
                  updateQuestion[24].type = 'number';
                  updateQuestion[24].rules = { required: true };
                  updateQuestion[25].show = false;
                  updateQuestion[25].range[0].rules = null;
                  updateQuestion[25].range[1].rules = null;
                  updateQuestion[26].show = false;
                  updateQuestion[26].rules = null;
                } else if (this.data.email[clave] === 2) {
                  updateQuestion[24].type = 'hidden';
                  updateQuestion[24].rules = null;
                  updateQuestion[25].show = true;
                  updateQuestion[25].range[0].rules = { required: true };
                  updateQuestion[25].range[1].rules = { required: true };
                  updateQuestion[26].show = false;
                  updateQuestion[26].rules = null;
                } else {
                  updateQuestion[24].type = 'hidden';
                  updateQuestion[24].rules = null;
                  updateQuestion[25].show = false;
                  updateQuestion[25].range[0].rules = null;
                  updateQuestion[25].range[1].rules = null;
                  updateQuestion[26].show = true;
                  updateQuestion[26].rules = { required: true };
                }
              }
              if (clave !== 'date_initial' && clave !== 'date_range_time_parameterization') item.value = this.data.email[clave];
              if (clave === 'date_initial') item.value = this.data.email[clave] || this.data.email[clave] !== '' ? moment(this.data.email[clave]).format() : moment().format();
              if (clave === 'date_range_time_parameterization') {
                item.range[0].value = this.data.email['date_start'] || this.data.email['date_start'] !== '' ? moment(this.data.email['date_start']).format() : moment().format();
                item.range[1].value = this.data.email['date_end'] || this.data.email['date_end'] !== '' ? moment(this.data.email['date_end']).format() : moment().format();
              }
            }
          });
        }
        this.idEmail = this.data.email.id;
        if (updateQuestion[18].value === 1 && (updateQuestion[23].value === 2 || updateQuestion[23].value === 3)) {
          updateQuestion[27].type = 'time';
          updateQuestion[28].type = 'time';
          updateQuestion[27].rules = { required: true };
          updateQuestion[28].rules = { required: true };
        } else {
          updateQuestion[27].type = 'hidden';
          updateQuestion[28].type = 'hidden';
          updateQuestion[27].rules = null;
          updateQuestion[28].rules = null;
        }
        this.questions = updateQuestion;
      }
    });
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que guarda o actualiza un correo. 
    * @param $event Evento emitido por el componente hijo. 
  */
  saveEmail($event: any): void {
    const formData = this.idEmail !== null ? { ...$event, id: this.idEmail } : $event;
    this.emailAdminService.storeEmail(formData, this.data.title).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: this.idEmail === null ? 'Se ha creado un nuevo correo con éxito' : 'Se ha actualizado un correo con éxito'
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.matDialogRef.close(res);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-10-11
    * Metodo que trae la data del select seleccionado del formulario. 
    * @param $event Evento emitido por el componente hijo. 
  */
  onChangeSelectForm($event: any): void {
    const updateQuestion = Array.from(this.questions);
    if ($event.key === 'auth_type') updateQuestion[18].value = $event.value;
    if ($event.key === 'time_parameterization') updateQuestion[23].value = $event.value;
    if ($event.key === 'auth_type' && $event.value === 1) {
      updateQuestion[19].type = 'text';
      updateQuestion[20].type = 'text';
      updateQuestion[21].type = 'text';
      updateQuestion[19].rules = { required: true };
      updateQuestion[20].rules = { required: true };
      updateQuestion[21].rules = { required: true };
    } else if ($event.key === 'auth_type' && $event.value !== 1) {
      updateQuestion[19].type = 'hidden';
      updateQuestion[20].type = 'hidden';
      updateQuestion[21].type = 'hidden';
      updateQuestion[19].rules = null;
      updateQuestion[20].rules = null;
      updateQuestion[21].rules = null;
    }
    if ($event.key === 'time_parameterization' && $event.value === 1) {
      updateQuestion[24].type = 'number';
      updateQuestion[24].rules = { required: true };
      updateQuestion[25].show = false;
      updateQuestion[25].range[0].rules = null;
      updateQuestion[25].range[1].rules = null;
      updateQuestion[26].show = false;
      updateQuestion[26].rules = null;
    } else if ($event.key === 'time_parameterization' && $event.value === 2) {
      updateQuestion[24].type = 'hidden';
      updateQuestion[24].rules = null;
      updateQuestion[25].show = true;
      updateQuestion[25].range[0].rules = { required: true };
      updateQuestion[25].range[1].rules = { required: true };
      updateQuestion[26].show = false;
      updateQuestion[26].rules = null;
    } else if ($event.key === 'time_parameterization' && $event.value === 3) {
      updateQuestion[24].type = 'hidden';
      updateQuestion[24].rules = null;
      updateQuestion[25].show = false;
      updateQuestion[25].range[0].rules = null;
      updateQuestion[25].range[1].rules = null;
      updateQuestion[26].show = true;
      updateQuestion[26].rules = { required: true };
    }
    if (updateQuestion[18].value === 1 && (updateQuestion[23].value === 2 || updateQuestion[23].value === 3)) {
      updateQuestion[27].type = 'time';
      updateQuestion[28].type = 'time';
      updateQuestion[27].rules = { required: true };
      updateQuestion[28].rules = { required: true };
    } else {
      updateQuestion[27].type = 'hidden';
      updateQuestion[28].type = 'hidden';
      updateQuestion[27].rules = null;
      updateQuestion[28].rules = null;
    }
    this.questions = updateQuestion;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-10-12
    * Metodo que trae la configuracion del formulario para las cuentas de correo. 
  */
  getConfigFormEmail(): any[] {
    return [
      { typeInput: 'input', type: 'hidden', key: 'area_id', label: 'Área', rules: { required: false }, value: 1 },
      { typeInput: 'input', type: 'text', key: 'host_email_entrada', label: 'Host de entrada', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'text', key: 'host_email_salida', label: 'Host de salida', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'number', key: 'port_email_entrada', label: 'Puerto email de entrada', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'number', key: 'port_email_salida', label: 'Puerto email de salida', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'text', key: 'encryption_email_entrada', label: 'Encriptación de entrada', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'text', key: 'encryption_email_salida', label: 'Encriptación de salida', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'text', key: 'user_name_email_entrada', label: 'Correo de entrada', rules: { required: true, email: true }, value: '' },
      { typeInput: 'input', type: 'text', key: 'user_name_email_salida', label: 'Correo de salida', rules: { required: true, email: true }, value: '' },
      { typeInput: 'input', type: 'password', hide: true, key: 'password_email_entrada', label: 'Contraseña de entrada', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'password', hide: true, key: 'password_email_salida', label: 'Contraseña de salida', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'text', key: 'protocol_email_entrada', label: 'Protocolo de entrada', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'text', key: 'protocol_email_salida', label: 'Protocolo de salida', rules: { required: true }, value: '' },
      { typeInput: 'input', type: 'hidden', key: 'configuracion_form_answer', label: 'Configuración form answer', rules: { required: false }, value: '{}' },
      { typeInput: 'select', key: 'requiere_firma_personal', label: 'Requiere firma de personal', rules: { required: true }, value: '', options: [{ value: 1, text: 'Si' }, { value: 0, text: 'No' }] },
      { typeInput: 'input', type: 'hidden', key: 'salida_email_area', label: 'Salida email area', rules: { required: false }, value: 0 },
      { typeInput: 'select', key: 'activo', label: '¿Activo?', rules: { required: true }, value: '', options: [{ value: 1, text: 'Si' }, { value: 0, text: 'No' }] },
      { typeInput: 'input', type: 'number', key: 'form_id', label: 'Formulario de CRM', rules: { required: true }, value: '' },
      { typeInput: 'select', key: 'auth_type', label: 'Tipo de autenticación', rules: { required: true }, value: '', options: [{ value: 0, text: 'Autenticación básica' }, { value: 1, text: 'Outlook Azure' }, { value: 2, text: 'Zimbra' }] },
      { typeInput: 'input', type: 'hidden', key: 'client_id', label: 'Id del cliente Azure', rules: null, value: '' },
      { typeInput: 'input', type: 'hidden', key: 'tenant_id', label: 'Tenant del cliente Azure', rules: null, value: '' },
      { typeInput: 'input', type: 'hidden', key: 'client_secret', label: 'Llave secreta del cliente - Azure', rules: null, value: '' },
      { typeInput: 'input', type: 'hidden', key: 'validate_cert_email', label: 'Valida certificado del email', rules: { required: false }, value: 1 },
      { typeInput: 'select', key: 'time_parameterization', label: 'Parametrización de tiempo', rules: { required: true }, value: 1, options: [{ value: 1, text: 'Rango inicial de dias' }, { value: 2, text: 'Rango por fechas' }, { value: 3, text: 'Rango inicial' }] },
      { typeInput: 'input', type: 'number', key: 'number_days', label: 'Numero de dias', rules: { required: true }, value: '0' },
      {
        typeInput: 'date-range', key: 'date_range_time_parameterization', show: false, range: [
          { key: 'date_start', rules: null, value: moment().format() },
          { key: 'date_end', rules: null, value: moment().format() },
        ]
      },
      { typeInput: 'date', key: 'date_initial', rules: null, value: moment().format(), show: false },
      { typeInput: 'input', type: 'hidden', key: 'start_time', label: 'Hora inicial', rules: null, value: '01:00' },
      { typeInput: 'input', type: 'hidden', key: 'end_time', label: 'Hora final', rules: null, value: '23:59' },
      { typeInput: 'select', key: 'survey_id', label: 'Nombre de la encuesta', rules: null, value: null, options: [] },
      { typeInput: 'input', type: 'number', key: 'limit', label: 'Límite de vencidos', rules: null, value: 0 }
    ];
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-22
    * Metodo que actualiza la propiedad hide de los campos del formulario. 
    * @param $event Evento emitido por el componente hijo 
  */
  updateHideTextField($event: number): void {
    this.questions[$event].hide = !this.questions[$event].hide;
    this.questions[$event].type = this.questions[$event].hide ? 'password' : 'text';
  }
  /**
    * Método que lista las encuestas públicas registradas en el sistema.
    * @returns Observable con la respuesta de las encuestas.
  */
  async getSurveys(): Promise<void> {
    return this.crudService.getData({ url: `${SURVEYS_URL}surveys/list/publics` }).toPromise().then(res => {
      this.surveys = [];
      for (const key in res.surveys) this.surveys.push({ value: parseInt(key), text: res.surveys[key] });
    });
  }
}