import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { AnsService } from '../../services/ans.service';

@Component({
  selector: 'app-ans',
  templateUrl: './ans.component.html',
  styleUrls: ['./ans.component.css']
})
export class AnsComponent implements OnInit {
  formAns: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    id_email_config: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required]),
    name: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.maxLength(15)]),
    day: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    hour: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    minutes: new FormControl({ value: '', disabled: this.data.viewOnly }, [Validators.required, Validators.min(0), Validators.max(999)]),
    color: new FormControl({ value: '#ff0000', disabled: this.data.viewOnly }, [Validators.required]),
  });
  emailsConfig: any[] = [];

  constructor(
    private matDialogRef: MatDialogRef<AnsComponent>,
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar,
    private ansService: AnsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if (this.data.ans) {
      this.formAns.get('id').setValue(this.data.ans.id);
      this.formAns.get('id_email_config').setValue(this.data.ans.id_email_config);
      this.formAns.get('name').setValue(this.data.ans.name);
      this.formAns.get('day').setValue(this.data.ans.day);
      this.formAns.get('hour').setValue(this.data.ans.hour);
      this.formAns.get('minutes').setValue(this.data.ans.minutes);
      this.formAns.get('color').setValue(this.data.ans.color);
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formAns.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que guarda o actualiza un ANS. 
  */
  saveAns(): void {
    if (this.formAns.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.ansService.storeAns(this.formAns.value).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: this.formAns.value.id === null ? 'Se ha creado un nuevo ANS con éxito' : 'Se ha actualizado un ANS con éxito'
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formAns.markAllAsTouched();
    }
  }
}