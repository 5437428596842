import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryService } from '../../services/category.service';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-assign-category',
  templateUrl: './assign-category.component.html',
  styleUrls: ['./assign-category.component.css']
})
export class AssignCategoryComponent implements OnInit {
  formAssignCategory: FormGroup = new FormGroup({
    category: new FormControl('', [Validators.required])
  });
  categories: any[] = [];

  constructor(
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<AssignCategoryComponent>,
    private categoryService: CategoryService,
    private emailService: EmailService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getCategoriesByIdEmailConfig();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-10
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formAssignCategory.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-10
    * Metodo que asigna una categoria al correo seleccionado. 
  */
  onSubmitFormAssignCategory(): void {
    if (this.formAssignCategory.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De asignar la categoría seleccionada?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          if (!Array.isArray(this.data.mail)) {
            this.emailService.updateCategoryToEmail(this.data.mail, this.formAssignCategory.value.category).subscribe(res => {
              this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                data: {
                  title: '¡Excelente!',
                  message: res.data
                },
                duration: 5000,
                verticalPosition: 'top',
                panelClass: ['green-snackbar']
              });
              this.matDialogRef.close(res);
            });
          } else {
            const data = {
              email_list: `[${this.data.mail.map((item: any) => item.id).toString()}]`,
              id_category: this.formAssignCategory.value.category
            };
            this.emailService.updateCategoryToListEmail(data).subscribe((res => {
              this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
                data: {
                  title: '¡Excelente!',
                  message: res.data
                },
                duration: 5000,
                verticalPosition: 'top',
                panelClass: ['green-snackbar']
              });
              this.matDialogRef.close(res);
            }));
          }
        }
      });
    } else {
      this.formAssignCategory.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que trae la categorias registradas en el sistema por id de configuracion de correo. 
  */
  getCategoriesByIdEmailConfig(): void {
    const emailConfigId = !Array.isArray(this.data.mail) ? this.data.mail.email_config_id : this.data.mail[0].email_config_id;
    this.categoryService.getCategoriesByIdEmailConfig(emailConfigId).subscribe(res => {
      this.categories = res.data;
    });
  }
}