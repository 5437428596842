import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';

const { VICIDIAL_URL } = environment;

@Injectable({
  providedIn: 'root',
})
export class VicidialService {
  constructor(private http: HttpClient) { }


  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Juan Carlos Alonso
    * @purpose Obtiene el listado de las grabaciones de soulphone.
    * @createdate 25-11-2024
  */
  getSoulphoneRecords(page:number, per_page:number,start_date:string = '', end_date:string = ''): Observable<any> {
    let params = new HttpParams()
    .set('page', page.toString())
    .set('perPage', per_page.toString())

    if(start_date){
      params = params.set('start', start_date)
    }

    if(end_date){
      params = params.set('end', end_date)
    }

    return this.http.get<any>(`${VICIDIAL_URL}listRecord/all?` , {params})
      .pipe(
        catchError(this.handleError)
      );
  }

}
