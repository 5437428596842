import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-management-history-view',
  templateUrl: './management-history-view.component.html',
  styleUrls: ['./management-history-view.component.css']
})
export class ManagementHistoryViewComponent implements OnInit {
  @ViewChild('containerViewMail') containerViewMail: ElementRef

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<ManagementHistoryViewComponent>,
    private alertService: AlertsService
  ) { }

  ngOnInit(): void {
    
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-01-03
    * Metodo que imprime el correo seleccionado.
  */
  async onClickPrintToEmailPdf() {
    const content = this.containerViewMail.nativeElement;
    const options = {
      margin: 10,
      filename: 'Email.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    html2pdf().from(content).set(options).save();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-21
    * Metodo que envia la informacion del correo seleccionado al componente padre.
  */
  onClickSaveEmail(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De almacenar el correo seleccionado en las bases de datos?').then(confirm => {
      if (confirm.isConfirmed) this.matDialogRef.close(this.data.management); 
    });
  }
}