<div class="d-flex justify-content-end">
  <div style="color: #353535;">
    <button mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">{{ data.titleDialog }}</div>
  <mat-divider></mat-divider>
  <form class="mt-4" [formGroup]="formState">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Nombre del estado</mat-label>
          <input matInput placeholder="Nombre del estado" formControlName="name">
          <mat-error *ngIf="error.name.errors">
            <span *ngIf="error.name.errors.required">El campo es requerido</span>
            <span *ngIf="error.name.errors.maxlength">Máximo 30 caracteres</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select formControlName="id_email_config">
            <mat-option *ngFor="let email of data.emailsConfig" [value]="email.id">{{ email.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.id_email_config.errors">
            <span *ngIf="error.id_email_config.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-checkbox formControlName="is_initial_state" color="primary">
          <span style="font-weight: 700; font-size: 14px; text-decoration: underline;">¿Es un estado inicial?</span>
        </mat-checkbox>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-md-12">
        <span style="font-weight: 700; font-size: 16px;">Semaforización</span>
        <br />
        <span style="font-weight: 400; font-size: 14px;">
          Escribe el número de días para cada uno de los estados (A tiempo, próximo a vencer y vencido).
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Días</mat-label>
          <input type="number" matInput placeholder="Días" formControlName="day_green">
          <mat-error *ngIf="error.day_green.errors">
            <span *ngIf="error.day_green.errors.required">El campo es requerido</span>
            <span *ngIf="error.day_green.errors.min">Solo valores positivos</span>
            <span *ngIf="error.day_green.errors.max">Máximo tres digitos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Horas</mat-label>
          <input type="number" matInput placeholder="Horas" formControlName="hour_green">
          <mat-error *ngIf="error.hour_green.errors">
            <span *ngIf="error.hour_green.errors.required">El campo es requerido</span>
            <span *ngIf="error.hour_green.errors.min">Solo valores positivos</span>
            <span *ngIf="error.hour_green.errors.max">Máximo tres digitos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Minutos</mat-label>
          <input type="number" matInput placeholder="Minutos" formControlName="minutes_green">
          <mat-error *ngIf="error.minutes_green.errors">
            <span *ngIf="error.minutes_green.errors.required">El campo es requerido</span>
            <span *ngIf="error.minutes_green.errors.min">Solo valores positivos</span>
            <span *ngIf="error.minutes_green.errors.max">Máximo tres digitos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <div class="w-100 h-75 border-1 rounded" style="background-color: #60BF66;"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Días</mat-label>
          <input type="number" matInput placeholder="Días" formControlName="day_yellow">
          <mat-error *ngIf="error.day_yellow.errors">
            <span *ngIf="error.day_yellow.errors.required">El campo es requerido</span>
            <span *ngIf="error.day_yellow.errors.min">Solo valores positivos</span>
            <span *ngIf="error.day_yellow.errors.max">Máximo tres digitos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Horas</mat-label>
          <input type="number" matInput placeholder="Horas" formControlName="hour_yellow">
          <mat-error *ngIf="error.hour_yellow.errors">
            <span *ngIf="error.hour_yellow.errors.required">El campo es requerido</span>
            <span *ngIf="error.hour_yellow.errors.min">Solo valores positivos</span>
            <span *ngIf="error.hour_yellow.errors.max">Máximo tres digitos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Minutos</mat-label>
          <input type="number" matInput placeholder="Minutos" formControlName="minutes_yellow">
          <mat-error *ngIf="error.minutes_yellow.errors">
            <span *ngIf="error.minutes_yellow.errors.required">El campo es requerido</span>
            <span *ngIf="error.minutes_yellow.errors.min">Solo valores positivos</span>
            <span *ngIf="error.minutes_yellow.errors.max">Máximo tres digitos</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <div class="w-100 h-75 border-1 rounded" style="background-color: #D9A847;"></div>
      </div>
    </div>
    <div class="row" style="height: 48px;">
      <div class="col-md-9 d-flex justify-content-center align-items-center">
        <div>El último estado no tiene tiempo límite</div>
      </div>
      <div class="col-md-3">
        <div class="w-100 h-100 border-1 rounded" style="background-color: #D64B4B;"></div>
      </div>
    </div>
    <div *ngIf="!data.viewOnly" class="row mt-5">
      <div class="col-md-6">
        <button mat-dialog-close type="button" mat-raised-button class="w-100"
          style="height: 48px; font-weight: 600; color: #A9A9A9; border: 2px solid #A9A9A9; background-color: #FFFFFF;">Cancelar</button>
      </div>
      <div class="col-md-6">
        <button (click)="saveState()" type="button" mat-raised-button color="primary" class="w-100"
          style="height: 48px; font-weight: 600;">Guardar</button>
      </div>
    </div>
  </form>
</div>