<div class="d-flex justify-content-between align-items-center p-2" style="background-color: #51B0CB;">
  <div style="font-weight: 700; font-size: 18px; color: #FFFFFF;">
    {{ data?.email === null ? 'Nuevo mensaje' : 'Nuevo mensaje (borrador)' }}
  </div>
  <div style="color: #FFFFFF;" [ngClass]="{'p-4': typeManagerMessage === 'Enviar borrador'}">
    <button *ngIf="typeManagerMessage !== 'Enviar borrador'" mat-dialog-close mat-icon-button>
      <i class="fi-rr-cross"></i>
    </button>
  </div>
</div>
<div class="py-2 px-3">
  <app-manager-message [typeManagerMessage]="typeManagerMessage" [emailDataDraft]="data?.email"
    (submitMessage)="onCloseDialogWhenSubmitMessage($event)"
    (onClickCancelButton)="onClickCancelButton()"></app-manager-message>
</div>