<div class="admin-menus">
  <div class="header">
    <h1>Menús<i class="fas fa-container-storage    "></i></h1>
    <p>En este espacio podrás revisar y/o crear Menús<br>no olvides, "El respeto y la empatía son clave"</p>
  </div>
  <div class="row col-12 filter">
    <mat-form-field class="col-3" appearance="fill">
      <mat-label>Aplicativo</mat-label>
      <mat-select [(ngModel)]="filterApp" (selectionChange)="applyFilter()">
        <mat-option *ngFor="let app of apps" [value]="app.id">{{ app.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-3" appearance="fill">
      <mat-label>Nombre</mat-label>
      <input [(ngModel)]="filterName" matInput (keydown.enter)="applyFilter()" (blur)="applyFilter()">
      <mat-icon matSuffix><span class="fi-rr-search"></span></mat-icon>
    </mat-form-field>
    <div class="col-6 d-flex justify-content-end">
      <button mat-raised-button color="primary" (click)="newMenu()" style="height: 48px;">Crear nuevo menú</button>
    </div>
  </div>
  <div class="table-container">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="acciones">
          <th class="start" mat-header-cell *matHeaderCellDef> Acciones</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="editMenu(row)">
              <i class="fi-rr-edit"></i>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="nameApp">
          <th mat-header-cell *matHeaderCellDef> Aplicación </th>
          <td mat-cell *matCellDef="let row"> {{row.nameApp}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef> Icono </th>
          <td mat-cell *matCellDef="let row"> {{row.icon}} </td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef> URL </th>
          <td mat-cell *matCellDef="let row"> {{row.url}} </td>
        </ng-container>
        <ng-container matColumnDef="index">
          <th class="end" mat-header-cell *matHeaderCellDef>Índice </th>
          <td mat-cell *matCellDef="let row"> {{row.index}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No hay datos que coincidan con los filtros</td>
        </tr>
      </table>
      <mat-paginator [length]="length" [pageSize]="pageSize" (page)="pageEvent($event)"
        [pageSizeOptions]="pageSizeOptions" [pageIndex]="page">
      </mat-paginator>
    </div>
  </div>
</div>