<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Soporte Correos</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">En este espacio podrás dar soporte a los correos de las distintas
        cuentas de correo registradas.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Correos no ingresados">
          <div class="p-3">
            <app-print-form-survey [ifFormFilter]="true" [textButtonSubmit]="'Filtrar'" [questions]="formFilterTabOne"
              (sendQuestions)="sendFilters($event, 1)"></app-print-form-survey>
            <div class="mt-3"></div>
            <shared-basic-table [columnsTable]="configColumnsTableTabOne" [dataTables]="dataTableTabOne"
              [configPaginator]="configPaginatorTableTabOne" [showTooltipTable]="true" [buttonsOptions]="buttonsOptionsTableOne"
              (updatePaginator)="onChangePageTable($event)" (eventEmitButton)="onClickButtonActionTableOne($event)"></shared-basic-table>
          </div>
        </mat-tab>
        <!-- <mat-tab label="Descarga historico de asignación">
          <div class="p-3">
            <app-print-form-survey [ifFormFilter]="true" [textButtonSubmit]="'Reporte'" [questions]="formFilterTabTwo"
              (sendQuestions)="sendFilters($event, 2)"></app-print-form-survey>
          </div>
        </mat-tab> -->
        <mat-tab label="Buscar correos excluidos">
          <div class="p-3">
            <app-print-form-survey [ifFormFilter]="true" [textButtonSubmit]="'Filtrar'" [questions]="formFilterTabThree"
              (sendQuestions)="sendFilters($event, 3)"></app-print-form-survey>
            <div class="mt-3"></div>
            <shared-basic-table [columnsTable]="configColumnsTableTabThree" [dataTables]="dataTableTabThree"
              [buttonsOptions]="buttonsOptionsTableThree" [showTooltipTable]="true"
              (eventEmitButton)="onClickButtonActionTableThree($event)"></shared-basic-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>