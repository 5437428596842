import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-modal-meetings',
  templateUrl: './modal-meetings.component.html',
  styleUrls: ['./modal-meetings.component.sass']
})
export class ModalMeetingsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['client_name', 'client_email', 'client_nic','client_observation', 'name', 'scheduled_date'];
  dataSource = new MatTableDataSource<any>();

  constructor(@Inject(MAT_DIALOG_DATA) public datos: any) { }

  ngOnInit(): void {
    this.dataSource.data = this.datos;
    console.log(this.datos);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

}
