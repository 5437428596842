import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { FormContactGroupComponent } from '../form-contact-group/form-contact-group.component';
import { ContactGroupService } from '../../services/contact-group.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { EmailService } from '../../services/email.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';

@Component({
  selector: 'app-table-contact-group',
  templateUrl: './table-contact-group.component.html',
  styleUrls: ['./table-contact-group.component.css']
})
export class TableContactGroupComponent implements OnInit {
  @Input() dataContactGroup: any[] = [];
  @Input() viewToSelectedGroups: boolean = true;
  @Input() nameFieldSelected: string = null;
  @Input() configPaginator: any = null;
  @Output() sendContactGroupSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangePaginator: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() updateContactGroupsList: EventEmitter<any> = new EventEmitter<any>();
  configColumnsTable: any[] = [
    { name: '', key: 'actions' },
    { name: 'Nombre del grupo', key: 'name' },
    { name: 'Correos electrónicos', key: 'email_list' }
  ];
  keysColumnsTable: string[] = [];
  contactGroupSelected: any[] = [];
  emailsConfig: any[] = [];

  constructor(
    private alertService: AlertsService,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private contactGroupService: ContactGroupService,
    private authService: AuthService,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map(item => item.key);
    this.getEmailsConfigByRol();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-02
    * Metodo que setea la configuracion de la paginacion.
    * @param $event Evento emitido por el paginador.  
  */
  onChangePage($event: PageEvent) {
    this.onChangePaginator.emit($event);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-02
    * Metodo que permite vizualizar mas informacion sobre el grupo de contacto.
    * @param contactGroup Grupo de contacto seleccionado de la tabla.  
  */
  onClickMoreInfoContactGroup(contactGroup: any) {
    this.matDialog.open(FormContactGroupComponent, {
      width: '80%',
      autoFocus: false,
      data: {
        title: this.viewToSelectedGroups ? 'Vizualizar grupo de contacto' : 'Editar grupo de contacto',
        contactGroup: contactGroup,
        btnText: 'Guardar',
        onlyShow: this.viewToSelectedGroups,
        emailsConfig: this.emailsConfig
      }
    }).afterClosed().subscribe(res => {
      if (res) this.updateContactGroupsList.emit();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-07
    * Metodo que permite eliminar un grupo de contacto seleccionado.
    * @param contactGroup Grupo de contacto seleccionado de la tabla.  
  */
  onClickChangeStateContactGroup($event: MatSlideToggleChange, contactGroup: any) {
    const contactGroupWithState = { ...contactGroup, state: $event.checked ? 1 : 2 };
    this.contactGroupService.storeGroupContact(contactGroupWithState).subscribe(res => {
      this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
        data: {
          title: '¡Excelente!',
          message: res.data
        },
        duration: 5000,
        verticalPosition: 'top',
        panelClass: ['green-snackbar']
      });
      this.updateContactGroupsList.emit();
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-02
    * Metodo que permite agregar o quitar un grupo de contacto de la lista de seleccionados.
    * @param $event Evento generado por el checkbox. 
    * @param contactGroup Grupo de contacto seleccionado de la tabla.  
  */
  onChangeSelectContactGroup($event: MatCheckboxChange, contactGroup: any) {
    if ($event.checked) this.contactGroupSelected.push(contactGroup);
    else this.contactGroupSelected = this.contactGroupSelected.filter(item => item.id !== contactGroup.id);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-02
    * Metodo que envia la lista de grupos de contacto seleccionados al componente padre. 
  */
  onSubmitGroupContactSelected(): void {
    this.alertService.alertWarning('¿Está seguro?', '¿De enviar los grupos de contacto seleccionados en la tabla?').then(confirm => {
      if (confirm.isConfirmed) {
        this.sendContactGroupSelected.emit({
          contactGroupSelected: this.contactGroupSelected,
          nameField: this.nameFieldSelected
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    const userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
}