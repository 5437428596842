<div class="p-5">
  <div class="mb-4" style="font-weight: 700; font-size: 28px;">Listado de correos</div>
  <mat-dialog-content>
    <shared-basic-table
    [columnsTable]="[{ name: '', key: 'actions', type: 'events' }, { name: 'Asunto', key: 'asunto' }, { name: 'Bandeja', key: 'bandeja' }, { name: 'De', key: 'de' }, { name: 'Para', key: 'para' }]"
    [dataTables]="dataTable"
    [configPaginator]="configPaginator"
    [showTooltipTable]="true"
    [buttonsOptions]="[{ class: 'fi-rr-eye', action: 'view' }]"
    [messageLoading]="messageLoading"
    (updatePaginator)="onChangePage($event)"
    (eventEmitButton)="onClickButtonTableMoreInfo($event)"></shared-basic-table>
  </mat-dialog-content>
</div>