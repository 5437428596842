import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdvanceFilterEmailsComponent } from '../../components/advance-filter-emails/advance-filter-emails.component';
import { PageEvent } from '@angular/material/paginator';
import { CategoryService } from '../../services/category.service';
import { EmailService } from '../../services/email.service';
import { MatSelectChange } from '@angular/material/select';
import { StateService } from '../../services/state.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { TrayService } from '../../services/tray.service';
import { AnsService } from '../../services/ans.service';
import { CreateMeetingComponent } from '../../components/create-meeting/create-meeting.component';
import { MoveEmailTrayComponent } from '../../components/move-email-tray/move-email-tray.component';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-email-manager-main',
  templateUrl: './email-manager-main.component.html',
  styleUrls: ['./email-manager-main.component.css']
})
export class EmailManagerMainComponent implements OnInit {
  emailsConfig: any[] = [];
  categories: any[] = [];
  listMails: any[] = [];
  statesTypesMails: any[] = [];
  configPaginator: PageEvent = { length: 0, pageIndex: 0, pageSize: 50 };
  traysEmail: any[] = [];
  emailsExpiration: any[] = [];
  advanceFilters: any = {
    order: 'desc',
    asunto: null,
    contiene_palabras: null,
    de: null,
    para: null,
    date_in: null,
    date_end: null,
    email_list: null,
    id_category: null,
    id_email_state: null,
    id_bandeja: null,
    id_ans: null
  };
  filterOrder: string = 'desc';
  mailSelected: any = null;
  emailsConfigSelected: any[] = [];
  userLogin: any = null;
  textValueFilters: string = null;
  keyTraySelected: string = null;
  checkAllAccountEmails: boolean = false;
  folders: any[] = [];

  constructor(
    private matDialog: MatDialog,
    private categoryService: CategoryService,
    private emailService: EmailService,
    private stateService: StateService,
    private authService: AuthService,
    private trayService: TrayService,
    private ansService: AnsService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit(): void {
    this.userLogin = this.authService.getUser();
    this.getEmailsConfig();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-24
    * Metodo que abre la modal para registrar los filtros avanzados.  
  */
  openDialogAdvanceFilters(): void {
    this.matDialog.open(AdvanceFilterEmailsComponent, {
      width: '35%',
      autoFocus: false,
      data: {
        filters: this.advanceFilters
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        if (res.hasOwnProperty('clearFilters')) {
          this.resetAdvanceFilters();
        } else {
          this.advanceFilters.asunto = res.affair;
          this.advanceFilters.de = res.from.length > 0 ? res.from.map((item: any) => item.name) : null;
          this.advanceFilters.para = res.to.length > 0 ? res.to.map((item: any) => item.name) : null;
          this.advanceFilters.date_in = res.dateStart !== null ? res.dateStart : null;
          this.advanceFilters.date_end = res.dateEnd !== null ? res.dateEnd : null;
          this.advanceFilters.contiene_palabras = res.containsWords;
          this.advanceFilters.id_email_state = res.state;
          this.configPaginator.pageIndex = 0;
          this.getMails();
        }
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-24
    * Metodo que trae los correos registrados en el sistema. 
  */
  getMails(): void {
    if (this.emailsConfigSelected.length > 0) {
      this.setValueTextFilters();
      const idEmailsConfig = this.emailsConfigSelected.map(item => item.id_email).toString();
      this.advanceFilters.email_list = `[${idEmailsConfig}]`;
      this.listMails = [];
      this.emailService.getEmails(this.configPaginator, this.advanceFilters).subscribe(res => {
        const getTypesRangesDates = res.data.data.map((mail: any) => mail.diferencia_en_dias);
        const setTypesRangesDates = new Set(getTypesRangesDates);
        const typesRangesDatesNotDuplicates = [...setTypesRangesDates];
        typesRangesDatesNotDuplicates.forEach((item: string) => {
          const rangeDateWithMails = { time: item, mails: [] };
          rangeDateWithMails.mails = res.data.data.filter((mail: any) => mail.diferencia_en_dias === item);
          this.listMails.push(rangeDateWithMails);
        });
        this.configPaginator.length = res.data.total;
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-29
    * Metodo que captura el key del item seleccionado en el menu y actualiza la lista de correos dependiendo el item seleccionado. 
    * @param $event Evento emitido por el menu lateral izquierdo. 
  */
  onChangeTraySelected($event: any): void {
    this.advanceFilters.id_bandeja = $event.trayId;
    this.keyTraySelected = $event.trayKey;
    this.mailSelected = null;
    this.getMails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-29
    * Metodo que camputa la informacion del email seleccionado y la setea sobre la variable. 
    * @param $event Evento emitido por el componente hijo. 
  */
  onChangeSelectedMail($event: any): void {
    this.mailSelected = $event;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que actualiza el estado de la paginacion y el listado de correos. 
    * @param $event Evento emitido por el componente hijo. 
  */
  onChangePage($event: PageEvent): void {
    this.configPaginator = $event;
    this.getMails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que trae la categorias registradas en el sistema. A partir del email config seleccionado en la lista. 
  */
  getCategories(): void {
    if (this.emailsConfigSelected.length > 0) {
      const stringToIdEmailsConfig = this.emailsConfigSelected.map(item => item.id_email).toString();
      this.categoryService.getCategoriesByIdEmailConfig(stringToIdEmailsConfig).subscribe(res => {
        this.categories = res.data;
      });
    } else {
      this.categories = [];
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-05
    * Metodo que trae las configuraciones de correos de salida registrados en el sistema, a partir del usuario logueado en el sistema.  
  */
  getEmailsConfig(): void {
    const idRrhh = this.userLogin.rrhh_id;
    this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
      this.emailsConfig = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-14
    * Metodo que filtra el orden de los correos.
    * @param $event Evento emitido por el campo select.  
  */
  onChangeFilterOrder($event: MatSelectChange) {
    this.filterOrder = $event.value;
    this.advanceFilters.order = this.filterOrder;
    this.setValueTextFilters();
    this.getMails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que retorna los estados registrados en el sistema. 
  */
  getStates(): void {
    this.stateService.getStates(null, '', false, this.emailsConfigSelected.map(item => item.id_email).toString()).subscribe(res => {
      this.statesTypesMails = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-15
    * Metodo que refresca la data de la vista. 
  */
  loadDataPage(): void {
    this.getTraysEmail();
    this.getCategories();
    this.getEmailsConfig();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-28
    * Metodo que actualiza la lista de cnfiguraciones de correo seleccionado. 
    * @param $event Evento emitido por el componente hijo.  
  */
  updateEmailsConfigSelected($event: any): void {
    if (!$event.allSelectedEmails) {
      if ($event.checked) this.emailsConfigSelected.push($event.emailConfig);
      else this.emailsConfigSelected = this.emailsConfigSelected.filter(item => item.id_email !== $event.emailConfig.id_email);
    } else {
      if ($event.checked) this.emailsConfigSelected = this.emailsConfig;
      else this.emailsConfigSelected = [];
    }
    if (this.emailsConfigSelected.length === 0) {
      this.listMails = [];
      this.mailSelected = null;
    }
    this.checkAllAccountEmails = this.emailsConfig.length === this.emailsConfigSelected.length ? true : false;
    this.advanceFilters.id_bandeja = null;
    this.getTraysEmail();
    this.getCategories();
    this.getAnsByAccountSelected();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-01
    * Metodo que retorna las bandejas registradas en el sistema. 
  */
  getTraysEmail(): void {
    if (this.emailsConfigSelected.length > 0) {
      const stringToIdEmailsConfig = this.emailsConfigSelected.map(item => item.id_email).toString();
      this.trayService.getTraysByUserAndEmails(stringToIdEmailsConfig).subscribe(res => {
        this.traysEmail = res.data.bandejas;
        this.folders = res.data.carpetas;
        if (this.traysEmail.length > 0) {
          if (this.advanceFilters.id_bandeja === null) {
            this.advanceFilters.id_bandeja = this.traysEmail[0].id;
            this.keyTraySelected = this.traysEmail[0].key;
          }
          this.getStates();
          this.getMails();
        }
      });
    } else {
      this.traysEmail = [];
      this.folders = [];
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-03
    * Metodo que setea el filtro de categoria.
    * @param $event Evento emitido por el componente hijo.   
  */
  updateFilterIdCategory($event: any): void {
    if ($event !== 0) this.advanceFilters.id_category = $event;
    else this.advanceFilters.id_category = null;
    this.getMails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-03
    * Metodo que setea el filtro de estado.
    * @param $event Evento emitido por el componente hijo.   
  */
  updateFilterIdState($event: any): void {
    if ($event !== 0) this.advanceFilters.id_email_state = $event;
    else this.advanceFilters.id_email_state = null;
    this.getMails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-07
    * Metodo que borra los filtros avanzados.
    * @param isOrderFilterReset Valida si se borra o no el filtro por orden.   
  */
  resetAdvanceFilters(): void {
    this.advanceFilters.asunto = null;
    this.advanceFilters.de = null;
    this.advanceFilters.para = null;
    this.advanceFilters.date_in = null;
    this.advanceFilters.date_end = null;
    this.advanceFilters.contiene_palabras = null;
    this.advanceFilters.id_email_state = null;
    this.advanceFilters.order = 'desc';
    this.filterOrder = 'desc';
    this.getMails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-07
    * Metodo que setea el texto del input sobre los filtros generados en la vista.  
  */
  setValueTextFilters(): void {
    let textFilters = '';
    for (const key in this.advanceFilters) {
      if (this.advanceFilters[key] !== null) textFilters += `${key}: ${JSON.stringify(this.advanceFilters[key])} `;
    }
    this.textValueFilters = textFilters;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-14
    * Metodo que recibe el email gestionado desde el formulario de manager message.
    * @param $event Evento emitido por el componente hijo.  
  */
  updateDataEmailSelected($event: any): void {
    this.emailService.getEmailById($event).subscribe(res => {
      this.mailSelected = res.data;
      this.getTraysEmail();
      this.mailSelected = null;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-30
    * Metodo que trae los ANS registrados por cuenta seleccionada.
  */
  getAnsByAccountSelected(): void {
    if (this.emailsConfigSelected.length > 0) {
      const stringToIdEmailsConfig = this.emailsConfigSelected.map(item => item.id_email).toString();
      this.ansService.getAnsByAccountsEmailSelected(stringToIdEmailsConfig).subscribe(res => {
        this.emailsExpiration = res.data;
      });
    } else {
      this.emailsExpiration = [];
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-30
    * Metodo que setea el filtro de ANS.
    * @param $event Evento emitido por el componente hijo.   
  */
  updateFilterIdAns($event: any): void {
    if ($event !== 0) this.advanceFilters.id_ans = $event;
    else this.advanceFilters.id_ans = null;
    this.getMails();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-10
    * Metodo que setea el filtro de asunto y contiene palabras.
    * @param $event Evento emitido por el campo.   
  */
  onChangeFilterSearchGlobal($event: Event): void {
    const value = ($event.target as HTMLInputElement).value;
    if (value !== '') {
      this.advanceFilters.asunto = value;
      this.configPaginator.pageIndex = 0;
      this.getMails();
    } else {
      this.advanceFilters.asunto = null;
      this.configPaginator.pageIndex = 0;
      this.getMails();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que recarga la pagina al asignar un correo a un usuario.
  */
  onAssignEmailUser(): void {
    this.loadDataPage();
    this.mailSelected = null;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-02-28
    * Metodo que abre la modal para crear una reunion. 
  */
  onClickToOpenDialogMeeting(): void {
    const filterEmailOutlook = this.emailsConfig.filter(item => item.auth_type === 1);
    if (filterEmailOutlook.length > 0) {
      this.matDialog.open(CreateMeetingComponent, {
        width: '70%',
        autoFocus: false,
        panelClass: 'dialog-padding-create-meeting',
        maxHeight: '95vh',
        data: {
          emailsConfig: filterEmailOutlook
        }
      });
    } else {
      this.snackBarService.showNotification({ title: '¡Advertencia!', message: 'No se tiene cuentas de correos tipo Outlook para usar esta funcionalidad.' });
    }
  }
  /**
    * @createdate 2024-01-22
    * Metodo que quita el foco del campo cuando realizo el filtro por keyup.
    * @param $event Evento generado por el campo de formulario. 
  */
  onKeyupSearchGlobal($event: Event): void {
    ($event.target as HTMLInputElement).blur();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-26
    * Metodo que actualiza la informacion de la vista principal.
  */
  onDeleteOrRestoreEmail(): void {
    this.mailSelected = null;
    this.loadDataPage();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-26
    * Metodo que abre la modal para mover los correos a distintas bandejas.
    * @param $event Id de los correos seleccionados. 
  */
  onClickButtonMoveEmails($event: any[]): void {
    const traysAndFolders = [{ name: 'Bandejas', list: this.traysEmail.filter(item => item.id_email_config !== 0) }, { name: 'Carpetas', list: this.folders }];
    traysAndFolders[0].list = traysAndFolders[0].list.filter((item: any) => item.id !== $event[0].id_bandeja);
    traysAndFolders[1].list = traysAndFolders[1].list.filter((item: any) => item.id !== $event[0].id_bandeja);
    this.matDialog.open(MoveEmailTrayComponent, {
      width: '50%',
      autoFocus: false,
      panelClass: 'dialog-padding-move-email',
      data: {
        traysAndFolders: traysAndFolders,
        emails: $event.map(item => item.id)
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.mailSelected = null;
        this.loadDataPage();
      }
    });
  }
}