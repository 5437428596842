import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from '../../services/template.service';
import { EDITOR_CONFIG } from '../../constantes/mail-config-editor';
import { validateWhiteSpaces } from 'src/app/modules/email-manager/constantes/custom-validations';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
  formTemplate: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl('', [Validators.required, Validators.maxLength(30), Validators.pattern(/^(?!\s)(.*\S)?$/)]),
    html: new FormControl(null, []),
    body: new FormControl('', [Validators.required, validateWhiteSpaces()]),
    id_email_config: new FormControl('', [Validators.required])
  });
  editorConfig: any = EDITOR_CONFIG;

  public configTextBody = {
    placeholder: 'Ingresa aqui el cuerpo del PDF',
    language: 'es'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<TemplatesComponent>,
    private matSnackBar: MatSnackBar,
    private templateService: TemplateService
  ) { }

  ngOnInit(): void {
    if (this.data.template) {
      this.formTemplate.get('id').setValue(this.data.template.id);
      this.formTemplate.get('name').setValue(this.data.template.name);
      this.formTemplate.get('html').setValue('');
      this.formTemplate.get('body').setValue(this.data.template.body);
      this.formTemplate.get('id_email_config').setValue(this.data.template.id_email_config);
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formTemplate.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que guarda o actualiza una plantilla. 
  */
  saveTemplate(): void {
    if (this.formTemplate.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          this.templateService.storeTemplate(this.formTemplate.value).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: this.formTemplate.value.id === null ? 'Se ha creado una nueva plantilla con éxito' : 'Se ha actualizado una plantilla con éxito'
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formTemplate.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-03-01
    * Metodo que precarga el contenido del archivo al texto enriquecido.
    * @param $event Evento emitido por el campo tipo file.  
  */
  onChangeFileUpload($event: Event): void {
    const inputFile = $event.target as HTMLInputElement;



    if (inputFile.files && inputFile.files.length > 0) {
      const file = inputFile.files[0];
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      if (fileExtension === 'html') {
        this.formTemplate.get('html').setValue(file.name);
        const reader = new FileReader();
        reader.onload = () => {
          const contentFile = reader.result as string;
          this.formTemplate.get('body').setValue(contentFile);
        };
        reader.readAsText(file);
      } else {
        this.alertService.alertError('¡Error!', 'Solo puede cargar archivos HTML');
      }
    }
    inputFile.value = '';
  }
}