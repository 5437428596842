import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class EmailAdminService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que retorna los correos registradas en el sistema.
    * @param paginator Paginador configurado en la vista. 
    * @param filter Filtro configurado en la vista.  
  */
  getEmails(paginator: any = null, filter: string = ''): Observable<any> {
    const url = paginator === null ? `${GESTIONADOR_CORREOS}emailconfig/showall` : `${GESTIONADOR_CORREOS}emailconfig/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filter}`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-25
    * Metodo que guardar o actualiza un correo en el sistema. 
    * @param email Informacion proveniente del formulario.  
  */
  storeEmail(email: any, typeManager: string = 'Crear correo'): Observable<any> {
    const url = typeManager === 'Crear correo' ? `${GESTIONADOR_CORREOS}emailconfig/store` : `${GESTIONADOR_CORREOS}emailconfig/update/${email.id}`;
    return this.httpClient.post(url, email).pipe(
      catchError(this.handleError)
    );
  }
}
