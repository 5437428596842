import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { ChatService } from "../../../services/chat.service";
import { UploadChatsModalComponent } from "../upload-chats-modal/upload-chats-modal.component";


@Component({
  selector: 'app-speech-uploadchat',
  templateUrl: './speech-uploadchat.component.html',
  styleUrls: ['./speech-uploadchat.component.sass']
})
export class SpeechUploadchatComponent implements OnInit {
  filter: string = '';
  formFilters:FormGroup; 
  textInputFilter: string = '';
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 1 };
  pageSizeOptions = [5, 10, 25, 50, 100];

  @ViewChild(MatPaginator) paginator: MatPaginator;


  //configuracion informacion a mostar
  configColumnsTable: any[] = [
    { key: 'actions', name: 'Acciones'},
    { key: 'id', name: '#' },
    { key: 'user', name: 'Usuario' },
    { key: 'file', name: 'Nombre archivo' },
    { key: 'state', name: 'Estado' },
    { key: 'creation_date', name: 'Fecha creación'},
  ]; 

  //configuracion columnas tabla
  keysColumnsTable: string[] = [];
  displayedColumns: string[] = ['actions','id', 'user', 'file', 'state', 'creation_date'];

//datasoure para manejo informacion en la tabla
  dataSource = new MatTableDataSource<any>();
  
//marsort para ordenar los datos cargados por la tabla
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
    this.uploadData();
  }

  constructor(
    private dialog:MatDialog,
    private chatService: ChatService,
  ) { }



  /**
   * Metodo abrir modal para cargar el dcumento excel
   * @author Yeison Sepulveda
   * @createdate 2024-07-09
  */
  uploadExcel(): void {
    const dialogRef= this.dialog.open(UploadChatsModalComponent, {
        width: '50%',
        maxWidth: '80vw',
        maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-category',
      data: {
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.uploadData();  
    });
  }

  /**
   * Metodo que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
   * @param $event Evento emitido por el paginador.
  */

  changePage($event: PageEvent): void {
    this.configPaginator.pageSize  = $event.pageSize;
    this.configPaginator.pageIndex  = $event.pageIndex + 1;
    this.uploadData();
  }

  /**
   * Metodo que filtra los datos de la tabla.
    * @author Yeison Sepulveda
    * @createdate 2024-02-15
    * @param $event Evento emitido por el input.
  */

  onSearchClick(value: string): void {
    this.configPaginator.pageIndex= 0;
    this.paginator.firstPage();
    this.filter = value;
    this.uploadData(); 
  }

  /**
   * Metodo obtine y carga la informacion de los cargues al datasource. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  uploadData(): void {
    this.chatService.uploadDataPaginate(this.filter, this.configPaginator).subscribe(
      res => {
        this.dataSource.data = res.data.data;
        this.dataSource.sort = this.sort;
        this.configPaginator.length = res.data.total;
      },
      error => {
        console.error('Error al cargar la información:', error);
      }
    );
  }

  /**
   * Metodo confirmar sid esea descargar el archivo
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
  */

  downloadUpload(element: any): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Estás seguro de descargar el documento?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.downloadFile(element.id, element.file);
      } else {
        element.active = !element.active;
      }
    });
  }
  
  /**
   * Metodo descargar documento excel subido de la carga
   * @author Yeison Sepulevda
   * @createdate 2024-07-10
   * 
  */
  downloadFile(id: number, filename: string): void {
    this.chatService.dowloadUploadTemplate(id).subscribe(
      (response: any) => {
        //objeto Blob con el contenido del archivo
        const blob = new Blob([response], { type: 'application/octet-stream' });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename; //asignar nombre documento
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      (error) => {
        console.error('Error al descargar el', error);
        Swal.fire({
          title: 'Error al descargar',
          text: 'Ha ocurrido un error al descargar el excel',
          icon: 'error',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
      }
    );
  }

  /**
   * Metodo confirmar eliminar el documento
   * @author Yeison Sepulevda
   * @createdate 2024-02-15
  */
  deleteUpload(element: any): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Estás seguro de eliminar el documento?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#2CABBC',
      cancelButtonColor: '#FFFFFF',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const body= {
          id: element
        }
        this.chatService.deleteUploadChatFile(body).subscribe(
          (response: any) => {
            console.log(response.message);
            this.uploadData();
          },
          (error) => {
            console.error('Error al eliminar el', error.message);
            Swal.fire({
              title: 'Opps...',
              text: 'Ha ocurrido un error al eliminar el archivo',
              icon: 'error',
              confirmButtonText: '¡Entendido!',
              confirmButtonColor: '#2CABBC',
            });
          }
        );
      } else {
        element.active = !element.active;
      }
    });
  }


}
