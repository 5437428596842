<div class="container-fluid p-5">
  <div class="pb-4">
    <button (click)="backToPreviousPage()" mat-mini-fab aria-label="Boton para regresar a la página anterior"
      style="background-color: #353535;">
      <i class="fi-rr-angle-small-left fs-4" style="color: #FBFBFB;"></i>
    </button>
    <span class="ms-2" style="font-weight: 600; font-size: 16px;">Regresar</span>
  </div>
  <div class="row mt-5">
    <div class="col-md-12 pb-3">
      <span style="font-size: 32px; font-weight: 700;">Análisis de correos</span>
    </div>
    <div class="col-md-12">
      <p class="lh-base" style="color: #353535">
        En este módulo podrás realizar la carga y el análisis de correos mediante el uso de la IA. <br />
        No olvides, “El respeto y la empatía son clave”.
      </p>
    </div>
  </div>
  <form [formGroup]="formFilter">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Cuenta de correo</mat-label>
          <mat-select (selectionChange)="onChangeSelectAccountEmail($event)" formControlName="email_list_id">
            <mat-option *ngFor="let email of emailsConfig" [value]="email.id">{{ email.name
              }}</mat-option>
          </mat-select>
          <mat-error *ngIf="error.email_list_id.errors">
            <span *ngIf="error.email_list_id.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Bandejas y carpetas</mat-label>
          <mat-select formControlName="id_bandeja">
            <mat-optgroup *ngFor="let group of traysAndFolders" [label]="group.name">
              <mat-option *ngFor="let item of group.list" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error *ngIf="error.id_bandeja.errors">
            <span *ngIf="error.id_bandeja.errors.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Asunto</mat-label>
          <input matInput placeholder="Asunto" formControlName="asunto">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Rango de fechas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input formControlName="date_in" matStartDate placeholder="Fecha inicial">
            <input formControlName="date_end" matEndDate placeholder="Fecha final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="error.date_in?.errors || error.date_end?.errors">
            <span *ngIf="error.date_in.errors?.required || error.date_end.errors?.required">El campo es requerido</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Palabras clave</mat-label>
          <input matInput placeholder="Palabras clave" formControlName="contiene_palabras">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <div class="col-md-6">
            <button (click)="getEmails(true, true)" color="primary" type="button" mat-raised-button class="w-100"
              style="height: 48px; font-weight: 600;" [disabled]="stateButtonForm">
              <span class="fi-rr-filter me-2"></span>
              Filtrar
            </button>
          </div>
          <div class="col-md-6">
            <button color="primary" type="button" mat-raised-button class="w-100"
              style="height: 48px; font-weight: 600;" mat-button [matMenuTriggerFor]="menu">
              Más opciones
            </button>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="clearValuesAdvanceFilters()">Limpiar filtros</button>
            <button mat-menu-item *ngIf="emailsTableSelected.length > 0" (click)="analysisEmailsSelected()">Analizar
              correos</button>
            <button mat-menu-item (click)="onClickDownloadReportExcel()">Descargar excel</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </form>
  <div class="row mt-2">
    <div class="col-md-12">
      <div class="col-md-12">
        <table mat-table [dataSource]="dataTable">
          <ng-container *ngFor="let column of configColumnsTable">
            <ng-container [matColumnDef]="column.key" *ngIf="column.key === 'actions'">
              <th mat-header-cell *matHeaderCellDef><mat-checkbox [(ngModel)]="checkAllEmails"
                  (change)="onChangeCheckboxAllSelected($event)" color="primary"></mat-checkbox></th>
              <td mat-cell width="10%" class="text-center ps-0" *matCellDef="let element">
                <div class="d-flex flex-row justify-content-center align-items-center gap-2">
                  <mat-checkbox (change)="onChangeCheckboxSelected($event, element)" [checked]="ifSelectedMail(element)"
                    class="ms-4" color="primary"></mat-checkbox>
                  <div class="d-flex flex-row mt-2 gap-2">
                    <i class="fi-rr-eye" style="cursor: pointer;" (click)="showEmailHistory(element)"></i>
                    <i *ngIf="element.stateIa && element.stateIa === 'COMPLETADO'" class="fi-rr-search" style="cursor: pointer;" (click)="showDialogAnalysisResult(element)"></i>
                    <i *ngIf="element.stateIa && element.stateIa === 'ERROR'" class="fi-rr-cross-circle" style="cursor: pointer;" (click)="showAlertByMessageError(element)"></i>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container [matColumnDef]="column.key" *ngIf="column.key !== 'actions'">
              <th mat-header-cell *matHeaderCellDef> {{ column.name }} </th>
              <td mat-cell [matTooltip]="element[column.key]" [matTooltipPosition]="'above'" *matCellDef="let element">
                {{ element[column.key] | limitText:30 }} </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="keysColumnsTable"></tr>
          <tr style="background: white" mat-row *matRowDef="let row; columns: keysColumnsTable;"></tr>
          <tr class="mat-row" style="background: white" *matNoDataRow>
            <td class="mat-cell fw-bold fs-5" [attr.colspan]="keysColumnsTable.length">{{ textLoadingTable }}</td>
          </tr>
        </table>
        <mat-paginator (page)="onChangePage($event)" [length]="configPaginator.length"
          [pageIndex]="configPaginator.pageIndex" [pageSize]="configPaginator.pageSize"
          [pageSizeOptions]="[5, 10, 20, 50, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>