import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-move-email-tray',
  templateUrl: './move-email-tray.component.html',
  styleUrls: ['./move-email-tray.component.css']
})
export class MoveEmailTrayComponent implements OnInit {
  formMoveEmail: FormGroup = new FormGroup({
    id_bandeja: new FormControl('', [Validators.required])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<MoveEmailTrayComponent>,
    private matSnackBar: MatSnackBar,
    private emailService: EmailService) { }

  ngOnInit(): void {

  }

  /**
    * @author Fabian Duran
    * @createdate 2024-01-26
    * Metodo que guarda el movimiento del correo entre bandejas o carpetas. 
  */
  saveMoveEmail(): void {
    if (this.formMoveEmail.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          const formData = new FormData();
          formData.append('id_bandeja', this.formMoveEmail.value.id_bandeja);
          formData.append('email_list', `[${this.data.emails.toString()}]`);
          this.emailService.moveEmails(formData).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: res.data
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          })
        }
      });
    } else {
      this.formMoveEmail.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-30
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formMoveEmail.controls;
  }
}