import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { EmailService } from '../../services/email.service';
import { Button, ConfigTable, Paginator } from 'src/app/shared/basic-table/type';
import { ManagementHistoryViewComponent } from '../../components/management-history-view/management-history-view.component';
import { MatDialog } from '@angular/material/dialog';
import { EmailSupportService } from '../../services/email-support.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';

@Component({
  selector: 'app-email-support',
  templateUrl: './email-support.component.html',
  styleUrls: ['./email-support.component.css']
})
export class EmailSupportComponent implements OnInit {
  formFilterTabOne: any[] = [
    { typeInput: 'select', key: 'emailConfig', label: 'Cuenta de correo', value: null, options: [], class: 'col-md-3' },
    {
      typeInput: 'date-range', key: 'dateRange', show: true, class: 'col-md-3', range: [
        { key: 'date_start', rules: null, value: null },
        { key: 'date_end', rules: null, value: null },
      ]
    },
    { typeInput: 'input', key: 'subject', label: 'Asunto', value: null, class: 'col-md-3' },
    { typeInput: 'input', key: 'from', label: 'De', value: null, class: 'col-md-3', rules: { email: true } }
  ];
  buttonsOptionsTableOne: Button[] = [
    { class: 'fi-rr-eye', action: 'view' },
    { class: 'fi-rr-add', action: 'save' }
  ];
  formFilterTabTwo: any[] = [
    { typeInput: 'select', key: 'emailConfig', label: 'Cuenta de correo', value: null, options: [], class: 'col-md-3' },
    {
      typeInput: 'date-range', key: 'dateRange', show: true, class: 'col-md-3', range: [
        { key: 'date_start', rules: null, value: null },
        { key: 'date_end', rules: null, value: null },
      ]
    },
    { typeInput: 'input', type: 'number', key: 'idEmail', label: 'Id de correo', value: null, class: 'col-md-3' },
  ];
  formFilterTabThree: any[] = [
    { typeInput: 'select', key: 'email_config_id', label: 'Cuenta de correo', value: null, options: [], class: 'col-md-3', rules: { required: true } },
    {
      typeInput: 'date-range', key: 'dateRange', show: true, class: 'col-md-3', range: [
        { key: 'date_start', rules: { required: true }, value: null },
        { key: 'date_end', rules: { required: true }, value: null },
      ]
    },
    { typeInput: 'input', key: 'time_start', label: 'Hora inicio', value: null, class: 'col-md-3', type: 'time' },
    { typeInput: 'input', key: 'time_end', label: 'Hora fin', value: null, class: 'col-md-3', type: 'time' },
    { typeInput: 'input', type: 'text', key: 'subject', label: 'Asunto', value: null, class: 'col-md-3' },
    { typeInput: 'input', key: 'from', label: 'De', value: null, class: 'col-md-3', rules: { email: true } },
    { typeInput: 'input', key: 'uid', label: 'UID', value: null, class: 'col-md-3' }
  ];
  configColumnsTableTabOne: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'UID', key: 'id_correo' },
    { name: 'De', key: 'de' },
    { name: 'Asunto', key: 'asunto' },
    { name: 'Fecha', key: 'fecha_correo' },
    { name: 'Error', key: 'error' },
    { name: 'Email padre', key: 'email_padre' },
  ];
  configPaginatorTableTabOne: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  dataTableTabOne: any[] = [];
  configColumnsTableTabThree: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'UID', key: 'uid' },
    { name: 'Asunto', key: 'asunto' },
    { name: 'Fecha', key: 'fecha_hora' },
    { name: 'De', key: 'de' }
  ];
  buttonsOptionsTableThree: Button[] = [{ class: 'fi-rr-eye', action: 'view' }];
  dataTableTabThree: any[] = [];

  constructor(
    private location: Location,
    private emailService: EmailService,
    private matDialog: MatDialog,
    private emailSupportService: EmailSupportService,
    private snackBarService: SnackBarService,
    private alertService: AlertsService
  ) { }

  ngOnInit(): void {
    this.getEmailsConfig();
    this.getLogErrors({});
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-05-15
    * Metodo que redirecciona a la pagina anterior visitada. 
  */
  backToPreviousPage(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-15
    * Metodo que consulta las cuentas de correo registradas.
  */
  getEmailsConfig(): void {
    this.emailService.getEmailsConfig().subscribe(res => {
      const emailsConfig = res.data.map((item: any) => {
        return { value: item.id, text: item.user_name_email_entrada };
      });
      this.formFilterTabOne[0].options = emailsConfig;
      this.formFilterTabTwo[0].options = emailsConfig;
      this.formFilterTabThree[0].options = emailsConfig;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-15
    * Metodo que recibe los valores de los filtros de busqueda.
    * @param $event Data emitida por el componente hijo.
    * @param typeFormFilter Tipo de busqueda que se va a realizar. 
  */
  sendFilters($event: any, typeFormFilter: number): void {
    if (typeFormFilter === 1) {
      this.configPaginatorTableTabOne.pageIndex = 0;
      this.formFilterTabOne[0].value = $event.emailConfig;
      this.formFilterTabOne[1].range[0].value = $event.date_start;
      this.formFilterTabOne[1].range[1].value = $event.date_end;
      this.formFilterTabOne[2].value = $event.subject;
      this.formFilterTabOne[3].value = $event.from;
      this.getLogErrors({
        idEmailConfig: this.formFilterTabOne[0].value,
        dateIn: this.formFilterTabOne[1].range[0].value,
        dateEnd: this.formFilterTabOne[1].range[1].value,
        subject: this.formFilterTabOne[2].value,
        from: this.formFilterTabOne[3].value
      });
    }
    else if (typeFormFilter === 3) {
      if (this.validateTimeRange($event.time_start, $event.time_end) || this.validateTimeRange($event.time_start, $event.time_end) === null) {
        this.formFilterTabThree[0].value = $event.email_config_id;
        this.formFilterTabThree[1].range[0].value = $event.date_start;
        this.formFilterTabThree[1].range[1].value = $event.date_end;
        this.formFilterTabThree[2].value = $event.subject;
        this.formFilterTabThree[3].value = $event.time_start;
        this.formFilterTabThree[4].value = $event.time_end;
        this.formFilterTabThree[5].value = $event.from;
        this.formFilterTabThree[6].value = $event.uid;
        this.onSubmitSearchEmail({
          email_config_id: this.formFilterTabThree[0].value,
          date_start: this.formFilterTabThree[1].range[0].value,
          date_end: this.formFilterTabThree[1].range[1].value,
          subject: this.formFilterTabThree[2].value,
          time_start: this.formFilterTabThree[3].value,
          time_end: this.formFilterTabThree[4].value,
          from: this.formFilterTabThree[5].value = $event.from,
          uid: this.formFilterTabThree[6].value = $event.uid
        })
      } else this.alertService.alertSuccess('Error de validación', 'Verifique los rangos de tiempo', { timer: null, icon: 'warning' });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-15
    * Metodo que actualiza la información de la paginación en las tablas.
    * @param $event Data emitida por el componente hijo.
  */
  onChangePageTable($event: Paginator,): void {
    this.configPaginatorTableTabOne = $event;
    this.getLogErrors({ idEmailConfig: this.formFilterTabOne[0].value, dateIn: this.formFilterTabOne[1].range[0].value, dateEnd: this.formFilterTabOne[1].range[1].value, subject: this.formFilterTabOne[2].value, from: this.formFilterTabOne[3].value });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-15
    * Metodo que permitira vizualizar el correo seleccionado.
    * @param $event Data emitida por el componente hijo.
  */
  onClickButtonActionTableThree($event: any): void {
    this.matDialog.open(ManagementHistoryViewComponent, {
      width: '95%',
      maxHeight: '95vh',
      autoFocus: false,
      panelClass: 'dialog-padding-management-history-view',
      data: {
        management: $event.element,
        toPreview: true,
        showButtonPrint: false,
        showButtonAddEmail: true,
        showDetails: false
      }
    }).afterClosed().subscribe(res => {
      this.emailSupportService.addEmailExternalAccount({ email_config_id: this.formFilterTabThree[0].value, uid: res.uid }).subscribe(res => {
        this.snackBarService.showNotification({ message: res.data });
      });
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-23
    * Metodo que retorna los logs de errores registrados en las gestiones de las cuentas de correo. 
    * @param idEmailConfig Cuenta de correo seleccionada.
    * @param dateIn Fecha inicial.
    * @param dateEnd Fecha final.
  */
  getLogErrors({ idEmailConfig = null, dateIn = null, dateEnd = null, subject = null, from = null }): void {
    this.emailSupportService.getLogsErrorsEmails({
      paginator: this.configPaginatorTableTabOne,
      idEmailConfig,
      dateIn,
      dateEnd,
      subject,
      from
    }).subscribe(res => {
      this.dataTableTabOne = res.data;
      this.configPaginatorTableTabOne.length = res.total;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-27
    * Metodo que setea la data en la tabla de la tercera tab. 
    * @param email_config_id Cuenta de correo seleccionada.
    * @param subject Nombre del asunto del correo.
    * @param date_start Fecha inicial del rango.
    * @param date_end Fecha final del rango.
  */
  onSubmitSearchEmail({ email_config_id, subject = null, date_start, date_end, time_start = null, time_end = null, from = null, uid = null }): void {
    this.emailSupportService.searchEmailExternal({ email_config_id, subject, date_start, date_end, time_start, time_end, from, uid }).subscribe(res => {
      this.dataTableTabThree = res.data;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-05-29
    * Metodo que permite ver o guardar el correo que tuvo errores
    * @param $event Data emitida por el componente hijo.
  */
  onClickButtonActionTableOne($event: any): void {
    if ($event.action === 'view') {
      const htmlToAlert = `
        <div class="d-flex flex-column">
          <div><span class="fw-bold">UID: </span>${$event.element.id_correo}</div>
          <div><span class="fw-bold">De: </span>${$event.element.de}</div>
          <div><span class="fw-bold">Asunto: </span>${$event.element.asunto}</div>
        </div>
      `;
      this.alertService.alertSuccess('Información del correo', htmlToAlert, { timer: null, icon: 'info' });
    } else {
      this.alertService.alertWarning('¿Está seguro?', '¿De registrar de nuevo el correo?').then(res => {
        if (res.isConfirmed) {
          this.emailSupportService.addEmailExternalAccount({ email_config_id: $event.element.id_email_config, uid: $event.element.id_correo }).subscribe(res => {
            this.snackBarService.showNotification({ message: res.data });
          });
        }
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-07-08
    * Metodo que valida un rango de tiempo emitido
    * @param timeStart Tiempo de inicio.
    * @param timeEnd Tiempo final.
  */
  validateTimeRange(timeStart: string | null, timeEnd: string | null): boolean | null {
    if (!timeStart && !timeEnd) return null;
    if ((timeStart && !timeEnd) || (!timeStart && timeEnd)) return false;
    const [startHour, startMinute] = timeStart.split(':').map(Number);
    const [endHour, endMinute] = timeEnd.split(':').map(Number);
    if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) return false;
    return true;
  }
}