import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EDITOR_CONFIG } from '../../constantes/mail-config-editor';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { validateArrayNotEmpty, validateIsEmails } from '../../constantes/custom-validations';
import { ContactGroupComponent } from '../contact-group/contact-group.component';
import { MatDialog } from '@angular/material/dialog'
import { MatSelectChange } from '@angular/material/select';
import { EmailService } from '../../services/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-mail-form',
  templateUrl: './mail-form.component.html',
  styleUrls: ['./mail-form.component.css']
})
export class MailFormComponent implements OnInit, OnChanges {
  @ViewChild('chipListTo') chipListTo: MatChipList;
  @ViewChild('chipListCc') chipListCc: MatChipList;
  @ViewChild('chipListCco') chipListCco: MatChipList;
  @ViewChild('editor') editor: any;
  @Input() formNewMessage: FormGroup = null;
  @Input() typeManagerMessage: string = 'Enviar';
  @Input() templates: any[] = [];
  @Input() filesToLoadEmail: any[] = [];
  @Input() preloadAttachments: any[] = [];
  @Input() labels: any[] = [];
  @Output() onChangeTemplate: EventEmitter<number> = new EventEmitter<number>();
  @Output() onDeleteFilesToLoadEmail: EventEmitter<number> = new EventEmitter<number>();
  @Output() clearMessage: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePreloadAttachments: EventEmitter<number> = new EventEmitter<number>();
  @Output() onChangeLabelSelected: EventEmitter<string> = new EventEmitter<string>();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ccSelect: boolean = false;
  ccoSelect: boolean = false;
  editorConfig: any = EDITOR_CONFIG;
  filteredOptions: Observable<any[]> = null;
  nameLabel: string = '';
  labelSelected: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private emailService: EmailService,
    private matSnackBar: MatSnackBar,
    private alertService: AlertsService
  ) { }

  ngOnInit(): void {
    this.formNewMessage.get('to')?.statusChanges.subscribe(status => { this.chipListTo.errorState = status === 'INVALID'; });
    this.filteredOptions = this.formNewMessage.get('label').valueChanges.pipe(
      startWith(''),
      map(value => this.filterLabel(value))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formNewMessage.get('cc') && this.formNewMessage.get('cc').value !== null && this.formNewMessage.get('cc').value.length > 0) this.ccSelect = true;
    if (this.formNewMessage.get('cco') && this.formNewMessage.get('cco').value !== null && this.formNewMessage.get('cco').value.length > 0) this.ccoSelect = true;
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-11-01
    * Filtra el array de labels a traves de lo digitado en el input.  
  */
  private filterLabel(value: string): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.labels.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-01
    * Metodo que busca la etiqueta para sacar el nombre. 
    * @param $event Evento emitido por el option. 
  */
  findLabel($event: MatOptionSelectionChange): void {
    const label = this.labels.find(item => item.content == $event.source.value);
    if (label) {
      this.nameLabel = label.name;
      this.labelSelected = label.content;
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-01
    * Metodo que agrega el contenido de la etiqueta al texto enriquecido. 
  */
  onClickSelectedLabelToInput(): void {
    this.editor.focus();
    this.editor.editorService.restoreSelection();
    this.editor.editorService.insertHtml(this.labelSelected);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-30
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formNewMessage.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-29
    * Metodo que agrega una cadena de texto a cada uno de los chip list.
    * @param event Manejador de eventos de los mat chip.
    * @param typeList Nombre del list chip en el cual se van agregar una cadena de texto.  
  */
  addEmail($event: MatChipInputEvent, typeList: string): void {
    const input = $event.input;
    const value = ($event.value || '').trim();
    if (value && value !== '') {
      const newFormGroup = this.formBuilder.group({ name: new FormControl(value, [Validators.email]) });
      const arrayForm = this.formNewMessage.get(typeList) as FormArray;
      arrayForm.push(newFormGroup);
      if (input) input.value = '';
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-29
    * Metodo que elimina una cadena de texto dentro un chip list.
    * @param index Cadena de texto seleccionada dentro del chip list. 
    * @param typeList Nombre del chip list en el cual se va a remover el elemento.
  */
  removeItem(index: number, typeList: string): void {
    const arrayForm = this.formNewMessage.get(typeList) as FormArray;
    arrayForm.removeAt(index);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-29
    * Metodo agrega un archivo a la lista de archivos del formulario. 
    * @param $event Evento emitido por el campo tipo archivo. 
  */
  addFile($event: any) {
    const files = $event.target.files;
    for (let i = 0; i < files.length; i++) {
      const newFormGroup = this.formBuilder.group({ file: new FormControl(files[i], []) });
      const arrayForm = this.formNewMessage.get('attachments') as FormArray;
      arrayForm.push(newFormGroup);
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-30
    * Metodo que muestra u oculta el campo con copia.  
  */
  onChangeStateCcSelect(): void {
    this.ccSelect = this.updateValidatorsFields(this.ccSelect, 'cc');
    this.formNewMessage.get('cc').statusChanges.subscribe(status => { this.chipListCc.errorState = status === 'INVALID'; });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-30
    * Metodo que muestra u oculta el campo con copia.  
  */
  onChangeStateCcoSelect(): void {
    this.ccoSelect = this.updateValidatorsFields(this.ccoSelect, 'cco');
    this.formNewMessage.get('cco').statusChanges.subscribe(status => { this.chipListCco.errorState = status === 'INVALID'; });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-06-30
    * Metodo que setea las validaciones segun el campo pasado por parametro. 
    * @param stateField Estado de la vizualización del campo.
    * @param nameField Nombre del campo del formulario.  
  */
  updateValidatorsFields(stateField: boolean, nameField: string): boolean {
    const newStateField = !stateField;
    const arrayField = this.formNewMessage.get(nameField) as FormArray;
    arrayField.clear();
    if (newStateField) this.formNewMessage.get(nameField).setValidators([validateArrayNotEmpty, validateIsEmails]);
    else this.formNewMessage.get(nameField).clearValidators();
    this.formNewMessage.get(nameField).updateValueAndValidity();
    return newStateField;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-02
    * Metodo que muestra la modal de grupos de contacto. 
    * @param nameField Nombre del campo de donde esta ubicado el boton.
  */
  onClickOpenDialogContacts(nameField: string): void {
    this.matDialog.open(ContactGroupComponent, {
      width: '70%',
      autoFocus: false,
      panelClass: 'dialog-padding-contact-group',
      maxHeight: '95vh',
      data: {
        nameField
      }
    }).afterClosed().subscribe(res => {
      if (res.contactGroupSelected && res.contactGroupSelected.length > 0)
        this.addMailsContactGroup(res.contactGroupSelected, res.nameField);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-02
    * Metodo que registra los correos de los grupos de contacto sobre los respectivos campos de texto.
    * @param mails Informacion del grupo de contacto. 
    * @param nameField Nombre del campo de donde esta ubicado el boton.
  */
  addMailsContactGroup(mails: any, nameField: string): void {
    const mailsToSplit = mails.map((item: any) => { return { ...item, mails: item.email_list.split(',') }; });
    mailsToSplit.forEach((contactGroup: any) => {
      contactGroup.mails.forEach((mail: string) => {
        const newFormGroup = this.formBuilder.group({ name: new FormControl(mail, [Validators.email]) });
        const arrayForm = this.formNewMessage.get(nameField) as FormArray;
        arrayForm.push(newFormGroup);
      });
    });
    this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
      data: {
        title: '¡Excelente!',
        message: 'Se ha añadido el grupo de contacto con éxito'
      },
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['green-snackbar']
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-11
    * Metodo que envia al componente padre la plantilla seleccionada desde el formulario.
    * @param $event Evento emitido por el select.  
  */
  onChangeTemplateSelected($event: MatSelectChange): void {
    this.onChangeTemplate.emit($event.value);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-15
    * Metodo que elimina un adjunto de un correo. 
    * @param idFile Id del archivo seleccionado.  
  */
  deleteFileByEmail(idFile: number): void {
    this.emailService.deleteAttachment(idFile).subscribe(res => {
      if (res) this.onDeleteFilesToLoadEmail.emit(idFile);
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-01-04
    * Metodo que envia el id del adjunto seleccionado al componente padre.  
    * @param index Id del archivo seleccionado.  
  */
  deletePreloadAttachments(index: number): void {
    this.updatePreloadAttachments.emit(index);
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-10-18
    * Metodo que agrega contenido al texto enriquecido de acuerdo a la etiqueta seleccionada.
    * @param label Etiqueta seleccionada. 
  */
  onClickSelectLabel(label: string): void {
    this.alertService.alertWarning('Información de la etiqueta', label, 'Agregar', 'Cancelar', { icon: 'info' }).then(res => {
      if (res.isConfirmed) {
        this.editor.focus();
        this.editor.editorService.restoreSelection();
        this.editor.editorService.insertHtml(label);
      }
    })
  }
}