import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssignPriorityService } from '../../services/assign-priority.service';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { validateArrayNotEmpty, validateIsEmails } from '../../constantes/custom-validations';

@Component({
  selector: 'app-assign-priority',
  templateUrl: './assign-priority.component.html',
  styleUrls: ['./assign-priority.component.css']
})
export class AssignPriorityComponent implements OnInit {
  @ViewChild('chipListEmails') chipListEmails: MatChipList;
  formAssignPriority: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    id_email_config: new FormControl('', [Validators.required]),
    email: new FormControl('', []),
    listEmails: this.formBuilder.array([], []),
    id_bandeja: new FormControl('', [Validators.required])
  });
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertsService,
    private matDialogRef: MatDialogRef<AssignPriorityComponent>,
    private matSnackBar: MatSnackBar,
    private assignPriorityService: AssignPriorityService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.data.assignPriority) {
      this.formAssignPriority.get('id').setValue(this.data.assignPriority.id);
      this.formAssignPriority.get('id_email_config').setValue(this.data.assignPriority.id_email_config);
      this.formAssignPriority.get('email').setValue(this.data.assignPriority.email);
      this.formAssignPriority.get('id_bandeja').setValue(this.data.assignPriority.id_bandeja);
      this.formAssignPriority.get('email').setValidators([Validators.required, Validators.email]);
      this.formAssignPriority.get('listEmails').setValidators([]);
      this.formAssignPriority.updateValueAndValidity();
    } else {
      this.formAssignPriority.get('listEmails')?.statusChanges.subscribe(status => { this.chipListEmails.errorState = status === 'INVALID'; });
      this.formAssignPriority.get('email').setValidators([]);
      this.formAssignPriority.get('listEmails').setValidators([validateArrayNotEmpty, validateIsEmails]);
      this.formAssignPriority.updateValueAndValidity();
    }
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-18
    * Metodo que retorna los errores generados por el formulario. 
  */
  get error(): any {
    return this.formAssignPriority.controls;
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-08-23
    * Metodo que guarda o actualiza una asignacion prioritaria. 
  */
  saveAssignPriority(): void {
    if (this.formAssignPriority.valid) {
      this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
        if (isConfirm.isConfirmed) {
          const dataFormAssignPriority = { 
            ...this.formAssignPriority.value,
            email: this.data.assignPriority ? 
              this.formAssignPriority.value.email : 
              this.formAssignPriority.value.listEmails.map((item: any) => item.name).join(';')
          };
          this.assignPriorityService.storeAssignPriority(dataFormAssignPriority).subscribe(res => {
            this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
              data: {
                title: '¡Excelente!',
                message: res.data
              },
              duration: 5000,
              verticalPosition: 'top',
              panelClass: ['green-snackbar']
            });
            this.matDialogRef.close(res);
          });
        }
      });
    } else {
      this.formAssignPriority.markAllAsTouched();
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-08
    * Metodo que agrega una cadena de texto a cada uno de los chip list.
    * @param event Manejador de eventos de los mat chip.
  */
  addEmail($event: MatChipInputEvent): void {
    const input = $event.input;
    const value = ($event.value || '').trim();
    if (value && value !== '') {
      const newFormGroup = this.formBuilder.group({ name: new FormControl(value, [Validators.email]) });
      const arrayForm = this.formAssignPriority.get('listEmails') as FormArray;
      arrayForm.push(newFormGroup);
      if (input) input.value = '';
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-08
    * Metodo que elimina una cadena de texto dentro un chip list.
    * @param index Cadena de texto seleccionada dentro del chip list. 
  */
  removeItem(index: number): void {
    const arrayForm = this.formAssignPriority.get('listEmails') as FormArray;
    arrayForm.removeAt(index);
  }
}