import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { CampaignService } from '../../../../services/campaign.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AdminServersService } from 'src/app/modules/speech-analytics/services/admin-servers.service';
import { ViewService } from 'src/app/modules/speech-analytics/services/view.service';
import { ModelIAService } from 'src/app/modules/speech-analytics/services/model_ia.service';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.sass']
})
export class CreateCampaignComponent implements OnInit {

  createCampaignForm: FormGroup;
  filteredOptionsCampaign: Observable<any[]>;
  campaigns: any;
  connections: any;
  fecha_llamada: any;
  modelsIA: any;

  constructor(
    public dialogRef: MatDialogRef<CreateCampaignComponent>,
    private form: FormBuilder,
    private adminServersService: AdminServersService,
    private modelsIAService: ModelIAService,
    private campaignService: CampaignService,
    private viewservice: ViewService,  ) { }

  ngOnInit(): void {
    this.formControl();
    this.getConexiones();
    this.getModelsIA();
    this.subscribeToCampaignSelection();
  }

  /**
   * Método donde se establecen las validaciones del formulario y adviarte antes de refrescar 
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   * @method unloadNotification
   * @param {Event} $event - El evento `beforeunload` del navegador.
   * @returns {void}
  */

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.createCampaignForm.dirty) {
      $event.returnValue = true;
    }
  }
  
  /**
   * Metodo donde se establecen las validaciones del formulario
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */
  
  formControl(): void {
    this.createCampaignForm = this.form.group({
      campaign_name: new FormControl('', Validators.required),
      campaign: new FormControl('', [Validators.required, this.campaignValidator.bind(this)]),
      dead_time: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
      campaign_bilingue: new FormControl('', Validators.required),
      campaign_conexion: new FormControl('', Validators.required),
      model_ia: new FormControl('', Validators.required),
      delimit_date: new FormControl('')
    });
  }

  /**
   * Metodo validar rango de fecha para desabilotar input fecha 2 meses
   * @author Yeison sepulveda
   * @createdate    14/03/2024
   * @returns void {void} 
   */
  dateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    const today = new Date();
    return date <= today;
  }
  
  /**
   * Metodo para traer todas las campañas
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */

  getCampaign(id_server: number): void {
    this.viewservice.getCampaigns(id_server).subscribe((resp) => {
      this.campaigns = resp.data;
    
      this.filteredOptionsCampaign = this.createCampaignForm.get('campaign')!.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : this.displayFnCampaign(value)),
        map(name => name ? this._filterCampaign(name) : this.campaigns.slice())
      );
    });
  }

  /**
   * Metodo para obtener la data de lso selctores segun el tipo de llamada
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */
  CampaignChange():void{
    if(this.createCampaignForm.get("campaign_conexion").value !== null && this.createCampaignForm.get("campaign_conexion").value !== "" ){
      const connection_id = this.createCampaignForm.get("campaign_conexion").value;
      this.getCampaign(connection_id);
    }
  }

  /**
   * Metodo para obtener fecha llamada segun la selccion de la campaña
   * @author Yeison Sepulveda
   * @createdate 2024-08-30
   */
  subscribeToCampaignSelection(): void {
    this.createCampaignForm.get('campaign')!.valueChanges.subscribe(campaignId => {
        const selectedCampaign = this.campaigns.find(c => c.campaign_id === campaignId);
        if (selectedCampaign) {
            this.fecha_llamada = selectedCampaign.fecha_llamada;
        }
    });
  }

  /**
   * Metodo obtener el listado de las conexiones activas para selector
   * @author Yeison Sepulveda
   * @createdate 2024-02-14
   */

  getConexiones(): void {
    this.adminServersService.filterServe().subscribe(
      result => {
        this.connections = result.data;
      },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.error,
          timer: 2000,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.dialogRef.close();
          }
        });
      }
    );
  }

  /**
   * Metodo obtener el listado de los modelos de intreligencia artificial activos
   * @author Yeison Sepulveda
   * @createdate 2024-10-21
   */

  getModelsIA(): void {
    this.modelsIAService.listModels().subscribe(
      result => {
        this.modelsIA = result.data;
      },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
          timer: 2000,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.dialogRef.close();
          }
        });
      }
    );
  }
  
  /**
   * Metodo que devuelve el nombre al momento de seleccionar una opcion
   * @author Daniel Martinez
   * @createdate 2021-02-04
   */
  displayFnCampaign(campaign_id: number): string {
    if (!campaign_id || !this.campaigns) {
      return '';
    }
    const campaign = this.campaigns.find(c => c.campaign_id === campaign_id);
    return campaign ? campaign.campaign_name : '';
  }

  /**
   * Filtra por nombre de rol, se usa para el autocompletable
   * @author Yeison Sepulveda
   * @createdate 2024-08-30
   * @param value valor a filtrar
   */
  private _filterCampaign(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.campaigns.filter(option => option.campaign_name.toLowerCase().includes(filterValue));
  }

  /**
   * Metodo que guarda o actualiza un servidor. 
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  sendDataCampaign() {
    if (this.createCampaignForm.valid) {
      Swal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: '¿De continuar con la gestión?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const dataToSend = {
            name: this.createCampaignForm.value.campaign_name,
            campaign_id: this.createCampaignForm.value.campaign,
            dead_time: this.createCampaignForm.value.dead_time,
            bilingue: this.createCampaignForm.value.campaign_bilingue,
            connection_id: this.createCampaignForm.value.campaign_conexion,
            model_ia: this.createCampaignForm.value.model_ia,
            delimit_date: this.createCampaignForm.value.delimit_date
          };
          this.campaignService.CreaCampaign(dataToSend).subscribe(
            res => {
              Swal.fire({
                icon: 'success',
                title: '¡Excelente!',
                text: 'Se ha registrado una nueva campaña',
                confirmButtonText: 'Continuar',
                confirmButtonColor: '#2CABBC',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.dialogRef.close();
                }
              });
            },
            error => {
              console.error('Error al crear la campaña', error.message);
              Swal.fire({
                icon: 'error',
                title: 'Error al crear la campaña',
                text: error.message,
                showConfirmButton: true,
                confirmButtonColor: '#2CABBC',
                confirmButtonText: 'Aceptar',
              });
            }
          );
        }
      });
    } else {
      this.createCampaignForm.markAllAsTouched();
    }
  }
    
  /**
   * Metodo alarta al momento de cancelar proceso
   * @author Yeison Sepulveda
   * @createdate 2024-02-15
  */
  cancelSave(): void {
    if (this.createCampaignForm.dirty) {
      Swal.fire({
        title: '¿Estás seguro?',
        icon: 'warning',
        text: '¿En verdad deseas cancelar la gestión?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#2CABBC',
        cancelButtonColor: '#FFFFFF',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.createCampaignForm.reset();
          this.dialogRef.close();
        }
      });
    }else {
      this.dialogRef.close();
    }
  }

  /**
   * Validador personalizado para el campo 'campaign'.
   * @author Yeison Sepulveda
   * @createdate 2024-07-19
   * @param formGroup El grupo de formularios que contiene el campo 'campaign'.
   * @returns objeto con el error 'invalidCampaign' si el valor es inválido, o null si es válido.
   */
  campaignValidator(control: FormControl): { [key: string]: boolean } | null {
    if (!this.campaigns) {
      return null;
    }
    const isValid = this.campaigns.some(c => c.campaign_id === control.value);
    return isValid ? null : { invalidCampaign: true };
  }
}
