import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chart, registerables } from 'chart.js';
import { OPTIONS_PIE } from 'src/app/modules/speech-analytics/modules/email-manager/utils/config/charts.config';
import { MatAccordion } from '@angular/material/expansion';
import { PDF_OPTIONS } from 'src/app/modules/speech-analytics/modules/email-manager/utils/config/pdf.config';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-analysis-result',
  templateUrl: './analysis-result.component.html',
  styleUrls: ['./analysis-result.component.sass']
})
export class AnalysisResultComponent implements OnInit {
  formatDataByResultIa = [];
  sentimentSummary: { motivo: string, sentimiento: string } = { motivo: 'N/R', sentimiento: 'N/R' };
  ctx: any = null;
  canvas: HTMLCanvasElement = null;
  pieChart: Chart<'pie'> = null;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('container') container: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    Chart.register(...registerables);
    Chart.register(ChartDataLabels);
  }

  ngOnInit(): void {
    this.formatDataByResultIa = [
      { title: 'Resumen: ', content: this.validateContent(this.data.result.resumen) },
      { title: 'Intención: ', content: this.validateContent(this.data.result.intencion) },
      { title: 'Entidades principales: ', content: this.validateContent(this.data.result.entidades_principales) },
      { title: 'Palabras clave: ', content: this.validateContent(this.data.result.palabras_clave) },
      { title: 'Puntos clave: ', content: this.validateContent(this.data.result.puntos_clave) },
      { title: 'Temas principales: ', content: this.validateContent(this.data.result.entidades_principales) }
    ];
    this.sentimentSummary.motivo = (this.data.result.analisis_sentimientos && this.data.result.analisis_sentimientos.motivo) ? this.data.result.analisis_sentimientos.motivo : 'N/R';
    this.sentimentSummary.sentimiento = (this.data.result.analisis_sentimientos && this.data.result.analisis_sentimientos.sentimiento) ? this.data.result.analisis_sentimientos.sentimiento : 'N/R';
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-11-19
    * Metodo que permite pintar la grafica con los datos obtenidos.
  */
  paintPieChart(): void {
    const labels = [];
    const data = [];
    for (const key in this.data.result.analisis_sentimientos.probabilidades) {
      labels.push(key);
      data.push(this.data.result.analisis_sentimientos.probabilidades[key]);
    }
    const dataPie = {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: this.generateColorsByChart(data.length),
        hoverOffset: 4
      }]
    };
    this.canvas = <HTMLCanvasElement>document.getElementById('pieChart');
    this.ctx = this.canvas.getContext('2d');
    this.pieChart = new Chart(this.ctx, {
      type: 'pie',
      data: dataPie,
      options: OPTIONS_PIE
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-19
    * Metodo que genera colores aleatorios dentro de un array.
    * @param length Longitud del array proveniente de las grafica. 
  */
  generateColorsByChart(length: number): Array<string> {
    const arrayColors = [];
    for (let index = 0; index < length; index++) {
      const r = Math.floor(Math.random() * 200) + 55;
      const g = Math.floor(Math.random() * 200) + 55;
      const b = Math.floor(Math.random() * 200) + 55;
      arrayColors.push('rgb(' + r + ',' + g + ',' + b + ', 0.5)');
    }
    return arrayColors;
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-20
    * Metodo que valida si hay o no contenido dentro de los resultados de la IA.
    * @param content Contenido del analisis.
  */
  validateContent(content: string | string[]): string {
    if (typeof content === 'string') return (content !== null || content !== '') ? content : 'N/R';
    if (Array.isArray(content)) return (content.length > 0) ? content.join(', ') : 'N/R';
    return 'N/R';
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-11-20
    * Metodo que imprime el contenido de la modal y lo genera en un PDF.
  */
  onClickPrintPdf(): void {
    this.accordion.openAll();
    const content = this.container.nativeElement;
    const options = PDF_OPTIONS;
    const testPDF = html2pdf().from(content).set(options).toContainer().toCanvas().toImg().toPdf();
    testPDF.get('pdf').then(function (pdf) {
      const totalHeight = content.scrollHeight * 0.264583 + 2 * 13;
      pdf.internal.pageSize = { width: 430, height: totalHeight };
    });
    testPDF.save();
  }
}