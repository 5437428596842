import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { EmailService } from '../../services/email.service';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { KEY_ADMIN, KEY_SUPERVISOR } from '../../constantes/keys-roles';
import { Location } from '@angular/common';

@Component({
  selector: 'app-assign-email-user',
  templateUrl: './assign-email-user.component.html',
  styleUrls: ['./assign-email-user.component.css']
})
export class AssignEmailUserComponent implements OnInit {
  adviserSelected: number = null;
  emailConfigSelected: number = null;
  advisersToRolManagerEmail: any[] = [];
  baseFirstList: any[] = [];
  baseSecondList: any[] = [];
  firstList: any[] = [];
  secondList: any[] = [];
  emailsConfig: any[] = [];
  userRol: string = '';
  keyAdmin: string = KEY_ADMIN;

  constructor(
    private alertService: AlertsService,
    private emailService: EmailService,
    private matSnackBar: MatSnackBar,
    private authService: AuthService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getEmailsConfigByRol();
  }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que filtra las listas a traves de una cadena de texto.
    * @param $event Evento emitido por el componente hijo.  
  */
  runFilterList($event: any): void {
    if ($event.name === 'FIRST') {
      if ($event.value !== '') this.firstList = this.baseFirstList.filter(item => item.name.toLowerCase().includes($event.value.toLowerCase()));
      else this.firstList = this.baseFirstList;
    } else {
      if ($event.value !== '') this.secondList = this.baseSecondList.filter(item => item.name.toLowerCase().includes($event.value.toLowerCase()));
      else this.secondList = this.baseSecondList;
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que trae las configuraciones de las listas del componente hijo.
    * @param $event Evento emitido por el componente hijo.  
  */
  saveConfig($event: any): void {
    const dataAssignEmail = {
      id_rrhh: this.adviserSelected,
      data: $event.secondList.map((item: any) => {
        return { id_email: item.id }
      })
    };
    this.alertService.alertWarning('¿Está seguro?', '¿De continuar con la gestión?').then(isConfirm => {
      if (isConfirm.isConfirmed) {
        this.emailService.assignEmailForUser(dataAssignEmail).subscribe(res => {
          this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
            data: {
              title: '¡Excelente!',
              message: res.data
            },
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['green-snackbar']
          });
          this.cancelProcess();
        });
      }
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que emite la cancelacion del proceso. 
  */
  cancelProcess(): void {
    this.location.back();
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-26
    * Metodo que setea los items entre las listas. 
    * @param $event Evento emitido por el componente hijo.   
  */
  updateList($event: any): void {
    if ($event.name === 'FIRST') {
      $event.list.forEach((itemList: any) => {
        this.secondList.push(itemList);
        this.firstList = this.firstList.filter(item => itemList.id !== item.id);
        this.baseFirstList = this.baseFirstList.filter(item => itemList.id !== item.id);
      });
    } else {
      $event.list.forEach((itemList: any) => {
        this.firstList.push(itemList);
        this.secondList = this.secondList.filter(item => itemList.id !== item.id);
        this.baseSecondList = this.baseSecondList.filter(item => itemList.id !== item.id);
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-27
    * Metodo que trae las bandejas ya asignadas del usuario seleccionado en el select.
    * @param $event Evento emitido por el select del formulario.  
  */
  onChangeSelectedAdviser($event: MatSelectChange): void {
    this.emailService.getConfigEmailByUser($event.value, null, 'false').subscribe(resByUser => {
      this.baseSecondList = resByUser.data.map((item: any) => { return { id: item.id_email, name: item.user_name_email_entrada } });
      this.secondList = this.baseSecondList;
      this.baseFirstList = this.emailsConfig;
      this.baseSecondList.forEach(itemSecondList => {
        this.baseFirstList = this.baseFirstList.filter(itemFirstList => itemSecondList.id !== itemFirstList.id);
      });
      this.firstList = this.baseFirstList;
    });
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que consulta las cuentas de correo dependiendo el rol del usuario. 
  */
  getEmailsConfigByRol(): void {
    const userInfo = this.authService.decryptToken();
    this.userRol = userInfo.roles.filter((item: any) => item === KEY_ADMIN || item === KEY_SUPERVISOR)[0];
    if (this.userRol === KEY_ADMIN) {
      this.emailService.getEmailsConfig().subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id, name: item.user_name_email_entrada };
        });
      });
    } else {
      const idRrhh = userInfo.rrhh_id;
      this.emailService.getConfigEmailByUser(idRrhh, null, 'false').subscribe(res => {
        this.emailsConfig = res.data.map((item: any) => {
          return { id: item.id_email, name: item.user_name_email_entrada };
        });
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-11-02
    * Metodo que trae los agentes asociados a una cuenta de correo seleccionado. 
    * @param $event Evento emitido por el campo.   
  */
  onChangeSelectAccountEmail($event: MatSelectChange): void {
    this.emailConfigSelected = $event.value;
    if (this.emailConfigSelected !== 0) {
      this.emailService.getConfigEmailByUser(null, this.emailConfigSelected, 'false').subscribe(res => {
        this.advisersToRolManagerEmail = res.data;
      });
    } else {
      this.emailService.getAdviserByRolManagerEmail().subscribe(res => {
        this.advisersToRolManagerEmail = res.data.map((item: any) => {
          return { id_rrhh: item.rrhh_id, name_user_asignado: item.name };
        });
      });
    }
  }
}