import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import * as html2pdf from 'html2pdf.js';
import { ResultService } from '../../../services/result.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-analytics-voice-general-modal',
  templateUrl: './analytics-voice-general-modal.component.html',
  styleUrls: ['./analytics-voice-general-modal.component.sass']
})
export class AnalyticsVoiceGeneralComponent implements OnInit {
  
  @ViewChild('containerViewReport') containerViewReport: ElementRef;
  @ViewChild('analisisTexto') analisisTexto: MatExpansionPanel;
  @ViewChild('analisisTono') analisisTono: MatExpansionPanel;

  view: any[] = [700, 400];  // Tamaño de la gráfica
  showLegend = true;
  showLabels = true;
  isDoughnut = false;
  gradient = false;
  colorScheme = {
    domain: ['#E57373', '#FFF176', '#B0BEC5', '#1565C0']
  };
  colorScheme2 = {
    domain: ['#1565C0', '#B0BEC5', '#FFF176']
  };
  sentimientosData: { name: string; value: number; }[];
  emocionesData: { name: string; value: number; }[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private resultService: ResultService
  ) { }

  ngOnInit(): void {
    console.log ('data enviar', this.data);
    this.getResults();
  }

  /**
   * Metodo obtener la data para establecer los segmentos de los tiempos muertos por campaña
   * @author Yeison Sepulveda
   * @createdate 27/02/2024
  */

  getResults(): void {
    this.resultService.massiveAnalyticsResults(this.data).subscribe(
      (response) => {
        console.log('resultado', response);
        if (response && response.length > 0) {
          const result = response[0];
  
          //convertir resultados setimientos en porcentajes
          const sentimientos = result.sentimientos;
          const totalSentimientos = 
            parseFloat(sentimientos.pro_neg) + 
            parseFloat(sentimientos.pro_neu) + 
            parseFloat(sentimientos.pro_pos);
          this.sentimientosData = [
            { name: 'Negativo', value: (parseFloat(sentimientos.pro_neg) / totalSentimientos) * 100 },
            { name: 'Neutral', value: (parseFloat(sentimientos.pro_neu) / totalSentimientos) * 100 },
            { name: 'Positivo', value: (parseFloat(sentimientos.pro_pos) / totalSentimientos) * 100 }
          ];
  
          //convertir emociones en porcentajes
          const emociones = result.emociones;
          const totalEmociones = 
            parseFloat(emociones.pro_ang) + 
            parseFloat(emociones.pro_hap) + 
            parseFloat(emociones.pro_neu) + 
            parseFloat(emociones.pro_sad);
          this.emocionesData = [
            { name: 'Enojo', value: (parseFloat(emociones.pro_ang) / totalEmociones) * 100 },
            { name: 'Felicidad', value: (parseFloat(emociones.pro_hap) / totalEmociones) * 100 },
            { name: 'Neutral', value: (parseFloat(emociones.pro_neu) / totalEmociones) * 100 },
            { name: 'Tristeza', value: (parseFloat(emociones.pro_sad) / totalEmociones) * 100 }
          ];
        }
      },
      (error) => {
        console.error('Error al recuperar resultado:', error.message);
        Swal.fire({
          title: 'Error al recuperar la resultado',
          text: error.message,
          icon: 'error',
          confirmButtonText: '¡Entendido!',
          confirmButtonColor: '#2CABBC',
        });
      }
    );
  }

  /**
   * Método asignar valor del label a la grafica de torta
   * @author Yeison Sepulveda
   * @createdate 2024-07-17
   */

  pieChartLabel(series: any[], name: string): string {
    const item = series.find(data => data.name === name);
    
    const value = item.value;
    const formattedValue = value % 1 !== 0 ? value.toFixed(2) : value.toString();
    return `${formattedValue}%`;
  }

  /**
   * Metodo generar el nombre del documento a descargar
   * @author Yeison Sepulveda
   * @createdate 2024-0-03
   * @return nombre del archivo
  */
  generateFilename(): string {
    const currentDate = new Date().toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '');
  
    const currentTime = new Date().toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).replace(/:/g, '');
  
    const dateTimeString = `${currentDate}${currentTime}`;
    return `reporte_sentimientos__${dateTimeString}.pdf`;
  }

  /**
   * Metodo descarga e imprime pantallazo del modal
   * @update Yeison Sepulveda
   * @updatedate 29-05-2024
  */
  async onClickPrintPdf() {
    this.analisisTexto.open();
    this.analisisTono.open();
    await new Promise(resolve => setTimeout(resolve, 1000));
    const content = this.containerViewReport.nativeElement;

    const filename = this.generateFilename();
    const margin = 13;

    const options = {
        margin: margin,
        filename: filename,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
            scale: 2,
            useCORS: true,
            logging: true
        },
        jsPDF: {
            unit: 'mm',
            format: 'tabloid',
            orientation: 'portrait'
        },
        pagebreak: {
            mode: ['avoid-all', 'css', 'legacy']
        }
    };

    const testPDF = html2pdf().from(content).set(options).toContainer().toCanvas().toImg().toPdf(); 
    testPDF.get('pdf').then(function (pdf) {
        const totalHeight = content.scrollHeight * 0.264583 + 2 * margin; 
        pdf.internal.pageSize = { width: 430, height: totalHeight }; 
    });

    testPDF.save()
  }

}
