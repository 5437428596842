import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { GESTIONADOR_CORREOS } = environment;

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private httpClient: HttpClient) { }

  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que retorna los errores generados por HTTP. 
    * @param error Instancia del httpErrorResponse.  
  */
  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que retorna las categorias registradas en el sistema.
    * @param paginator Paginador configurado en la vista. 
    * @param filter Filtro configurado en la vista. 
    * @param showAllCategories Muestra o no todas las categorias activas.  
    * @param filterEmail Filtro de cuenta de correo.
  */
  getCategories(paginator: any = null, filter: string = '', showAllCategories: boolean = false, filterEmail: number = 0): Observable<any> {
    const paramState = showAllCategories ? '' : 'state=1';
    const paramEmail = filterEmail !== 0 ? `&email_list=[${filterEmail}]` : '';
    const url = paginator === null ? `${GESTIONADOR_CORREOS}category/showall?${paramState}` : `${GESTIONADOR_CORREOS}category/showall?page=${(paginator.pageIndex + 1)}&perPage=${paginator.pageSize}&name=${filter}${paramEmail}`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-13
    * Metodo que guardar o actualiza una categoria en el sistema. 
    * @param category Informacion proveniente del formulario.  
  */
  storeCategory(category: any): Observable<any> {
    const url = category.id === null ? `${GESTIONADOR_CORREOS}category/store` : `${GESTIONADOR_CORREOS}category/update/${category.id}`;
    return this.httpClient.post(url, category).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * @author Fabian Duran
    * @createdate 2023-07-31
    * Metodo que trae las categorias por id de configuracion del correo. 
    * @param idEmailConfig Correo seleccionado de la lista de configuracion de correos.  
  */
  getCategoriesByIdEmailConfig(idEmailConfig: string): Observable<any> {
    return this.httpClient.get(`${GESTIONADOR_CORREOS}category/showall?email_list=[${idEmailConfig}]&state=1`).pipe(
      catchError(this.handleError)
    );
  }
}
